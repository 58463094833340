import { Modal, notification } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { SalesOrders } from '../../services/api/sales-orders'
import { orderStatuses } from '../../utils/constants'
import Input from '../input'

const UpdateOrderShippingDateModal = ({
	visible,
	onCancel,
	onComplete,
	salesOrderIds = []
}) => {
	const [date, setDate] = useState()
	const [salesOrders, setSalesOrders] = useState([])
	const [isUpdatingShippingDate, setIsUpdatingShippingDate] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		getSalesOrders()
	}, [salesOrderIds])

	const getSalesOrders = async () => {
		try {
			setIsLoading(true)
			const { data } = await SalesOrders.multiple(salesOrderIds)
			setSalesOrders(data.filter(salesOrder => {
				const status = salesOrder.status.toLowerCase()
				return status !== orderStatuses.SALES_ORDER_SHIPPED_STATUS &&
				status !== orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS &&
				status !== orderStatuses.SALES_ORDER_COMPLETED_STATUS &&
				status !== orderStatuses.SALES_ORDER_CANCELLED_STATUS
			}))
		} catch (e) {
			notification.error({
				message: 'Unable to get sales orders',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const onOk = () => {
		Modal.confirm({
			title: 'Update Shipping Dates',
			content: `Are you sure you want to update the shipping date of ${salesOrders.length} order(s)? ${salesOrders.length < salesOrderIds.length ? 'Cannot update certain orders as their statuses are invalid.' : ''}`,
			closable: true,
			maskClosable: true,
			onOk () {
				onUpdate()
			},
			okText: 'Confirm',
			cancelText: 'Cancel'
		})
	}

	const onUpdate = async () => {
		try {
			setIsUpdatingShippingDate(true)
			const orders = salesOrders.map(salesOrder => ({
				id: salesOrder.id,
				orderDate: date
			}))
			const { data } = await SalesOrders.updateOrders(orders)
			if (data.errors.length > 0) {
				throw new Error([...new Set(data.errors)].toString())
			}
			notification.success({
				message: 'Shipping Dates Updated',
				description: `Successfully updated order shipping dates to: ${moment(date).format('MMM D, YYYY')}.`,
				placement: 'bottomLeft'
			})
			onComplete()
		} catch (e) {
			notification.error({
				message: 'Unable to Update Shipping Dates',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingShippingDate(false)
		}
	}

	return (
		<Modal
			title='Update Shipping Date'
			visible={visible}
			onCancel={onCancel}
			onOk={onOk}
			okButtonProps={{
				loading: isUpdatingShippingDate || isLoading,
				disabled: !date || salesOrders.length === 0
			}}
			okText='Update'
			width={420}
		>
			<Input
				title='Shipping Date'
				type='date'
				tooltip='Update the shipping date of the selected orders.'
				style={{ background: 'white' }}
				value={date ? moment(date) : null}
				onChange={(date) => setDate(date?.toDate())}
			/>
		</Modal>
	)
}

export default UpdateOrderShippingDateModal
