import { TransportQuotations } from '../../services/api/transport-quotations'
import {
	ADD_TRANSPORT_QUOTATION_SUCCESS,
	FETCH_TRANSPORT_QUOTATIONS_SUCCESS,
	FETCH_TRANSPORT_QUOTATION_DETAILS_SUCCESS,
	UPDATE_TRANSPORT_QUOTATION_SUCCESS
} from './action-types'

export function fetchTransportQuotations ({
	page,
	searchTerm
}) {
	return async (dispatch) => {
		const response = await TransportQuotations.index({
			page,
			searchTerm
		})
		dispatch({ type: FETCH_TRANSPORT_QUOTATIONS_SUCCESS, payload: response.data })
	}
}

export function addTransportQuotation (params) {
	return async (dispatch) => {
		const response = await TransportQuotations.create(params)
		dispatch({ type: ADD_TRANSPORT_QUOTATION_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function fetchTransportQuotationDetails (id) {
	return async (dispatch) => {
		const response = await TransportQuotations.single(id)
		dispatch({ type: FETCH_TRANSPORT_QUOTATION_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateTransportQuotation (id, params) {
	return async (dispatch) => {
		const response = await TransportQuotations.update(id, params)
		dispatch({ type: UPDATE_TRANSPORT_QUOTATION_SUCCESS, payload: response.data })
	}
}
