import { DeliveryPartners } from '../../services/api/delivery-partner'
import {
	FETCH_PARTNERS_SUCCESS,
	ADD_PARTNER_SUCCESS,
	UPDATE_PARTNER_SUCCESS,
	FETCH_PARTNER_DETAILS_SUCCESS,
	FETCH_PARTNER_METRICS_SUCCESS
} from './action-types'

export function fetchDeliveryPartners ({
	searchTerm,
	partnerId,
	warehouseId,
	active,
	creationFromDate,
	creationToDate,
	page,
	deleted
} = {}) {
	return async (dispatch) => {
		const response = await DeliveryPartners.index({
			searchTerm,
			partnerId,
			warehouseId,
			active,
			creationFromDate,
			creationToDate,
			page,
			deleted
		})
		dispatch({ type: FETCH_PARTNERS_SUCCESS, payload: { data: response.data, deleted } })
	}
}

export function fetchDeliveryPartnerMetrics () {
	return async (dispatch) => {
		const response = await DeliveryPartners.metrics()
		dispatch({ type: FETCH_PARTNER_METRICS_SUCCESS, payload: response.data })
	}
}

export function addDeliveryPartner (params) {
	return async (dispatch) => {
		const response = await DeliveryPartners.create(params)
		dispatch({ type: ADD_PARTNER_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function updateDeliveryPartner (id, params) {
	return async (dispatch) => {
		const response = await DeliveryPartners.update(id, params)
		dispatch({ type: UPDATE_PARTNER_SUCCESS, payload: response.data })
	}
}

export function fetchDeliveryPartnerDetails (id) {
	return async (dispatch) => {
		const response = await DeliveryPartners.single(id)
		dispatch({ type: FETCH_PARTNER_DETAILS_SUCCESS, payload: response.data })
	}
}
