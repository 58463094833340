import { Vehicles } from '../../services/api/vehicles'
import {
	CREATE_VEHICLE_REFUEL_SUCCESS,
	CREATE_VEHICLE_SUCCESS,
	FETCH_MORE_VEHICLE_TRIPS_SUCCESS,
	FETCH_TRACKER_TYPES_SUCCESS,
	FETCH_VEHICLES_SUCCESS,
	FETCH_VEHICLE_DETAIL_SUCCESS,
	FETCH_VEHICLE_METRICS_SUCCESS,
	FETCH_VEHICLE_REFUELS_SUCCESS,
	FETCH_VEHICLE_REFUEL_DETAIL_SUCCESS,
	FETCH_VEHICLE_TRIPS_SUCCESS,
	FETCH_VEHICLE_TYPES_SUCCESS,
	UPDATE_VEHICLE_REFUEL_SUCCESS,
	UPDATE_VEHICLE_SUCCESS
} from './action-types'

export function fetchVehicles (
	{
		page = 0,
		toDate,
		fromDate,
		searchTerm,
		statuses,
		unassigned,
		type,
		deliveryPlanItemId,
		transportVendorId,
		locationId,
		driver,
		helper,
		trips,
		available
	} = {}
) {
	return async (dispatch) => {
		const response = await Vehicles.index({
			page,
			toDate,
			fromDate,
			searchTerm,
			statuses,
			unassigned,
			type,
			deliveryPlanItemId,
			transportVendorId,
			locationId,
			driver,
			helper,
			trips,
			available
		})
		dispatch({
			type: FETCH_VEHICLES_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function fetchVehicleTypes () {
	return async (dispatch) => {
		const response = await Vehicles.fetchVehicleTypes()
		dispatch({
			type: FETCH_VEHICLE_TYPES_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function fetchTrackerTypes () {
	return async (dispatch) => {
		const response = await Vehicles.fetchTrackerTypes()
		dispatch({
			type: FETCH_TRACKER_TYPES_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function fetchVehicleDetail (vehicleId) {
	return async (dispatch) => {
		const response = await Vehicles.single(vehicleId)
		dispatch({
			type: FETCH_VEHICLE_DETAIL_SUCCESS,
			payload: { data: response.data, id: vehicleId }
		})
	}
}

export function createVehicleRefuel (vehicleId, refuelData) {
	return async (dispatch) => {
		const response = await Vehicles.createRefuel(vehicleId, refuelData)
		dispatch({
			type: CREATE_VEHICLE_REFUEL_SUCCESS,
			payload: { data: response.data }
		})
		return response.data
	}
}

export function updateVehicleRefuel (vehicleId, refuelId, refuelData) {
	return async (dispatch) => {
		const response = await Vehicles.updateRefuelDetail(vehicleId, refuelId, refuelData)
		dispatch({
			type: UPDATE_VEHICLE_REFUEL_SUCCESS,
			payload: response.data
		})
		return response.data
	}
}

export function fetchVehicleRefuels (vehicleId, params = {}) {
	return async (dispatch) => {
		const response = await Vehicles.fetchRefuels(vehicleId, params)
		dispatch({
			type: FETCH_VEHICLE_REFUELS_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function fetchVehicleRefuelDetail (vehicleId, refuelId) {
	return async (dispatch) => {
		const response = await Vehicles.fetchRefuelDetail(vehicleId, refuelId)
		dispatch({
			type: FETCH_VEHICLE_REFUEL_DETAIL_SUCCESS,
			payload: { data: response.data, id: refuelId }
		})
	}
}

export function fetchVehicleTrips (vehicleId, params = {}) {
	return async (dispatch) => {
		const response = await Vehicles.fetchVehicleTrips(vehicleId, params)
		dispatch({
			type: params.page > 0 ? FETCH_MORE_VEHICLE_TRIPS_SUCCESS : FETCH_VEHICLE_TRIPS_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function createVehicle (vehicleData) {
	return async (dispatch) => {
		const response = await Vehicles.create(vehicleData)
		dispatch({
			type: CREATE_VEHICLE_SUCCESS,
			payload: { data: response.data }
		})
		return response.data
	}
}

export function updateVehicle (id, vehicleData) {
	return async (dispatch) => {
		const response = await Vehicles.update(id, vehicleData)
		dispatch({
			type: UPDATE_VEHICLE_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function fetchVehicleMetrics () {
	return async (dispatch) => {
		const response = await Vehicles.getMetrics()
		dispatch({ type: FETCH_VEHICLE_METRICS_SUCCESS, payload: response.data })
	}
}
