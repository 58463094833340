require('./CustomSearchableSelectSecondary.less')
import { Empty, Select, Spin } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './CustomSearchableSelectSecondary.module.css'
import { LoadingOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'

const CustomSearchableSelectSecondary = ({
	containerStyle,
	style,
	value,
	onChange,
	onChangeData,
	onClear,
	placeholder,
	searchTask, // Should be a function that takes in a search constraint parameter and returns a Promise that resolves to an array of results
	labelIndex, // The field that should be the label when creating options asynchronously
	valueIndex, // The field that should be the value when creating options asynchronously
	descriptionIndex, // The field that should be the description value when creating options
	disabledOptions, // Map of options to disable.
	defaultOptions,
	dropdownConfig,
	isLoading,
	disabled,
	showSearch,
	labelInValue,
	allowClear,
	customLabel, // A function in the form: (data) => {}, that can be used to formulate a label for the option.
	customDescription, // A function in the form: (data) => {}, that can be used to formulate a description for the option.
	popupContainer,
	notFoundContent,
	className,
	renderOption
}) => {
	const [loading, setLoading] = useState(isLoading ? isLoading : false)
	const [options, setOptions] = useState([])
	const [searchTerm, setSearchTerm] = useState()
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const selectRef = useRef(null)

	useEffect(() => {
		if (!notFoundContent) {
			return
		}
		const updateDropdownState = ({ target }) => !(target.closest('.ant-select') || target.closest('.ant-select-dropdown'))
			&& setIsDropdownOpen(false)
		window.addEventListener('click', updateDropdownState)
		return () => window.removeEventListener('click', updateDropdownState)
	}, [])

	useEffect(() => {
		setLoading(isLoading)
	}, [isLoading])

	useEffect(() => {
		if (defaultOptions) {
			const options = mapOptions(defaultOptions)
			setOptions(options)
		}
	}, [defaultOptions])

	const fetchRef = useRef(0)
	const debounceSearch = useMemo(() => {
		const loadOptions = searchParam => {
			fetchRef.current += 1
			const fetchId = fetchRef.current
			setOptions([])
			setLoading(true)
			setSearchTerm(searchParam)
			searchTask(searchParam)
				.then(response => {
					if (fetchId !== fetchRef.current) {
						return
					}
					const { data } = response
					const results = Array.isArray(data) ? data : data.results
					if (results.length) {
						const newOptions = mapOptions(results)
						setOptions(newOptions)
					} else {
						setOptions([])
					}
				})
				.finally(() => setLoading(false))
		}
		return debounce(loadOptions, 800)
	})

	const mapOptions = data => {
		return data.map(result => {
			let label = result[labelIndex]
			if (customLabel) {
				label = customLabel(result)
			}
			let value = result[valueIndex]
			let description = result[descriptionIndex]
			if (customDescription) {
				description = customDescription(result)
			}
			return {
				label,
				value,
				data: result,
				disabled: disabledOptions[value],
				description
			}
		})
	}

	const handleChange = value => {
		const option = options.find(option => option.value === value?.value)
		if (!option || !option.data) {
			return onChange(null)
		}
		const { data } = option
		const label = data.label || data[labelIndex] || data[descriptionIndex] || customLabel?.(data)
		onChange({ ...value, label, data })
		if (option) {
			onChangeData(option.data)
		}
	}

	const renderDropdown = () => {
		return (
			<div className={styles.customSearchableSelectDropdown}>
				{
					loading &&
					<div className={styles.customSearchableSelectDropdownSpinner}>
						<Spin size='small' />
					</div>
				}
				{!loading && !options.length && notFoundContent?.(setIsDropdownOpen, searchTerm)}
				{
					options.map((option) => {
						return (
							<div
								key={option.value}
								className={`${styles.customSearchableSelectDropdownOption} 
									${value?.value === option.value && styles.customSearchableSelectDropdownOptionActive}`}
								onClick={() => {
									setIsDropdownOpen(false)
									selectRef.current.blur()
									handleChange(option)
								}}
							>
								{
									dropdownConfig.map((property) => <p key={property}>{option.data[property]}</p>)
								}
							</div>
						)
					})
				}
			</div>
		)
	}

	return (
		<div style={containerStyle} className={`${styles.customSearchableSelectSecondary} custom-searchable-select-secondary`}>
			{
				isLoading &&
                <div className={styles.loadingSpinner}>
                	<Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
                </div>
			}
			<Select
				style={style}
				className={className}
				allowClear={allowClear}
				showSearch={showSearch}
				labelInValue={labelInValue}
				filterOption={false}
				showArrow={false}
				placeholder={placeholder}
				onSearch={showSearch ? debounceSearch : undefined}
				onClear={onClear}
				disabled={disabled}
				dropdownRender={dropdownConfig ? renderDropdown : null}
				notFoundContent={
					loading ? <Spin size='small' /> :
						notFoundContent?.() || <Empty />
				}
				value={value}
				onChange={handleChange}
				onFocus={() => setIsDropdownOpen(true)}
				ref={selectRef}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
				{...(notFoundContent ? { open: isDropdownOpen } : {})}
			>
				{
					options.map(option => {
						return (
							<Select.Option
								key={option.value}
								value={option.value}
								disabled={disabledOptions[option.value]}
							>
								<div className={styles.option}>
									{
										renderOption ?
											renderOption(option) :
											<>
												<p className={styles.label}>{option.label}</p>
												<p className={styles.description}>{option.description}</p>
											</>
									}
								</div>
							</Select.Option>
						)
					})
				}
			</Select>
		</div>
	)
}

CustomSearchableSelectSecondary.propTypes = {
	containerStyle: PropTypes.object,
	style: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]))]),
	onChange: PropTypes.func,
	onChangeData: PropTypes.func,
	onClear: PropTypes.func,
	placeholder: PropTypes.node,
	searchTask: PropTypes.func,
	labelIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	valueIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	descriptionIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	disabledOptions: PropTypes.object,
	defaultOptions: PropTypes.arrayOf(PropTypes.object),
	dropdownConfig: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	showSearch: PropTypes.bool,
	labelInValue: PropTypes.bool,
	allowClear: PropTypes.bool,
	customLabel: PropTypes.func,
	customDescription: PropTypes.func,
	popupContainer: PropTypes.func,
	notFoundContent: PropTypes.func,
	className: PropTypes.string,
	renderOption: PropTypes.func
}

CustomSearchableSelectSecondary.defaultProps = {
	onChange: () => {},
	onChangeData: () => {},
	descriptionIndex: -1,
	disabledOptions: {},
	showSearch: true,
	labelInValue: true,
	allowClear: false,
	className: ''
}

export default CustomSearchableSelectSecondary
