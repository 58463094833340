import { useEffect, useRef } from 'react'

export default function useInterval (callback, interval) {
	const savedCallback = useRef()
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		const tick = () => savedCallback.current()
		if (interval !== null) {
			const id = setInterval(tick, interval)
			return () => clearInterval(id)
		}
	}, [callback, interval])
}
