import { Button, notification, Modal, Collapse, Steps, Form, Row, Tabs, ConfigProvider, Select as AntSelect } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { Vehicles } from '../../services/api/vehicles'
import { assignVehicleToDeliveryPlanItem } from '../../store/delivery-plans/actions'
import { createVehicle, fetchTrackerTypes, fetchVehicleTypes } from '../../store/vehicles/actions'
import Input from '../../components/input'
import Select from '../../components/custom-select'
import CustomSearchableSelect from '../custom-searchable-select'
import styles from './AssignVehicleModal.module.css'
import CustomPhoneInput from '../phone-input'
import { TransportVendors } from '../../services/api/transport-vendors'
import { fetchTransportVendors } from '../../store/transport-vendors/actions'
import { Users } from '../../services/api/users'
import { claimsRoles, LocationTypes, ThirdPartyLogisticsType } from '../../utils/constants'
import { convertEnumToString } from '../../utils'
import { Locations } from '../../services/api/locations'
import { VEHICLE_ACTIVE_STATUS, getVehicleTypeLabel, fuelTypeOptions, fuelUnitOptions, getFuelUnitLabel } from '../../utils/vehicles'
import { useMemo } from 'react'
import { usePrevious } from '../../hooks/usePrevious'
import { Custom } from '../../services/api/custom'
import NumberInput from '../number-input'
import TextInput from '../text-input'
import { fetchUserProfile } from '../../store/auth/actions'

const { Panel } = Collapse
const { Step } = Steps
const tabValues = {
	INTERNAL_FLEET: 'internal_fleet',
	EXTERNAL_FLEET: 'external_fleet'
}

const tabs = [
	{
		title: 'Internal Fleet',
		value: tabValues.INTERNAL_FLEET
	},
	{
		title: 'External Fleet',
		value: tabValues.EXTERNAL_FLEET
	}
]

const AssignVehicleModal = ({
	visible,
	onCancel,
	onOk,
	deliveryPlanItemId,
	deliveryPlanItemInternalId,
	vehicleType
}) => {
	const dispatch = useDispatch()
	const { companyDetails, userProfile } = useSelector(state => state.authReducer)
	const [isLoadingVehicles, setIsLoadingVehicles] = useState(false)
	const [isAssigningVehicle, setIsAssigningVehicle] = useState(false)
	const [vehicle, setVehicle] = useState()
	const { vehicleTypes, trackerTypes } = useSelector(state => state.vehiclesReducer)
	const { transportVendors } = useSelector(state => state.transportVendorsReducer)
	const [currentStep, setCurrentStep] = useState(0)
	const [brand, setBrand] = useState('')
	const [model, setModel] = useState('')
	const [year, setYear] = useState(null)
	const [type, setType] = useState()
	const [plateNumber, setPlateNumber] = useState('')
	const [manufacturer, setManufacturer] = useState('')
	const [trackerModel, setTrackerModel] = useState()
	const [imei, setImei] = useState('')
	const [simCardNumber, setSimCardNumber] = useState('')
	const [isAddingVehicle, setIsAddingVehicle] = useState(false)
	const [currentTab, setCurrentTab] = useState(tabs[0].value)
	const [isLoading, setIsLoading] = useState(false)
	const [vendorData, setVendorData] = useState()
	const [vendor, setVendor] = useState()
	const [driver, setDriver] = useState(null)
	const [helper, setHelper] = useState(null)
	const [isLoadingDrivers, setIsLoadingDrivers] = useState(false)
	const [isLoadingHelpers, setIsLoadingHelpers] = useState(false)
	const [vendorVehicles, setVendorVehicles] = useState([])
	const [activeVehicles, setActiveVehicles] = useState([])
	const [drivers, setDrivers] = useState([])
	const [helpers, setHelpers] = useState([])
	const [vehicleData, setVehicleData] = useState(null)
	const [location, setLocation] = useState()
	const [locations, setLocations] = useState()
	const [preferredVehicleTypes, setPreferredVehicleTypes] = useState([])
	const [fuelType, setFuelType] = useState(null)
	const [fuelUnit, setFuelUnit] = useState(null)
	const [maxFuelCapacity, setMaxFuelCapacity] = useState(0)
	const [maxWeightCapacity, setMaxWeightCapacity] = useState(0)
	const [maxVolumeCapacity, setMaxVolumeCapacity] = useState(0)
	const [kpl, setKpl] = useState(0)
	const [isLoadingIntegrationData, setIsLoadingIntegrationData] = useState(false)
	const [integrationData, setIntegrationData] = useState({})
	const locationIds = useMemo(() => {
		const locationIds = userProfile ? userProfile.locations.map(({ id }) => id) : undefined
		return locationIds?.length ? locationIds : undefined
	}, [userProfile])

	useEffect(() => {
		fetchActiveVehicles()
		dispatch(fetchVehicleTypes())
		dispatch(fetchTrackerTypes())
	}, [])

	useEffect(() => {
		if (visible) {
			dispatch(fetchUserProfile())
		}
	}, [visible])

	useEffect(() => {
		if (vendor) {
			getTransportVendorVehicles()
		}
	}, [vendor])

	useEffect(() => {
		if (userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setLocations(locations)
				if (locations.length === 1) {
					const location = locations[0]
					const { label, id } = location
					setLocation({ label, value: id, data: location })
				}
			} else {
				fetchLocations()
			}
			fetchDrivers()
			fetchHelpers()
		}
	}, [userProfile])

	useEffect(() => {
		if (vehicleData) {
			if (vehicleData.driver) {
				setDriver({ value: vehicleData.driver.id, label: vehicleData.driver.name })
			} else {
				setDriver(null)
			}
			if (vehicleData.helper) {
				setHelper({ value: vehicleData.helper.id, label: vehicleData.helper.name })
			} else {
				setHelper(null)
			}
		} else {
			setDriver(null)
			setHelper(null)
		}
	}, [vehicleData])

	useEffect(() => {
		if (currentTab === tabValues.EXTERNAL_FLEET) {
			getTransportVendors()
		}
		setVendor(null)
		setVehicle(null)
	}, [currentTab])

	useEffect(() => {
		setVehicle(null)
	}, [vendor])

	useEffect(() => {
		if (companyDetails) {
			setPreferredVehicleTypes(companyDetails.preferredVehicleTypes || [])
		}
	}, [companyDetails])

	const previousVendorData = usePrevious(vendorData)
	useEffect(() => {
		if (!previousVendorData && vendorData) {
			fetchIntegrationData()
		} else if (previousVendorData && vendorData) {
			if (previousVendorData.thirdPartyLogisticsType !== vendorData.thirdPartyLogisticsType) {
				fetchIntegrationData()
			}
		}
	}, [vendorData])

	const modifiedVehicleTypes = useMemo(() => {
		return vehicleTypes.filter(vehicleType => preferredVehicleTypes.includes(vehicleType.value))
	}, [preferredVehicleTypes, vehicleTypes])

	const clearRegistrationForm = () => {
		setBrand('')
		setModel('')
		setYear(null)
		setType()
		setPlateNumber('')
	}

	const clearTrackerForm = () => {
		setManufacturer('')
		setTrackerModel()
		setImei('')
		setSimCardNumber('')
	}

	const clearAdditionalInfoForm = () => {
		setLocation(null)
		setDriver(null)
		setHelper(null)
		setKpl(0)
		setMaxFuelCapacity(0)
		setMaxWeightCapacity(0)
		setMaxVolumeCapacity(0)
		setFuelType(null)
		setFuelUnit(null)
	}

	const fetchDrivers = async () => {
		setIsLoadingDrivers(true)
		const response = await Users.index({
			role: claimsRoles.DRIVER,
			locationIds,
			available: true
		})
		setDrivers(response.data.results)
		setIsLoadingDrivers(false)
	}

	const fetchHelpers = async () => {
		setIsLoadingHelpers(true)
		const response = await Users.index({
			role: claimsRoles.HELPER,
			locationIds,
			available: true
		})
		setHelpers(response.data.results)
		setIsLoadingHelpers(false)
	}

	const searchDrivers = value => {
		return Users.index({
			searchTerm: value,
			role: claimsRoles.DRIVER,
			locationIds,
			available: true
		})
	}

	const searchHelpers = value => {
		return Users.index({
			searchTerm: value,
			role: claimsRoles.HELPER,
			locationIds,
			available: true
		})
	}

	const isRegistrationFormValid = () => {
		if (currentTab === tabValues.EXTERNAL_FLEET) {
			if (!type) {
				return false
			}
			if (!plateNumber || plateNumber.trim().length === 0) {
				return false
			}
		} else {
			if (brand && brand.trim().length === 0) {
				return false
			}
			if (model && model.trim().length === 0) {
				return false
			}
			if (!type) {
				return false
			}
			if (!plateNumber || plateNumber.trim().length === 0) {
				return false
			}
		}
		return true
	}

	const isTrackerFormValid = () => {
		if (!manufacturer || manufacturer.trim().length === 0) {
			return false
		}
		if (!trackerModel) {
			return false
		}
		if (!imei || imei.trim().length === 0) {
			return false
		} else {
			if (imei.length !== 15) {
				return false
			}
		}
		if (!simCardNumber) {
			return false
		} else {
			if (!isValidPhoneNumber(simCardNumber)) {
				return false
			}
		}
		return true
	}

	const fetchLocations = async () => {
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY], page: -1 })
		setLocations(response.data.results)
	}

	const onFinish = async ({ tracker = true, additionalInfo = true }) => {
		setIsAddingVehicle(true)
		try {
			const vehicleData = {
				brand,
				model,
				year: year ? moment(year).format('YYYY') : '',
				type,
				plateNumber
			}
			if (currentTab === tabValues.EXTERNAL_FLEET && !vendor) {
				throw new Error(
					'Could not create vehicle without a transport vendor.'
				)
			}
			if (vendor) {
				vehicleData.transportVendorId = vendor.value
			}
			if (isTrackerFormValid() && tracker) {
				vehicleData.tracker = {
					manufacturer,
					model: trackerModel.value,
					imei,
					simCardNumber
				}
			}
			if (additionalInfo) {
				if (location) {
					vehicleData.locationId = location.value
				}
				if (driver) {
					vehicleData.driverId = driver.value
				}
				if (helper) {
					vehicleData.helperId = helper.value
				}
				if (fuelType) {
					vehicleData.fuelType = fuelType
				}
				if (fuelUnit) {
					vehicleData.fuelUnit = fuelUnit
				}
				if (maxFuelCapacity) {
					vehicleData.maxFuelCapacity = +maxFuelCapacity
				}
				if (maxWeightCapacity) {
					vehicleData.maxWeightCapacity = +maxWeightCapacity
				}
				if (maxVolumeCapacity) {
					vehicleData.maxVolumeCapacity = +maxVolumeCapacity
				}
				if (kpl) {
					vehicleData.kpl = +kpl
				}
			}
			const createdVehicle = await dispatch(createVehicle(vehicleData))
			setVehicle({
				value: createdVehicle.id,
				label: createdVehicle.plateNumber
			})
			clearRegistrationForm()
			clearTrackerForm()
			clearAdditionalInfoForm()
			setCurrentStep(0)
			setVehicleData(createdVehicle)
			notification.success({
				message: 'Vehicle Added',
				description: 'New vehicle successfully added!',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Add Vehicle',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsAddingVehicle(false)
		}
	}

	const fetchActiveVehicles = async () => {
		setIsLoadingVehicles(true)
		const { data } = await Vehicles.index({
			available: true,
			deliveryPlanItemId,
			transportVendorId: vendor?.value,
			driver: true,
			helper: true
		})
		setActiveVehicles(data.results)
		setIsLoadingVehicles(false)
	}

	const getTransportVendors = async () => {
		setIsLoading(true)
		await dispatch(fetchTransportVendors({ page: 0 }))
		setIsLoading(false)
	}

	const searchTransportVendors = value => {
		return TransportVendors.index({ searchTerm: value })
	}

	const searchVehicles = value => {
		return Vehicles.index({
			searchTerm: value,
			available: true,
			deliveryPlanItemId,
			helper: true,
			driver: true
		})
	}

	const searchVendorVehicles = value => {
		return Vehicles.index({
			searchTerm: value,
			available: true,
			deliveryPlanItemId,
			transportVendorId: vendor?.value,
			helper: true,
			driver: true
		})
	}

	const getTransportVendorVehicles = async value => {
		try {
			setIsLoadingVehicles(true)
			const { data } = await Vehicles.index({
				searchTerm: value,
				available: true,
				unassigned: true,
				deliveryPlanItemId,
				transportVendorId: vendor?.value
			})
			setVendorVehicles(data.results)
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Transport Vendor Vehicles',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingVehicles(false)
		}
	}

	const assignVehicle = async () => {
		setIsAssigningVehicle(true)
		try {
			if (vendorData && vendorData.thirdPartyLogisticsType !== ThirdPartyLogisticsType.OTHER) {
				await TransportVendors.update(vendorData.id, {
					type: vendorData.thirdPartyLogisticsType,
					integrationConfig: vendorData.integrationConfig
				})
			}
			await dispatch(assignVehicleToDeliveryPlanItem(deliveryPlanItemId, {
				vehicleId: vehicle.value,
				driverId: driver ? driver.value : null,
				helperId: helper ? helper.value : null
			}))

			onCancel()
			await onOk()
			notification.success({
				message: 'Vehicle Assigned to Delivery Plan',
				description: 'Vehicle has been successfully assigned to a route.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Assign Vehicle to Route',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsAssigningVehicle(false)
		}
	}

	const onVehicleSelected = data => {
		const vehicleOptions = vendor ? vendorVehicles : activeVehicles
		const selectedVehicle = vehicleOptions.find(vehicle => vehicle.id === data.id)
		if (selectedVehicle && selectedVehicle.type !== vehicleType) {
			Modal.confirm({
				title: 'Different Vehicle Type',
				content: <span>Your selected vehicle <i>{getVehicleTypeLabel(selectedVehicle.type)}</i> is not recommended. Do you still want to continue?</span>,
				closable: true,
				maskClosable: true,
				onOk: () => {
					setVehicle({ value: data.id, label: data.plateNumber })
					setVehicleData(data)
				},
				okText: 'Yes',
				cancelText: 'No'
			})
		} else {
			setVehicle({ value: data.id, label: data.plateNumber })
			setVehicleData(data)
		}
	}

	const fetchIntegrationData = async () => {
		setIsLoadingIntegrationData(true)
		switch (vendorData.thirdPartyLogisticsType) {
			case ThirdPartyLogisticsType.GIM: {
				const { data } = await Custom.getGimGoodTypes()
				setIntegrationData({
					goodsTypes: data.data
				})
				break
			}
		}
		setIsLoadingIntegrationData(false)
	}

	const renderCustomVehicleLabel = (data) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div>
					<div>{`${data.plateNumber} (${data.status === VEHICLE_ACTIVE_STATUS ? 'Available' : data.currentTripId ? 'Assigned' : 'Unassigned'})`}</div>
					<div>{data.location ? `${data.location.internalName ? `${data.location.label} (${data.location.internalName})` : data.location.label}` : 'No Base Location'}</div>
				</div>
				<div style={{ marginLeft: 'auto', fontSize: 12 }}>
					{getVehicleTypeLabel(data.type)}
				</div>
			</div>
		)
	}

	const renderCustomUserLabel = (data) => {
		return (
			<div>
				<div>{`${data.name} (${data.lastUnassignedTripId ? 'Unassigned' : 'Available'})`}</div>
			</div>
		)
	}

	const render3plIntegrationConfig = (type) => {
		switch (type) {
			case ThirdPartyLogisticsType.GIM: {
				const goodsTypes = integrationData.goodsTypes || []
				return (
					<div style={{ marginTop: 12 }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h4 style={{ marginBottom: 0, marginRight: 12 }}>
								Goods Type
							</h4>
							<AntSelect
								loading={isLoadingIntegrationData}
								showSearch={true}
								style={{ width: 200 }}
								options={goodsTypes.map(goodType => ({ label: goodType.text, value: `${goodType.id}` }))}
								value={goodsTypes.length > 0 ? vendorData.integrationConfig.goodsType : null}
								onChange={value => setVendorData({
									...vendorData,
									integrationConfig: {
										...vendorData.integrationConfig,
										goodsType: value
									}
								})}
								filterOption={(input, option) => {
									return (
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
									)
								}}
							/>
						</div>
					</div>
				)
			}
			default:
				return null
		}
	}

	const renderModalFooter = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
				<Button
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					type='primary'
					loading={isAssigningVehicle}
					disabled={!vehicle}
					onClick={assignVehicle}
				>
					Assign Vehicle
				</Button>
			</div>
		)
	}

	return (
		<Modal
			title={vehicleType ? <span>{'Assign Vehicle'}<i style={{ margin: '0 8px' }}>{ `(${getVehicleTypeLabel(vehicleType)})`}</i>{deliveryPlanItemInternalId ? ` to ${deliveryPlanItemInternalId}` : ''}</span> : 'Assign Vehicle'}
			visible={visible}
			onCancel={onCancel}
			width={650}
			footer={renderModalFooter()}
		>
			<Tabs
				onChange={setCurrentTab}
			>
				{
					tabs.map(tab => {
						return (
							<Tabs.TabPane
								tab={tab.title}
								key={tab.value}
								onClick={() => {
									setCurrentTab(tab.value)
									if (tab.value === tabValues.EXTERNAL_FLEET) {
										setCurrentStep(0)
										clearAdditionalInfoForm()
									}
								}}
							>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{
										currentTab === tabValues.EXTERNAL_FLEET ?
											<Form.Item>
												<CustomSearchableSelect
													searchTask={searchTransportVendors}
													defaultOptions={transportVendors}
													isLoading={isLoading}
													labelIndex='name'
													valueIndex='id'
													title='Transport Vendor'
													placeholder='Select Transport Vendor'
													onChangeData={data => setVendorData(data)}
													onChange={option => setVendor(option)}
													onClear={() => setVendor(null)}
													value={vendor}
													allowClear={true}
												/>
											</Form.Item> : null
									}
									{
										currentTab === tabValues.EXTERNAL_FLEET && vendorData && vendorData.thirdPartyLogisticsType !== ThirdPartyLogisticsType.OTHER ?
											<Form.Item>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<h4 style={{ marginBottom: 0, marginRight: 12 }}>
														3PL Integration
													</h4>
													<div>
														{convertEnumToString(vendorData.thirdPartyLogisticsType)}
													</div>
												</div>
												{render3plIntegrationConfig(vendorData.thirdPartyLogisticsType)}
											</Form.Item> : null
									}
									{
										currentTab === tabValues.INTERNAL_FLEET ?
											<Form.Item>
												<CustomSearchableSelect
													searchTask={searchVehicles}
													defaultOptions={activeVehicles}
													isLoading={isLoadingVehicles}
													title='Vehicle'
													className='vehicle-select'
													labelIndex='plateNumber'
													valueIndex='id'
													customLabel={renderCustomVehicleLabel}
													placeholder='Select Vehicle'
													onChangeData={onVehicleSelected}
													onClear={() => setVehicle(null)}
													value={vehicle}
													allowClear={true}
													popupContainer={() => document.querySelector('.ant-modal-body')}
												/>
											</Form.Item> :
											vendor &&
											<Form.Item>
												<CustomSearchableSelect
													searchTask={searchVendorVehicles}
													defaultOptions={vendorVehicles}
													isLoading={isLoadingVehicles}
													title='Vehicle'
													className='vehicle-select'
													labelIndex='plateNumber'
													valueIndex='id'
													customLabel={renderCustomVehicleLabel}
													placeholder='Select Vehicle'
													onChangeData={onVehicleSelected}
													onClear={() => setVehicle(null)}
													value={vehicle}
													allowClear={true}
													popupContainer={() => document.querySelector('.ant-modal-body')}
												/>
											</Form.Item>
									}
									{	vehicle && currentTab === tabValues.INTERNAL_FLEET &&
										<>
											<Form.Item>
												<CustomSearchableSelect
													searchTask={searchDrivers}
													defaultOptions={drivers}
													isLoading={isLoadingDrivers}
													labelIndex='name'
													valueIndex='id'
													title='Driver'
													customLabel={renderCustomUserLabel}
													placeholder='Select Driver'
													onChange={option => setDriver(option)}
													onClear={() => setDriver(null)}
													value={driver}
													allowClear={true}
												/>
											</Form.Item>
											<Form.Item>
												<CustomSearchableSelect
													searchTask={searchHelpers}
													defaultOptions={helpers}
													isLoading={isLoadingHelpers}
													labelIndex='name'
													valueIndex='id'
													title='Helper'
													customLabel={renderCustomUserLabel}
													placeholder='Select Helper'
													onChange={option => setHelper(option)}
													onClear={() => setHelper(null)}
													value={helper}
													allowClear={true}
												/>
											</Form.Item>
										</>}
									<Collapse style={{ borderRadius: 0 }}>
										<Panel header='Create New Vehicle'>
											<div className={styles.container}>
												{
													currentTab === tabValues.EXTERNAL_FLEET ?
														<Form
															className={styles.formContainer}
														>
															<Form.Item>
																<TextInput
																	title='Vehicle Brand'
																	value={brand}
																	onChange={e => {
																		setBrand(e.target.value)
																	}}
																/>
															</Form.Item>
															<Form.Item>
																<TextInput
																	title='Vehicle Model'
																	value={model}
																	onChange={e => {
																		setModel(e.target.value)
																	}}
																/>
															</Form.Item>
															<Form.Item>
																<Input
																	onChange={date => {
																		setYear(date?.toDate())
																	}}
																	value={year ? moment(year) : null}
																	title={<span>Year<span className={styles.optional}>(Optional)</span></span>}
																	picker='year'
																	type='date'
																	popupContainer={() => document.querySelector('.ant-modal-body')}
																/>
															</Form.Item>
															<Form.Item>
																<ConfigProvider renderEmpty={() => {
																	return (
																		<div style={{ padding: '10px 5px' }}>
																			No Vehicle Type is selected from Fleet Preference, Please Select Vehicle Type from the Fleet Preference
																		</div>
																	)
																}}
																>
																	<Select
																		onChange={option => setType(option)}
																		value={type}
																		title='Vehicle Type'
																		placeholder='Select Vehicle Type'
																		options={vehicleTypes}
																		popupContainer={() => document.querySelector('.ant-modal-body')}
																	/>
																</ConfigProvider>
															</Form.Item>
															<Form.Item>
																<TextInput
																	title='Vehicle Plate Number'
																	value={plateNumber}
																	onChange={e => {
																		const value = e.target.value || ''
																		setPlateNumber(value.toUpperCase())
																	}}
																/>
															</Form.Item>
															<Form.Item>
																<Row justify='center' style={{ marginTop: 12 }}>
																	<Button
																		style={{ margin: '0 6px' }}
																		onClick={clearRegistrationForm}
																	>
																		Clear
																	</Button>
																	<Button
																		type='primary'
																		disabled={!isRegistrationFormValid()}
																		style={{ margin: '0 6px' }}
																		onClick={() => onFinish({ tracker: false, additionalInfo: false })}
																	>
																		Create
																	</Button>
																</Row>
															</Form.Item>
														</Form>
														:
														<>
															<Steps current={currentStep} className={styles.progressContainer}>
																<Step title='Registration' />
																<Step title='Tracker Link' />
																<Step title='Additional Info' />
															</Steps>
															{
																currentStep === 0 ?
																	<Form
																		className={styles.formContainer}
																	>
																		<Form.Item>
																			<TextInput
																				title='Vehicle Brand'
																				value={brand}
																				onChange={e => {
																					setBrand(e.target.value)
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<TextInput
																				title='Vehicle Model'
																				value={model}
																				onChange={e => {
																					setModel(e.target.value)
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<Input
																				onChange={date => {
																					setYear(date?.toDate())
																				}}
																				value={year ? moment(year) : null}
																				title={<span>Year<span className={styles.optional}>(Optional)</span></span>}
																				picker='year'
																				type='date'
																				popupContainer={() => document.querySelector('.ant-modal-body')}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<ConfigProvider renderEmpty={() => {
																				return (
																					<div style={{ padding: '10px 5px' }}>
																						No Vehicle Type is selected from Fleet Preference, Please Select Vehicle Type from the Fleet Preference
																					</div>
																				)
																			}}
																			>
																				<Select
																					onChange={option => setType(option)}
																					value={type}
																					title='Vehicle Type'
																					placeholder='Select Vehicle Type'
																					options={modifiedVehicleTypes}
																					popupContainer={() => document.querySelector('.ant-modal-body')}
																				/>
																			</ConfigProvider>
																		</Form.Item>
																		<Form.Item>
																			<TextInput
																				title='Vehicle Plate Number'
																				value={plateNumber}
																				onChange={e => {
																					const value = e.target.value || ''
																					setPlateNumber(value.toUpperCase())
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<Row justify='center' style={{ marginTop: 12 }}>
																				<Button
																					style={{ margin: '0 6px' }}
																					onClick={clearRegistrationForm}
																				>
																					Clear
																				</Button>
																				{
																					isRegistrationFormValid() ?
																						<Button
																							type='primary'
																							disabled={!isRegistrationFormValid()}
																							style={{ margin: '0 6px' }}
																							onClick={() => {
																								onFinish({ tracker: false, additionalInfo: false })
																							}}
																						>
																							{
																								currentTab === tabValues.INTERNAL_FLEET ?
																									'Create Without Tracker And Additional Info' : 'Create Without Tracker'
																							}
																						</Button> : null
																				}
																				<Button
																					type='primary'
																					disabled={!isRegistrationFormValid()}
																					style={{ margin: '0 6px' }}
																					onClick={() => setCurrentStep(1)}
																				>
																					Continue
																				</Button>
																			</Row>
																		</Form.Item>
																	</Form> : null
															}
															{
																currentStep === 1 ?
																	<Form
																		className={styles.formContainer}
																	>
																		<Form.Item>
																			<TextInput
																				title='Manufacturer'
																				value={manufacturer}
																				onChange={e => {
																					setManufacturer(e.target.value)
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<Select
																				labelInValue
																				onChange={option => setTrackerModel(option)}
																				value={trackerModel}
																				title='Model'
																				placeholder='Select Model'
																				options={trackerTypes}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<TextInput
																				title='IMEI'
																				value={imei}
																				onChange={e => {
																					setImei(e.target.value)
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<CustomPhoneInput
																				title='SIM Card Number'
																				value={simCardNumber}
																				onChange={setSimCardNumber}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<Row justify='center' style={{ marginTop: 24 }}>
																				<Button
																					style={{ margin: '0 6px' }}
																					onClick={clearTrackerForm}
																				>
																					Clear
																				</Button>
																				<Button
																					style={{ margin: '0 6px' }}
																					onClick={() => setCurrentStep(0)}
																				>
																					Previous
																				</Button>
																				{
																					isTrackerFormValid() && currentTab === tabValues.INTERNAL_FLEET ?
																						<Button
																							type='primary'
																							disabled={!isTrackerFormValid()}
																							style={{ margin: '0 6px' }}
																							onClick={() => onFinish({ additionalInfo: false })}
																						>
																							Create
																						</Button> : null
																				}
																				{
																					currentTab === tabValues.INTERNAL_FLEET &&
																		<Button
																			type='primary'
																			style={{ margin: '0 6px' }}
																			onClick={() => {
																				clearTrackerForm()
																				setCurrentStep(2)
																			}}
																		>
																			Skip
																		</Button>
																				}
																				<Button
																					type='primary'
																					disabled={!isTrackerFormValid()}
																					style={{ margin: '0 6px' }}
																					loading={isAddingVehicle}
																					onClick={() => {
																						if (currentTab === tabValues.INTERNAL_FLEET) {
																							setCurrentStep(2)
																						} else {
																							onFinish({ additionalInfo: false })
																						}
																					}}
																				>
																					{
																						currentTab === tabValues.INTERNAL_FLEET ? 'Continue' : 'Finish'
																					}
																				</Button>
																			</Row>
																		</Form.Item>
																	</Form> : null
															}
															{
																currentStep === 2 ?
																	<Form
																		className={styles.formContainer}
																	>
																		<Form.Item>
																			<CustomSearchableSelect
																				title={<span>Base Location<span className={styles.optional}>(Optional)</span></span>}
																				defaultOptions={locations}
																				customLabel={(data) => data.label ? data.label : convertEnumToString(data.type.toLowerCase())}
																				valueIndex='id'
																				descriptionIndex='address'
																				placeholder='Select Base Location'
																				onChange={setLocation}
																				onClear={() => setLocation(null)}
																				value={location}
																				unclipOptions={true}
																				showSearch={false}
																				allowClear
																			/>
																		</Form.Item>
																		<Form.Item>
																			<CustomSearchableSelect
																				title={<span>Driver<span className={styles.optional}>(Optional)</span></span>}
																				searchTask={searchDrivers}
																				defaultOptions={drivers}
																				isLoading={isLoadingDrivers}
																				labelIndex='name'
																				valueIndex='id'
																				placeholder='Select Driver'
																				onChange={option => setDriver(option)}
																				onClear={() => setDriver(null)}
																				value={driver}
																				allowClear
																			/>
																		</Form.Item>
																		<Form.Item>
																			<CustomSearchableSelect
																				title={<span>Helper<span className={styles.optional}>(Optional)</span></span>}
																				searchTask={searchHelpers}
																				defaultOptions={helpers}
																				isLoading={isLoadingHelpers}
																				labelIndex='name'
																				valueIndex='id'
																				placeholder='Select Helper'
																				onChange={option => setHelper(option)}
																				onClear={() => setHelper(null)}
																				value={helper}
																				allowClear
																			/>
																		</Form.Item>
																		<Form.Item>
																			<NumberInput
																				title={'Max Weight Capacity (Tonnes)'}
																				value={maxWeightCapacity}
																				onChange={(value) => {
																					if (isNaN(+value)) {
																						setMaxWeightCapacity(0)
																					} else {
																						setMaxWeightCapacity(+value)
																					}
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<NumberInput
																				title={'Max Volume Capacity (CBM)'}
																				value={maxVolumeCapacity}
																				onChange={(value) => {
																					if (isNaN(+value)) {
																						setMaxVolumeCapacity(0)
																					} else {
																						setMaxVolumeCapacity(+value)
																					}
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<Select
																				onChange={option => setFuelType(option)}
																				value={fuelType}
																				title='Fuel Type'
																				placeholder='Select Fuel Type'
																				options={fuelTypeOptions}
																				allowClear
																			/>
																		</Form.Item>
																		<Form.Item>
																			<Select
																				onChange={option => setFuelUnit(option)}
																				value={fuelUnit}
																				title='Fuel Unit'
																				placeholder='Select Fuel Unit'
																				options={fuelUnitOptions}
																				allowClear
																			/>
																		</Form.Item>
																		<Form.Item>
																			<NumberInput
																				title={fuelUnit ? `Max Fuel Capacity (${getFuelUnitLabel(fuelUnit)})` : 'Max Fuel Capacity'}
																				value={maxFuelCapacity}
																				onChange={(value) => {
																					if (isNaN(+value)) {
																						setMaxFuelCapacity(0)
																					} else {
																						setMaxFuelCapacity(+value)
																					}
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<NumberInput
																				title={'KPL'}
																				value={kpl}
																				onChange={(value) => {
																					if (isNaN(+value)) {
																						setKpl(0)
																					} else {
																						setKpl(+value)
																					}
																				}}
																			/>
																		</Form.Item>
																		<Form.Item>
																			<Row justify='center' className={styles.buttonContainer}>
																				<Button
																					style={{ margin: '0 6px' }}
																					onClick={clearAdditionalInfoForm}
																				>
																					Clear
																				</Button>
																				<Button
																					style={{ margin: '0 6px' }}
																					onClick={() => setCurrentStep(1)}
																				>
																					Previous
																				</Button>
																				<Button
																					type='primary'
																					style={{ margin: '0 6px' }}
																					loading={isAddingVehicle}
																					onClick={onFinish}
																				>
																					Finish
																				</Button>
																			</Row>
																		</Form.Item>
																	</Form> : null
															}
														</>
												}
											</div>
										</Panel>
									</Collapse>
								</div>
							</Tabs.TabPane>
						)
					})
				}
			</Tabs>
		</Modal>
	)
}

AssignVehicleModal.propTypes = {
	visible: PropTypes.bool,
	onCancel: PropTypes.func,
	onOk: PropTypes.func,
	deliveryPlanItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	deliveryPlanItemInternalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	vehicleType: PropTypes.string
}

AssignVehicleModal.defaultProps = {
	visible: false,
	onCancel: () => {},
	onOk: () => {}
}

export default AssignVehicleModal
