import {
	FETCH_ORDER_COUNT_FORECAST_SUCCESS,
	FETCH_ORDER_AMOUNT_FORECAST_SUCCESS
} from './action-types'


const initialState = {
	orderAmountForecast: [],
	orderCountForecast: []
}

const forecastReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_ORDER_COUNT_FORECAST_SUCCESS: {
			return {
				...state,
				orderCountForecast: payload
			}
		}
		case FETCH_ORDER_AMOUNT_FORECAST_SUCCESS: {
			return {
				...state,
				orderAmountForecast: payload
			}
		}
		default:
			return state
	}
}

export default forecastReducer
