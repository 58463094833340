import styles from './BarSpinner.module.css'

const BarSpinner = () => {
	return (
		<div className={styles.barSpinner}>
			<div className={styles.bar} />
			<div className={styles.bar} />
			<div className={styles.bar} />
			<div className={styles.bar} />
			<div className={styles.bar} />
			<div className={styles.bar} />
		</div>
	)
}

export default BarSpinner
