export const Environment = {
	DEVELOPMENT: 'development',
	PRODUCTION: 'production',
	STAGING: 'staging'
}

/**
 * Determines the subdomain to be appended to a URL to call external services
 * based on the ENV environment variable.
 *
 * @returns A string representing the subdomain based on the environment.
 */
export const getSubdomain = () => {
	switch (process.env.NEXT_PUBLIC_ENV) {
		case Environment.DEVELOPMENT:
			return 'dev.'
		case Environment.STAGING:
			return 'staging.'
		case Environment.PRODUCTION:
			return ''
		default:
			return ''
	}
}

export const isDev = () => {
	return process.env.NEXT_PUBLIC_ENV === Environment.DEVELOPMENT
}

export const getCloudFunctionBaseUrl = () => {
	if (process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST) {
		return 'http://127.0.0.1:5001/nuport-dev/us-central1/'
	}
	switch (process.env.NEXT_PUBLIC_ENV) {
		case Environment.DEVELOPMENT:
			return 'https://us-central1-nuport-dev.cloudfunctions.net/'
		case Environment.STAGING:
			return 'https://us-central1-nuport-staging.cloudfunctions.net/'
		case Environment.PRODUCTION:
			return 'https://us-central1-nuport-prod.cloudfunctions.net/'
		default:
			return 'http://127.0.0.1:5001/nuport-dev/us-central1/'
	}
}
