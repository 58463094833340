import { Notifications } from '../../services/api/firebase'
import {
	ADD_NOTIFICATION,
	FETCH_NOTIFICATIONS_SUCCESS,
	READ_NOTIFICATION,
	SET_UNREAD_NOTIFICATION_COUNT,
	SET_NOTIFICATION_COUNT,
	FETCH_UNREAD_NOTIFICATIONS_SUCCESS,
	UPDATE_NOTIFICATION,
	SET_NEW_INSIGHTS,
	READ_NEW_INSIGHTS
} from './action-types'

export function fetchNotifications (uid) {
	return async (dispatch) => {
		const notifications = await Notifications.fetchNotificationsOnce(uid)
		dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: notifications })
	}
}

export function fetchUnreadNotifications (uid) {
	return async (dispatch) => {
		const notifications = await Notifications.fetchUnreadNotificationsOnce(uid)
		dispatch({ type: FETCH_UNREAD_NOTIFICATIONS_SUCCESS, payload: notifications })
	}
}

export function setUnreadCount (payload) {
	return async (dispatch) => {
		dispatch({ type: SET_UNREAD_NOTIFICATION_COUNT, payload })
	}
}

export function setNewInsights (payload) {
	return async (dispatch) => {
		dispatch({ type: SET_NEW_INSIGHTS, payload })
	}
}

export function setCount (payload) {
	return async (dispatch) => {
		dispatch({ type: SET_NOTIFICATION_COUNT, payload })
	}
}

export function addNotification (payload) {
	return async (dispatch) => {
		dispatch({ type: ADD_NOTIFICATION, payload })
	}
}

export function updateNotification (payload) {
	return async (dispatch) => {
		dispatch({ type: UPDATE_NOTIFICATION, payload })
	}
}

export function readNotification (id) {
	return async (dispatch) => {
		dispatch({ type: READ_NOTIFICATION, payload: id })
		await Notifications.readNotification(id)
	}
}

export function readNewInsights () {
	return async (dispatch) => {
		dispatch({ type: READ_NEW_INSIGHTS, payload: false })
		await Notifications.setNewInsights(false)
	}
}
