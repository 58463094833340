require('./ExpenseFilter.less')
import styles from './ExpenseFilter.module.css'
import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import Input from '../../components/input'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import useSearchParams from '../../hooks/useSearchParams'
import { Users } from '../../services/api/users'
import { Expenses } from '../../services/api/expenses'
import { Button } from 'antd'

const ExpenseFilter = ({
	setIsFilterDropdownOpen,
	isFilterDropdownOpen
}) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [searchTerm, setSearchTerm] = useState('')
	const [expenseType, setExpenseType] = useState(null)
	const [isLoadingAssignees, setIsLoadingAssignees] = useState(false)
	const [assignees, setAssignees] = useState([])
	const [assignee, setAssignee] = useState(null)
	const [creationFromDate, setCreationFromDate] = useState(searchParams.creationFromDate)
	const [creationToDate, setCreationToDate] = useState(searchParams.creationToDate)
	const [isLoadingExpenseTypes, setIsLoadingExpenseTypes] = useState(false)
	const [expenseTypes, setExpenseTypes] = useState([])

	useEffect(() => {
		if (!isFilterDropdownOpen) {
			return
		}
		getExpenseTypes()
		getUsers({ searchParams })
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		const expenseType = expenseTypes.find(({ id }) => id === searchParams.expenseTypeId)
		setExpenseType(expenseType ? { key: expenseType.id, value: expenseType.id, label: expenseType.type, data: expenseType } : null)
	}, [expenseTypes])

	const getExpenseTypes = async () => {
		setIsLoadingExpenseTypes(true)
		const { data } = await Expenses.expenseTypes()
		setExpenseTypes(data)
		setIsLoadingExpenseTypes(false)
	}

	const handleApply = async () => {
		const expenseTypeId = expenseType?.data?.id
		const assigneeId = assignee?.data?.id
		const filters = {
			searchTerm,
			expenseTypeId,
			assigneeId
		}

		if (creationFromDate && creationToDate) {
			filters.creationFromDate = moment(creationFromDate).startOf('day').toDate()
			filters.creationToDate = moment(creationToDate).endOf('day').toDate()
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setAssignee(null)
		setExpenseType(null)
		setCreationFromDate(null)
		setCreationToDate(null)
		clearFilter()
		getExpenseTypes()
		getUsers()
	}

	const getUsers = async ({ params = {}, searchParams = {} } = {}) => {
		setIsLoadingAssignees(true)
		const response = await Users.index(params)
		if (response.data) {
			setAssignees(response.data.results)
			let assignee = response.data.results.find(user => user.id === searchParams.assigneeId)
			assignee = assignee ? {
				key: assignee.id,
				value: assignee.id,
				label: assignee.name,
				data: assignee
			} : null
			setAssignee(assignee)
		}
		setIsLoadingAssignees(false)
	}

	const searchUsers = async value => {
		return await Users.index({ searchTerm: value })
	}

	const searchExpenseTypes = async value => {
		const trimmedValue = value.trim?.() || ''
		if (!trimmedValue) {
			return {
				data: []
			}
		}
		return {
			data: expenseTypes.filter(({ type }) => type.trim().toLowerCase().includes(trimmedValue.toLowerCase()))
		}
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Expense Type</p>
				<CustomSearchableSelectSecondary
					searchTask={searchExpenseTypes}
					defaultOptions={expenseTypes}
					isLoading={isLoadingExpenseTypes}
					valueIndex='id'
					labelIndex='type'
					placeholder='Select Expense Type'
					onChange={setExpenseType}
					onClear={() => setExpenseType(null)}
					value={expenseType}
					allowClear={true}
				/>
			</div>
			<div>
				<p className={styles.title}>Assignee</p>
				<CustomSearchableSelectSecondary
					searchTask={searchUsers}
					defaultOptions={assignees}
					isLoading={isLoadingAssignees}
					valueIndex='id'
					labelIndex='name'
					descriptionIndex='role'
					placeholder='Select Assignee'
					onChange={setAssignee}
					onClear={() => setAssignee(null)}
					value={assignee}
					allowClear={true}
				/>
			</div>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='Start Date'
						value={creationFromDate ? moment(creationFromDate) : null }
						onChange={(date) => setCreationFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='End Date'
						value={creationToDate ? moment(creationToDate) : null }
						onChange={(date) => setCreationToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(creationFromDate)}
					/>
				</div>
			</div>
			<div className={styles.buttons}>
				<Button
					className={`${styles.button} ${styles.resetButton}`}
					onClick={resetFilter}
				>
					<span className={styles.buttonText}>
						Reset All
					</span>
				</Button>
				<Button
					type='primary'
					className={styles.button}
					disabled={!expenseType && !assignee && !creationFromDate && !creationToDate}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

export default ExpenseFilter
