require('./ProductPickingModal.less')
import { Button, Checkbox, Image, Modal, notification, Popover, Radio, Table } from 'antd'
import ReactToPrint from 'react-to-print'
import CustomSearchableSelectWithTags from '../custom-searchable-select-with-tags'
import styles from './ProductPickingModal.module.css'
import CustomTable from '../table'
import { PrinterOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useRef, useState } from 'react'
import { downloadArrayOfObjectsAsCSV } from '../../utils'
import SalesOrders from '../../services/api/sales-orders'
import Link from 'next/link'
import { LocationTypes, paths } from '../../utils/constants'
import { Locations } from '../../services/api/locations'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Vendors } from '../../services/api/vendors'
import CustomSearchableSelect from '../custom-searchable-select'
import { useLocalStorage } from '../../hooks/useLocalStorage'

const { Summary } = Table


const invoiceTableColumns = [
	{
		title: 'SL',
		key: 'sl',
		width: 70,
		render: (item, record, index) => {
			return (
				<div>{index + 1}</div>
			)
		}
	},
	{
		title: 'Invoice No',
		key: 'invoiceNo',
		render: ({ internalId, id }) => {
			return (
				<Link href={`${paths.SALES_ORDERS}/${id}`}>
					<a>{internalId}</a>
				</Link>
			)
		}
	},
	{
		title: 'Shipping Date',
		key: 'orderDate',
		render: ({ orderDate }) => {
			return (
				<div>{moment(orderDate).format('MMM D, YYYY')}</div>
			)
		}
	},
	{
		title: 'Product Qty',
		dataIndex: 'productQuantity',
		key: 'productQuantity'
	}
]

const ProductPickingModal = ({
	visible,
	onCancel,
	status
}) => {
	const { userProfile, companyDetails } = useSelector(state => state.authReducer)
	const [pickingProducts, setPickingProducts] = useState([])
	const [vendors, setVendors] = useState([])
	const [selectedVendor, setSelectedVendor] = useState()
	const [isPrintingPickingTable, setIsPrintingPickingTable] = useState(false)
	const [isLoadingExportPickingProducts, setIsLoadingExportPickingProducts] = useState(false)
	const [isLoadingProducts, setIsLoadingProducts] = useState(false)
	const [isLoadingVendors, setIsLoadingVendors] = useState(false)
	const [warehouse, setWarehouse] = useState(null)
	const [warehouses, setWarehouses] = useState([])
	const [selectedWarehouses, setSelectedWarehouses] = useState([])
	const [isFetchingWarehouseLocations, setIsFetchingWarehouseLocations] = useState(false)
	const [areAllWarehouseLocationsSelected, setAreAllWarehouseLocationsSelected] = useState(false)
	const [stockStatus, setStockStatus] = useState('both')
	const pickingTableRef = useRef(null)
	const printRef = useRef()
	const [filterColumnsMap, setFilterColumnsMap] = useLocalStorage('pickingColumns', {
		'productId': true,
		'images': true,
		'name': true,
		'totalQuantity': true,
		'availableQuantity': true,
		'shortageQuantity': true,
		'vendor': false
	})

	const pickingOrderTableColumns = [
		{
			title: 'Product SKU',
			key: 'productId',
			render: ({ id, sku, orders, totalOrderQuantity }) => {
				return (
					<>
						<Link href={`${paths.PRODUCTS}/${id}`}>
							<a>{sku ? sku : 'No SKU'}</a>
						</Link>
						{
							!isLoadingProducts &&
							<Popover
								overlayClassName='item-details-popover'
								placement='rightTop'
								arrowPointAtCenter
								trigger={['hover', 'click']}
								content={
									<>
										<p className={styles.invoiceNo}>Invoice Information</p>
										<CustomTable
											pagination={false}
											scroll={{ y: '275px' }}
											columns={invoiceTableColumns}
											dataSource={orders}
											summary={() => {
												return (
													<Summary.Row className={`${styles.summaryRow}`}>
														<Summary.Cell />
														<Summary.Cell />
														<Summary.Cell>Total</Summary.Cell>
														<Summary.Cell>{totalOrderQuantity?.toFixed(2)}</Summary.Cell>
													</Summary.Row>
												)
											}}
										/>
									</>
								}
							>
								<InfoCircleOutlined
									style={{ fontSize: 16, marginLeft: 60, cursor: 'pointer' }}
									className={styles.pickingProductInfoIcon}
								/>
							</Popover>
						}
					</>
				)
			}
		},
		{
			title: 'Images',
			key: 'images',
			width: 64,
			render: ({ imageUrls }) => {
				return (
					imageUrls.length > 0 ?
						<div
							key={imageUrls[0]}
							className={`${styles.imageContainer} product-image`}
						>
							<Image
								width={44}
								height={44}
								src={imageUrls[0]}
								placeholder={true}
								style={{ objectFit: 'contain', borderRadius: '0px' }}
								referrerPolicy='no-referrer'
							/>
						</div> :
						<Image
							width={44}
							height={44}
							placeholder={true}
							style={{ objectFit: 'contain', borderRadius: '0px' }}
							referrerPolicy='no-referrer'
						/>
				)
			}
		},
		{
			title: 'Product Name',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: 'Picking Qty',
			dataIndex: 'totalQuantity',
			key: 'totalQuantity'
		},
		{
			title: 'Available Qty',
			dataIndex: 'availableQuantity',
			key: 'availableQuantity'
		},
		{
			title: 'Shortage Qty',
			dataIndex: 'shortageQuantity',
			key: 'shortageQuantity'
		},
		{
			title: 'Vendor',
			key: 'vendor',
			render: ({ vendor }) => {
				if (!vendor) {
					return 'N/A'
				}

				return (
					<div>
						{vendor.name}
					</div>
				)
			}
		}
	]

	const filteredPickingOrderTableColumns = useMemo(() => {
		if (companyDetails?.isPurchaseOrderEnabled && companyDetails?.isVendorEnabled) {
			return pickingOrderTableColumns
		} else {
			return pickingOrderTableColumns.filter(column => column.key !== 'vendor')
		}
	}, [companyDetails])

	useEffect(() => {
		if (userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setWarehouses(locations)
			} else {
				getWarehouses()
			}
		}
	}, [userProfile])

	useEffect(() => {
		if (visible) {
			getAllPickingOrders()
			getAllVendors()
		}
	}, [visible])

	const modifiedPickingProducts = pickingProducts
		.filter(({ inventoryItems }) => {
			if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
				return true
			} else {
				return inventoryItems.some(({ locationId }) => selectedWarehouses.some(({ id }) => id === locationId))
			}
		})
		.map(product => {
			const totalQuantity = product.orders
				.filter(({ pickUpLocationId }) => {
					if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
						return true
					} else {
						return selectedWarehouses.some(({ id }) => id === pickUpLocationId)
					}
				})
				.reduce((acc, curr) => {
					return acc + curr.productQuantity
				}, 0)
			const availableQuantity = product.inventoryItems
				.filter(({ quantity, locationId }) => {
					if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
						return quantity > 0
					} else if (selectedWarehouses.length > 0) {
						return quantity > 0 && selectedWarehouses.some(({ id }) => id === locationId)
					} else {
						return false
					}
				})
				.reduce((acc, curr) => {
					return acc + curr.quantity
				}, 0)
			const shortageQuantity = product.inventoryItems
				.filter(({ quantity, locationId }) => {
					if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
						return quantity < 0
					} else if (selectedWarehouses.length > 0) {
						return quantity < 0 && selectedWarehouses.some(({ id }) => id === locationId)
					} else {
						return false
					}
				})
				.reduce((acc, curr) => {
					return acc + curr.quantity
				}, 0)
			const orders = product.orders.filter(({ pickUpLocationId }) => {
				if (areAllWarehouseLocationsSelected || selectedWarehouses.length === 0) {
					return true
				} else {
					return selectedWarehouses.some(({ id }) => id === pickUpLocationId)
				}
			}) || []
			const totalOrderQuantity = orders.reduce((acc, curr) => {
				return acc + curr.productQuantity
			}, 0)
			return {
				...product,
				orders,
				totalOrderQuantity,
				totalQuantity,
				availableQuantity,
				shortageQuantity
			}
		})
		.filter(({ totalQuantity, vendor }) => {
			if (selectedVendor) {
				return totalQuantity > 0 && selectedVendor.value === vendor?.id
			} else {
				return totalQuantity > 0
			}
		})
		.filter(({ availableQuantity, shortageQuantity }) => {
			if (stockStatus === 'both') {
				return true
			} else if (stockStatus === 'available') {
				return availableQuantity > 0
			} else if (stockStatus === 'shortage') {
				return shortageQuantity < 0
			}
		})

	const getAllPickingOrders = async () => {
		try {
			setIsLoadingProducts(true)
			const { data } = await SalesOrders.getPickingProducts({
				status
			})
			setPickingProducts(data)
		} catch (e) {
			notification.error({
				message: 'Unable to get picking products.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingProducts(false)
		}
	}

	const getAllVendors = async () => {
		try {
			setIsLoadingVendors(true)
			const { data } = await Vendors.index({ page: -1 })
			setVendors(data.results)
		} catch (e) {
			notification.error({
				message: 'Unable to get vendors.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingVendors(false)
		}
	}

	const searchVendors = value => {
		return Vendors.index({ searchTerm: value })
	}

	const getWarehouses = async () => {
		try {
			setIsFetchingWarehouseLocations(true)
			const response = await Locations.index({
				types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY],
				page: -1
			})
			const locations = response.data.results
			setWarehouses(locations)
		} catch (err) {
			notification.error({
				message: 'Unable to Fetch User\'s Locations',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFetchingWarehouseLocations(false)
		}
	}

	const searchWarehouses = value => {
		return Locations.index({
			searchTerm: value,
			types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY]
		})
	}

	const onWarehouseSelected = value => {
		const { data } = value
		const newSelectedLocation = { ...data, value: data.id, label: data.label || data.address }
		setWarehouse(newSelectedLocation)
		const existedLocation = selectedWarehouses.find(p => p.id === newSelectedLocation.id)
		if (!existedLocation) {
			selectedWarehouses.push(newSelectedLocation)
		}
	}

	const onExportPickingProducts = async () => {
		try {
			setIsLoadingExportPickingProducts(true)
			if (pickingProducts.length) {
				const csvData = modifiedPickingProducts
					.map(product => {
						const row = {}
						if (filterColumnsMap.productId) {
							row['Product SKU'] = product.sku || 'No SKU'
						}
						if (filterColumnsMap.images) {
							row['Image'] = product.imageUrls.length > 0 ? product.imageUrls[0] : ''
						}
						if (filterColumnsMap.name) {
							row['Product Name'] = product.name
						}
						if (filterColumnsMap.totalQuantity) {
							row['Picking Quantity'] = product.totalQuantity
						}
						if (filterColumnsMap.availableQuantity) {
							row['Available Quantity'] = product.availableQuantity
						}
						if (filterColumnsMap.shortageQuantity) {
							row['Shortage Quantity'] = product.shortageQuantity
						}
						if (filterColumnsMap.vendor && companyDetails?.isPurchaseOrderEnabled && companyDetails?.isVendorEnabled) {
							row['Vendor'] = product.vendor ? product.vendor.name : 'N/A'
						}
						return row
					})
				downloadArrayOfObjectsAsCSV(csvData, 'Product and Stock Checklist', { includeDateInFileName: true })
			}
			setIsLoadingExportPickingProducts(false)
		} catch (e) {
			notification.error({
				message: 'Export Error',
				description: e.message || 'An error occurred during export.',
				placement: 'bottomLeft'
			})
			setIsLoadingExportPickingProducts(false)
		}
	}

	const renderPickingTable = () => {
		return (
			<CustomTable
				loading={isLoadingProducts}
				refresh={getAllPickingOrders}
				columns={filteredPickingOrderTableColumns.filter(column => filterColumnsMap[column.key])}
				dataSource={modifiedPickingProducts}
				pagination={false}
				title={() => <span style={{ opacity:0, userSelect: 'none' }}>.</span>}
				headerChildren={
					<>
						<Button
							type='primary'
							loading={isPrintingPickingTable}
							icon={<PrinterOutlined />}
							onClick={() => {
								setIsPrintingPickingTable(true)
								printRef.current.handleClick()
							}}
						>
							Print
						</Button>
						<Button
							type='primary'
							loading={isLoadingExportPickingProducts}
							icon={<DownloadOutlined />}
							onClick={onExportPickingProducts}
						>
							Export As CSV
						</Button>
					</>
				}
				filter={true}
				filterTrigger='click'
				filterPlacement='bottomLeft'
				filterContent={
					<div className={styles.filterOptions}>
						<h4>Columns</h4>
						<div>
							<Checkbox
								checked={Object.values(filterColumnsMap).every((value) => value === true)}
								value='isAllChecked'
								onChange={(e) => {
									if (e.target.checked) {
										setFilterColumnsMap({
											...filterColumnsMap,
											...Object.fromEntries(Object.keys(filterColumnsMap).map(key => [key, true]))
										})
									} else {
										setFilterColumnsMap({
											...filterColumnsMap,
											...Object.fromEntries(Object.keys(filterColumnsMap).map(key => [key, false]))
										})
									}
								}}
							>
                                Select All
							</Checkbox>
						</div>
						{
							filteredPickingOrderTableColumns.map(column => {
								return (
									<div key={column.key}>
										<Checkbox
											checked={filterColumnsMap[column.key]}
											value={column.key}
											onChange={(e) => setFilterColumnsMap({
												...filterColumnsMap,
												[column.key]: e.target.checked
											})}
										>
											{column.title}
										</Checkbox>
									</div>
								)
							})
						}
					</div>
				}
			/>
		)
	}

	const renderPickingModalContent = () => {
		return (
			<div className={`${styles.pickingModal} product-picking-modal ant-modal-content`}>
				<div className={styles.modalTitleContainer}>
					<p className={styles.modalTitle}>Product and Stock Checklist</p>
					<svg className={styles.closeIcon} onClick={() => onCancel()}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div style={{ display: 'flex', marginBottom: 12, gap: 24 }}>
					<div style={{ flex: 1 }}>
						<CustomSearchableSelectWithTags
							searchTask={searchWarehouses}
							defaultOptions={warehouses}
							isLoading={isFetchingWarehouseLocations}
							title='Warehouse'
							selectedTitle='Selected Warehouses'
							customLabel={(data) => data.label || data.address}
							valueIndex='id'
							descriptionIndex='address'
							placeholder='Search Warehouse'
							onChange={onWarehouseSelected}
							onClear={() => setWarehouse(null)}
							value={warehouse}
							setValue={setWarehouse}
							areAllValuesSelected={areAllWarehouseLocationsSelected}
							setAreAllValuesSelected={setAreAllWarehouseLocationsSelected}
							onAreAllValuesSelectedChange={areAllWarehousesSelected => {
								setSelectedWarehouses(areAllWarehousesSelected ? warehouses : [])
								setWarehouse(null)
							}}
							selectedValues={selectedWarehouses}
							setSelectedValues={setSelectedWarehouses}
						/>
					</div>
					<div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 12 }}>
						<div className={styles.title}>Stock Status</div>
						<Radio.Group
							className={styles.inputGroup}
							value={stockStatus}
							onChange={e => {
								setStockStatus(e.target.value)
							}}
						>
							<Radio className={styles.inputTitle} value='both'>Both</Radio>
							<Radio className={styles.inputTitle} value='available'>Available Stock</Radio>
							<Radio className={styles.inputTitle} value='shortage'>Shortage Stock</Radio>
						</Radio.Group>
					</div>
				</div>
				{
					companyDetails?.isPurchaseOrderEnabled && companyDetails?.isVendorEnabled &&
					<div style={{ display: 'flex', marginBottom: 12, gap: 24 }}>
						<div style={{ flex: 0.5 }}>
							<CustomSearchableSelect
								searchTask={searchVendors}
								defaultOptions={vendors}
								isLoading={isLoadingVendors}
								labelIndex='name'
								valueIndex='id'
								title='Vendors'
								placeholder='Select Vendor'
								onChange={option => setSelectedVendor(option)}
								onClear={() => setSelectedVendor(null)}
								value={selectedVendor}
								allowClear={true}
							/>
						</div>
					</div>
				}
				<div
					className={`${styles.modalContent} ${styles.pickingTableContainer}`}
					ref={pickingTableRef}
				>
					<p className={styles.printTitle}>Product and Stock Checklist</p>
					<div className={styles.pickingTables}>
						{renderPickingTable()}
						{renderPickingTable()}
					</div>
					<ReactToPrint
						ref={printRef}
						content={() => pickingTableRef.current}
						documentTitle={`Product and Stock Checklist_${moment().format('YYYY-MM-DD')}`}
						onAfterPrint={() => {
							setIsPrintingPickingTable(false)
						}}
					/>
				</div>
			</div>
		)
	}

	return (
		<Modal
			width={1000}
			visible={visible}
			centered={true}
			destroyOnClose={true}
			modalRender={renderPickingModalContent}
		/>
	)
}

export default ProductPickingModal
