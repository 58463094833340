import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { DeliveryPartners } from '../../services/api/delivery-partner'

const DeliveryAreaSelectMpCourier = ({ deliveryPartnerId, title, error, value, onChange }) => {
	const [isLoadingPickupAddresses, setIsLoadingPickupAddresses] = useState(false)
	const [isLoadingCities, setIsLoadingCities] = useState(false)
	const [pickupAddresses, setPickupAddresses] = useState([])
	const [cities, setCities] = useState([])

	useEffect(() => {
		if (deliveryPartnerId) {
			getPickupAddresses(deliveryPartnerId)
			getCities(deliveryPartnerId)
		}
	}, [deliveryPartnerId])

	const getPickupAddresses = async deliveryPartnerId => {
		setIsLoadingPickupAddresses(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryPickupAddresses(deliveryPartnerId)
			const pickupAddressOptions = data?.map(pickupAddress => ({
				...pickupAddress,
				value: pickupAddress.locationID,
				label: pickupAddress.locationName || pickupAddress.locationAddress
			})) || []
			setPickupAddresses(pickupAddressOptions)
		} catch (e) {
			onChange({ ...value, pickupAddress: null })
			setPickupAddresses([])
			notification.error({
				message: 'Unable To Get Areas',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingPickupAddresses(false)
		}
	}

	const getCities = async deliveryPartnerId => {
		setIsLoadingCities(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryCities(deliveryPartnerId)
			const cityOptions = data?.map(city => ({
				value: city,
				label: city
			})) || []
			setCities(cityOptions)
		} catch (e) {
			onChange({ ...value, city: null })
			setCities([])
			notification.error({
				message: 'Unable To Get Cities',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingCities(false)
		}
	}

	const onPickupAddressChange = (_, option) => {
		onChange({
			...value,
			pickupAddress: option ? { value: option.value, label: option.label } : null
		})
	}

	const onCityChange = (_, option) => {
		onChange({
			...value,
			city: option ? { value: option.value, label: option.label } : null
		})
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'pickupAddress',
					placeholder: 'Pickup Location',
					loading: isLoadingPickupAddresses,
					options: pickupAddresses,
					value: value?.pickupAddress || null,
					onChange: onPickupAddressChange,
					onClear: () => onPickupAddressChange(),
					style: { width: '50%' }
				},
				{
					id: 'city',
					placeholder: 'City',
					loading: isLoadingCities,
					options: cities,
					value: value?.city || null,
					onChange: onCityChange,
					onClear: () => onCityChange(),
					style: { width: '50%' }
				}
			]}
		/>
	)
}

DeliveryAreaSelectMpCourier.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectMpCourier.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectMpCourier
