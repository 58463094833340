import { calculateDiscount } from '.'

export const PURCHASE_ORDER_REQUESTED_STATUS = 'requested'
export const PURCHASE_ORDER_APPROVED_STATUS = 'approved'
export const PURCHASE_ORDER_RECEIVED_STATUS = 'received'
export const PURCHASE_ORDER_FLAGGED_STATUS = 'flagged'
export const PURCHASE_ORDER_CANCELLED_STATUS = 'cancelled'
export const PURCHASE_ORDER_RETURNED_SUB_STATUS = 'returned'
export const PURCHASE_ORDER_DAMAGED_SUB_STATUS = 'damaged'
export const PURCHASE_ORDER_EXPIRED_SUB_STATUS = 'expired'
export const PURCHASE_ORDER_PAYMENT_DUE_SUB_STATUS = 'payment_due'
export const PURCHASE_ORDER_PAYMENT_PAID_SUB_STATUS = 'payment_paid'

export const PURCHASE_ORDER_STATUS_COLORS = {
	[PURCHASE_ORDER_REQUESTED_STATUS]: '#5486F9',
	[PURCHASE_ORDER_FLAGGED_STATUS]: '#FF7A7A',
	[PURCHASE_ORDER_APPROVED_STATUS]: '#EBCA60',
	[PURCHASE_ORDER_RECEIVED_STATUS]: '#22E6C2',
	[PURCHASE_ORDER_CANCELLED_STATUS]: '#FF5959'
}

export const PURCHASE_ORDER_SUB_STATUS_COLORS = {
	[PURCHASE_ORDER_PAYMENT_PAID_SUB_STATUS]: '#18A188',
	[PURCHASE_ORDER_PAYMENT_DUE_SUB_STATUS]: '#F6BA5F',
	[PURCHASE_ORDER_RETURNED_SUB_STATUS]: '#8C1F28',
	[PURCHASE_ORDER_DAMAGED_SUB_STATUS]: '#591C21',
	[PURCHASE_ORDER_EXPIRED_SUB_STATUS]: '#D92525'
}

export const getShortId = (purchaseOrder) => {
	if (purchaseOrder.internalId) {
		return `${purchaseOrder.internalId}`
	} else {
		return `PO-${purchaseOrder.shortId}`
	}
}

export const getAdjustedItemQuantity = (purchaseOrderItem, status) => {
	if (purchaseOrderItem.packaging) {
		const packageSize = purchaseOrderItem.packaging.size || 0
		let packageQuantity = purchaseOrderItem.packageQuantity || 0
		let quantity = purchaseOrderItem.quantity || 0
		switch (status?.toLowerCase()) {
			case PURCHASE_ORDER_APPROVED_STATUS: {
				packageQuantity = purchaseOrderItem.approvedPackageQuantity || 0
				quantity = purchaseOrderItem.approvedQuantity || 0
				break
			}
			case PURCHASE_ORDER_RECEIVED_STATUS: {
				packageQuantity = purchaseOrderItem.receivedPackageQuantity || 0
				quantity = purchaseOrderItem.receivedQuantity || 0
				break
			}
			case PURCHASE_ORDER_FLAGGED_STATUS: {
				packageQuantity = purchaseOrderItem.flaggedPackageQuantity || 0
				quantity = purchaseOrderItem.flaggedQuantity || 0
				break
			}
			default: {
				packageQuantity = purchaseOrderItem.packageQuantity || 0
				quantity = purchaseOrderItem.quantity || 0
			}
		}
		return (packageQuantity * packageSize) + quantity
	} else {
		switch (status?.toLowerCase()) {
			case PURCHASE_ORDER_APPROVED_STATUS:
				return purchaseOrderItem.approvedQuantity || 0
			case PURCHASE_ORDER_RECEIVED_STATUS:
				return purchaseOrderItem.receivedQuantity || 0
			case PURCHASE_ORDER_FLAGGED_STATUS:
				return purchaseOrderItem.flaggedQuantity || 0
			default:
				return purchaseOrderItem.quantity || 0
		}
	}
}

export const getPurchaseItemTotals = (item, status) => {
	const purchaseStatus = status?.toLowerCase() === PURCHASE_ORDER_FLAGGED_STATUS ? PURCHASE_ORDER_REQUESTED_STATUS : status
	const price = +item.price > 0 ? +item.price : +item.product?.purchasePrice || 0
	const totalQuantity = getAdjustedItemQuantity(item, purchaseStatus)
	const totalPrice = totalQuantity * price
	const discountAmount = +item.discount ? calculateDiscount(totalPrice, +item.discount) : +item.discountAmount
	return {
		price,
		totalPrice,
		discountedPrice: totalPrice - discountAmount,
		totalQuantity,
		discountAmount
	}
}

export const generateBatchNumber = (purchaseOrder, sku, sequenceNumber = 1) => {
	const purchaseOrderId = purchaseOrder.internalId ?? `PO-${purchaseOrder.shortId}`
	const vendorId = purchaseOrder.vendor.internalId ?? `V-${purchaseOrder.vendor.shortId}`
	const batchNumber = `${vendorId}${sku}${purchaseOrderId}${sequenceNumber}`.replace(/-/g, '')
	return batchNumber.toUpperCase()
}

export const getAdjustedPackageQuantities = (quantity, packaging) => {
	const packageSize = packaging ? packaging.size : 0
	const packageCount = quantity > packageSize && packageSize !== 0 ? Math.floor(quantity / packageSize) : 0
	const remainder = quantity > packageSize ? quantity % packageSize : 0
	const adjustedPackageQuantity = packageSize !== 0 ? packageCount : 0
	const adjustedQuantity = packageCount > 0 ? remainder : quantity
	return { adjustedQuantity, adjustedPackageQuantity }
}
