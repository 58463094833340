import { Loader } from '@googlemaps/js-api-loader'

const initMaps = () => {
	if (typeof window !== 'undefined') {
		const loader = new Loader({
			apiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY,
			libraries: ['places', 'geometry']
		})
		loader.load()
			.then(() => { /** No-op **/ })
			.catch(e => console.error(e))
	}
}

export default initMaps
