import GoogleMapReact from 'google-map-react'
import MapStyles from '../../styles/map-styles.json'
import { useEffect, useState } from 'react'
import { Maps } from '../../services/maps'
import { getCountryCode } from '../../utils'
import { countryMap } from '../../utils/countries'
import { Button } from 'antd'
require('./Map.less')

const Map = ({
	containerStyle,
	children,
	onMapsLoaded,
	center,
	defaultZoom = 11,
	zoom,
	draggableCursor = 'grab',
	hideable = false,
	onMapClick = () => {},
	points = []
}) => {
	const countryCode = getCountryCode()
	const [derivedCenter, setDerivedCenter] = useState()
	const [derivedBounds, setDerivedBounds] = useState()
	const [map, setMap] = useState()
	const [maps, setMaps] = useState()
	const [isMapVisible, setIsMapVisible] = useState(false)

	let defaultCenter = { lat: 23.8103, lng: 90.4125 }
	if (countryCode === 'PK') {
		defaultCenter = { lat: 30.3753, lng: 69.3451 }
	}

	useEffect(() => {
		if (!center) {
			if (isMapVisible && map) {
				getCenter()
			}
		}
	}, [center, isMapVisible, map])

	useEffect(() => {
		if (map && derivedBounds) {
			map.fitBounds(derivedBounds)
		}
	}, [derivedBounds, map])

	useEffect(() => {
		if (points?.length > 0 && map && maps) {
			const bounds = new maps.LatLngBounds()
			points.forEach(point => bounds.extend(new maps.LatLng(+point.latitude, +point.longitude)))
			map.setCenter(bounds.getCenter())
		}
	}, [points, map, maps])

	const getCenter = async () => {
		const countryName = countryMap[countryCode]
		const geometry = await Maps.getGeometry(countryName)
		if (geometry) {
			const { bounds } = geometry
			setDerivedBounds(geometry.bounds)
			const point = bounds.getCenter()
			setDerivedCenter({ lat: point.lat(), lng: point.lng() })
		}
	}

	return (
		<>
			{
				hideable ?
					<div>
						{
							!isMapVisible &&
							<Button
								size='small'
								onClick={() => setIsMapVisible(true)}
							>
								View Map
							</Button>
						}
						{
							isMapVisible &&
							<div className='custom-map' style={containerStyle}>
								<GoogleMapReact
									options={{
										styles: MapStyles,
										clickableIcons: false,
										draggableCursor
									}}
									bootstrapURLKeys={{
										key: process.env.NEXT_PUBLIC_MAPS_API_KEY,
										libraries: ['places', 'geometry']
									}}
									defaultZoom={defaultZoom}
									zoom={zoom}
									onGoogleApiLoaded={({ map, maps }) => {
										setMap(map)
										onMapsLoaded ? onMapsLoaded(map, maps) : null
									}}
									defaultCenter={defaultCenter}
									center={center || derivedCenter}
									yesIWantToUseGoogleMapApiInternals
									onClick={onMapClick}
								>
									{children}
								</GoogleMapReact>
							</div>
						}
					</div> :
					<div className='custom-map' style={containerStyle}>
						<GoogleMapReact
							options={{
								styles: MapStyles,
								clickableIcons: false,
								draggableCursor
							}}
							bootstrapURLKeys={{
								key: process.env.NEXT_PUBLIC_MAPS_API_KEY,
								libraries: ['places', 'geometry']
							}}
							defaultZoom={defaultZoom}
							zoom={zoom}
							onGoogleApiLoaded={({ map, maps }) => {
								setMap(map)
								setMaps(maps)
								onMapsLoaded ? onMapsLoaded(map, maps) : null
							}}
							defaultCenter={defaultCenter}
							center={center || derivedCenter}
							yesIWantToUseGoogleMapApiInternals
							onClick={onMapClick}
						>
							{children}
						</GoogleMapReact>
					</div>
			}
		</>
	)
}

export default Map
