import { get, post, patch, destroy } from './index'

export const TransportQuotations = {
	index: ({
		page = 0,
		searchTerm
	} = {}) =>
		get('/transport-quotations', {
			params: {
				searchTerm,
				page
			}
		}),
	getUploadedQuotationItems: () =>
		get('/transport-quotations/uploaded-items'),
	removeUploadedQuotationItems: () =>
		destroy('/transport-quotations/uploaded-items'),
	single: (id) =>
		get(`/transport-quotations/${id}`),
	create: (params) =>
		post('/transport-quotations', params),
	update: (id, params) =>
		patch(`/transport-quotations/${id}`, params),
	remove: (id) =>
		destroy(`/transport-quotations/${id}`)
}
