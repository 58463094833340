import moment from 'moment'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { SalesOrders } from '../../../services/api/sales-orders'
import { convertWeightUnit, WeightUnits } from '../../../utils/units'
import styles from './SalesOrder.module.css'

export const SALES_ORDER_CHALLAN_LOADED = 'sales-order-loaded'

const SalesOrderChallan = () => {
	const router = useRouter()
	const { query, isReady } = router
	const { id, accessToken } = query
	const [salesOrder, setSalesOrder] = useState()

	useEffect(() => {
		if (isReady) {
			if (id && accessToken) {
				getSalesOrder()
			}
		}
	}, [isReady])

	useEffect(() => {
		if (salesOrder) {
			parent.postMessage({ action: SALES_ORDER_CHALLAN_LOADED })
		}
	}, [salesOrder])

	const getSalesOrder = async () => {
		const response = await SalesOrders.single(id, { accessToken, company: true })
		const { data } = response
		if (data) {
			setSalesOrder(data)
		}
	}

	return (
		<div id='content' className='pdf-content'>
			{
				salesOrder &&
				<SalesOrderChallanPage salesOrder={salesOrder} />
			}
		</div>
	)
}

export default SalesOrderChallan

export const SalesOrderChallanPage = ({
	visible = true,
	salesOrder
}) => {

	const getTotalQuantity = (salesOrder) => {
		return salesOrder.salesOrderItems
			.map(salesOrderItem => +salesOrderItem.quantity)
			.reduce((a, b) => a + b, 0)
	}

	const getTotalWeight = (salesOrder) => {
		return salesOrder.salesOrderItems
			.map(salesOrderItem => convertWeightUnit(+salesOrderItem.totalWeight, salesOrderItem.totalWeightUnit || WeightUnits.KG))
			.reduce((a, b) => a + b, 0)
	}

	return (
		<div className={visible ? styles.page : styles.pageInvisible}>
			<div style={{ display: 'flex' }}>
				<div>
					{
						salesOrder.deliveryPlan &&
						<div className={styles.valueContainer}>
							<div className={styles.valueTitle}>
							Delivery No.
							</div>
							<div className={styles.value}>
								{`DP-${salesOrder.deliveryPlan.shortId}`}
							</div>
						</div>
					}
					<div className={styles.valueContainer}>
						<div className={styles.valueTitle}>
							Ref No.
						</div>
						<div className={styles.value}>
							{salesOrder.internalId}
						</div>
					</div>
				</div>
				<div className={styles.valueContainer} style={{ marginLeft: 'auto' }}>
					<div className={styles.valueTitle}>
						Dated
					</div>
					<div className={styles.value}>
						{moment(salesOrder.deliveryDate).format('MMM D, YYYY')}
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
				<div className={styles.columnValueContainer}>
					<div style={{ fontWeight: 'bold' }}>
						{salesOrder.company.name}
					</div>
					{
						salesOrder.company.locations && salesOrder.company.locations.length > 0 &&
								<div className={styles.columnValueContainer}>
									{salesOrder.company.locations[0].address}
								</div>
					}
					<div>
						{salesOrder.company.phone}
					</div>
				</div>
				<h4 style={{ fontWeight: 'bold', margin: '6px 0' }}>DELIVERY NOTE</h4>
				<div className={styles.columnValueContainer}>
					{
						salesOrder.distributor &&
						<div>
							{salesOrder.distributor.name}
						</div>
					}
					{
						salesOrder.location &&
								<div className={styles.columnValueContainer}>
									{salesOrder.location.address}
								</div>
					}
					{
						salesOrder.distributor &&
						<div>
							{salesOrder.distributor.phone}
						</div>
					}
				</div>
			</div>
			<div className={styles.table}>
				<div style={{ borderBottom: '1px solid black' }}>
					<div colSpan='3'>
						<div style={{ display: 'flex' }}>
							<div style={{ textAlign: 'start', padding: '4px' }}>
								<div style={{ fontSize: 12, fontWeight: 'bold' }}>Order No.</div>
								<div style={{ fontSize: 12 }}>{salesOrder.internalId}</div>
								<div style={{ fontSize: 12 }}>{moment(salesOrder.deliveryDate).format('MMM D, YYYY')}</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ borderBottom: '1px solid black', display: 'flex' }}>
					<div style={{ borderRight: '1px solid black', flex: 1 }}><div className={styles.smallValue}>No.</div></div>
					<div style={{ flex: 8 }}><div className={styles.smallValue}>Description of Goods</div></div>
					<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}><div className={styles.smallValue}>Weight</div></div>
					<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}><div className={styles.smallValue}>Quantity</div></div>
				</div>
				{
					salesOrder.salesOrderItems.map((salesOrderItem, index) => {
						return (
							<div key={index} style={{ borderBottom: '1px solid black', display: 'flex' }}>
								<div style={{ borderRight: '1px solid black', flex: 1 }}>
									<div className={styles.smallValue}>{index + 1}</div>
								</div>
								<div style={{ flex: 8 }}>
									<div className={styles.smallValue} style={{ fontWeight: 'bold', textAlign: 'left', padding: '4px 4px' }}>
										{salesOrderItem.product.name}
									</div>
								</div>
								<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}>
									<div className={styles.smallValue} style={{ fontWeight: 'bold' }}>
										{(+salesOrderItem.totalWeight).toFixed(2)} {salesOrderItem.totalWeightUnit}
									</div>
								</div>
								<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}>
									{
										salesOrderItem.packageQuantity > 0 &&
										<div className={styles.smallValue} style={{ fontWeight: 'bold' }}>
											{salesOrderItem.packageQuantity} pkgs.
										</div>
									}
									<div className={styles.smallValue} style={{ fontWeight: 'bold' }}>
										{salesOrderItem.quantity} pcs.
									</div>
								</div>
							</div>
						)
					})
				}
				<div style={{ borderBottom: '1px solid black', display: 'flex', flex: 1 }}>
					<div style={{ borderRight: '1px solid black', flex: 1 }}><div className={styles.smallValue} /></div>
					<div style={{ verticalAlign: 'top', textAlign: 'start', flex: 8 }} />
					<div style={{ borderLeft: '1px solid black', verticalAlign: 'top', flex: 2, maxWidth: 103.73 }} />
					<div style={{ borderLeft: '1px solid black', verticalAlign: 'top', flex: 2, maxWidth: 103.73 }} />
				</div>
				<div style={{ display: 'flex' }}>
					<div style={{ borderRight: '1px solid black', flex: 1 }}><div className={styles.smallValue} /></div>
					<div style={{ display: 'flex', flex: 8 }}>
						<div className={styles.smallValue} style={{ fontWeight: 'bold', marginLeft: 'auto', fontSize: 12 }}>
									Total
						</div>
					</div>
					<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}>
						<div className={styles.smallValue} style={{ fontWeight: 'bold', fontSize: 12 }}>
							{getTotalWeight(salesOrder).toFixed(2)} kg
						</div>
					</div>
					<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}>
						<div className={styles.smallValue} style={{ fontWeight: 'bold' }}>
							{getTotalQuantity(salesOrder)}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.valueContainer} style={{ marginTop: 'auto', paddingTop: 12 }}>
				<div
					className={styles.value}
					style={{ textAlign: 'left' }}
				>
					<span>Additional Notes:</span>
					<span
						className={styles.smallValue}
						style={{ fontSize: 13, marginLeft: 4 }}
					>
						{salesOrder.additionalNotes}
					</span>
				</div>
			</div>
			<div className={styles.valueContainer} style={{ marginTop: 'auto', paddingTop: 12 }}>
				<div className={styles.value}>
							Received in Good Condition
				</div>
			</div>
			<div className={styles.divider} />
			<div style={{ marginTop: 12, display: 'flex' }}>
				<div className={styles.value} style={{ flex: 1 }}>Customer Signature</div>
				<div className={styles.value} style={{ flex: 1 }}>Prepared By</div>
				<div className={styles.value} style={{ flex: 1 }}>Checked By</div>
				<div className={styles.value} style={{ flex: 1 }}>Approved By</div>
			</div>
		</div>
	)
}
