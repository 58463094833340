import { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Radio } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './UserFilter.module.css'
import { LocationTypes, claimsRoles, ecommerceIndustryType, industries, paths, roles } from '../../utils/constants'
import DropdownOverlay from '../dropdown-overlay'
import CustomSelectSecondary from '../custom-select-secondary'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { Companies } from '../../services/api/companies'
import { useSelector } from 'react-redux'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { Permissions } from '../../services/api/permissions'
import { Locations } from '../../services/api/locations'

export const roleOptions = [
	{
		label: 'Owner',
		value: roles.OWNER
	},
	{
		label: 'General',
		value: roles.USER
	},
	{
		label: 'Driver',
		value: roles.DRIVER
	},
	{
		label: 'Helper',
		value: roles.HELPER
	},
	{
		label: 'Sales & Marketing',
		value: roles.SALES_MANAGER
	},
	{
		label: 'Procurement & Sourcing',
		value: roles.PURCHASE_MANAGER
	}
]

const UserFilter = ({
	setIsFilterDropdownOpen
}) => {
	const { searchParams } = useSearchParams()
	const shouldResetFilter = useContext(GlobalFilterContext)
	const router = useRouter()
	const { role: currentRole, companyDetails } = useSelector(state => state.authReducer)
	const { applyFilter, clearFilter } = useSearchParams()
	const [companies] = useState([])
	const [disabled, setIsDisabled] = useState(searchParams.disabled)
	const [fromDate, setFromDate] = useState(searchParams.fromDate)
	const [toDate, setToDate] = useState(searchParams.toDate)
	const [role, setRole] = useState(searchParams.role)
	const [company, setCompany] = useState(searchParams.company)
	const [isLoadingCompanies] = useState(false)
	const [permissionGroups, setPermissionGroups] = useState([])
	const [permissionGroup, setPermissionGroup] = useState(searchParams.permissionGroupId)
	const [warehouses, setWarehouses] = useState([])
	const [warehouse, setWarehouse] = useState(searchParams.locationIds?.[0])

	const modifiedRoleOptions = useMemo(() => {
		if (companyDetails?.industry === industries.E_COMMERCE) {
			const modifiedRoles = roleOptions.filter(role => role.value !== roles.DRIVER && role.value !== roles.HELPER)
			if (companyDetails?.industryType === ecommerceIndustryType.HEALTH_CARE) {
				modifiedRoles.push({ value: roles.DOCTOR, label: 'Doctor' })
			}
			return modifiedRoles
		} else {
			return roleOptions
		}
	}, [companyDetails])

	useEffect(() => {
		getGroupPermissions()
		getWarehouses()
	}, [])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	const getGroupPermissions = async () => {
		const response = await Permissions.fetchPermissionGroups({ includeDefaultGroups: true })
		if (response.data) {
			setPermissionGroups(response.data.map(datum => ({
				label: datum.label,
				value: datum.id
			})))
		}
	}

	const getWarehouses = async () => {
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.BRANCH] })
		if (response.data) {
			setWarehouses(response.data.results.map(datum => ({
				label: datum.label || datum.address,
				value: datum.id
			})))
		}
	}

	const searchCompanies = value => {
		return Companies.index({ searchTerm: value })
	}

	const isFilterEnabled = () => {
		return (fromDate && toDate) || disabled !== undefined || role || company || permissionGroup || warehouse
	}

	const handleApply = async () => {
		const filters = {
			disabled
		}

		if (fromDate) {
			filters.fromDate = moment(fromDate).startOf('day').toDate()
		}
		if (toDate) {
			filters.toDate = moment(toDate).endOf('day').toDate()
		}
		if (company) {
			filters.company = company
			filters.companyId = company.value
		}
		if (role) {
			filters.role = role
		}
		if (permissionGroup) {
			filters.permissionGroupId = permissionGroup
		}
		if (warehouse) {
			filters.locationIds = [warehouse]
			filters.emptyLocationsIds = true
		}
		applyFilter(filters)
		setIsFilterDropdownOpen(false)
		router.push(`${paths.USERS}?view=list`)
	}

	const resetFilter = () => {
		setFromDate(null)
		setToDate(null)
		setIsDisabled()
		setRole(null)
		setCompany(null)
		setPermissionGroup(null)
		setWarehouse(null)
		clearFilter()
	}

	return (
		<DropdownOverlay>
			<div style={{ maxWidth: 320, display: 'grid', gap: 24 }}>
				<div>
					<p className={styles.title}>Status</p>
					<Radio.Group
						className={styles.inputGroup}
						onChange={e => setIsDisabled(e.target.value)}
						value={disabled}
					>
						<Radio className={styles.inputTitle} value={false}>Enabled</Radio>
						<Radio className={styles.inputTitle} value={true}>Disabled</Radio>
					</Radio.Group>
				</div>
				<div>
					<p className={styles.title}> Date Range</p>
					<div className={styles.inputFlex}>
						<Input
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Input
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</div>
				</div>
				<div>
					<CustomSelectSecondary
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						onChange={option => setRole(option)}
						value={role}
						title='Role'
						placeholder='Select Role'
						options={modifiedRoleOptions}
						allowClear
					/>
				</div>
				{
					currentRole !== claimsRoles.ADMIN &&
					<div>
						<p className={styles.title}>Warehouse</p>
						<CustomSelectSecondary
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							onChange={option => setWarehouse(option)}
							value={warehouse}
							title='Warehouse'
							placeholder='Select Warehouse'
							options={warehouses}
							allowClear
						/>
					</div>
				}
				{
					currentRole !== claimsRoles.ADMIN &&
					<div>
						<p className={styles.title}>Permission Group</p>
						<CustomSelectSecondary
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							onChange={option => setPermissionGroup(option)}
							value={permissionGroup}
							title='Permission Group'
							placeholder='Select Permission Group'
							options={permissionGroups}
							allowClear
						/>
					</div>
				}
				{
					currentRole === claimsRoles.ADMIN &&
					<div>
						<CustomSearchableSelectSecondary
							placeholder='Search Company Name'
							customLabel={data => {
								return (
									<div>
										<div style={{ fontWeight: 'bold' }}>{data.name}</div>
										<div style={{ fontSize: 12 }}>{moment(data.createdAt).format('LLL')}</div>
									</div>
								)
							}}
							labelIndex='name'
							valueIndex='id'
							allowClear={true}
							isLoading={isLoadingCompanies}
							defaultOptions={companies}
							value={company}
							onChange={option => {
								if (option?.value) {
									setCompany(option)
								}
							}}
							onClear={() => setCompany(null)}
							searchTask={searchCompanies}
						/>
					</div>
				}
				<div className={styles.buttons}>
					{
						isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
					}
					<Button
						type='primary'
						className={styles.button}
						disabled={!isFilterEnabled()}
						onClick={handleApply}
					>
						<span className={styles.buttonText}>
						Apply Filter
						</span>
					</Button>
				</div>
			</div>
		</DropdownOverlay>
	)
}

export default UserFilter
