export const SET_AUTH_USER = 'set_auth_user'
export const FETCH_USER_PROFILE_SUCCESS = 'fetch_user_profile_success'
export const SET_ROLE = 'set_role'
export const SET_COMPANY_ID = 'set_company'
export const SET_PLAN = 'set_plan'
export const SIGN_OUT = 'sign_out'
export const FETCH_PERMISSIONS_SUCCESS = 'fetch_permissions_success'
export const SET_APP = 'set_app'
export const SET_PAGE_NAME = 'set_page_name'
export const SET_COMPANY_DETAILS = 'set_company_details'
