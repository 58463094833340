import { get, post, patch, destroy } from './index'

export const TransportVendors = {
	index: ({
		page = 0,
		disabled,
		searchTerm,
		is3pl
	} = {}) =>
		get('/transport-vendors', {
			params: {
				searchTerm,
				page,
				disabled,
				is3pl
			}
		}),
	single: (id) =>
		get(`/transport-vendors/${id}`),
	create: (params) =>
		post('/transport-vendors', params),
	update: (id, params) =>
		patch(`/transport-vendors/${id}`, params),
	updateVehicle: (id, vehicleId, { status } = {}) =>
		patch(`/transport-vendors/${id}/vehicles/${vehicleId}`, {
			status
		}),
	remove: (id) =>
		destroy(`/transport-vendors/${id}`),
	verifyCredentials: ({ apiKey, clientId, secretKey, username, password, type, accessToken } = {}) => {
		return post('/transport-vendors/credentials-verification', {
			apiKey,
			clientId,
			secretKey,
			username,
			password,
			type,
			accessToken
		})
	}
}
