import { get, patch, post } from './index'

export const Locations = {
	index: ({
		searchTerm,
		page = 0,
		description,
		type,
		fromDate,
		toDate,
		types,
		inventory,
		userLocation,
		usages,
		deleted,
		includeDeleted,
		companyId,
		includeDeliveryPartnerPreference,
		ids
	} = {}) =>
		get('/locations', {
			params: {
				searchTerm,
				page,
				description,
				userLocation,
				fromDate,
				toDate,
				type,
				types,
				inventory,
				usages,
				deleted,
				includeDeleted,
				companyId,
				includeDeliveryPartnerPreference,
				ids
			}
		}),
	create: (params) =>
		post('/locations', params),
	single: (id) =>
		get(`/locations/${id}`),
	update: (id, params) => patch(`/locations/${id}`, params),
	getShippingAddressDetails: (lat, lng) =>
		get(`/locations/shipping/area?lat=${lat}&lng=${lng}`),
	locality: (id) => get(`/locations/${id}/locality`),
	updateLocations: (locations = []) =>
		patch('locations/multiple', { locations }),
	divisions: ({ searchTerm, district, havingVendorsOnly } = {}) =>
		get('/locations/divisions', {
			params: {
				searchTerm,
				district,
				havingVendorsOnly
			}
		}),
	districts: ({ searchTerm, division, havingVendorsOnly, partnerType } = {}) =>
		get('/locations/districts', {
			params: {
				searchTerm,
				division,
				havingVendorsOnly,
				partnerType
			}
		})
}
