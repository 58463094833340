import { useEffect, useState } from 'react'
import styles from './ChatSupport.module.css'
import { MessageOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Users } from '../../services/api/users'
import { CloseOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { industries } from '../../utils/constants'

const ChatSupport = () => {
	const { authUser, userProfile, companyDetails } = useSelector(state => state.authReducer)
	const [unreadCount, setUnreadCount] = useState(0)
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		if (authUser && userProfile && companyDetails?.industry === industries.E_COMMERCE) {
			initFreshChat()
		}
	}, [authUser, userProfile, companyDetails])

	const initFreshChat = async () => {
		if (!window.fcWidget) {
			return
		}
		const {
			fcWidget
		} = window
		if (fcWidget.isInitialized()) {
			return
		}
		const restoreId = userProfile.metadata && userProfile.metadata.freshdeskRestoreId ? userProfile.metadata.freshdeskRestoreId : null
		fcWidget.init({
			token: '6925093d-e5af-459c-a257-c34793df6147',
			host: 'https://nuport.freshchat.com',
			config: {
				headerProperty: {
					hideChatButton: true
				}
			},
			externalId: authUser.uid,
			restoreId
		})
		fcWidget.on('user:created', (res) => {
			linkUser(res)
		})
		fcWidget.on('unreadCount:notify', (res) => {
			if (res) {
				setUnreadCount(res.count)
				if (res.count > 0) {
					setIsVisible(true)
				}
			}
		})
		fcWidget.on('widget:opened', () => {
			setIsVisible(true)
		})
		fcWidget.user.setProperties({
			firstName: authUser.displayName,
			phone: authUser.phoneNumber,
			email: authUser.email,
			uid: authUser.uid,
			cid: userProfile?.company?.name || '',
			'Company Name': companyDetails?.name || ''
		})
		if (!restoreId) {
			await linkUser()
		}
	}

	const linkUser = async (res) => {
		try {
			const {
				fcWidget
			} = window
			const response = res ? res : await fcWidget.user.get()
			if (response && response.status === 200 && response.data) {
				await Users.update(authUser.uid, {
					metadata: {
						freshdeskRestoreId: response.data.restoreId
					}
				})
			}
		} catch (e) {
			console.error(e)
		}
	}

	const showLiveSupport = () => {
		if (window.fcWidget) {
			const { fcWidget } = window
			fcWidget.show()
			fcWidget.open()
		}
	}

	const onDismissChat = e => {
		e.stopPropagation()
		if (window.fcWidget) {
			const { fcWidget } = window
			fcWidget.close()
		}
		setIsVisible(false)
	}

	return (
		isVisible && userProfile && companyDetails?.industry === industries.E_COMMERCE ?
			<div className={styles.iconContainer} onClick={showLiveSupport}>
				<MessageOutlined style={{ color: 'white', fontSize: 24 }} />
				{
					unreadCount > 0 &&
					<div
						className={styles.badge}
					>
						{unreadCount}
					</div>
				}
				<Tooltip title='Dismiss chat.'>
					<div className={styles.dismissButton} onClick={onDismissChat}>
						<CloseOutlined style={{ color: 'white', fontSize: 12 }} />
					</div>
				</Tooltip>
			</div> : null
	)
}

export default ChatSupport
