import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './AdvancedOnHandStockFilter.module.css'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import { convertEnumToString } from '../../utils'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { Button } from 'antd'

const AdvancedOnHandStockFilter = ({ isFilterDropdownOpen, setIsFilterDropdownOpen }) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [locations, setLocations] = useState()
	const [location, setLocation] = useState()
	const [searchTerm, setSearchTerm] = useState('')
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)

	useEffect(() => {
		if (isFilterDropdownOpen) {
			fetchWarehouses()
		}
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!locations?.length || !searchParams.locationId) {
			return
		}
		const location = locations.find(({ id }) => id === searchParams.locationId)

		setLocation({
			id: location.id,
			key: location.id,
			label: location.label,
			data: location
		})
	}, [locations])

	const fetchWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.SITE] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const searchLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.SITE] })
	}

	const handleApply = async () => {
		const filters = {
			searchTerm
		}
		if (location) {
			filters.locationId = location.value
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setLocation(null)
		clearFilter()
	}

	const isFilterEnabled = () => {
		return searchTerm.trim().length > 0 || location
	}

	return (
		<DropdownOverlay className='dropdown-overlay'>
			<div className={styles.dropdownContainer}>
				<div>
					<p className={styles.title}>Warehouse</p>
					<CustomSearchableSelectSecondary
						searchTask={searchLocations}
						defaultOptions={locations}
						isLoading={isLoadingLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						placeholder='Select Warehouse'
						onChange={location => setLocation(location)}
						onClear={() => setLocation(null)}
						value={location}
					/>
				</div>
				<div className={styles.buttons}>
					{
						isFilterEnabled() &&
						<Button
							className={`${styles.button} ${styles.resetButton}`}
							onClick={resetFilter}
						>
							<span className={styles.buttonText}>
								Reset All
							</span>
						</Button>
					}
					<Button
						type='primary'
						className={styles.button}
						disabled={!isFilterEnabled()}
						onClick={handleApply}
					>
						<span className={styles.buttonText}>
							Apply Filter
						</span>
					</Button>
				</div>
			</div>
		</DropdownOverlay>
	)
}

AdvancedOnHandStockFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func
}

export default AdvancedOnHandStockFilter
