import PropTypes from 'prop-types'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'

const DeliveryAreaSelectPaperfly = ({ title, error, value, onChange }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [districts, setDistricts] = useState([])

	useEffect(() => {
		getDeliveryAreas()
	}, [])

	const thanas = useMemo(() => {
		const thanas = districts.find(district => district.value === value?.zone?.value)?.thanas || []
		return thanas.map(thana => ({
			...thana,
			value: thana['thanaName'],
			label: thana['thanaName']
		}))
	}, [value?.zone])

	const getDeliveryAreas = async () => {
		try {
			setIsLoading(true)
			const { data } = await axios.get('/api/paperfly-delivery-areas')
			setDistricts(data.districts.map(district => ({
				...district,
				value: district.district,
				label: district.district
			})))
		} catch (e) {
			console.error(e)
		} finally {
			setIsLoading(false)
		}
	}

	const onZoneChange = async (_, option) => {
		onChange({
			...value,
			zone: option ? { value: option.value, label: option.label } : null,
			area: null
		})
	}

	const onAreaChange = (_, option) => {
		onChange({
			...value,
			area: option ? { value: option.value, label: option.label } : null
		})
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'zone',
					placeholder: 'District',
					loading: isLoading,
					options: districts,
					value: value?.zone || null,
					onChange: onZoneChange,
					onClear: () => onZoneChange(),
					style: { width: '50%' }
				},
				{
					id: 'area',
					placeholder: 'Thana',
					loading: isLoading,
					options: thanas,
					value: value?.area || null,
					onChange: onAreaChange,
					onClear: () => onAreaChange(),
					style: { width: '50%' }
				}
			]}
		/>
	)
}

DeliveryAreaSelectPaperfly.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectPaperfly.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectPaperfly
