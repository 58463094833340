import { Insights } from '../../services/api/insights'
import { FETCH_ORDER_AMOUNT_INSIGHTS_SUCCESS, FETCH_ORDER_COUNT_INSIGHTS_SUCCESS } from './action-types'


export function fetchOrderCountInsights ({
	page,
	fromDate,
	toDate,
	pageSize
} = {}) {
	return async (dispatch) => {
		const response = await Insights.index({
			type: 'SALES_ORDERS_COUNT',
			page,
			fromDate,
			toDate,
			pageSize
		})
		dispatch({ type: FETCH_ORDER_COUNT_INSIGHTS_SUCCESS, payload: { data: response.data } })
	}
}

export function fetchOrderAmountInsights ({
	page,
	fromDate,
	toDate,
	pageSize
} = {}) {
	return async (dispatch) => {
		const response = await Insights.index({
			type: 'SALES_ORDERS_AMOUNT',
			page,
			fromDate,
			toDate,
			pageSize
		})
		dispatch({ type: FETCH_ORDER_AMOUNT_INSIGHTS_SUCCESS, payload: { data: response.data } })
	}
}
