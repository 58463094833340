import moment from 'moment'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { DeliveryPlans } from '../../../services/api/delivery-plans'
import { convertWeightUnit, WeightUnits } from '../../../utils/units'
import styles from './DeliveryPlanItem.module.css'

export const DELIVERY_PLAN_ITEM_CHALLAN_LOADED = 'delivery-plan-item-loaded'

const DeliveryPlanItemChallan = () => {
	const router = useRouter()
	const { query, isReady } = router
	const { id, accessToken } = query
	const [salesOrders, setSalesOrders] = useState([])
	const [deliveryPlan, setDeliveryPlan] = useState()

	useEffect(() => {
		if (isReady) {
			if (id && accessToken) {
				getDeliveryPlanItemDetails()
			}
		}
	}, [isReady])

	useEffect(() => {
		if (salesOrders && salesOrders.length > 0) {
			parent.postMessage({ action: DELIVERY_PLAN_ITEM_CHALLAN_LOADED })
		}
	}, [salesOrders])

	const getDeliveryPlanItemDetails = async () => {
		const response = await DeliveryPlans.singleDeliveryPlanItem(id, { accessToken })
		const { data } = response
		if (data) {
			setDeliveryPlan(data.deliveryPlan)
			const salesOrders = getSortedSalesOrdersByDistance(data.route, data.salesOrders)
			setSalesOrders(salesOrders)
		}
	}

	const findDistance = (route, salesOrder) => {
		const point = route.find(point => point.id === salesOrder.id)
		return point?.distance || 0
	}

	const getSortedSalesOrdersByDistance = (route, salesOrders) => {
		return salesOrders.sort((so1, so2) => {
			return findDistance(route, so2) - findDistance(route, so1)
		})
	}

	const getTotalQuantity = (salesOrder) => {
		return salesOrder.salesOrderItems
			.map(salesOrderItem => +salesOrderItem.quantity)
			.reduce((a, b) => a + b, 0)
	}

	const getTotalWeight = (salesOrder) => {
		return salesOrder.salesOrderItems
			.map(salesOrderItem => convertWeightUnit(+salesOrderItem.totalWeight, WeightUnits.KG))
			.reduce((a, b) => a + b, 0)
	}

	return (
		<div id='content' className='pdf-content'>
			{
				salesOrders.map((salesOrder, index) => {
					return (
						<div className={styles.page} key={index}>
							<div style={{ display: 'flex' }}>
								<div>
									{
										deliveryPlan &&
										<div className={styles.valueContainer}>
											<div className={styles.valueTitle}>
											Delivery No.
											</div>
											<div className={styles.value}>
												{`DP-${deliveryPlan.shortId}`}
											</div>
										</div>
									}
									<div className={styles.valueContainer}>
										<div className={styles.valueTitle}>
											Ref No.
										</div>
										<div className={styles.value}>
											{salesOrder.internalId}
										</div>
									</div>
								</div>
								<div className={styles.valueContainer} style={{ marginLeft: 'auto' }}>
									<div className={styles.valueTitle}>
										Dated
									</div>
									<div className={styles.value}>
										{moment(salesOrder.deliveryDate).format('MMM D, YYYY')}
									</div>
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
								{
									deliveryPlan &&
									<div className={styles.columnValueContainer}>
										<div style={{ fontWeight: 'bold' }}>
											{deliveryPlan.company.name}
										</div>
										{
											deliveryPlan.company.locations && deliveryPlan.company.locations.length > 0 &&
										<div className={styles.columnValueContainer}>
											{deliveryPlan.company.locations[0].address}
										</div>
										}
										<div>
											{deliveryPlan.company.phone}
										</div>
									</div>
								}
								<h4 style={{ fontWeight: 'bold', margin: '6px 0' }}>DELIVERY NOTE</h4>
								<div className={styles.columnValueContainer}>
									{
										salesOrder.distributor &&
										<div>
											{salesOrder.distributor.name}
										</div>
									}
									{
										salesOrder.location &&
										<div className={styles.columnValueContainer}>
											{salesOrder.location.address}
										</div>
									}
									{
										salesOrder.distributor &&
										<div>
											{salesOrder.distributor.phone}
										</div>
									}
								</div>
							</div>
							<div className={styles.table}>
								<div style={{ borderBottom: '1px solid black' }}>
									<div colSpan='3'>
										<div style={{ display: 'flex' }}>
											<div style={{ textAlign: 'start', padding: '4px' }}>
												<div style={{ fontSize: 12, fontWeight: 'bold' }}>Order No.</div>
												<div style={{ fontSize: 12 }}>{salesOrder.internalId}</div>
												<div style={{ fontSize: 12 }}>{moment(salesOrder.deliveryDate).format('MMM D, YYYY')}</div>
											</div>
										</div>
									</div>
								</div>
								<div style={{ borderBottom: '1px solid black', display: 'flex' }}>
									<div style={{ borderRight: '1px solid black', flex: 1 }}><div className={styles.smallValue}>No.</div></div>
									<div style={{ flex: 8 }}><div className={styles.smallValue}>Description of Goods</div></div>
									<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}><div className={styles.smallValue}>Weight</div></div>
									<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}><div className={styles.smallValue}>Quantity</div></div>
								</div>
								{
									salesOrder.salesOrderItems.map((salesOrderItem, index) => {
										return (
											<div key={index} style={{ borderBottom: '1px solid black', display: 'flex' }}>
												<div style={{ borderRight: '1px solid black', flex: 1 }}>
													<div className={styles.smallValue}>{index + 1}</div>
												</div>
												<div style={{ flex: 8 }}>
													<div className={styles.smallValue} style={{ fontWeight: 'bold', textAlign: 'left', padding: '4px 4px' }}>
														{salesOrderItem.product.name}
													</div>
												</div>
												<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}>
													<div className={styles.smallValue} style={{ fontWeight: 'bold' }}>
														{(+salesOrderItem.totalWeight).toFixed(2)} {salesOrderItem.totalWeightUnit}
													</div>
												</div>
												<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}>
													<div className={styles.smallValue} style={{ fontWeight: 'bold' }}>
														{salesOrderItem.quantity}
													</div>
												</div>
											</div>
										)
									})
								}
								<div style={{ borderBottom: '1px solid black', display: 'flex', flex: 1 }}>
									<div style={{ borderRight: '1px solid black', flex: 1 }}><div className={styles.smallValue} /></div>
									<div style={{ verticalAlign: 'top', textAlign: 'start', flex: 8 }}>
										<div className={styles.smallValue} style={{ textAlign: 'end', fontWeight: 'bold' }}>
											Discount
										</div>
									</div>
									<div style={{ borderLeft: '1px solid black', verticalAlign: 'top', flex: 2, maxWidth: 103.73 }} />
									<div style={{ borderLeft: '1px solid black', verticalAlign: 'top', flex: 2, maxWidth: 103.73 }}>
										<div className={styles.smallValue} style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>
											{salesOrder.discount}%
										</div>
									</div>
								</div>
								<div style={{ display: 'flex' }}>
									<div style={{ borderRight: '1px solid black', flex: 1 }}><div className={styles.smallValue} /></div>
									<div style={{ display: 'flex', flex: 8 }}>
										<div className={styles.smallValue} style={{ fontWeight: 'bold', marginLeft: 'auto', fontSize: 12 }}>
											Total
										</div>
									</div>
									<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}>
										<div className={styles.smallValue} style={{ fontWeight: 'bold', fontSize: 12 }}>
											{getTotalWeight(salesOrder).toFixed(2)} kg
										</div>
									</div>
									<div style={{ borderLeft: '1px solid black', flex: 2, maxWidth: 103.73 }}>
										<div className={styles.smallValue} style={{ fontWeight: 'bold' }}>
											{getTotalQuantity(salesOrder)}
										</div>
									</div>
								</div>
							</div>
							<div className={styles.valueContainer} style={{ marginTop: 'auto', paddingTop: 12 }}>
								<div
									className={styles.value}
									style={{ textAlign: 'left' }}
								>
									<span>Additional Notes:</span>
									<span
										className={styles.smallValue}
										style={{ fontSize: 13, marginLeft: 4 }}
									>
										{salesOrder.additionalNotes}
									</span>
								</div>
							</div>
							<div className={styles.valueContainer} style={{ marginTop: 'auto', paddingTop: 12 }}>
								<div className={styles.value}>
									Received in Good Condition
								</div>
							</div>
							<div className={styles.divider} />
							<div style={{ marginTop: 12, display: 'flex' }}>
								<div className={styles.value} style={{ flex: 1 }}>Customer Signature</div>
								<div className={styles.value} style={{ flex: 1 }}>Prepared By</div>
								<div className={styles.value} style={{ flex: 1 }}>Checked By</div>
								<div className={styles.value} style={{ flex: 1 }}>Approved By</div>
							</div>
						</div>
					)
				})
			}
		</div>
	)
}

export default DeliveryPlanItemChallan
