import { destroy, get, patch, post } from './index'

export const Geofences = {
	index: () =>
		get('/geofences'),
	create: ({
		name,
		shape,
		color,
		center,
		radius,
		points
	} = {}) =>
		post('/geofences', {
			name,
			shape,
			color,
			center,
			radius,
			points
		}),
	update: (id, {
		name,
		shape,
		color,
		center,
		radius,
		points
	} = {}) =>
		patch(`/geofences/${id}`, {
			name,
			shape,
			color,
			center,
			radius,
			points
		}),
	delete: (id) => destroy(`/geofences/${id}`)
}
