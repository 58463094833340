import moment from 'moment'
import { calculateAmountAfterDiscount, calculateDiscount } from '.'
import { convertVolumeUnit, convertWeightUnit, WeightUnits } from './units'
import { orderStatuses } from './constants'

export const getShortId = (salesOrder, { invoiceIdInitial } = {}) => {
	const copyCount = `${salesOrder.copyCount > 0 ? `-${salesOrder.copyCount}` : ''}`
	const isReturn = salesOrder.isReturn

	if (salesOrder.internalId) {
		return salesOrder.internalId
	}
	if (invoiceIdInitial) {
		return `${invoiceIdInitial}${salesOrder.shortId}${copyCount}`
	} else {
		return `${salesOrder.isStockTransfer ? 'STO' : 'SO'}-${String(salesOrder.shortId).padStart(4, '0')}${copyCount}${isReturn ? 'R' : ''}`
	}
}

export const getSalesOrderWeightInTonnes = (salesOrder) => {
	const salesOrderItems = salesOrder.salesOrderItems || []
	let totalWeight = 0
	for (const salesOrderItem of salesOrderItems) {
		const weightInKgs = convertWeightUnit(+salesOrderItem.totalWeight, salesOrderItem.totalWeightUnit)
		totalWeight += weightInKgs
	}
	return (totalWeight / 1000).toFixed(2)
}

export const getSalesOrderVolumeInCbm = (salesOrder) => {
	const salesOrderItems = salesOrder.salesOrderItems || []
	let totalVolume = 0
	for (const salesOrderItem of salesOrderItems) {
		const volumeInCbm = convertWeightUnit(+salesOrderItem.totalVolume, salesOrderItem.totalVolumeUnit)
		totalVolume += volumeInCbm
	}
	return totalVolume.toFixed(2)
}

export const calculateSalesOrderMetrics = (salesOrders) => {
	let totalAmount = 0
	const distributorCountMap = {}
	const pickUpLocationMap = {}
	const salesOrderItems = salesOrders
		.map(salesOrder => {
			if (salesOrder.distributor) {
				distributorCountMap[salesOrder.distributor.id] = true
			}
			const pickUpLocation = salesOrder.pickUpLocation
			if (pickUpLocation) {
				pickUpLocationMap[pickUpLocation.id] = pickUpLocation
			}

			totalAmount += +salesOrder.totalAmount || 0
			return salesOrder.salesOrderItems
		})
		.reduce((a, b) => a.concat(b), [])
	let totalWeight = 0
	let totalVolume = 0
	for (const salesOrderItem of salesOrderItems) {
		const weightInKgs = convertWeightUnit(+salesOrderItem?.totalWeight, salesOrderItem?.totalWeightUnit)
		const volumeInCbm = convertVolumeUnit(+salesOrderItem?.totalVolume, salesOrderItem?.totalVolumeUnit)
		totalWeight += weightInKgs
		totalVolume += volumeInCbm
	}

	return {
		totalAmount,
		totalWeightInTonnes: totalWeight / 1000,
		totalVolumeInCbm: totalVolume,
		distributorCount: Object.keys(distributorCountMap).length,
		pickUpLocations: Object.values(pickUpLocationMap)
	}
}

export const calculateSalesAmountAfterDiscount = (order) => {
	const hasDeliveredQty = order.salesOrderItems?.some(salesOrderItem => {
		return salesOrderItem.deliveredQuantity > 0
	})
	const totalItemAmount = order.salesOrderItems?.reduce((acc, salesOrderItem) => {
		let quantity = salesOrderItem.packageQuantity > 0 ? salesOrderItem.packageQuantity : salesOrderItem.quantity
		if (hasDeliveredQty) {
			quantity = salesOrderItem.deliveredPackageQuantity > 0 ? salesOrderItem.deliveredPackageQuantity : salesOrderItem.deliveredQuantity
		}
		const price = +salesOrderItem.price
		const totalPrice = price * quantity
		return acc + Number(calculateAmountAfterDiscount(totalPrice, salesOrderItem.discountAmount <= totalPrice ? salesOrderItem.discountAmount : 0, salesOrderItem.discount))
	}, 0)
	return calculateAmountAfterDiscount(totalItemAmount, order.discountAmount, order.discount).toFixed(2)
}

export const getSalesOrderItemWeight = (salesOrderItem) => {
	let weight = 0
	let weightUnit = WeightUnits.KG
	const salesOrderItemSpecifications = salesOrderItem.salesOrderItemSpecifications ? salesOrderItem.salesOrderItemSpecifications : []
	const weightSpecification = salesOrderItemSpecifications.find(spec => spec.specificationKey === 'weight')
	if (weightSpecification) {
		weight = weightSpecification.value
		weightUnit = weightSpecification.unit
	} else {
		const weightSpecification = salesOrderItem.product.productSpecifications.find(spec => spec.specificationKey === 'weight')
		if (weightSpecification) {
			weight = weightSpecification.value
			weightUnit = weightSpecification.unit
		}
	}
	return { weight: isNaN(+weight) ? 0 : +weight, weightUnit: weightUnit ? weightUnit : WeightUnits.KG }
}

export const internalNotesModifier = (notes, user) => {
	if (notes?.trim()?.length === 0) {
		return
	}
	return `${notes} [At ${moment().format('hh:mm A')} On ${moment().format('D MMM YYYY')} By ${user}]`
}

export const getQuantityField = (status, isAdvancedInventory = false) => {
	switch (status?.toLowerCase()) {
		case orderStatuses.SALES_ORDER_APPROVED_STATUS:
		case orderStatuses.SALES_ORDER_SHIPPED_STATUS:
		case orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS:
		case orderStatuses.SALES_ORDER_UNLOADED_STATUS:
		case orderStatuses.SALES_ORDER_ARRIVED_STATUS:
		case orderStatuses.SALES_ORDER_PROCESSING_STATUS:
			return isAdvancedInventory ? 'approvedQuantity' : 'quantity'
		case orderStatuses.SALES_ORDER_COMPLETED_STATUS:
			return 'deliveredQuantity'
		case orderStatuses.SALES_ORDER_REQUESTED_STATUS:
		case orderStatuses.SALES_ORDER_CANCELLED_STATUS:
		case orderStatuses.SALES_ORDER_FLAGGED_STATUS:
		default:
			return 'quantity'
	}
}

export const getQuantityFields = (status, isAdvancedInventory = false) => {
	switch (status?.toLowerCase()) {
		case orderStatuses.SALES_ORDER_APPROVED_STATUS:
		case orderStatuses.SALES_ORDER_SHIPPED_STATUS:
		case orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS:
		case orderStatuses.SALES_ORDER_UNLOADED_STATUS:
		case orderStatuses.SALES_ORDER_ARRIVED_STATUS:
		case orderStatuses.SALES_ORDER_PROCESSING_STATUS:
			return isAdvancedInventory ? {
				quantityField: 'approvedQuantity',
				packageQuantityField: 'approvedPackageQuantity'
			} : {
				quantityField: 'quantity',
				packageQuantityField: 'packageQuantity'
			}
		case orderStatuses.SALES_ORDER_COMPLETED_STATUS:
			return {
				quantityField: 'deliveredQuantity',
				packageQuantityField: 'deliveredPackageQuantity'
			}
		case orderStatuses.SALES_ORDER_REQUESTED_STATUS:
		case orderStatuses.SALES_ORDER_CANCELLED_STATUS:
		case orderStatuses.SALES_ORDER_FLAGGED_STATUS:
		default:
			return {
				quantityField: 'quantity',
				packageQuantityField: 'packageQuantity'
			}
	}
}

export const getItemTotals = (salesOrderItem, quantityField = '') => {
	const {
		discount,
		discountAmount,
		packaging
	} = salesOrderItem
	const price = +salesOrderItem.price !== +salesOrderItem.product.price ? +salesOrderItem.price : +salesOrderItem.product.price

	let quantityFieldName = ''
	let packageQuantityFieldName = ''
	switch (quantityField) {
		case 'quantity': {
			quantityFieldName = 'quantity'
			packageQuantityFieldName = 'packageQuantity'
			break
		}
		case 'approvedQuantity': {
			quantityFieldName = 'approvedQuantity'
			packageQuantityFieldName = 'approvedPackageQuantity'
			break
		}
		case 'deliveredQuantity': {
			quantityFieldName = 'deliveredQuantity'
			packageQuantityFieldName = 'deliveredPackageQuantity'
			break
		}
		case 'flaggedQuantity': {
			quantityFieldName = 'flaggedQuantity'
			packageQuantityFieldName = 'flaggedPackageQuantity'
			break
		}
		default:
			// no-op
	}
	let quantity = 0
	let packageQuantity = 0
	if (quantityFieldName && packageQuantityFieldName) {
		quantity = salesOrderItem[quantityFieldName] || 0
		packageQuantity = salesOrderItem[packageQuantityFieldName] || 0
	} else {
		quantity = salesOrderItem.deliveredQuantity || salesOrderItem.approvedQuantity || salesOrderItem.quantity
		packageQuantity = salesOrderItem.deliveredPackageQuantity || salesOrderItem.approvedPackageQuantity || salesOrderItem.packageQuantity
	}

	const totalQuantity = packaging ? (packaging.size * packageQuantity) + quantity : quantity
	const totalPrice = totalQuantity * +price
	let discountValue = 0
	if (+discount > 0) {
		discountValue = calculateDiscount(totalPrice, +discount)
	} else {
		discountValue = +discountAmount
	}

	return {
		discountedPrice: totalPrice - discountValue,
		discountValue,
		totalPrice,
		totalQuantity
	}
}

export const convertDiscountAmountToPercentage = (totalAmount = 0, discountAmount = 0) => {
	return totalAmount ? discountAmount / totalAmount * 100 : 0
}
