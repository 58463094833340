import { createContext, useContext, useState } from 'react'

const PageContext = createContext({})

export default function PageDataContext ({ children }) {
	const [pageTitle, setPageTitle] = useState()
	const [pageChildTitle, setPageChildTitle] = useState()
	const [backPageLink, setBackPageLink] = useState()

	return (
		<PageContext.Provider value={{
			pageTitle,
			setPageTitle,
			pageChildTitle,
			setPageChildTitle,
			backPageLink,
			setBackPageLink
		}}
		>
			{children}
		</PageContext.Provider>
	)
}

export const usePage = () => useContext(PageContext)
