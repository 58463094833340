import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Select } from 'antd'
import Input from '../input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './LocationFilter.module.css'
import { locationTypes } from '../../utils/constants'
import DropdownOverlay from '../dropdown-overlay'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
require('./LocationFilter.less')

const LocationFilter = ({ setIsFilterDropdownOpen }) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter, isSearchApplied } = useSearchParams()
	const [fromDate, setFromDate] = useState(searchParams.fromDate || null)
	const [toDate, setToDate] = useState(searchParams.toDate || null)
	const [types, setTypes] = useState(searchParams.types || [])
	const [searchTerm, setSearchTerm] = useState('')

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	const isFilterEnabled = () => {
		return searchTerm || (fromDate && toDate) || types.length > 0
	}

	const handleApply = async () => {
		const filters = {
			searchTerm
		}

		if (fromDate && toDate) {
			filters.fromDate = moment(fromDate).startOf('day').toDate()
			filters.toDate = moment(toDate).endOf('day').toDate()
		}
		if (types && types.length) {
			filters.types = types
		} else {
			filters.types = []
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setTypes([])
		clearFilter()
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Type</p>
				<Select
					mode='multiple'
					allowClear
					style={{ width: '100%' }}
					placeholder='Select Types'
					className='location-type-select'
					value={types}
					options={locationTypes}
					onChange={setTypes}
				/>
			</div>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='From'
						type='date'
						value={fromDate ? moment(fromDate) : null }
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='To'
						type='date'
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>

			<div className={styles.buttons}>
				{
					(isFilterEnabled() || isSearchApplied) &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

LocationFilter.propTypes = {
	setIsFilterDropdownOpen: PropTypes.func
}

export default LocationFilter

