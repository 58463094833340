import { useEffect, useMemo, useRef, useState } from 'react'
import Link from 'next/link'
import styles from './ExpandableSidebarLink.module.css'
import { useRouter } from 'next/router'
import { Tooltip } from 'antd'
import useSearchParams from '../../hooks/useSearchParams'

const isActive = (path, router) => router.pathname === path.split('?')[0]

const ExpandableSidebarLink = ({
	link: { title, icon, children },
	expanded
}) => {
	const router = useRouter()
	const { clearSearchParams } = useSearchParams()
	const [hasExpanded, setHasExpanded] = useState(false)
	const [isShowingPreview, setIsShowingPreview] = useState(false)
	const [isTooltipVisible, setIsTooltipVisible] = useState(false)
	const [offsetTop, setOffsetTop] = useState(0)
	const doesContainActiveLink = useMemo(() => children.some(({ path }) => isActive(path, router)), [router.pathname])
	const headerRef = useRef(null)
	const previewRef = useRef(null)

	useEffect(() => {
		const updateIsShowingPreview = ({ target }) => {
			const isLinkHeader = target.closest(`.${styles.linkHeader}`)
			if (isLinkHeader) {
				return
			}
			setIsShowingPreview(!!target.closest(`.${styles.preview}`))
		}

		window.addEventListener('click', updateIsShowingPreview)
		return () => window.removeEventListener('click', updateIsShowingPreview)
	}, [])

	useEffect(() => {
		const sidebar = document.querySelector('.sidebar')
		const updateOffsetTop = () => {
			if (!isShowingPreview) {
				return
			}

			setOffsetTop(getOffsetTop())
		}

		sidebar.addEventListener('scroll', updateOffsetTop)
		return () => sidebar.removeEventListener('scroll', updateOffsetTop)
	}, [isShowingPreview])

	useEffect(() => {
		if (expanded) {
			return setIsShowingPreview(false)
		}
		setHasExpanded(false)
	}, [expanded])

	useEffect(() => expanded || isShowingPreview && setIsTooltipVisible(false), [expanded, isShowingPreview])

	const onClick = () => {
		expanded ? toggleExpand() : togglePreview()
		setOffsetTop(getOffsetTop())
	}

	const toggleExpand = () => setHasExpanded(!hasExpanded)

	const togglePreview = () => !expanded && setIsShowingPreview(!isShowingPreview)

	const getOffsetTop = () => {
		const { top } = headerRef.current.getBoundingClientRect()
		const doesPreviewOverflow = top + previewRef.current.offsetHeight > window.innerHeight || top > window.innerHeight
		return doesPreviewOverflow ?
			Math.min(
				(top - previewRef.current.offsetHeight) + headerRef.current.offsetHeight,
				window.innerHeight - previewRef.current.offsetHeight - 8
			) :
			top
	}

	const renderLinks = () => {
		return children.map(({ title, path }) => {
			return (
				<Link key={path} href={path}>
					<a
						className={`${styles.link} ${isActive(path, router) && styles.linkActive}`}
						onClick={(e) => {
							e.stopPropagation()
							setIsShowingPreview(false)
							clearSearchParams()
						}}
					>
						<span className={styles.dot} />
						<span className={styles.linkTitle}>{title}</span>
					</a>
				</Link>
			)
		})
	}

	return (
		<div className={styles.root}>
			<div className={`${styles.expandableSidebarLink} ${expanded && styles.sidebarExpanded} ${hasExpanded && styles.linkExpanded}`}>
				<Tooltip
					visible={isTooltipVisible}
					title={title}
					placement='right'
					zIndex={1103}
				>
					<button
						className={`${styles.linkHeader} ${doesContainActiveLink && styles.linkHeaderActive} ${hasExpanded && styles.linkHeaderExpanded}`}
						onClick={onClick}
						onMouseEnter={() => {
							if (expanded || isShowingPreview) {
								return setIsTooltipVisible(false)
							}
							setIsTooltipVisible(true)
						}}
						onMouseLeave={() => setIsTooltipVisible(false)}
						ref={headerRef}
					>
						<span className={styles.titleContainer}>
							<div className={styles.iconContainer}>
								<svg className={styles.icon}>
									<use href={icon} />
								</svg>
							</div>
							<span className={styles.title}>{title}</span>
						</span>
						<svg className={`${styles.chevron} ${hasExpanded && styles.chevronInverted}`}>
							<use href='/img/chevron-up.svg#icon' />
						</svg>
					</button>
				</Tooltip>
				<div className={styles.links}>{renderLinks()}</div>
			</div>
			<div
				style={{ top: offsetTop }}
				className={`${styles.preview} ${isShowingPreview && styles.previewVisible}`}
				ref={previewRef}
			>
				{renderLinks()}
			</div>
		</div>
	)
}

export default ExpandableSidebarLink
