import { get, post, patch } from './index'

export const Vehicles = {
	index: ({
		page = 0,
		toDate,
		fromDate,
		searchTerm,
		statuses,
		unassigned,
		type,
		deliveryPlanItemId,
		transportVendorId,
		locationId,
		locationIds,
		driver,
		helper,
		trips,
		available,
		sortNullLocation
	} = {}) => {
		const config = {
			params: {
				page,
				toDate,
				fromDate,
				searchTerm,
				statuses,
				unassigned,
				type,
				deliveryPlanItemId,
				transportVendorId,
				locationId,
				locationIds,
				helper,
				driver,
				trips,
				available,
				sortNullLocation
			}
		}
		return get('/vehicles', config)
	},
	fetchVehicleTypes: () => {
		return get('/vehicles/types')
	},
	fetchTrackerTypes: () => {
		return get('/vehicles/tracker-types')
	},
	create: (data) => {
		return post('/vehicles', data)
	},
	update: (id, data) => {
		return patch(`/vehicles/${id}`, data)
	},
	updateStatus: (id, data) => {
		return patch(`/vehicles/${id}/status`, data)
	},
	single: (id) => get(`/vehicles/${id}`),
	getMetrics: () => get('/vehicles/metrics'),
	fetchVehicleTrips: (
		id,
		{
			page = 0,
			status,
			searchTerm,
			fromDate,
			toDate
		}
	) => {
		const config = {
			params: {
				page,
				status,
				searchTerm,
				fromDate,
				toDate
			}
		}
		return get(`/vehicles/${id}/trips`, config)
	},
	fetchActualMileage: (id, { startDate, endDate } = {}) => {
		return get(`/vehicles/${id}/actual-mileage`, { params: { startDate, endDate } })
	},
	fetchRefuels: (
		id,
		{
			page = 0
		}
	) => {
		const config = {
			params: {
				page
			}
		}
		return get(`/vehicles/${id}/refuels`, config)
	},
	createRefuel: (id, data) => {
		return post(`/vehicles/${id}/refuels`, data)
	},
	fetchRefuelDetail: (vehicleId, refuelId) => {
		return get(`/vehicles/${vehicleId}/refuels/${refuelId}`)
	},
	updateRefuelDetail: (vehicleId, refuelId, data) => {
		return patch(`/vehicles/${vehicleId}/refuels/${refuelId}`, data)
	},
	fetchUserVehicle: (userId) => get(`/vehicles/users/${userId}`)
}
