import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { DeliveryPartners } from '../../services/api/delivery-partner'

const DeliveryAreaSelectRiderCourier = ({ deliveryPartnerId, title, error, value, onChange }) => {
	const [isLoadingCities, setIsLoadingCities] = useState(false)
	const [isLoadingAreas, setIsLoadingAreas] = useState(false)
	const [cities, setCities] = useState([])
	const [areas, setAreas] = useState([])

	useEffect(() => {
		if (deliveryPartnerId) {
			getCities(deliveryPartnerId)
		}
	}, [deliveryPartnerId])

	useEffect(() => {
		if (!value?.city?.value) {
			setAreas([])
			return
		}
		getAreas(value.city.value)
	}, [value?.city?.value])

	const getCities = async deliveryPartnerId => {
		setIsLoadingCities(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryCities(deliveryPartnerId)
			const cityOptions = data?.map(city => ({
				...city,
				value: city.id,
				label: city.description || city.title
			})) || []
			setCities(cityOptions)
		} catch (e) {
			onChange({ ...value, originCity: null, city: null, area: null })
			setCities([])
			setAreas([])
			notification.error({
				message: 'Unable To Get Cities',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingCities(false)
		}
	}

	const getAreas = async cityId => {
		setIsLoadingAreas(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryAreas(deliveryPartnerId, { cityId })
			const areaOptions = data?.map(area => ({
				...area,
				value: area.id,
				label: area.description || area.title
			})) || []
			setAreas(areaOptions)
		} catch (e) {
			onChange({ ...value, area: null })
			notification.error({
				message: 'Unable To Get Areas',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingAreas(false)
		}
	}

	const onOriginCityChange = (_, option) => {
		onChange({
			...value,
			originCity: option ? { value: option.value, label: option.label } : null
		})
	}

	const onCityChange = (_, option) => {
		onChange({
			...value,
			city: option ? { value: option.value, label: option.label } : null,
			area: null
		})
	}

	const onAreaChange = (_, option) => {
		onChange({
			...value,
			area: option ? { value: option.value, label: option.label } : null
		})
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'originCity',
					placeholder: 'Origin City',
					loading: isLoadingCities,
					options: cities,
					value: value?.originCity || null,
					onChange: onOriginCityChange,
					onClear: () => onOriginCityChange(),
					style: { width: '33%' }
				},
				{
					id: 'city',
					placeholder: 'City',
					loading: isLoadingCities,
					options: cities,
					value: value?.city || null,
					onChange: onCityChange,
					onClear: () => onCityChange(),
					style: { width: '33%' }
				},
				{
					id: 'area',
					placeholder: 'Area',
					loading: isLoadingAreas,
					options: areas,
					value: value?.area || null,
					onChange: onAreaChange,
					onClear: () => onAreaChange(),
					style: { width: '33%' }
				}
			]}
		/>
	)
}

DeliveryAreaSelectRiderCourier.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectRiderCourier.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectRiderCourier
