import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { notification, Select } from 'antd'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { Locations } from '../../services/api/locations'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { PartnerType } from '../../utils/constants'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import axios from 'axios'
import { RightOutlined } from '@ant-design/icons'
require('./DeliveryAreaSelectRedX.less')

const DeliveryAreaSelectRedX = ({ deliveryPartnerId, title, error, value, onChange }) => {
	const [isLoadingDivisions, setIsLoadingDivisions] = useState(false)
	const [isLoadingDistricts, setIsLoadingDistricts] = useState(false)
	const [isLoadingAreas, setIsLoadingAreas] = useState(false)
	const [divisions, setDivisions] = useState([])
	const [districts, setDistricts] = useState([])
	const [areas, setAreas] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [isSearchingAreas, setIsSearchingAreas] = useState(false)
	const [searchResults, setSearchResults] = useState([])
	const debouncedSearchTerm = useDebounceValue(searchTerm, 500)
	const lastValidSearchTerm = useRef(searchTerm)
	const lastKeyPressed = useRef(null)

	useEffect(() => {
		getDivisions()
	}, [])

	useEffect(() => {
		if (!value?.division?.value) {
			setDistricts([])
			setAreas([])
			return
		}
		getDistricts(value.division.value)
	}, [value?.division?.value])

	useEffect(() => {
		if (!value?.district?.value) {
			setAreas([])
			return
		}
		getAreas(deliveryPartnerId, value.district.value)
	}, [value?.district?.value])

	useEffect(() => {
		searchAreas(debouncedSearchTerm)
	}, [debouncedSearchTerm])

	useEffect(() => {
		if (searchTerm !== '') {
			lastValidSearchTerm.current = searchTerm
		}
	}, [searchTerm])

	const getDivisions = async () => {
		setIsLoadingDivisions(true)
		try {
			const { data } = await Locations.divisions()
			setDivisions(data?.length ? data : [])
		} catch (e) {
			onChange({ ...value, division: null })
			setDivisions([])
			notification.error({
				message: 'Unable To Get Divisions',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDivisions(false)
		}
	}

	const getDistricts = async division => {
		setIsLoadingDistricts(true)
		try {
			const { data } = await Locations.districts({ division, partnerType: PartnerType.REDX })
			setDistricts(data?.length ? data : [])
		} catch (e) {
			onChange({ ...value, district: null })
			setDistricts([])
			notification.error({
				message: 'Unable To Get Districts',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDistricts(false)
		}
	}

	const getAreas = async (deliveryPartnerId, district) => {
		setIsLoadingAreas(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryAreas(deliveryPartnerId, { district })
			const areaOptions = data?.map(area => ({
				...area,
				value: area.id,
				label: area.name
			})) || []
			setAreas(areaOptions)
		} catch (e) {
			onChange({ ...value, area: null })
			setAreas([])
			notification.error({
				message: 'Unable To Get Areas',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingAreas(false)
		}
	}

	const searchAreas = async (searchValue) => {
		setIsSearchingAreas(true)
		try {
			const { data } = await axios.get('/api/redx-areas', {
				params: {
					searchTerm: searchValue
				}
			})
			if (!data.isError && data.body) {
				setSearchResults(data.body.areas)
			} else {
				setSearchResults([])
			}
		} catch (e) {
			notification.error({
				message: 'Unable To Find Areas',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSearchingAreas(false)
		}
	}

	const onDivisionChange = async (_, option) => {
		onChange({
			...value,
			division: option ? { value: option.value, label: option.label } : null,
			district: null,
			area: null
		})
	}

	const onDistrictChange = async (_, option) => {
		onChange({
			...value,
			district: option ? { value: option.value, label: option.label } : null,
			area: null
		})
	}

	const onAreaChange = (_, option) => {
		onChange({
			...value,
			area: option ? { value: option.value, label: option.label } : null
		})
	}

	const onResultChange = (option) => {
		const [division, district, area] = option.value.split('~')
		const divisionOption = {
			value: division,
			label: division.toUpperCase()
		}
		const districOption = {
			value: district,
			label: district.toUpperCase()
		}
		const areaOption = {
			value: +area,
			label: option.label
		}
		if (!divisions.find(division => division.value === divisionOption.value)) {
			setDivisions([...divisions, divisionOption])
		}
		if (!districts.find(district => district.value === districOption.value)) {
			setDistricts([...districts, districOption])
		}
		if (!areas.find(area => area.value === areaOption.value)) {
			setAreas([...areas, areaOption])
		}

		onChange({
			...value,
			division: divisionOption,
			district: districOption,
			area: areaOption
		})
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'division',
					placeholder: 'Division',
					loading: isLoadingDivisions,
					options: divisions,
					value: value?.division || null,
					onChange: onDivisionChange,
					onClear: () => onDivisionChange(),
					style: { width: '33%' }
				},
				{
					id: 'district',
					placeholder: 'District',
					loading: isLoadingDistricts,
					options: districts,
					value: value?.district || null,
					onChange: onDistrictChange,
					onClear: () => onDistrictChange(),
					style: { width: '33%' }
				},
				{
					id: 'area',
					placeholder: 'Area',
					loading: isLoadingAreas,
					options: areas,
					value: value?.area || null,
					onChange: onAreaChange,
					onClear: () => onAreaChange(),
					style: { width: '33%' }
				}
			]}
			style={{
				height: 96
			}}
			component={
				<div>
					<Select
						className='redx-area-select'
						placeholder={!searchTerm && 'Search Area'}
						showSearch
						allowClear
						value={null}
						defaultActiveFirstOption={false}
						onKeyDown={e => {
							lastKeyPressed.current = e.key
						}}
						showArrow={false}
						filterOption={false}
						searchValue={searchTerm}
						onSearch={(value) => {
							if (lastValidSearchTerm.current && lastValidSearchTerm.current.length === 1 && !value) {
								setSearchTerm('')
								lastValidSearchTerm.current = ''
							} else if (lastKeyPressed.current === 'Backspace' && !value) {
								setSearchTerm('')
								lastValidSearchTerm.current = ''
							} else {
								setSearchTerm(value || lastValidSearchTerm.current)
							}
						}}
						onBlur={() => {
							if (!searchTerm) {
								setSearchTerm(lastValidSearchTerm.current)
							}
						}}
						onClear={() => {
							setSearchTerm('')
							lastValidSearchTerm.current = ''
						}}
						onChange={onResultChange}
						notFoundContent={null}
						loading={isSearchingAreas}
						labelInValue
						options={searchResults.map(searchResult => {
							return {
								label:
								<div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
									<div>
										{searchResult['DIVISION_NAME']?.toUpperCase()}
									</div>
									{
										searchResult['DISTRICT_NAME'] && <RightOutlined />
									}
									<div>
										{searchResult['DISTRICT_NAME']?.toUpperCase()}
									</div>
								</div>,
								options: (searchResult['AREAS'] || []).map(area => {
									return {
										value: `${searchResult['DIVISION_NAME']}~${searchResult['DISTRICT_NAME']}~${area['ID']}`,
										label: area['NAME']
									}
								})
							}
						})}
					/>
				</div>
			}
		/>
	)
}

DeliveryAreaSelectRedX.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectRedX.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectRedX
