import styles from './HeaderTab.module.css'
import PropTypes from 'prop-types'
require('./HeaderTab.less')

const HeaderTab = ({
	style,
	title,
	count,
	isSelected,
	onClick,
	children,
	loading
}) => {
	return (
		<button
			style={style}
			className={`${styles.headerTab} ${isSelected() && styles.headerTabSelected}`}
			onClick={onClick}
		>
			<p className={styles.title}>{title}</p>
			{
				loading && (count === undefined || count === 0) ?
					<div className='header-tab-skeleton-box' style={{ width: 24 }} /> :
					count !== undefined ?
						<span className={styles.count}>{count}</span> : null
			}
			{children}
		</button>
	)
}

HeaderTab.propTypes = {
	style: PropTypes.object,
	title: PropTypes.string,
	count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	isSelected: PropTypes.func,
	onClick: PropTypes.func,
	loading: PropTypes.bool
}

export default HeaderTab
