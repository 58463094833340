import { Space } from 'antd'
import styles from './ItemQuantityCell.module.css'
import { packageTypeLabels, packageUnitLabels } from '../../utils/constants'
import NumberInput from '../number-input'

const ItemQuantityCell = ({
	packaging,
	quantity = 0,
	packageQuantity = 0,
	editable = false,
	showQuantityWithPackaging = true,
	minQuantity = Number.MIN_SAFE_INTEGER,
	maxQuantity = Number.MAX_SAFE_INTEGER,
	minPackageQuantity = Number.MIN_SAFE_INTEGER,
	maxPackageQuantity = Number.MAX_SAFE_INTEGER,
	onQuantityChange = () => {},
	onPackageQuantityChange = () => {}
}) => {

	return (
		<Space size='large'>
			{
				packaging &&
					<span className={styles.quantityContainer}>
						{
							editable ?
								<NumberInput
									containerClassName={styles.cellInput}
									defaultValue={packageQuantity}
									step={1}
									min={minPackageQuantity}
									max={maxPackageQuantity}
									onChange={value => onPackageQuantityChange(value)}
									value={packageQuantity}
								/> :
								<b style={{ color: 'black' }}>{packageQuantity || 0}</b>
						}
						<img src='/img/close.svg' style={{ margin: '0 4px' }} />
						{packageTypeLabels[packaging.type]}
						<i style={{ marginLeft: 4 }}>{`(${packaging.size})`}</i>
					</span>
			}
			{
				!packaging ||
				(packaging && showQuantityWithPackaging) ?
					<span className={styles.quantityContainer}>
						{
							editable ?
								<NumberInput
									containerClassName={styles.cellInput}
									defaultValue={quantity}
									step={1}
									min={minQuantity}
									max={maxQuantity}
									onChange={value => onQuantityChange(value)}
									value={quantity}
								/> :
								<b style={{ color: 'black' }}>{quantity}</b>
						}
						<img src='/img/close.svg' style={{ margin: '0 4px' }} />
						{
							packaging ? packageUnitLabels[packaging.sizeUnit] : packageUnitLabels['PIECE']
						}
					</span> : null
			}
		</Space>
	)
}

export default ItemQuantityCell
