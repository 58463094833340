import { PurchaseOrders } from '../../services/api/purchase-orders'
import { ADD_PURCHASE_ORDERS_SUCCESS, FETCH_PURCHASE_ORDERS_SUCCESS, FETCH_PURCHASE_ORDER_DETAILS_SUCCESS, UPDATE_PURCHASE_ORDER_SUCCESS } from './action-types'

export function fetchPurchaseOrders ({
	page,
	searchTerm,
	statuses,
	disabledStatuses,
	status,
	subStatuses,
	subStatus,
	fromDate,
	toDate,
	approvedFromDate,
	approvedToDate,
	receivedFromDate,
	receivedToDate,
	vendorId,
	locationId,
	inventory
} = {}) {
	return async dispatch => {
		const { data } = await PurchaseOrders.index({
			page,
			searchTerm,
			statuses,
			disabledStatuses,
			status,
			subStatuses,
			subStatus,
			fromDate,
			toDate,
			approvedFromDate,
			approvedToDate,
			receivedFromDate,
			receivedToDate,
			vendorId,
			locationId,
			inventory
		})
		dispatch({ type: FETCH_PURCHASE_ORDERS_SUCCESS, payload: { data, status, statuses } })
	}
}

export function addPurchaseOrder (params) {
	return async (dispatch) => {
		const response = await PurchaseOrders.create(params)
		dispatch({ type: ADD_PURCHASE_ORDERS_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function fetchPurchaseOrderDetails (id) {
	return async (dispatch) => {
		const response = await PurchaseOrders.single(id, { inventory: true })
		dispatch({ type: FETCH_PURCHASE_ORDER_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updatePurchaseOrder (id, params) {
	return async (dispatch) => {
		const response = await PurchaseOrders.update(id, params)
		dispatch({ type: UPDATE_PURCHASE_ORDER_SUCCESS, payload: response.data })
	}
}
