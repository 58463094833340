import { HYDRATE } from 'next-redux-wrapper'
import {
	ADD_VENDOR_SUCCESS,
	FETCH_VENDORS_SUCCESS,
	FETCH_VENDOR_DETAILS_SUCCESS,
	FETCH_VENDOR_METRICS_SUCCESS,
	UPDATE_VENDOR_SUCCESS
} from './action-types'

const initialState = {
	totalCount: 0,
	page: 0,
	vendors: [],
	vendorDetails: {},
	metrics: {
		new: 0,
		total: 0,
		active: 0
	}
}

const vendorsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_VENDORS_SUCCESS: {
			return {
				...state,
				vendors: payload.results,
				totalCount: payload.count,
				page: +payload.page
			}
		}
		case ADD_VENDOR_SUCCESS: {
			const vendors = [payload, ...state.vendors]
			return {
				...state,
				vendors
			}
		}
		case UPDATE_VENDOR_SUCCESS: {
			const newState = { ...state }
			const vendor = newState.vendorDetails[payload.id]
			if (vendor) {
				const updatedVendor = Object.assign(vendor, payload)
				newState.vendorDetails[payload.id] = updatedVendor
			}
			return newState
		}
		case FETCH_VENDOR_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.vendorDetails[payload.id] = payload.data
			return newState
		}
		case FETCH_VENDOR_METRICS_SUCCESS: {
			return {
				...state,
				metrics: payload
			}
		}
		default:
			return state
	}
}

export default vendorsReducer
