import { Button, Modal, Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { SalesOrderChallanPage } from '../../pages/challan/sales-order/[id]'
import { SalesOrders } from '../../services/api/sales-orders'

const PrintSalesOrderChallansModal = ({ visible, salesOrderIds, onCancel }) => {
	const { userProfile } = useSelector(state => state.authReducer)
	const [salesOrders, setSalesOrders] = useState([])
	const [message, setMessage] = useState('Rendering Challans...')
	const [hasError, setHasError] = useState(false)
	const printableRef = useRef()
	const printRef = useRef()

	useEffect(() => {
		if (userProfile) {
			getSalesOrders()
		}
	}, [userProfile])

	const getSalesOrders = async () => {
		try {
			const response = await SalesOrders.multiple(salesOrderIds)
			const salesOrders = response.data
			if (salesOrders) {
				if (salesOrders.length === 0) {
					setHasError(true)
					setMessage('No sales orders to print challans for.')
				} else {
					setSalesOrders(salesOrders)
					setTimeout(() => {
						printRef.current.handleClick()
					}, 1500)
				}
			}
		} catch (e) {
			console.error(e)
			setHasError(true)
			setMessage('An error has occurred when trying to print the selected orders.')
			onCancel()
		}
	}

	const renderModalFooter = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
				{
					hasError &&
					<Button
						onClick={onCancel}
					>
						Cancel
					</Button>
				}
				{
					!hasError &&
					<Button
						type='primary'
						loading={true}
					>
						Printing...
					</Button>
				}
			</div>
		)
	}

	return (
		<Modal
			title='Print Challans'
			visible={visible}
			onCancel={onCancel}
			footer={renderModalFooter()}
		>
			<div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', width: '100%' }}>
				{
					!hasError && <Spin style={{ marginRight: 12 }} />
				}
				{message}
			</div>
			<div ref={printableRef}>
				{
					salesOrders.map(salesOrder => {
						return (
							<>
								{SalesOrderChallanPage({
									visible: false,
									salesOrder
								})}
							</>
						)
					})
				}
			</div>
			<ReactToPrint
				ref={printRef}
				content={() => printableRef.current}
				onAfterPrint={() => onCancel()}
			/>
		</Modal>
	)
}

export default PrintSalesOrderChallansModal
