import { SalesOrders } from '../../services/api/sales-orders'
import { Transactions } from '../../services/api/transactions'
import {
	ADD_SALES_ORDERS_SUCCESS,
	FETCH_SALES_ORDERS_SUCCESS,
	FETCH_SALES_ORDER_DETAILS_SUCCESS,
	UPDATE_SALES_ORDER_SUCCESS,
	ADD_TRANSACTION_SUCCESS,
	FETCH_SALES_ORDER_COUNTS_SUCCESS,
	SET_SALES_ORDER_COUNTS,
	RESET_SALES_ORDER_COUNTS,
	EDIT_TRANSACTION_SUCCESS,
	FETCH_SALES_ORDER_PAGINATION_SUCCESS,
	SELECT_SALES_ORDERS
} from './action-types'

export function fetchSalesOrders (
	{
		status,
		subStatus,
		page,
		pageSize,
		searchTerm,
		minOrderValue,
		maxOrderValue,
		startingInvoice,
		endingInvoice,
		fromDate,
		toDate,
		deliveryFromDate,
		deliveryToDate,
		unassigned,
		deliveryPlanId,
		isStockTransfer,
		pickUpLocationIds,
		accountManagerId,
		sources,
		excludeFullyDamagedReturnOrders,
		includeCompany,
		sortByOrderValue,
		creationFromDate,
		creationToDate,
		salesOrderItems,
		salesOrderItemsWithProduct
	} = {}
) {
	return async (dispatch) => {
		const response = await SalesOrders.index({
			status,
			subStatus,
			page,
			pageSize,
			searchTerm,
			minOrderValue,
			maxOrderValue,
			sortByOrderValue,
			startingInvoice,
			endingInvoice,
			fromDate,
			toDate,
			deliveryFromDate,
			deliveryToDate,
			unassigned,
			deliveryPlanId,
			isStockTransfer,
			pickUpLocationIds,
			accountManagerId,
			sources,
			excludeFullyDamagedReturnOrders,
			includeCompany,
			creationFromDate,
			creationToDate,
			salesOrderItems,
			salesOrderItemsWithProduct
		})
		dispatch({ type: FETCH_SALES_ORDERS_SUCCESS, payload: { data: response.data, status } })
	}
}

export function updateSalesOrders (data, status) {
	return async (dispatch) => dispatch({ type: FETCH_SALES_ORDERS_SUCCESS, payload: { data, status } })
}

export function addSalesOrder (params) {
	return async (dispatch) => {
		const response = await SalesOrders.create(params)
		dispatch({ type: ADD_SALES_ORDERS_SUCCESS, payload: response.data ? response.data : [] })
		return response.data
	}
}

export function fetchSalesOrderDetails (id, inventory) {
	return async (dispatch) => {
		const response = await SalesOrders.single(id, {}, inventory)
		dispatch({ type: FETCH_SALES_ORDER_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateSalesOrder (id, params) {
	return async (dispatch) => {
		const response = await SalesOrders.update(id, params)
		dispatch({ type: UPDATE_SALES_ORDER_SUCCESS, payload: response.data })
	}
}

export function addTransaction (params) {
	return async (dispatch) => {
		const response = await Transactions.create(params)
		dispatch({ type: ADD_TRANSACTION_SUCCESS, payload: { data: response.data, id: params.salesOrderId || params.purchaseOrderId } })
	}
}

export function editTransaction (id, params) {
	return async (dispatch) => {
		const response = await Transactions.update(id, params)
		dispatch({ type: EDIT_TRANSACTION_SUCCESS, payload: { data: response.data, id: params.salesOrderId || params.purchaseOrderId } })
	}
}

export function fetchSalesOrderCounts () {
	return async (dispatch) => {
		const response = await SalesOrders.counts()
		dispatch({ type: FETCH_SALES_ORDER_COUNTS_SUCCESS, payload: response.data })
	}
}

export function updateSalesOrderCounts (counts) {
	return async (dispatch) => {
		dispatch({ type: SET_SALES_ORDER_COUNTS, payload: counts })
	}
}

export function resetSalesOrderCounts () {
	return async (dispatch) => {
		dispatch({ type: RESET_SALES_ORDER_COUNTS })
	}
}

export function fetchSalesOrderPagination (id, status, subStatus) {
	return async (dispatch) => {
		const response = await SalesOrders.getPagination(id, { status, subStatus })
		dispatch({ type: FETCH_SALES_ORDER_PAGINATION_SUCCESS, payload: response.data })
	}
}

export function selectSalesOrderIds (ids, orders) {
	return async (dispatch) => {
		dispatch({ type: SELECT_SALES_ORDERS, payload: { ids, orders } })
	}
}
