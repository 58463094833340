import { Geofences } from '../../services/api/geofences'
import { CREATE_GEOFENCE_SUCCESS, DELETE_GEOFENCE_SUCCESS, FETCH_GEOFENCES_SUCCESS, UPDATE_GEOFENCE_SUCCESS } from './action-types'

export function fetchGeofences () {
	return async (dispatch) => {
		const response = await Geofences.index()
		dispatch({
			type: FETCH_GEOFENCES_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function createGeofence (geofenceData) {
	return async (dispatch) => {
		const response = await Geofences.create(geofenceData)
		dispatch({
			type: CREATE_GEOFENCE_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function updateGeofence (id, geofenceData) {
	return async (dispatch) => {
		const response = await Geofences.update(id, geofenceData)
		dispatch({
			type: UPDATE_GEOFENCE_SUCCESS,
			payload: { data: response.data }
		})
	}
}

export function deleteGeofence (id) {
	return async (dispatch) => {
		await Geofences.delete(id)
		dispatch({
			type: DELETE_GEOFENCE_SUCCESS,
			payload: { id }
		})
	}
}
