export const VEHICLE_ACTIVE_STATUS = 'ACTIVE'
export const VEHICLE_INACTIVE_STATUS = 'INACTIVE'
export const VEHICLE_IN_MAINTENANCE_STATUS = 'IN_MAINTENANCE'
export const VEHICLE_UNAVAILABLE_STATUS = 'UNAVAILABLE'
export const VEHICLE_UNASSIGNED_STATUS = 'UNASSIGNED'

const vehicleTypes = {
	VT_40FT_HQ_TRAILER: '40FT HQ (High Cube) Trailer',
	VT_40FT_TRAILER_18_WHEELER: '40FT Trailer (18 Wheeler)',
	VT_40FT_TRAILER_14_WHEELER: '40FT Trailer (14 Wheeler)',
	VT_20FT_TRAILER_20_WHEELER: '20FT Trailer (Power 20 Wheeler)',
	VT_20FT_TRAILER_14_WHEELER: '20FT Trailer (Power 14 Wheeler)',
	VT_20FT_TRAILER_12_WHEELER: '20FT Trailer (12 Wheeler)',
	VT_20FT_TRAILER_10_WHEELER: '20FT Trailer (10 Wheeler)',
	VT_20FT_TRAILER_6_WHEELER: '20FT Trailer (6 Wheeler)',
	VT_23FT_COVERED_VAN: '23FT Covered Van',
	VT_20FT_OPEN_TRUCK: '20FT Open Truck',
	VT_18FT_COVERED_VAN: '18FT Covered Van',
	VT_17_5FT_OPEN_TRUCK: '17.5T Open Truck',
	VT_17FT_COVERED_VAN: '17FT Covered Van',
	VT_16FT_COVERED_VAN: '16FT Covered Van',
	VT_16FT_OPEN_TRUCK: '16FT Open Truck',
	VT_14FT_COVERED_VAN: '14FT Covered Van',
	VT_13_5FT_COVERED_VAN: '13.5FT Covered Van',
	VT_13FT_COVERED_VAN: '13FT Covered Van',
	VT_12_5FT_COVERED_VAN: '12.5FT Covered Van',
	VT_12_5FT_OPEN_TRUCK: '12.5FT Open Truck',
	VT_12FT_OPEN_TRUCK: '12FT Open Truck',
	VT_11FT_OPEN_TRUCK: '11FT Open Truck',
	VT_10FT_COVERED_VAN: '10FT Covered Van',
	VT_8FT_COVERED_VAN: '8FT Covered Van',
	VT_7_5FT_COVERED_VAN: '7.5FT Covered Van',
	VT_7FT_COVERED_VAN: '7FT Covered Van',
	VT_7FT_OPEN_TRUCK: '7FT Open Truck'
}

export const getVehicleTypeLabel = (type) => {
	return vehicleTypes[type] || type
}

export const getStatusColor = (status) => {
	switch (status) {
		case VEHICLE_INACTIVE_STATUS:
			return '#4f4f4f'
		case VEHICLE_IN_MAINTENANCE_STATUS:
			return '#FF5959'
		case VEHICLE_ACTIVE_STATUS:
		default:
			return '#27ae60'
	}
}

export const getVehicleStatusLabel = (status) => {
	switch (status) {
		case VEHICLE_INACTIVE_STATUS:
			return 'Inactive'
		case VEHICLE_IN_MAINTENANCE_STATUS:
			return 'In Maintenance'
		case VEHICLE_ACTIVE_STATUS:
			return 'Available'
		case VEHICLE_UNAVAILABLE_STATUS:
			return 'Unavailable'
		default:
			return status
	}
}

export const fuelTypes = {
	DIESEL: 'DIESEL',
	PETROL: 'PETROL',
	OCTANE: 'OCTANE',
	CNG: 'CNG',
	LPG: 'LPG'
}

export const fuelTypeOptions = [
	{ label: 'Diesel', value: fuelTypes.DIESEL },
	{ label: 'Petrol', value: fuelTypes.PETROL },
	{ label: 'Octane', value: fuelTypes.OCTANE },
	{ label: 'Compressed Natural Gas (CNG)', value: fuelTypes.CNG },
	{ label: 'Liquefied Petroleum Gas (LPG)', value: fuelTypes.LPG }
]

export const fuelUnits = {
	LITRE: 'LITRE',
	KILOGRAM: 'KILOGRAM'
}

export const fuelUnitOptions = [
	{ label: 'Litre', value: fuelUnits.LITRE },
	{ label: 'Kg', value: fuelUnits.KILOGRAM }
]

export const getFuelUnitLabel = (unitValue) => {
	switch (unitValue) {
		case fuelUnits.LITRE:
			return 'Litre'
		case fuelUnits.KILOGRAM:
			return 'Kg'
		default:
			return unitValue
	}
}

export const getFuelTypeLabel = (value) => {
	switch (value) {
		case fuelTypes.DIESEL:
			return 'Diesel'
		case fuelTypes.PETROL:
			return 'Petrol'
		case fuelTypes.OCTANE:
			return 'Octane'
		case fuelTypes.CNG:
			return 'CNG'
		case fuelTypes.LPG:
			return 'LPG'
		default:
			return value
	}
}
