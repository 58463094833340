import { HYDRATE } from 'next-redux-wrapper'
import { CREATE_USER_SUCCESS, FETCH_USERS_SUCCESS, FETCH_USER_DETAILS_SUCCESS, UPDATE_USERS_SUCCESS } from './action-types'

const initialState = {
	users: [],
	userDetails: {},
	totalCount: 0,
	page: 0
}

const usersReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_USERS_SUCCESS: {
			return {
				...state,
				users: payload.data.results,
				totalCount: payload.data.count,
				page: payload.data.page
			}
		}
		case FETCH_USER_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.userDetails[payload.data.id] = payload.data
			return newState
		}
		case UPDATE_USERS_SUCCESS: {
			return {
				...state,
				users: state.users.map(user => user.id === payload.id ? Object.assign(user, payload) : user)
			}
		}
		case CREATE_USER_SUCCESS: {
			return {
				...state,
				users: [payload, ...state.users]
			}
		}
		default:
			return state
	}
}

export default usersReducer
