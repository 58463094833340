import { initializeApp, getApps } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

export const firebaseClientInitConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY ? process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY : 'default',
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
}

let app = null
const getFirebaseApp = () => {
	if (getApps().length !== 0) {
		return getApps()[0]
	}
	if (app) {
		return app
	}
	app = initializeApp(firebaseClientInitConfig)
	const db = getFirestore(app)
	if (process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST) {
		const localUrl = process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST.split(':')
		connectFirestoreEmulator(db, localUrl[0], +localUrl[1])
	}
	if (process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST) {
		const storage = getStorage(app)
		const localUrl = process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST.split(':')
		connectStorageEmulator(storage, localUrl[0], +localUrl[1])
	}
	if (process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST) {
		const auth = getAuth(app)
		connectAuthEmulator(auth, `http://${process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST}`)
	}
	return app
}

export default getFirebaseApp
