import styles from './DeliveryAreaSelectECourier.module.css'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { DeliveryPartners } from '../../services/api/delivery-partner'

const DeliveryAreaSelectECourier = ({ deliveryPartnerId, title, error, enableFooter, value, onChange }) => {
	const [isLoadingCities, setIsLoadingCities] = useState(false)
	const [isLoadingAreas, setIsLoadingAreas] = useState(false)
	const [cities, setCities] = useState([])
	const [areas, setAreas] = useState([])

	useEffect(() => {
		getCities()
	}, [])

	useEffect(() => {
		if (!value?.city?.value) {
			setAreas([])
			return
		}
		getAreas(value.city.value)
	}, [value?.city?.value])

	const getCities = async () => {
		setIsLoadingCities(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryCities(deliveryPartnerId)
			const cities = data?.length
				? data.map(city => ({
					...city,
					label: city?.name || 'N/A'
				}))
				: []
			setCities(cities)
		} catch (e) {
			onChange({ ...value, city: null, area: null, thana: null, postCode: null })
			setCities([])
			notification.error({
				message: 'Unable To Get Cities',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingCities(false)
		}
	}

	const getAreas = async district => {
		setIsLoadingAreas(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryAreas(deliveryPartnerId, { district })
			const areas = data?.length
				? data.map(area => ({
					...area,
					value: area.id,
					label: area?.name || 'N/A'
				}))
				: []
			setAreas(areas)
		} catch (e) {
			onChange({ ...value, area: null, thana: null, postCode: null })
			setAreas([])
			notification.error({
				message: 'Unable To Get Areas',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingAreas(false)
		}
	}

	const onCityChange = async (_, option) => {
		onChange({
			...value,
			city: option ? { value: option.value, label: option.label } : null,
			area: null,
			thana: null,
			postCode: null
		})
	}

	const onAreaChange = (_, option) => {
		onChange({
			...value,
			area: option ? { value: option.value, label: option.label } : null,
			thana: option ? { value: option.thana, label: option.thana } : null,
			postCode: option ? { value: option.post_code, label: option.post_code } : null
		})
	}

	return (
		<div className={styles.selectContainer}>
			<CustomDeliveryAreaSelect
				title={title}
				error={error}
				levels={[
					{
						id: 'district',
						placeholder: 'District',
						loading: isLoadingCities,
						options: cities,
						value: value?.city || null,
						onChange: onCityChange,
						onClear: () => onCityChange(),
						style: { width: '50%' }
					},
					{
						id: 'area',
						placeholder: 'Area',
						loading: isLoadingAreas,
						options: areas,
						value: value?.area || null,
						onChange: onAreaChange,
						onClear: () => onAreaChange(),
						style: { width: '50%' }
					}
				]}
			/>
			{
				enableFooter && (value?.thana?.label || value?.postCode?.label) ?
					<div className={styles.footerContainer}>
						<span>
							<strong className={styles.label}>Thana:</strong>
							<text>{value?.thana?.label || 'N/A'}</text>
						</span>
						<span>
							<strong className={styles.label}>Post Code:</strong>
							<text>{value?.postCode?.label || 'N/A'}</text>
						</span>
					</div>
					:
					null
			}
		</div>
	)
}

DeliveryAreaSelectECourier.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	enableFooter: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectECourier.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	enableFooter: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectECourier
