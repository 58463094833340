import { HYDRATE } from 'next-redux-wrapper'
import { ExpenseStatus } from '../../utils/expenses'
import { FETCH_EXPENSES_SUCCESS, FETCH_EXPENSE_COUNTS_SUCCESS, FETCH_EXPENSE_DETAILS_SUCCESS, SWAP_EXPENSE } from './action-types'

const initialState = {
	requestedExpenses: {
		count: 0,
		page: 0,
		results: []
	},
	approvedExpenses: {
		count: 0,
		page: 0,
		results: []
	},
	paidExpenses: {
		count: 0,
		page: 0,
		results: []
	},
	refusedExpenses: {
		count: 0,
		page: 0,
		results: []
	},
	allExpenses: {
		count: 0,
		page: 0,
		results: []
	},
	counts: {},
	allCounts: {},
	expenseDetails: {}
}

const expensesReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_EXPENSES_SUCCESS: {
			const newState = { ...state }
			const countsMap = {}
			const counts = payload.data?.counts || []
			for (const count of counts) {
				countsMap['all'] = countsMap['all'] ? countsMap['all'] + count._count : count._count
				countsMap[count.status] = count._count
			}
			newState.counts = countsMap
			switch (payload.status) {
				case ExpenseStatus.REQUESTED: {
					return {
						...newState,
						requestedExpenses: {
							count: payload.data.count,
							page: payload.data.page,
							results: payload.data.results
						}
					}
				}
				case ExpenseStatus.APPROVED: {
					return {
						...newState,
						approvedExpenses: {
							count: payload.data.count,
							page: payload.data.page,
							results: payload.data.results
						}
					}
				}
				case ExpenseStatus.PAID: {
					return {
						...newState,
						paidExpenses: {
							count: payload.data.count,
							page: payload.data.page,
							results: payload.data.results
						}
					}
				}
				case ExpenseStatus.REFUSED: {
					return {
						...newState,
						refusedExpenses: {
							count: payload.data.count,
							page: payload.data.page,
							results: payload.data.results
						}
					}
				}
				default: {
					return {
						...newState,
						allExpenses: {
							count: payload.data.count,
							page: payload.data.page,
							results: payload.data.results
						}
					}
				}
			}
		}
		case FETCH_EXPENSE_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.expenseDetails[payload.id] = payload.data
			return newState
		}
		case SWAP_EXPENSE: {
			const newState = { ...state }
			const getList = (status) => {
				switch (status) {
					case ExpenseStatus.REQUESTED:
						return newState.requestedExpenses.results
					case ExpenseStatus.APPROVED:
						return newState.approvedExpenses.results
					case ExpenseStatus.PAID:
						return newState.paidExpenses.results
					case ExpenseStatus.REFUSED:
						return newState.refusedExpenses.results
				}
			}
			const { sourceIndex, destinationIndex, source, destination } = payload
			const sourceArray = getList(source)
			const destinationArray = getList(destination)
			const [removed] = sourceArray.splice(sourceIndex, 1)
			destinationArray.splice(destinationIndex, 0, removed)

			return newState
		}
		case FETCH_EXPENSE_COUNTS_SUCCESS: {
			const countsMap = {}
			const counts = payload.data || []
			for (const count of counts) {
				countsMap['all'] = countsMap['all'] ? countsMap['all'] + count._count : count._count
				countsMap[count.status] = count._count
			}
			return {
				...state,
				allCounts: countsMap
			}
		}
		default:
			return state
	}
}

export default expensesReducer
