import { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Input from '../input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './CompanyFilter.module.css'
import { SubscriptionPeriod, SubscriptionStatus, SubscriptionTier, ecommerceIndustryTypeOptions, industries, industryGroup as IndustryGroups } from '../../utils/constants'
import DropdownOverlay from '../dropdown-overlay'
import CustomSelectSecondary from '../custom-select-secondary'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { useRouter } from 'next/router'
import { Button } from 'antd'

const enterpriseIndustryTypeOptions = [
	{
		value: industries.AGRICULTURE,
		label: 'Agriculture'
	},
	{
		value: industries.FMCG,
		label: 'FMCG'
	},
	{
		value: industries.ELECTRONICS,
		label: 'Electronics'
	},
	{
		value: industries.CONSTRUCTION,
		label: 'Construction'
	},
	{
		value: industries.PHARMACEUTICALS,
		label: 'Pharmacy'
	},
	{
		value: industries.METAL_STEEL,
		label: 'Metal & Steel'
	},
	{
		value: industries.CASH_DISTRIBUTION,
		label: 'Cash Distribution'
	},
	{
		value: industries.MANUFACTURING_DISTRIBUTION,
		label: 'Manufacturing & Distribution'
	},
	{
		value: industries.REAL_ESTATE_BUILDING_SUPPLIES,
		label: 'Real Estate Building Supplies'
	},
	{
		value: industries.GENERAL,
		label: 'Other'
	}
]

const NO_SUBSCRIPTION_PLAN = 'NO_SUBSCRIPTION_PLAN'

const subscriptionPlanOptions = [
	{
		label: 'Basic',
		value: SubscriptionTier.BASIC
	},
	{
		label: 'Pro',
		value: SubscriptionTier.PRO
	},
	{
		label: 'Advanced',
		value: SubscriptionTier.ADVANCED
	},
	{
		label: 'Platinum',
		value: SubscriptionTier.PLATINUM
	},
	{
		label: 'No Activated Plan',
		value: NO_SUBSCRIPTION_PLAN
	}
]

const accountStatusGroup = {
	TRIAL: 'TRIAL',
	LIVE: 'LIVE',
	CHURNED: 'CHURNED'
}

const accountStatusOptions = [
	{
		label: 'Trial',
		value: accountStatusGroup.TRIAL
	},
	{
		label: 'Live',
		value: accountStatusGroup.LIVE
	},
	{
		label: 'Churned',
		value: accountStatusGroup.CHURNED
	}
]

const liveStatusOptions = [
	{
		label: 'Active',
		value: SubscriptionStatus.ACTIVE
	},
	{
		label: 'Incomplete',
		value: SubscriptionStatus.INCOMPLETE
	}
]

const billingCycleOptions = [
	{
		label: 'Monthly',
		value: SubscriptionPeriod.MONTHLY.value
	},
	{
		label: 'Yearly',
		value: SubscriptionPeriod.YEARLY.value
	}
]

const CompanyFilter = ({
	setIsFilterDropdownOpen
}) => {
	const router = useRouter()
	const { query } = router
	const { industryGroup } = query
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { applyFilter, clearFilter } = useSearchParams()
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [industry, setIndustry] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [subscriptionPlan, setSubscriptionPlan] = useState(null)
	const [accountStatus, setAccountStatus] = useState(null)
	const [liveStatus, setLiveStatus] = useState(null)
	const [billingCycle, setBillingCycle] = useState(null)
	const isFilterEnabled = useMemo(() => {
		return searchTerm || (fromDate && toDate) || industry || subscriptionPlan || accountStatus || liveStatus || billingCycle
	}, [searchTerm, fromDate, toDate, industry, subscriptionPlan, accountStatus, liveStatus, billingCycle])
	const isECommerceTab = useMemo(() => {
		return industryGroup === IndustryGroups.E_COMMERCE
	}, [industryGroup])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	const handleApply = async () => {
		const filters = {
			searchTerm,
			industry
		}
		if (fromDate) {
			filters.fromDate = moment(fromDate).startOf('day').toDate()
		}
		if (toDate) {
			filters.toDate = moment(toDate).endOf('day').toDate()
		}
		if (industry) {
			switch (industryGroup) {
				case IndustryGroups.E_COMMERCE:
					filters.industry = [industries.E_COMMERCE]
					filters.industryType = [industry]
					break
				case IndustryGroups.ENTERPRISE:
					filters.industry = [industry]
					break
			}
		}
		if (subscriptionPlan === NO_SUBSCRIPTION_PLAN) {
			filters.subscriptionPlan = []
			filters.subscriptionStatus = [SubscriptionStatus.CANCELED]
		}
		if (subscriptionPlan && subscriptionPlan !== NO_SUBSCRIPTION_PLAN) {
			filters.subscriptionPlan = [subscriptionPlan]
		}
		if (accountStatus && subscriptionPlan !== NO_SUBSCRIPTION_PLAN) {
			switch (accountStatus) {
				case accountStatusGroup.TRIAL:
					filters.subscriptionStatus = [SubscriptionStatus.TRIALING]
					break
				case accountStatusGroup.LIVE:
					if (liveStatus) {
						filters.subscriptionStatus = [liveStatus]
					} else {
						filters.subscriptionStatus = [SubscriptionStatus.ACTIVE, SubscriptionStatus.INCOMPLETE]
					}
					break
				case accountStatusGroup.CHURNED:
					filters.subscriptionStatus = [SubscriptionStatus.PAST_DUE]
					break
			}
		}
		if (billingCycle) {
			filters.subscriptionPeriod = [billingCycle]
		}
		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setIndustry(null)
		setSubscriptionPlan(null)
		setAccountStatus(null)
		setLiveStatus(null)
		setBillingCycle(null)
		clearFilter()
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='From'
						type='date'
						value={fromDate ? moment(fromDate) : null }
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='To'
						type='date'
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>
			{
				isECommerceTab &&
				<>
					<div>
						<CustomSelectSecondary
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							onChange={option => setSubscriptionPlan(option)}
							value={subscriptionPlan}
							title='Payment Tier'
							placeholder='Select Plan'
							options={subscriptionPlanOptions}
							allowClear
						/>
					</div>
					<div>
						<CustomSelectSecondary
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							onChange={option => setAccountStatus(option)}
							value={accountStatus}
							title='Payment Status'
							placeholder='Select Status'
							options={accountStatusOptions}
							disabled={subscriptionPlan === NO_SUBSCRIPTION_PLAN}
							allowClear
						/>
					</div>
					{
						accountStatus === accountStatusGroup.LIVE &&
						<div>
							<CustomSelectSecondary
								style={{ background: '#F7F7F7', borderRadius: 0 }}
								onChange={option => setLiveStatus(option)}
								value={liveStatus}
								title='Live Status'
								placeholder='Select Status'
								options={liveStatusOptions}
								allowClear
							/>
						</div>
					}
					<div>
						<CustomSelectSecondary
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							onChange={option => setBillingCycle(option)}
							value={billingCycle}
							title='Billing Cycle'
							placeholder='Select Cycle'
							options={billingCycleOptions}
							allowClear
						/>
					</div>
				</>
			}
			<div>
				<CustomSelectSecondary
					style={{ background: '#F7F7F7', borderRadius: 0 }}
					onChange={option => setIndustry(option)}
					value={industry}
					title='Industry Type'
					placeholder='Select Industry'
					options={
						industryGroup === IndustryGroups.E_COMMERCE
							? ecommerceIndustryTypeOptions
							: enterpriseIndustryTypeOptions
					}
					allowClear
				/>
			</div>
			<div className={styles.buttons}>
				{
					isFilterEnabled &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

CompanyFilter.propTypes = {
	setIsFilterDropdownOpen: PropTypes.func
}

export default CompanyFilter
