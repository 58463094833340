
import '../styles/globals.css'
require('../styles/antd.less')
import initMaps from '../config/initMaps'
import getFirebaseApp from '../config/initFirebase'
import { wrapper } from '../store'
import { useEffect, useState } from 'react'
import { getAuth } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPermissions, fetchUserProfile, setApp, setAuthUser, setCompanyId, setPlan, setRole } from '../store/auth/actions'
import { setUnreadCount, addNotification, setCount, updateNotification, setNewInsights } from '../store/notifications/actions'
import { Companies, DeliveryPlans, Notifications, Tasks } from '../services/api/firebase'
import { notification, message, Spin, Modal } from 'antd'
import axios from 'axios'
import FullPageLoader from '../components/full-page-loader'
import { LoadingOutlined } from '@ant-design/icons'
import { getNotificationArgs } from '../components/notifications-tray'
import { fetchDeliveryPlanDetails, fetchDeliveryPlans, updateDeliveryPlanLoadState } from '../store/delivery-plans/actions'
import useInterval from '../hooks/useInterval'
import { claimsRoles, Apps } from '../utils/constants'
import PageDataContext from '../hooks/usePage'
import GlobalFilterProvider from '../contexts/GlobalFilter'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { DELIVERY_PLAN_PENDING_STATUS } from '../utils/delivery-plans'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NotificationBarProvider from '../contexts/NotificationBar'
import ChatSupport from '../components/chat-support'
import { ReCaptchaProvider } from 'next-recaptcha-v3'

const app = getFirebaseApp()
const auth = getAuth(app)
initMaps()

const App = ({ Component, pageProps }) => {
	const getLayout = Component.getLayout || ((page) => page)
	const dispatch = useDispatch()
	const router = useRouter()
	const { role, userProfile, authUser, companyDetails, app } = useSelector(state => state.authReducer)
	const { unreadNotificationsMap } = useSelector(state => state.notificationsReducer)
	const [showRefreshPrompt, setShowRefreshPrompt] = useState(false)

	useEffect(() => {
		const handleRouteChange = (url) => {
			if (window.gtag) {
				const { gtag } = window
				gtag('event', 'page_view', {
					page_title: url,
					page_location: url
				})
			}
			if (window.mixpanel) {
				const { mixpanel } = window
				mixpanel.track('Page view', { url })
			}
		}
		router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router])

	useEffect(() => {
		fetchAuthUser()
	}, [])

	useEffect(() => {
		if (userProfile && userProfile.company) {
			/**
			 * We set this data using localStorage so that we can easily access this value
			 * in utility functions.
			 */
			localStorage.setItem('currency', userProfile.company.currency)
			localStorage.setItem('country', userProfile.company.country)

		}
	}, [userProfile])

	useEffect(() => {
		if (showRefreshPrompt) {
			message.info({
				content: <Message onClick={() => window.location.reload()} message={'A new version of the application has been deployed. Please refresh the application by clicking here.'} />,
				duration: 0
			})
		}
	}, [showRefreshPrompt])

	useEffect(() => {
		let unsubscribe
		let unsubscribeCompany
		if (authUser) {
			(async () => {
				const tokenResult = await authUser.getIdTokenResult()
				const companyId = tokenResult.claims.cid
				const plan = tokenResult.claims.plan
				unsubscribe = getDeliveryPlans(companyId)
				unsubscribeCompany = getCompany(companyId, plan)
			})()
		}
		return () => {
			if (unsubscribe) {
				const { unsubscribeDeliveryPlans, unsubscribeLoadingDeliveryPlans } = unsubscribe
				if (unsubscribeDeliveryPlans) {
					unsubscribeDeliveryPlans()
				}
				if (unsubscribeLoadingDeliveryPlans) {
					unsubscribeLoadingDeliveryPlans()
				}
			}
			if (unsubscribeCompany) {
				unsubscribeCompany()
			}
		}
	}, [authUser])

	useEffect(() => {
		if (!userProfile || !userProfile.companyId) {
			return
		}
		const unsubscribeProductTasks = Tasks.listenToTasks(
			userProfile.companyId,
			'bulk-upload-integrated-products',
			task => {
				if (task?.loadState === 'LOADING') {
					notification.open({
						placement: 'bottomLeft',
						key: 'sync-products',
						message: 'Syncing Products',
						duration: 0,
						description:
							<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
								<Spin />
								Syncing products from WooCommerce...
							</div>
					})
				}
			},
			undefined,
			async () => {
				const task = await Tasks.fetchTaskOnce(
					userProfile.companyId,
					'sync-products'
				)
				notification.close('sync-products')
				if (task?.loadState === 'SUCCEEDED') {
					notification.success({
						placement: 'bottomLeft',
						key: 'sync-products',
						message: 'Syncing Products Succeeded'
					})
				} else if (task?.loadState === 'FAILED') {
					let description = undefined
					if (task.logs?.length) {
						description = task.logs[0]
					}
					notification.error({
						placement: 'bottomLeft',
						key: 'sync-products',
						message: 'Syncing Products Failed',
						description
					})
				}
			}
		)
		const unsubscribeOrderTasks = Tasks.listenToTasks(
			userProfile.companyId,
			'bulk-upload-integrated-orders',
			task => {
				if (task?.loadState === 'LOADING') {
					notification.open({
						placement: 'bottomLeft',
						key: 'sync-orders',
						message: 'Syncing Orders',
						duration: 0,
						description:
							<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
								<Spin />
								Syncing orders from WooCommerce...
							</div>
					})
				}
			},
			undefined,
			async () => {
				const task = await Tasks.fetchTaskOnce(
					userProfile.companyId,
					'sync-orders'
				)
				notification.close('sync-orders')
				if (task?.loadState === 'SUCCEEDED') {
					notification.success({
						placement: 'bottomLeft',
						key: 'sync-products',
						message: 'Syncing Orders Succeeded'
					})
				} else if (task?.loadState === 'FAILED') {
					let description = undefined
					if (task.logs?.length) {
						description = task.logs[0]
					}
					notification.error({
						placement: 'bottomLeft',
						key: 'sync-orders',
						message: 'Syncing Orders Failed',
						description
					})
				}
			}
		)
		return () => {
			unsubscribeProductTasks()
			unsubscribeOrderTasks()
		}
	}, [userProfile])

	useEffect(() => {
		let unsubscribe
		if (authUser) {
			const uid = authUser.uid
			unsubscribe = getUserNotifications(uid)
		}
		setupFreshworksSupportWidget()
		setUserId()
		return () => {
			if (unsubscribe) {
				const { unsubscribeNotificationCount, unsubscribeNotifications } = unsubscribe
				if (unsubscribeNotificationCount) {
					unsubscribeNotificationCount()
				}
				if (unsubscribeNotifications) {
					unsubscribeNotifications()
				}
			}
		}
	}, [authUser])

	useEffect(() => {
		if (companyDetails && app && role !== claimsRoles.ADMIN) {
			const apps = new Set(companyDetails.apps)
			const currentApp = localStorage.getItem('app') || app
			const modalConfig = {
				title: '',
				content: '',
				closable: false,
				onOk: resetApp,
				okText: 'Navigate to default app'
			}
			switch (currentApp) {
				case Apps.WMS:
					if (!apps.has(Apps.WMS)) {
						modalConfig.title = 'WMS Access Denied'
						modalConfig.content = 'You do not have access to the Warehouse Management System.'
						Modal.error(modalConfig)
					}
					break
				case Apps.OMS:
					if (!apps.has(Apps.OMS)) {
						modalConfig.title = 'OMS Access Denied'
						modalConfig.content = 'You do not have access to the Order Management System.'
						Modal.error(modalConfig)
					}
					break
				case Apps.DMS:
					if (!apps.has(Apps.DMS)) {
						modalConfig.title = 'DMS Access Denied'
						modalConfig.content = 'You do not have access to the Delivery Management System.'
						Modal.error(modalConfig)
					}
					break
				case Apps.TMS:
					if (!apps.has(Apps.TMS)) {
						modalConfig.title = 'TMS Access Denied'
						modalConfig.content = 'You do not have access to the Transport Management System.'
						Modal.error(modalConfig)
					}
					break
			}
		}
	}, [companyDetails, app, role])

	useInterval(async () => {
		try {
			const { buildId } = (await axios.get('/api/build-id')).data
			if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
				// A new version of the application has been deployed. Prompt user to refresh.
				if (!showRefreshPrompt) {
					setShowRefreshPrompt(true)
				}
			}
		} catch (e) {
			console.error(e)
		}
	}, 300000)

	const setUserId = () => {
		if (!authUser) {
			return
		}
		if (window.gtag) {
			const { gtag } = window
			gtag('set', { 'user_id': authUser.uid })
		}
		if (window.mixpanel) {
			const { mixpanel } = window
			mixpanel.identify(authUser.uid)
			mixpanel.people.set({
				$email: authUser.email,
				$phone: authUser.phoneNumber,
				$name: authUser.displayName
			})
		}
	}

	const setupFreshworksSupportWidget = () => {
		if (window.FreshworksWidget) {
			const { FreshworksWidget } = window
			FreshworksWidget('hide', 'launcher')
			if (authUser) {
				const {
					displayName,
					email
				} = authUser
				FreshworksWidget('identify', 'ticketForm', {
					name: displayName,
					email
				})
			}
		}
	}

	const fetchAuthUser = () => {
		auth.onIdTokenChanged(async authUser => {
			if (authUser) {
				const tokenResult = await authUser.getIdTokenResult()
				if (tokenResult.claims) {
					dispatch(setRole(tokenResult.claims.role))
					const plan = tokenResult.claims.plan
					const companyId = tokenResult.claims.cid
					dispatch(setPlan(plan))
					dispatch(setCompanyId(companyId))
					if (plan === 'expired') {
						Modal.error({
							title: 'Your Plan has Expired',
							content: 'Please check your subscription in order to continue using Nuport.'
						})
					}
				}
				dispatch(setAuthUser(authUser))
				dispatch(fetchUserProfile())
				dispatch(fetchPermissions())
			}
		})
	}

	const getUserNotifications = uid => {
		const unsubscribeNotificationCount = Notifications.listenToNotificationCount(uid, data => {
			dispatch(setUnreadCount(data.unreadCount ? data.unreadCount : 0))
			dispatch(setNewInsights(data.newInsights || false))
			dispatch(setCount(data.count ? data.count : 0))
		})
		const unsubscribeNotifications = Notifications.listenToUnreadNotifications(
			uid,
			notification => {
				if (!unreadNotificationsMap[notification.id]) {
					showNotification(notification)
					dispatch(addNotification(notification))
				}
			},
			notification => {
				showNotification(notification)
				dispatch(updateNotification(notification))
			})
		return { unsubscribeNotificationCount, unsubscribeNotifications }
	}

	const getCompany = (cid, plan) => {
		if (!cid) {
			return
		}
		return Companies.listenToCompany(cid, company => {
			const companyDetails = company?.companyDetails
			if (companyDetails && plan) {
				if (companyDetails.subscriptionPlan?.toLowerCase() !== plan) {
					dispatch(setPlan(companyDetails.subscriptionPlan.toLowerCase()))
				}
			}
		})
	}

	const getDeliveryPlans = cid => {
		if (!cid) {
			return
		}
		const unsubscribeDeliveryPlans = DeliveryPlans.listenToDeliveryPlans(cid, () => {
			dispatch(fetchDeliveryPlans(undefined, 0))
			dispatch(fetchDeliveryPlans(DELIVERY_PLAN_PENDING_STATUS, 0))
		})
		const unsubscribeLoadingDeliveryPlans = DeliveryPlans.listenToLoadingDeliveryPlans(
			cid,
			createdDeliveryPlan => {
				showDeliveryPlanLoadingNotification(createdDeliveryPlan)
				dispatch(updateDeliveryPlanLoadState(createdDeliveryPlan.id, true))
			},
			updatedDeliveryPlan => {
				showDeliveryPlanLoadingNotification(updatedDeliveryPlan)
				dispatch(updateDeliveryPlanLoadState(updatedDeliveryPlan.id, true))
			},
			removedDeliveryPlan => {
				notification.close(removedDeliveryPlan.id)
				dispatch(updateDeliveryPlanLoadState(removedDeliveryPlan.id, false))
				dispatch(fetchDeliveryPlanDetails(removedDeliveryPlan.id))
			}
		)
		return { unsubscribeDeliveryPlans, unsubscribeLoadingDeliveryPlans }
	}

	const showDeliveryPlanLoadingNotification = (deliveryPlan) => {
		notification.open({
			key: deliveryPlan.id,
			message: 'Processing Delivery Plan',
			description:
			<div style={{ display: 'flex' }}>
				<Spin
					style={{ marginRight: 12 }}
					indicator={<LoadingOutlined />}
				/>
				<p style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}><span style={{ color: '#278EA5' }}>DP-{deliveryPlan.shortId} </span>&nbsp;{' is currently being updated.'}</p>
			</div>,
			placement: 'bottomLeft',
			duration: 0
		})
	}

	const showNotification = (notificationData) => {
		notification.open(getNotificationArgs(notificationData))
	}

	const resetApp = async () => {
		await dispatch(setApp(companyDetails.defaultApp))
		window.location.href = '/'
	}

	return (
		role && role !== claimsRoles.ADMIN && !userProfile ?
			<FullPageLoader /> :
			<>
				<Script>
					{
						`
						window.fwSettings={
							'widget_id':151000004480
						};
						!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
						`
					}
				</Script>
				<Script
					type='text/javascript'
					src='https://widget.freshworks.com/widgets/151000004480.js'
					async
					defer
				/>
				<Script
					type='text/javascript'
					src='https://nuport.freshchat.com/js/widget.js'
					async
				/>
				<Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}`} />
				<Script id='google-analytics'>
					{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
			
						gtag('config', '${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}');
					`}
				</Script>
				{
					typeof window != 'undefined' && !window.mixpanel &&
					<Script
						strategy='afterInteractive'
						dangerouslySetInnerHTML={{
							__html: `
							(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
							for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
							MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
							mixpanel.init('${process.env.NEXT_PUBLIC_MIXPANEL_TOKEN}', { track_pageview: true, persistence: 'localStorage' });
						` }}
					/>
				}
				<Script src='https://embed.released.so/1/embed.js' />
				<GoogleOAuthProvider
					clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
				>
					<ReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_SECRET}>
						<PageDataContext>
							<GlobalFilterProvider>
								<NotificationBarProvider>
									{getLayout(<Component {...pageProps} />)}
								</NotificationBarProvider>
							</GlobalFilterProvider>
						</PageDataContext>
					</ReCaptchaProvider>
				</GoogleOAuthProvider>
				<ChatSupport />
			</>
	)
}

const Message = ({ message, onClick }) => {
	return (
		<div
			onClick={onClick}
			style={{ cursor: 'pointer', padding: '0 12px' }}
		>
			{message}
		</div>
	)
}

export default wrapper.withRedux(App)
