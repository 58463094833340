import PropTypes from 'prop-types'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'

const areas = [
	{
		label: 'Same day (Same City)',
		value: 'same_day'
	},
	{
		label: 'Next day (Same City)',
		value: 'next_day'
	},
	{
		label: 'Sub City',
		value: 'sub_city'
	},
	{
		label: 'Outside Dhaka',
		value: 'outside_dhaka'
	}
]

const DeliveryAreaSelectMoveX = ({ title, error, value, onChange }) => {

	const onAreaChange = (_, option) => {
		onChange({
			...value,
			area: option ? { value: option.value, label: option.label } : null
		})
	}


	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			style={{ width: '100%', maxWidth: 'unset' }}
			levels={[
				{
					id: 'area',
					placeholder: 'Area',
					options: areas,
					value: value?.area || null,
					onChange: onAreaChange,
					onClear: () => onAreaChange()
				}
			]}
		/>
	)
}

DeliveryAreaSelectMoveX.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectMoveX.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectMoveX
