import { HYDRATE } from 'next-redux-wrapper'
import { SALES_ORDER_FLAGGED_STATUS } from '../../pages/sales-orders'
import { convertCamelCaseToString, convertStringToEnum } from '../../utils'
import { Apps } from '../../utils/constants'
import { UPDATE_COMPANY_DETAILS_SUCCESS, UPDATE_COMPANY_DELIVERY_PLAN_SETTINGS_SUCCESS, UPDATE_COMPANY_PREFERENCES_SUCCESS } from '../companies/action-types'
import { UPDATE_USER_SUCCESS } from '../users/action-types'
import {
	SET_AUTH_USER,
	FETCH_USER_PROFILE_SUCCESS,
	SET_ROLE,
	SET_PLAN,
	FETCH_PERMISSIONS_SUCCESS,
	SET_COMPANY_ID,
	SET_APP,
	SET_PAGE_NAME,
	SET_COMPANY_DETAILS
} from './action-types'
import { preferenceKeys } from '../../utils/preferences'

const defaultPermissionsState = {
	addProducts: true,
	uploadProducts: true,
	editProducts: true,
	viewProducts: true,

	addDistributors: true,
	uploadDistributors: true,
	editDistributors: true,
	viewDistributors: true,

	addFleet: true,
	uploadFleet: true,
	editFleet: true,
	viewFleet: true,

	addPurchaseOrders: true,
	uploadPurchaseOrders: true,
	editPurchaseOrders: true,
	viewPurchaseOrders: true,

	addOrders: true,
	uploadOrders: true,
	editOrders: true,
	viewOrders: true,
	shipOrders: true,
	unloadOrders: true,
	completeOrders: true,
	cancelOrders: true,

	viewRequestedOrders: true,
	viewFlaggedOrders: true,
	viewApprovedOrders: true,
	viewProcessingOrders: true,
	viewCompletedOrders: true,
	viewShippedOrders: true,
	viewInTransitOrders: true,
	viewDeliveredOrders: true,
	viewCanceledOrders: true,
	viewDamagedOrders: true,
	viewReturnedOrders: true,
	viewPickingOrders: true,
	viewPackingOrders: true,

	addDeliveryPlans: true,
	editDeliveryPlans: true,
	viewDeliveryPlans: true,

	addInventory: true,
	uploadInventory: true,
	editInventory: true,
	viewInventory: true,
	createLabels: true,
	createPalletLabels: true,

	addVendors: true,
	uploadVendors: true,
	editVendors: true,
	viewVendors: true,

	addLocations: true,
	editLocations: true,
	viewLocations: true,

	addReports: true,
	editReports: true,
	viewReports: true,

	addUsers: true,
	editUsers: true,
	viewUsers: true,

	editPermissions: true,
	addPermissionGroup: true,

	viewGeneralAnalytics: true,
	viewSalesAnalytics: true
}

const defaultPreferencesState = Object.values(preferenceKeys).reduce((acc, { key, value }) => {
	acc[key] = value
	return acc
}, {})

const initialState = {
	authUser: null,
	userProfile: null,
	role: null,
	plan: null,
	companyDetails: null,
	companyId: null,
	permissions: defaultPermissionsState,
	preferences: defaultPreferencesState,
	enabledOrderStatuses: {},
	enabledExpenseStatuses: {},
	enabledPurchaseOrderStatuses: {},
	app: typeof window !== 'undefined' ? localStorage.getItem('app') || Apps.OMS : null,
	pageName: null
}

const authReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case SET_AUTH_USER: {
			return {
				...state,
				authUser: payload
			}
		}
		case UPDATE_USER_SUCCESS: {
			return {
				...state,
				userProfile: {
					...state.userProfile,
					...payload
				}
			}
		}
		case FETCH_PERMISSIONS_SUCCESS: {
			const permissionKeys = Object.keys(payload)
			const enabledOrderStatuses = {}
			const enabledExpenseStatuses = {}
			const enabledPurchaseOrderStatuses = {}
			for (const key of permissionKeys) {
				if (key.toLowerCase().includes('view') &&
				key.toLowerCase().includes('order') &&
				!key.toLowerCase().includes('purchase') &&
				!key.includes('viewOrders') &&
				payload[key] === true) {
					// Order status view permissions
					const statusText = key.replace('view', '').replace('Orders', '')
					const status = convertStringToEnum(convertCamelCaseToString(statusText).toLowerCase())
					enabledOrderStatuses[status] = true
				} else if (key.toLowerCase().includes('view') &&
				key.toLowerCase().includes('expenses') &&
				!key.includes('viewExpenses') &&
				payload[key] === true) {
					// Expense status view permissions
					const statusText = key.replace('view', '').replace('Expenses', '')
					const status = statusText.toUpperCase()
					enabledExpenseStatuses[status] = true
				} else if (
					key.toLowerCase().includes('view') &&
					key.toLowerCase().includes('order') &&
					key.toLowerCase().includes('purchase') &&
					!key.includes('viewPurchaseOrders') &&
					payload[key] === true
				) {
					const statusText = key.replace('view', '').replace('PurchaseOrders', '')
					const status = statusText.toLowerCase()
					enabledPurchaseOrderStatuses[status] = true
				}
			}
			if (!enabledOrderStatuses['returned'] && !enabledOrderStatuses['damaged']) {
				enabledOrderStatuses[SALES_ORDER_FLAGGED_STATUS] = false
			}
			return {
				...state,
				enabledOrderStatuses,
				enabledExpenseStatuses,
				enabledPurchaseOrderStatuses,
				permissions: payload
			}
		}
		case FETCH_USER_PROFILE_SUCCESS: {
			const newState = { ...state }
			newState.userProfile = payload
			const company = payload.company
			if (company && company.companyDetails) {
				newState.companyDetails = company.companyDetails
				newState.app = company.companyDetails.defaultApp || Apps.OMS
			}
			if (company && company.preferences) {
				newState.preferences = company.preferences.reduce((newPreferences, preference) => {
					const keyMapping = preferenceKeys[preference.key]
					if (keyMapping) {
						newPreferences[keyMapping.key] = preference.value
					}
					return newPreferences
				}, { ...newState.preferences })
			}
			return newState
		}
		case UPDATE_COMPANY_DELIVERY_PLAN_SETTINGS_SUCCESS: {
			const newState = { ...state }
			newState.companyDetails = payload.data
			return newState
		}
		case SET_ROLE: {
			return {
				...state,
				role: payload
			}
		}
		case SET_COMPANY_ID: {
			return {
				...state,
				companyId: payload
			}
		}
		case SET_PLAN: {
			return {
				...state,
				plan: payload
			}
		}
		case SET_COMPANY_DETAILS: {
			return {
				... state,
				companyDetails: payload
			}
		}
		case UPDATE_COMPANY_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.companyDetails = payload.data
			newState.app = payload.data.defaultApp || Apps.OMS
			const userProfile = newState.userProfile
			if (userProfile && userProfile.company) {
				userProfile.company.companyDetails = payload.data
			}
			return newState
		}
		case UPDATE_COMPANY_PREFERENCES_SUCCESS: {
			const newState = { ...state }
			const preferences = payload.data
			if (preferences) {
				newState.preferences = preferences.reduce((newPreferences, preference) => {
					const keyMapping = preferenceKeys[preference.key]
					if (keyMapping) {
						newPreferences[keyMapping.key] = preference.value
					}
					return newPreferences
				}, { ...newState.preferences })
			}
			return newState
		}
		case SET_APP: {
			return {
				...state,
				app: payload
			}
		}
		case SET_PAGE_NAME: {
			return {
				...state,
				pageName: payload
			}
		}
		default:
			return state
	}
}

export default authReducer
