import { useContext, useEffect, useState } from 'react'
import styles from './BillingInvoiceFilter.module.css'
import useSearchParams from '../../hooks/useSearchParams'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { useRouter } from 'next/router'
import DropdownOverlay from '../dropdown-overlay'
import { Companies } from '../../services/api/companies'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import moment from 'moment'
import { paths } from '../../utils/constants'
import Input from '../input'
import { Button } from 'antd'

const BillingInvoiceFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen
}) => {
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const shouldResetFilter = useContext(GlobalFilterContext)
	const router = useRouter()
	const { query } = router
	const { status } = query
	const [companies] = useState([])
	const [cycleStartDate, setCycleStartDate] = useState(searchParams.cycleStartDate)
	const [cycleEndDate, setCycleEndDate] = useState(searchParams.cycleEndDate)
	const [selectedCompany, setSelectedCompany] = useState(searchParams.selectedCompany || null)

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!isFilterDropdownOpen) {
			return
		}
	}, [isFilterDropdownOpen])

	const searchCompanies = value => {
		return Companies.index({ searchTerm: value })
	}

	const resetFilter = () => {
		setSelectedCompany(null)
		setCycleStartDate(null)
		setCycleEndDate(null)
		clearFilter()
	}

	const isFilterEnabled = () => {
		return selectedCompany || cycleStartDate || cycleEndDate
	}

	const handleApply = async () => {
		const filters = {}
		if (cycleStartDate) {
			filters.cycleStartDate = moment(cycleStartDate).startOf('day').toDate()
		}
		if (cycleEndDate) {
			filters.cycleEndDate = moment(cycleEndDate).endOf('day').toDate()
		}
		if (selectedCompany) {
			filters.company = selectedCompany
			filters.companyId = selectedCompany.value
		}
		applyFilter(filters)
		setIsFilterDropdownOpen(false)
		if (status) {
			router.push(`${paths.BILLING_AND_PAYMENTS}?module=billing&status=${status}`)
		} else {
			router.push(`${paths.BILLING_AND_PAYMENTS}?module=billing`)
		}
	}

	return (
		<DropdownOverlay>
			<div>
				<CustomSearchableSelectSecondary
					placeholder='Search Company Name'
					customLabel={data => {
						return (
							<div>
								<div style={{ fontWeight: 'bold' }}>{data.name}</div>
								<div style={{ fontSize: 12 }}>{moment(data.createdAt).format('LLL')}</div>
							</div>
						)
					}}
					labelIndex='name'
					valueIndex='id'
					allowClear={true}
					defaultOptions={companies}
					value={selectedCompany}
					onChange={option => {
						if (option?.value) {
							setSelectedCompany(option)
						}
					}}
					onClear={() => setSelectedCompany(null)}
					searchTask={searchCompanies}
				/>
			</div>
			<div>
				<p className={styles.title}>Payment Cycle</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='Start Date'
						type='date'
						value={cycleStartDate ? moment(cycleStartDate) : null }
						onChange={(date) => setCycleStartDate(date?.toDate())}
						disabledDate={current => cycleEndDate && current.valueOf() >= moment(cycleEndDate).valueOf()}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='End Date'
						type='date'
						value={cycleEndDate ? moment(cycleEndDate) : null }
						onChange={(date) => setCycleEndDate(date?.toDate())}
						disabledDate={current => cycleStartDate && current.valueOf() <= moment(cycleStartDate).valueOf()}
					/>
				</div>
			</div>
			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

export default BillingInvoiceFilter
