export const FETCH_SALES_ORDERS_SUCCESS = 'fetch_sales_orders_success'
export const ADD_SALES_ORDERS_SUCCESS = 'add_sales_orders_success'
export const FETCH_SALES_ORDER_DETAILS_SUCCESS = 'fetch_sales_order_details_success'
export const UPDATE_SALES_ORDER_SUCCESS = 'update_sales_order_success'
export const ADD_TRANSACTION_SUCCESS = 'add_transaction_success'
export const EDIT_TRANSACTION_SUCCESS = 'edit_transaction_success'
export const FETCH_SALES_ORDER_COUNTS_SUCCESS = 'fetch_sales_orders_counts_success'
export const SET_SALES_ORDER_COUNTS = 'set_sales_order_counts'
export const RESET_SALES_ORDER_COUNTS = 'reset_sales_order_counts'
export const FETCH_SALES_ORDER_PAGINATION_SUCCESS = 'fetch_sales_order_pagination_success'
export const SELECT_SALES_ORDERS = 'select_sales_orders'
