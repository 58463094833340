import { useContext, useEffect, useState } from 'react'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './EnterpriseInventoryFilter.module.css'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import Input from '../input'
import moment from 'moment'
import { convertEnumToString } from '../../utils'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { Button } from 'antd'

const EnterpriseInventoryFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen
}) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [locations, setLocations] = useState()
	const [location, setLocation] = useState()
	const [fromDate, setFromDate] = useState(searchParams.updatedFrom || null)
	const [toDate, setToDate] = useState(searchParams.updatedTo || null)
	const [searchTerm, setSearchTerm] = useState('')
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)

	useEffect(() => {
		if (isFilterDropdownOpen) {
			fetchWarehouses()
		}
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!locations?.length || !searchParams.locationId) {
			return
		}
		const location = locations.find(({ id }) => id === searchParams.locationId)

		setLocation({
			id: location.id,
			key: location.id,
			label: location.label,
			data: location
		})
	}, [locations])

	const fetchWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const searchLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const handleApply = async () => {
		const filters = {
			searchTerm
		}
		if (location) {
			filters.locationId = location.value
		}
		if (fromDate) {
			filters.updatedFrom = fromDate
		}
		if (toDate) {
			filters.updatedTo = toDate
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setLocation(null)
		setFromDate(null)
		setToDate(null)
		clearFilter()
	}

	return (
		<DropdownOverlay>
			<CustomSearchableSelectSecondary
				searchTask={searchLocations}
				defaultOptions={locations}
				isLoading={isLoadingLocations}
				customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
				valueIndex='id'
				descriptionIndex='address'
				placeholder='Select Warehouse'
				allowClear
				onChange={location => setLocation(location)}
				onClear={() => setLocation(null)}
				value={location}
			/>
			<div>
				<p className={styles.title}>Updated At</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='From'
						type='date'
						value={fromDate ? moment(fromDate) : null }
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='To'
						type='date'
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>

			<div className={styles.buttons}>
				<Button
					className={`${styles.button} ${styles.resetButton}`}
					onClick={resetFilter}
				>
					<span className={styles.buttonText}>
						Reset All
					</span>
				</Button>
				<Button
					type='primary'
					className={styles.button}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

export default EnterpriseInventoryFilter
