import { createContext, useState } from 'react'

export const NotificationBarContext = createContext(false)
export const NotificationBarSetter = createContext(() => {})

const NotificationBarProvider = ({ children }) => {
	const [isNotificationBarVisible, setIsNotificationBarVisible] = useState(false)

	return (
		<NotificationBarContext.Provider value={isNotificationBarVisible}>
			<NotificationBarSetter.Provider value={setIsNotificationBarVisible}>
				{children}
			</NotificationBarSetter.Provider>
		</NotificationBarContext.Provider>
	)
}

export default NotificationBarProvider
