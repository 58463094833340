import { HYDRATE } from 'next-redux-wrapper'
import { CREATE_PERMISSION_GROUP_SUCCESS, EDIT_PERMISSION_GROUP_SUCCESS, FETCH_PERMISSION_GROUPS_SUCCESS, FETCH_PERMISSION_GROUP_DETAILS_SUCCESS, FETCH_PERMISSION_GROUP_USERS_SUCCESS } from './action-types'

const initialState = {
	permissionGroups: [],
	permissionGroupDetails: {},
	permissionGroupUsers: {}
}

const permissionsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_PERMISSION_GROUPS_SUCCESS: {
			return {
				...state,
				permissionGroups: payload
			}
		}
		case FETCH_PERMISSION_GROUP_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.permissionGroupDetails[payload.id] = payload
			return newState
		}
		case FETCH_PERMISSION_GROUP_USERS_SUCCESS: {
			const newState = { ...state }
			newState.permissionGroupUsers[payload.id] = {
				results: payload.data.results,
				totalCount: payload.data.count,
				page: payload.data.page
			}
			return newState
		}
		case CREATE_PERMISSION_GROUP_SUCCESS: {
			return {
				...state,
				permissionGroups: [payload, ...state.permissionGroups]
			}
		}
		case EDIT_PERMISSION_GROUP_SUCCESS: {
			const newState = { ...state }
			newState.permissionGroupDetails[payload.id] = payload
			return {
				...state
			}
		}
		default:
			return state
	}
}

export default permissionsReducer
