import { convertEnumToString } from '.'
import { agriculturePriceTypeFields, customerTypes, eCommercePriceTypeFields, industries, priceTypeFields } from './constants'

export const getPriceFieldByCustomerTypeAndIndustry = (customerType, industry) => {
	let currentPriceTypeFields = priceTypeFields
	let defaultPriceField = 'price'
	if (industry === industries.AGRICULTURE) {
		currentPriceTypeFields = agriculturePriceTypeFields
		defaultPriceField = 'distributorPrice'
	}
	if (industry === industries.E_COMMERCE) {
		currentPriceTypeFields = eCommercePriceTypeFields
	}
	const priceType = currentPriceTypeFields.find(priceType => priceType.type === customerType)
	return priceType ? priceType.field : defaultPriceField
}

export const getCustomerNamePlaceholder = (customerType) => {
	switch (customerType) {
		case customerTypes.DISTRIBUTOR:
		case customerTypes.DEALER:
		case customerTypes.RETAILER:
		case customerTypes.TRADER:
		case customerTypes.MICRO_ENTREPRENEUR:
		case customerTypes.CORPORATE:
			return `${convertEnumToString(customerType.toLowerCase())} Name`
		default:
			return 'Customer Name'
	}
}

export const agricultureSpecificCustomerTypes = [
	customerTypes.TRADER,
	customerTypes.MICRO_ENTREPRENEUR,
	customerTypes.CORPORATE
]
