import { useEffect, useState, useMemo } from 'react'
import { Divider, Modal, notification, Space, Radio, Checkbox, Button } from 'antd'
import Input from '../input'
import moment from 'moment'
import 'moment-timezone'
import {
	SALES_ORDER_APPROVED_STATUS,
	SALES_ORDER_CANCELLED_STATUS,
	SALES_ORDER_COMPLETED_STATUS,
	SALES_ORDER_FLAGGED_STATUS,
	SALES_ORDER_PROCESSING_STATUS,
	SALES_ORDER_REQUESTED_STATUS,
	SALES_ORDER_SHIPPED_STATUS,
	SALES_ORDER_IN_TRANSIT_STATUS,
	SALES_ORDER_ON_HOLD_STATUS
} from '../../pages/sales-orders'
import { EXPORT_TYPE, sources as eCommerceOrderSourceOptions } from '../../utils/constants'

export const ORDER_CREATION_DATE = 'createdAt'
export const ORDER_DATE = 'orderDate'
export const DELIVERY_DATE = 'deliveredAt'
export const TRANSITION_DATE = 'transitionDate'

import styles from './SalesOrdersExportModal.module.css'
import { SalesOrders } from '../../services/api/sales-orders'
import { Pdf } from '../../services/api/pdf'
import { useDispatch, useSelector } from 'react-redux'
import { industries } from '../../utils/constants'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { fetchDeliveryPartners } from '../../store/delivery-partners/actions'
import CustomSearchableSelectWithTags from '../custom-searchable-select-with-tags'

const SalesOrdersExportModal = ({ visible, onCancel, exportType, filteredColumns, isDownloadingSummary }) => {
	const dispatch = useDispatch()
	const { companyDetails } = useSelector(state => state.authReducer)
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [isExporting, setIsExporting] = useState(false)
	const [isStockTransfer, setIsStockTransfer] = useState(undefined)
	const [dateRangeType, setDateRangeType] = useState(ORDER_DATE)
	const [isLoadingDeliveryPartners, setIsLoadingDeliveryPartners] = useState(false)
	const [areAllDeliveryPartnersSelected, setAreAllDeliveryPartnersSelected] = useState(false)
	const [deliveryPartner, setDeliveryPartner] = useState(null)
	const [selectedDeliveryPartners, setSelectedDeliveryPartners] = useState([])
	const [areAllOrderStatusSelected, setAreAllOrderStatusSelected] = useState(false)
	const [orderStatus, setOrderStatus] = useState(null)
	const [statuses, setStatuses] = useState([])
	const [areAllOrderSourceSelected, setAreAllOrderSourceSelected] = useState(false)
	const [orderSource, setOrderSource] = useState(null)
	const [sources, setSources] = useState([])
	const orderStatusFlow = companyDetails?.orderStatusFlow
	const { deliveryPartners } = useSelector(state => state.deliveryPartnersReducer)

	const statusOptions = [
		{ label: 'Requested', value: SALES_ORDER_REQUESTED_STATUS },
		{ label: 'Approved', value: SALES_ORDER_APPROVED_STATUS },
		{ label: 'Delivered', value: SALES_ORDER_COMPLETED_STATUS },
		{ label: 'Flagged', value: SALES_ORDER_FLAGGED_STATUS },
		{ label: 'Processing', value: SALES_ORDER_PROCESSING_STATUS },
		{ label: 'Cancelled', value: SALES_ORDER_CANCELLED_STATUS }
	]

	const ECommerceStatusOptions = [
		{ label: 'Pending', value: SALES_ORDER_REQUESTED_STATUS },
		{ label: 'On Hold', value: SALES_ORDER_ON_HOLD_STATUS },
		{ label: 'Flagged', value: SALES_ORDER_FLAGGED_STATUS },
		{ label: 'Approved', value: SALES_ORDER_APPROVED_STATUS },
		{ label: 'Processing', value: SALES_ORDER_PROCESSING_STATUS },
		{ label: 'Shipped', value: SALES_ORDER_SHIPPED_STATUS },
		{ label: 'In-Transit', value: SALES_ORDER_IN_TRANSIT_STATUS },
		{ label: 'Delivered', value: SALES_ORDER_COMPLETED_STATUS },
		{ label: 'Cancelled', value: SALES_ORDER_CANCELLED_STATUS }
	]

	const dateRangeOptions = [
		{ label: 'Creation Date', value: ORDER_CREATION_DATE },
		{ label: 'Shipping Date', value: ORDER_DATE },
		{ label: 'Delivery Date', value: DELIVERY_DATE },
		{ label: 'Transition Date', value: TRANSITION_DATE }
	]

	const modifiedStatusOptions = useMemo(() => {
		let options = companyDetails?.industry === industries.E_COMMERCE ? ECommerceStatusOptions : statusOptions
		options = companyDetails?.industry !== industries.E_COMMERCE && orderStatusFlow ? options.filter(option => orderStatusFlow.statuses.includes(option.value.toUpperCase())) : options
		return options
	}, [companyDetails])

	useEffect(() => {
		if (selectedDeliveryPartners.length && selectedDeliveryPartners.length === deliveryPartners.length) {
			setAreAllDeliveryPartnersSelected(true)
		} else {
			setAreAllDeliveryPartnersSelected(false)
		}
	}, [selectedDeliveryPartners])

	useEffect(() => {
		if (statuses.length && statuses.length === modifiedStatusOptions.length) {
			setAreAllOrderStatusSelected(true)
		} else {
			setAreAllOrderStatusSelected(false)
		}
	}, [statuses])

	useEffect(() => {
		if (sources.length && sources.length === eCommerceOrderSourceOptions.length) {
			setAreAllOrderSourceSelected(true)
		} else {
			setAreAllOrderSourceSelected(false)
		}
	}, [sources])

	useEffect(() => {
		if (companyDetails?.industry === industries.E_COMMERCE && exportType === EXPORT_TYPE.CSV) {
			getDeliveryPartners()
		}
	}, [companyDetails, exportType])

	const isFilterEnabled = () => {
		return (fromDate && toDate) || statuses.length !== 0
	}

	const onExport = async () => {
		try {
			if (!isFilterEnabled()) {
				return
			}
			const columns = Object.keys(filteredColumns).filter((key) => filteredColumns[key]).join(',')
			const filters = {
				isStockTransfer,
				dateRangeType,
				columns,
				industry: companyDetails?.industry,
				timezone: moment.tz.guess(),
				isDownloadingSummary
			}
			if (fromDate) {
				filters.fromDate = moment(fromDate).startOf('day').toDate()
			}
			if (toDate) {
				filters.toDate = moment(toDate).endOf('day').toDate()
			}
			if (selectedDeliveryPartners) {
				filters.preferredDeliveryPartners = selectedDeliveryPartners.map((partner) => partner.id)
			}
			if (statuses && statuses.length) {
				filters.statuses = statuses.map((status) => status.value)
			} else {
				filters.statuses = modifiedStatusOptions.map((status) => status.value)
			}
			if (sources) {
				filters.sources = sources.map((source) => source.value)
			}
			setIsExporting(true)
			if (exportType == EXPORT_TYPE.CSV) {
				await SalesOrders.downloadCSV(filters)
			} else if (exportType == EXPORT_TYPE.PDF) {
				await Pdf.downloadSalesOrders(filters)
			}
			onCancel()
		} catch (e) {
			notification.error({
				message: `Unable to export ${exportType}`,
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsExporting(false)
		}
	}

	const resetFilter = () => {
		setIsStockTransfer(null)
		setDateRangeType(null)
		setFromDate(null)
		setToDate(null)
		setStatuses([])
		setSources([])
		setSelectedDeliveryPartners([])
		setAreAllDeliveryPartnersSelected(false)
		setAreAllOrderSourceSelected(false)
		setAreAllOrderStatusSelected(false)
	}

	const onDeliveryPartnerSelected = value => {
		const { data } = value
		const newlySelectedDeliveryPartner = { value: data.id, label: data.name }
		setDeliveryPartner(newlySelectedDeliveryPartner)
		let newDeliveryPartner = [...selectedDeliveryPartners]
		const existingDeliveryPartner = selectedDeliveryPartners.find(deliveryPartner => deliveryPartner.value === newlySelectedDeliveryPartner.value)
		if (!existingDeliveryPartner) {
			newDeliveryPartner.push({ value: data.id, label: data.name, ...data })
		} else {
			newDeliveryPartner.splice(newDeliveryPartner.indexOf(existingDeliveryPartner), 1)
		}
		setSelectedDeliveryPartners(newDeliveryPartner)
	}

	const onOrderStatusSelected = value => {
		const { data } = value
		setOrderStatus(value)
		let newStatuses = [...statuses]
		const existingOrderStatus = statuses.find(orderStatus => orderStatus.value === data.value)
		if (!existingOrderStatus) {
			newStatuses.push(data)
		} else {
			newStatuses.splice(newStatuses.indexOf(existingOrderStatus), 1)
		}
		setStatuses(newStatuses)
	}

	const onOrderSourceSelected = value => {
		const { data } = value
		setOrderSource(value)
		let newOrderSources = [...sources]
		const existingOrderSource = sources.find(orderSource => orderSource.value === data.value)
		if (!existingOrderSource) {
			newOrderSources.push(data)
		} else {
			newOrderSources.splice(newOrderSources.indexOf(existingOrderSource), 1)
		}
		setSources(newOrderSources)
	}

	const searchDeliveryPartners = value => {
		return DeliveryPartners.index({
			searchTerm: value
		})
	}

	const searchOrderStatuses = async value => {
		const filteredData = modifiedStatusOptions.filter(option => option.label.toLowerCase().includes(value.toLowerCase()))
		return { data: filteredData }
	}

	const searchOrderSources = async value => {
		const filteredData = eCommerceOrderSourceOptions.filter(option => option.label.toLowerCase().includes(value.toLowerCase()))
		return { data: filteredData }
	}

	const getDeliveryPartners = async () => {
		setIsLoadingDeliveryPartners(true)
		await dispatch(fetchDeliveryPartners({
			page: 0
		}))
		setIsLoadingDeliveryPartners(false)
	}

	const getAllDeliveryPartners = async () => {
		try {
			setIsLoadingDeliveryPartners(true)
			const { data } = await DeliveryPartners.index({
				page: -1
			})
			const deliveryPartners = data.results
			const deliveryPartnerOptions = deliveryPartners.map(deliveryPartner => ({ value: deliveryPartner.id, label: deliveryPartner.name, ...deliveryPartner }))
			setSelectedDeliveryPartners(deliveryPartnerOptions)
			setDeliveryPartner(null)
		} catch (err) {
			notification.error({
				message: 'Unable to Fetch Delivery Partners',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDeliveryPartners(false)
		}
	}

	const renderFooter = () => {
		return (
			<div className={styles.footer}>
				<Button
					style={{
						marginRight: 'auto'
					}}
					key='reset'
					onClick={resetFilter}
					disabled={!isFilterEnabled()}
				>
					Reset Filter
				</Button>
				<Button
					key='cancel'
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					key='apply'
					type='primary'
					loading={isExporting}
					disabled={!isFilterEnabled()}
					onClick={onExport}
				>
					{
						isDownloadingSummary ?
							'Export Summary' :
                            `Export ${exportType}`
					}
				</Button>
			</div>
		)
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={538}
			okText='Apply'
			footer={renderFooter()}
			title='Export'
		>
			<div className={styles.exportModalContainer}>
				{
					companyDetails?.industry !== industries.E_COMMERCE &&
					<>
						<div className={styles.section}>
							<Radio.Group
								style={{ display: 'flex' }}
								onChange={e => setIsStockTransfer(e.target.value)}
								value={isStockTransfer}
							>
								<div className={styles.radioColumn}>
									<Radio style={{ marginBottom: 16 }} value={false}>Sales Order</Radio>
								</div>
								<div className={styles.radioColumn}>
									<Radio style={{ marginBottom: 16 }} value={true}>Stock Transfer Order</Radio>
								</div>
							</Radio.Group>
						</div>
						<Divider />
					</>
				}
				<div className={styles.section}>
					<h3 className={styles.label}>Date Range</h3>
					<Radio.Group
						style={{ display: 'flex' }}
						onChange={e => setDateRangeType(e.target.value)}
						value={dateRangeType}
					>
						{
							dateRangeOptions.map((option, index) =>
								<Radio style={{ marginBottom: 16 }} key={index} value={option.value}>{option.label}</Radio>
							)
						}
					</Radio.Group>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Input
							style={{ borderRadius: 0, flex: 1 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<div className={styles.dash} />
						<Input
							style={{ borderRadius: 0, flex: 1 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</div>
				</div>
				<Divider />
				<div className={styles.section}>
					<Space align='start'>
						<CustomSearchableSelectWithTags
							disabled={dateRangeType === TRANSITION_DATE && (!fromDate || !toDate)}
							style={{ width: 490 }}
							searchTask={searchOrderStatuses}
							defaultOptions={modifiedStatusOptions}
							title='Order Status'
							labelIndex='label'
							valueIndex='value'
							placeholder='Select Order Status'
							onChange={onOrderStatusSelected}
							onClear={() => setOrderStatus(null)}
							value={orderStatus}
							setValue={setOrderStatus}
							areAllValuesSelected={areAllOrderStatusSelected}
							setAreAllValuesSelected={setAreAllOrderStatusSelected}
							onAreAllValuesSelectedChange={areAllOrderStatusSelected => {
								setStatuses(areAllOrderStatusSelected ? modifiedStatusOptions : [])
								setOrderStatus(null)
							}}
							selectedValues={statuses}
							setSelectedValues={setStatuses}
							isOnChangeDropdownOpen={true}
							dropdownConfig={['label']}
							renderOption={({ data: { label } }) => {
								return (
									<div className={styles.option}>
										<div className={styles.customerNameContainer}>
											<Checkbox
												checked={statuses.some(status => status.label === label)}
												onChange={() => {
													onOrderStatusSelected({ data: { value: modifiedStatusOptions.find(status => status.label === label).value, label } })
												}}
												style={{ marginRight: 8 }}
											/>
											{label}
										</div>
									</div>
								)
							}}
							popupContainer={() => document.querySelector('.ant-modal-body')}
						/>
					</Space>
				</div>
				<Divider />
				{
					companyDetails?.industry === industries.E_COMMERCE &&
					exportType === EXPORT_TYPE.CSV &&
					<div className={styles.section}>
						<Space align='start'>
							<CustomSearchableSelectWithTags
								style={{ width: 490 }}
								searchTask={searchOrderSources}
								defaultOptions={eCommerceOrderSourceOptions}
								title='Order Source'
								selectedTitle='Selected Order Sources'
								labelIndex='label'
								valueIndex='value'
								placeholder='Select Order Source'
								onChange={onOrderSourceSelected}
								onClear={() => setOrderSource(null)}
								value={orderSource}
								setValue={setOrderSource}
								areAllValuesSelected={areAllOrderSourceSelected}
								setAreAllValuesSelected={setAreAllOrderSourceSelected}
								onAreAllValuesSelectedChange={areAllOrderSourceSelected => {
									setSources(areAllOrderSourceSelected ? eCommerceOrderSourceOptions : [])
									setOrderSource(null)
								}}
								selectedValues={sources}
								setSelectedValues={setSources}
								isOnChangeDropdownOpen={true}
								dropdownConfig={['label']}
								renderOption={({ data: { label } }) => {
									return (
										<div className={styles.option}>
											<div className={styles.customerNameContainer}>
												<Checkbox
													checked={sources.some(source => source.label === label)}
													onChange={() => {
														onOrderSourceSelected({ data: { value: eCommerceOrderSourceOptions.find(source => source.label === label).value, label } })
													}}
													style={{ marginRight: 8 }}
												/>
												{label}
											</div>
										</div>
									)
								}}
								popupContainer={() => document.querySelector('.ant-modal-body')}
							/>
						</Space>
					</div>
				}
				{
					companyDetails?.industry === industries.E_COMMERCE &&
					exportType === EXPORT_TYPE.CSV &&
					<div className={styles.section}>
						<Divider />
						<Space align='start'>
							<CustomSearchableSelectWithTags
								style={{ width: 490 }}
								searchTask={searchDeliveryPartners}
								defaultOptions={deliveryPartners}
								isLoading={isLoadingDeliveryPartners}
								title='Delivery Partner'
								selectedTitle='Selected Delivery Partners'
								labelIndex='name'
								valueIndex='id'
								placeholder='Select Delivery Partner'
								onChange={onDeliveryPartnerSelected}
								onClear={() => setDeliveryPartner(null)}
								value={deliveryPartner}
								setValue={setDeliveryPartner}
								areAllValuesSelected={areAllDeliveryPartnersSelected}
								setAreAllValuesSelected={setAreAllDeliveryPartnersSelected}
								onAreAllValuesSelectedChange={areAllDeliveryPartnersSelected => {
									if (areAllDeliveryPartnersSelected) {
										getAllDeliveryPartners()
									} else {
										setSelectedDeliveryPartners([])
									}
									setDeliveryPartner(null)
								}}
								selectedValues={selectedDeliveryPartners}
								setSelectedValues={setSelectedDeliveryPartners}
								isOnChangeDropdownOpen={true}
								dropdownConfig={['label']}
								renderOption={({ data: { name } }) => {
									return (
										<div className={styles.option}>
											<div className={styles.customerNameContainer}>
												<Checkbox
													checked={selectedDeliveryPartners.some(deliveryPartner => deliveryPartner.name === name)}
													onChange={() => {
														onDeliveryPartnerSelected({ data: { id: deliveryPartners.find(deliveryPartner => deliveryPartner.name === name).id, name } })
													}}
													style={{ marginRight: 8 }}
												/>
												{name}
											</div>
										</div>
									)
								}}
								popupContainer={() => document.querySelector('.ant-modal-body')}
							/>
						</Space>
					</div>
				}
			</div>
		</Modal>
	)
}

SalesOrdersExportModal.defaultProps = {
	exportType: ''
}

export default SalesOrdersExportModal
