import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { DeliveryPartners } from '../../services/api/delivery-partner'

const DeliveryAreaSelectPidex = ({ deliveryPartnerId, title, error, value, onChange }) => {
	const [isLoadingCities, setIsLoadingCities] = useState(false)
	const [isLoadingZones, setIsLoadingZones] = useState(false)
	const [isLoadingAreas, setIsLoadingAreas] = useState(false)
	const [cities, setCities] = useState([])
	const [zones, setZones] = useState([])
	const [areas, setAreas] = useState([])

	useEffect(() => {
		getCities()
	}, [])

	useEffect(() => {
		if (!value?.city?.value) {
			setZones([])
			setAreas([])
			return
		}
		getZones(value.city.value)
	}, [value?.city?.value])

	useEffect(() => {
		if (!value?.zone?.value) {
			setAreas([])
			return
		}
		getAreas(value.zone.value)
	}, [value?.zone?.value])

	const getCities = async () => {
		setIsLoadingCities(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryCities(deliveryPartnerId)
			const cities = data?.length
				? data.map(city => ({
					...city,
					value: city.city_id,
					label: city.city_name
				}))
				: []
			setCities(cities)
		} catch (e) {
			onChange({ ...value, city: null })
			setCities([])
			notification.error({
				message: 'Unable To Get Cities',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingCities(false)
		}
	}

	const getZones = async cityId => {
		setIsLoadingZones(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryZones(deliveryPartnerId, { cityId })
			const zones = data?.length
				? data.map(zone => ({
					...zone,
					value: zone.zone_id,
					label: zone.zone_name
				}))
				: []
			setZones(zones)
		} catch (e) {
			onChange({ ...value, zone: null })
			setZones([])
			notification.error({
				message: 'Unable To Get Zones',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingZones(false)
		}
	}

	const getAreas = async zoneId => {
		setIsLoadingAreas(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryAreas(deliveryPartnerId, { zoneId })
			const areas = data?.length
				? data.map(area => ({
					...area,
					value: area.area_id,
					label: area.area_name
				}))
				: []
			setAreas(areas)
		} catch (e) {
			onChange({ ...value, area: null })
			setAreas([])
			notification.error({
				message: 'Unable To Get Areas',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingAreas(false)
		}
	}

	const onCityChange = async (_, option) => {
		onChange({
			...value,
			city: option ? { value: option.value, label: option.label } : null,
			zone: null,
			area: null
		})
	}

	const onZoneChange = async (_, option) => {
		onChange({
			...value,
			zone: option ? { value: option.value, label: option.label } : null,
			area: null
		})
	}

	const onAreaChange = (_, option) => {
		onChange({
			...value,
			area: option ? { value: option.value, label: option.label } : null
		})
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'city',
					placeholder: 'City',
					loading: isLoadingCities,
					options: cities,
					value: value?.city || null,
					onChange: onCityChange,
					onClear: () => onCityChange(),
					style: { width: '33%' }
				},
				{
					id: 'zone',
					placeholder: 'Zone',
					loading: isLoadingZones,
					options: zones,
					value: value?.zone || null,
					onChange: onZoneChange,
					onClear: () => onZoneChange(),
					style: { width: '33%' }
				},
				{
					id: 'area',
					placeholder: 'Area',
					loading: isLoadingAreas,
					options: areas,
					value: value?.area || null,
					onChange: onAreaChange,
					onClear: () => onAreaChange(),
					style: { width: '33%' }
				}
			]}
		/>
	)
}

DeliveryAreaSelectPidex.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectPidex.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectPidex
