import { HYDRATE } from 'next-redux-wrapper'
import {
	ADD_PRODUCT_SUCCESS,
	FETCH_INVENTORY_SUCCESS,
	FETCH_INVENTORY_PRODUCTS_SUCCESS,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PRODUCT_CATEGORIES_SUCCESS,
	FETCH_PRODUCT_DETAILS_SUCCESS,
	FETCH_PRODUCT_METRICS_SUCCESS,
	FETCH_WAREHOUSE_DETAILS_SUCCESS,
	UPDATE_PRODUCT_SUCCESS,
	FETCH_INVENTORY_SKUS_SUCCESS,
	UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS,
	ADD_INVENTORY_PRODUCT_ITEM_SUCCESS,
	FETCH_INVENTORY_FEED_ITEMS_SUCCESS,
	FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS,
	ADD_INVENTORY_FEED_ITEM,
	FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS,
	FETCH_INVENTORY_STOCK_MONITORING_SUCCESS,
	FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS,
	FETCH_BLOCKED_INVENTORY_SUCCESS,
	FETCH_INVENTORY_LOGS_SUCCESS,
	FETCH_INVENTORY_BATCH_ITEMS_SUCCESS
} from './action-types'

const initialState = {
	totalCount: 0,
	page: 0,
	products: [],
	categories: {},
	steelCategories: {},
	productDetails: {},
	inventoryTotalCount: 0,
	inventoryPage: 0,
	warehouseDetails: {},
	inventory: [],
	inventoryProducts: [],
	inventoryProductsTotalCount: 0,
	inventoryProductsPage: 0,
	inventoryUnpalletedProducts: [],
	inventoryUnpalletedProductsTotalCount: 0,
	inventoryUnpalletedProductsPage: 0,
	inventoryProductsBlocked: [],
	inventoryProductsBlockedTotalCount: 0,
	inventoryProductsBlockedPage: 0,
	inventorySkus: [],
	inventorySkusTotalCount: 0,
	inventorySkusPage: 0,
	inventoryOnHandStock: [],
	inventoryOnHandStockTotalCount: 0,
	inventoryOnHandStockPage: 0,
	inventoryOrderMovement: [],
	inventoryOrderMovementTotalCount: 0,
	inventoryOrderMovementPage: 0,
	inventoryFeedItems: [],
	inventoryFeedItemMap: {},
	inventoryStockMonitoring: [],
	inventoryStockMonitoringTotalCount: 0,
	inventoryStockMonitoringPage: 0,
	inventoryLogs: [],
	inventoryLogsTotalCount: 0,
	inventoryLogsPage: 0,
	inventoryBatchItems: [],
	inventoryBatchItemsTotalCount: 0,
	inventoryBatchItemsPage: 0,
	metrics: {
		new: 0,
		total: 0,
		active: 0,
		inactive: 0,
		totalVariants: 0
	}
}

const productsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_PRODUCTS_SUCCESS: {
			return {
				...state,
				products: payload.results,
				totalCount: payload.count,
				page: +payload.page
			}
		}
		case ADD_PRODUCT_SUCCESS: {
			const products = [payload, ...state.products]
			return {
				...state,
				products
			}
		}
		case UPDATE_PRODUCT_SUCCESS: {
			const newState = { ...state }
			if (payload) {
				const product = newState.productDetails[payload.id]
				const ids = new Set()
				ids.add(payload.id)
				if (product) {
					const newProduct = Object.assign(product, payload)
					newState.productDetails[payload.id] = newProduct
					if (payload.variants) {
						for (const updatedVariant of payload.variants) {
							const variant = newState.productDetails[updatedVariant.id]
							if (variant && variant.deleted !== payload.deleted) {
								newState.productDetails[variant.id] = Object.assign(variant, { deleted: payload.deleted })
							}
						}
					}
				}
				newState.products = newState.products.map(product => {
					if (product.id === payload.id) {
						return Object.assign(product, payload)
					} else if (product.parentProductId === payload.id && product.deleted !== payload.deleted) {
						return Object.assign(product, { deleted: payload.deleted })
					} else {
						return product
					}
				})
			}
			return newState
		}
		case FETCH_PRODUCT_CATEGORIES_SUCCESS:
			if (payload.industry === 'steel') {
				return {
					...state,
					steelCategories: payload.data
				}
			} else {
				return {
					...state,
					categories: payload.data
				}
			}
		case FETCH_PRODUCT_DETAILS_SUCCESS: {
			const newState = { ...state }
			const productDetail = payload.data
			newState.productDetails[productDetail.id] = productDetail
			return newState
		}
		case FETCH_INVENTORY_SUCCESS: {
			return {
				...state,
				inventory: payload.results,
				inventoryTotalCount: payload.count,
				inventoryPage: +payload.page
			}
		}
		case FETCH_INVENTORY_PRODUCTS_SUCCESS: {
			const data = payload.data
			if (payload.isInStock) {
				return {
					...state,
					inventoryProducts: data.results,
					inventoryProductsTotalCount: data.count,
					inventoryProductsPage: +data.page
				}
			} else {
				return {
					...state,
					inventoryUnpalletedProducts: data.results,
					inventoryUnpalletedProductsTotalCount: data.count,
					inventoryUnpalletedProductsPage: +data.page
				}
			}
		}
		case FETCH_BLOCKED_INVENTORY_SUCCESS: {
			const data = payload.data
			return {
				...state,
				inventoryProductsBlocked: data.results,
				inventoryProductsBlockedTotalCount: data.count,
				inventoryProductsBlockedPage: +data.page
			}
		}
		case FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS: {
			return {
				...state,
				inventoryOnHandStock: payload.results,
				inventoryOnHandStockTotalCount: payload.count,
				inventoryOnHandStockPage: +payload.page
			}
		}
		case FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS: {
			return {
				...state,
				inventoryOrderMovement: payload.results,
				inventoryOrderMovementTotalCount: payload.count,
				inventoryOrderMovementPage: +payload.page
			}
		}
		case FETCH_INVENTORY_STOCK_MONITORING_SUCCESS: {
			return {
				...state,
				inventoryStockMonitoring: payload.results,
				inventoryStockMonitoringTotalCount: payload.count,
				inventoryStockMonitoringPage: +payload.page
			}
		}
		case FETCH_INVENTORY_SKUS_SUCCESS: {
			return {
				...state,
				inventorySkus: payload.results,
				inventorySkusTotalCount: payload.count,
				inventorySkusPage: +payload.page
			}
		}
		case FETCH_INVENTORY_FEED_ITEMS_SUCCESS: {
			return {
				...state,
				inventoryFeedItems: payload
			}
		}
		case FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS: {
			const inventoryFeedItems = [...state.inventoryFeedItems, ...payload]
			return {
				...state,
				inventoryFeedItems
			}
		}
		case ADD_INVENTORY_FEED_ITEM: {
			if (state.inventoryFeedItemMap[payload.id]) {
				return state
			}
			const maxSize = Math.max(50, state.inventoryFeedItems.length)
			const inventoryFeedItems = [payload, ...state.inventoryFeedItems].slice(0, maxSize)
			return {
				...state,
				inventoryFeedItemMap: {
					...state.inventoryFeedItemMap,
					[payload.id]: true
				},
				inventoryFeedItems
			}
		}
		case FETCH_PRODUCT_METRICS_SUCCESS: {
			return {
				...state,
				metrics: payload
			}
		}
		case FETCH_WAREHOUSE_DETAILS_SUCCESS: {
			const newState = { ...state }
			const warehouseDetail = payload.data
			newState.warehouseDetails[warehouseDetail.id] = warehouseDetail
			return newState
		}
		case UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProducts = newState.inventoryProducts.map(inventoryProduct =>
				payload.id === inventoryProduct.id ?
					Object.assign(inventoryProduct, payload) :
					inventoryProduct
			)
			return newState
		}
		case ADD_INVENTORY_PRODUCT_ITEM_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProducts = [payload, ...newState.inventoryProducts]
			return newState
		}
		case FETCH_INVENTORY_LOGS_SUCCESS: {
			return {
				...state,
				inventoryLogs: payload.results,
				inventoryLogsTotalCount: payload.count,
				inventoryLogsPage: +payload.page
			}
		}
		case FETCH_INVENTORY_BATCH_ITEMS_SUCCESS: {
			return {
				...state,
				inventoryBatchItems: payload.results,
				inventoryBatchItemsTotalCount: payload.count,
				inventoryBatchItemsPage: +payload.page
			}
		}
		default:
			return state
	}
}

export default productsReducer
