import { Badge, Button, Cascader, Modal, notification } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { SalesOrders } from '../../services/api/sales-orders'
import { InventoryEditions, SALES_ORDER_STATUS_COLORS, SALES_ORDER_SUB_STATUS_COLORS, orderStatuses } from '../../utils/constants'
import FlagReasoningForm from '../flag-reasoning-form'
import useOrderQuantityForm from '../use-order-quantity-form'
import { SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS } from '../../pages/sales-orders'

const {
	SALES_ORDER_APPROVED_STATUS,
	SALES_ORDER_REQUESTED_STATUS,
	SALES_ORDER_PROCESSING_STATUS,
	SALES_ORDER_FLAGGED_STATUS,
	SALES_ORDER_COMPLETED_STATUS,
	SALES_ORDER_CANCELLED_STATUS,
	SALES_ORDER_PAYMENT_DUE_STATUS,
	SALES_ORDER_PAYMENT_COLLECTED_STATUS,
	SALES_ORDER_RETURNED_STATUS,
	SALES_ORDER_DAMAGED_STATUS,
	SALES_ORDER_EXPIRED_STATUS,
	SALES_ORDER_IN_TRANSIT_STATUS,
	SALES_ORDER_SHIPPED_STATUS,
	SALES_ORDER_UNLOADED_STATUS
} = orderStatuses

const statusOptions = [
	{
		label: <Badge text='Requested' color={SALES_ORDER_STATUS_COLORS[SALES_ORDER_REQUESTED_STATUS]} />,
		value: SALES_ORDER_REQUESTED_STATUS
	},
	{
		label: <Badge text='Flagged' color={SALES_ORDER_STATUS_COLORS[SALES_ORDER_FLAGGED_STATUS]} />,
		value: SALES_ORDER_FLAGGED_STATUS,
		children: [
			{
				label: <Badge text='Returned' color={SALES_ORDER_SUB_STATUS_COLORS[SALES_ORDER_RETURNED_STATUS]} />,
				value: SALES_ORDER_RETURNED_STATUS
			},
			{
				label: <Badge text='Damaged' color={SALES_ORDER_SUB_STATUS_COLORS[SALES_ORDER_DAMAGED_STATUS]} />,
				value: SALES_ORDER_DAMAGED_STATUS
			},
			{
				label: <Badge text='Expired' color={SALES_ORDER_SUB_STATUS_COLORS[SALES_ORDER_EXPIRED_STATUS]} />,
				value: SALES_ORDER_EXPIRED_STATUS
			}
		]
	},
	{
		label: <Badge text='Approved' color={SALES_ORDER_STATUS_COLORS[SALES_ORDER_APPROVED_STATUS]} />,
		value: SALES_ORDER_APPROVED_STATUS
	},
	{
		label: <Badge text='Processing' color={SALES_ORDER_STATUS_COLORS[SALES_ORDER_PROCESSING_STATUS]} />,
		value: SALES_ORDER_PROCESSING_STATUS,
		children: [
			{
				label: <Badge text='Shipped' color={SALES_ORDER_SUB_STATUS_COLORS[SALES_ORDER_SHIPPED_STATUS]} />,
				value: SALES_ORDER_SHIPPED_STATUS
			},
			{
				label: <Badge text='In Transit' color={SALES_ORDER_SUB_STATUS_COLORS[SALES_ORDER_IN_TRANSIT_STATUS]} />,
				value: SALES_ORDER_IN_TRANSIT_STATUS
			},
			{
				label: <Badge text='Arrived' color={SALES_ORDER_SUB_STATUS_COLORS[SALES_ORDER_UNLOADED_STATUS]} />,
				value: SALES_ORDER_UNLOADED_STATUS
			}
		]
	},
	{
		label: <Badge text='Delivered' color={SALES_ORDER_STATUS_COLORS[SALES_ORDER_COMPLETED_STATUS]} />,
		value: SALES_ORDER_COMPLETED_STATUS,
		children: [
			{
				label: <Badge text='Payment Due' color={SALES_ORDER_SUB_STATUS_COLORS[SALES_ORDER_PAYMENT_DUE_STATUS]} />,
				value: SALES_ORDER_PAYMENT_DUE_STATUS
			},
			{
				label: <Badge text='Payment Collected' color={SALES_ORDER_SUB_STATUS_COLORS[SALES_ORDER_PAYMENT_COLLECTED_STATUS]} />,
				value: SALES_ORDER_PAYMENT_COLLECTED_STATUS,
				disabled: true
			}
		]
	},
	{
		label: <Badge text='Cancelled' color={SALES_ORDER_STATUS_COLORS[SALES_ORDER_CANCELLED_STATUS]} />,
		value: SALES_ORDER_CANCELLED_STATUS
	}
]

const UpdateOrderStatusModal = ({
	visible,
	onCancel,
	onComplete,
	currentStatus,
	currentSubStatus,
	salesOrderIds = []
}) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [isUpdating, setIsUpdating] = useState(false)
	const [status, setStatus] = useState()
	const [subStatus, setSubStatus] = useState()
	const [statuses, setStatuses] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [salesOrders, setSalesOrders] = useState([])
	const [flagReason, setFlagReason] = useState()
	const orderQuantityForm = useOrderQuantityForm()
	const [isCompleteOrderModalVisible, setIsCompleteModalVisible] = useState(false)
	const [isFlagOrderModalVisible, setIsFlagModalVisible] = useState(false)
	const [isSingleOrder, setIsSingleOrder] = useState(false)
	const { orderStatusFlow, inventoryEdition } = useMemo(() => {
		return {
			inventoryEdition: companyDetails?.inventoryEdition,
			orderStatusFlow: companyDetails?.orderStatusFlow
		}
	}, [companyDetails])
	const isAdvancedInventory = useMemo(() => inventoryEdition === InventoryEditions.ADVANCED, [inventoryEdition])

	useEffect(() => {
		if ([SALES_ORDER_COMPLETED_STATUS, SALES_ORDER_FLAGGED_STATUS].includes(status)) {
			getSalesOrders()
		}
	}, [status])

	useEffect(() => {
		if ([SALES_ORDER_FLAGGED_STATUS, SALES_ORDER_COMPLETED_STATUS].includes(status) && salesOrders.length === 1) {
			setIsSingleOrder(true)
			orderQuantityForm.setOrderDetail(salesOrders[0])
		}
	}, [status, salesOrders])

	const getSalesOrders = async () => {
		setIsLoading(true)
		const response = await SalesOrders.multiple(salesOrderIds)
		const salesOrders = response.data
		if (salesOrders) {
			setSalesOrders(salesOrders)
		}
		setIsLoading(false)
	}

	const onUpdate = async () => {
		try {
			setIsUpdating(true)
			let orders = salesOrderIds.map(id => ({ id, status, subStatus, flagReason }))
			if (status.toLowerCase() === SALES_ORDER_COMPLETED_STATUS) {
				orders = salesOrders.map(order => {
					return {
						id: order.id,
						status,
						subStatus,
						salesOrderItems: order.salesOrderItems.map(item => {
							return {
								productId: item.productId,
								packageId: item.packageId,
								quantity: item.quantity,
								packageQuantity: item.packageQuantity,
								approvedQuantity: item.approvedQuantity,
								inventoryBatchItemId: item.inventoryBatchItemId,
								approvedPackageQuantity: item.approvedPackageQuantity,
								deliveredQuantity: item.quantity,
								deliveredPackageQuantity: item.packageQuantity,
								salesOrderItemId: item.id
							}
						})
					}
				})
			}
			await SalesOrders.updateOrders(orders)
			notification.success({
				message: 'Updated Order Statuses',
				description: `Successfully updated order statuses to ${status.toLowerCase()}.`,
				placement: 'bottomLeft'
			})
			setFlagReason()
			onComplete(isAdvancedInventory && status === SALES_ORDER_FLAGGED_STATUS ? [] : salesOrderIds)
		} catch (e) {
			notification.error({
				message: 'Unable to Update Order Statuses',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdating(false)
		}
	}

	const completeOrder = async () => {
		try {
			setIsUpdating(true)
			const { orderDetail, getValues } = orderQuantityForm
			const params = getValues()
			params.status = status
			params.subStatus = subStatus
			await SalesOrders.update(orderDetail.id, params)
			onComplete(salesOrderIds)
		} catch (e) {
			notification.error({
				message: 'Unable to Deliver Order',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdating(false)
		}
	}

	const flagOrder = async () => {
		try {
			setIsUpdating(true)
			const { orderDetail, getValues } = orderQuantityForm
			const params = getValues()
			if (flagReason) {
				params.flagReason = flagReason
			}
			await SalesOrders.update(orderDetail.id, params)
		} catch (e) {
			notification.error({
				message: 'Unable to Flag Order',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdating(false)
		}
	}

	const onContinue = () => {
		if (isSingleOrder) {
			switch (status) {
				case SALES_ORDER_COMPLETED_STATUS: {
					setIsCompleteModalVisible(true)
					break
				}
				case SALES_ORDER_FLAGGED_STATUS: {
					setIsFlagModalVisible(true)
					break
				}
			}
			setIsSingleOrder(false)
		}
	}

	const modifiedStatusOptions = useMemo(() => {
		const newOptions = [...statusOptions]
		const filteredOptions = orderStatusFlow ?
			newOptions.filter(option => orderStatusFlow.statuses.includes(option.value.toUpperCase())) : newOptions
		const newStatusOptions = filteredOptions.map(option => {
			if (!isAdvancedInventory && [SALES_ORDER_PROCESSING_STATUS, SALES_ORDER_FLAGGED_STATUS].includes(option.value)) {
				option.children = undefined
			}
			if (option.children) {
				const childOptions = [...option.children]
				childOptions.forEach(childOption => {
					childOption.disabled = childOption.value === currentSubStatus
				})
				option.children = [...childOptions]
			} else {
				option.disabled = option.value === currentStatus
			}
			return option
		})
		if (isAdvancedInventory) {
			return newStatusOptions.filter((option) => {
				switch (currentStatus) {
					case SALES_ORDER_REQUESTED_STATUS:
						return [
							SALES_ORDER_APPROVED_STATUS,
							SALES_ORDER_CANCELLED_STATUS
						].includes(option.value)
					case SALES_ORDER_APPROVED_STATUS:
						return [
							SALES_ORDER_REQUESTED_STATUS,
							SALES_ORDER_PROCESSING_STATUS,
							SALES_ORDER_CANCELLED_STATUS
						].includes(option.value)
					case SALES_ORDER_PROCESSING_STATUS:
						return [
							SALES_ORDER_APPROVED_STATUS,
							SALES_ORDER_PROCESSING_STATUS,
							SALES_ORDER_COMPLETED_STATUS
						].includes(option.value)
					case SALES_ORDER_COMPLETED_STATUS:
						if (currentSubStatus === SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS) {
							return [SALES_ORDER_FLAGGED_STATUS].includes(option.value)
						} else {
							return [
								SALES_ORDER_FLAGGED_STATUS,
								SALES_ORDER_COMPLETED_STATUS
							].includes(option.value)
						}
					case SALES_ORDER_CANCELLED_STATUS:
						return SALES_ORDER_REQUESTED_STATUS === option.value
					case SALES_ORDER_FLAGGED_STATUS:
						return SALES_ORDER_REQUESTED_STATUS === option.value && currentSubStatus === SALES_ORDER_RETURNED_STATUS
					default:
						return false
				}
			})
		}

		return newStatusOptions
	}, [orderStatusFlow, currentStatus, currentSubStatus, isAdvancedInventory])

	const isDisabled = useMemo(() => {
		if (status === SALES_ORDER_COMPLETED_STATUS) {
			return !status || !subStatus
		}
		if (isAdvancedInventory && status === SALES_ORDER_FLAGGED_STATUS) {
			return !flagReason
		}
		return !status
	}, [status, subStatus, isAdvancedInventory, flagReason])

	const onOk = () => {
		if (isSingleOrder) {
			return onContinue()
		} else if (isCompleteOrderModalVisible) {
			return completeOrder()
		} else if (isFlagOrderModalVisible) {
			return flagOrder()
		} else {
			return onUpdate()
		}
	}

	const modalProps = useMemo(() => {
		let title = 'Change Status'
		let width = '420px'
		let okText = 'Change Status'
		let disabled = false
		if (isCompleteOrderModalVisible) {
			title = 'Deliver Order'
			width = 'calc(100vw - 100px)'
			okText = 'Deliver'
		}
		if (isFlagOrderModalVisible) {
			title = 'Flag Order'
			okText = 'Flag'
			width = 'calc(100vw - 100px)'
			disabled = orderQuantityForm.isInvalidForm
			if (subStatus === SALES_ORDER_RETURNED_STATUS) {
				title = 'Return Order'
				okText = 'Return'
			}
			if (subStatus === SALES_ORDER_DAMAGED_STATUS) {
				title = 'Damage Order'
				okText = 'Damage'
			}
			if (subStatus === SALES_ORDER_EXPIRED_STATUS) {
				title = 'Expire Order'
				okText = 'Expire'
			}
		}
		if (status === SALES_ORDER_CANCELLED_STATUS) {
			title = 'Cancel Order'
			okText = 'Confirm'
			disabled = !flagReason
		}
		if (isSingleOrder) {
			okText = 'Continue'
		}

		return {
			title,
			width,
			okText,
			disabled
		}
	}, [isCompleteOrderModalVisible, isFlagOrderModalVisible, flagReason, isSingleOrder, status, subStatus, orderQuantityForm.isInvalidForm])

	const renderInitialContent = () => {
		return (
			<div>
				<Cascader
					expandTrigger='hover'
					placeholder='Select Status'
					changeOnSelect={true}
					onChange={(options) => {
						setStatuses(options)
						setStatus(options[0])
						setSubStatus(options[1])
						orderQuantityForm.setSelectedStatus(options[0])
						orderQuantityForm.setSelectedSubStatus(options[1])
					}}
					displayRender={(labels) => labels[labels.length - 1]}
					value={statuses}
					options={modifiedStatusOptions}
				/>
				{
					isAdvancedInventory && [SALES_ORDER_FLAGGED_STATUS, SALES_ORDER_CANCELLED_STATUS].includes(status) &&
					<div style={{ marginTop: '24px' }}>
						<FlagReasoningForm
							reason={flagReason}
							setReason={setFlagReason}
							flag={subStatus || status}
						/>
					</div>
				}
			</div>
		)
	}

	const renderModalContent = () => {
		if (isCompleteOrderModalVisible || isFlagOrderModalVisible) {
			return orderQuantityForm.renderContent()
		}

		return renderInitialContent()
	}

	return (
		<Modal
			title={modalProps.title}
			visible={visible}
			onCancel={onCancel}
			width={modalProps.width}
			footer={
				<ModalFooter
					onOk={onOk}
					okText={modalProps.okText}
					onCancel={onCancel}
					loading={isLoading || isUpdating}
					disabled={isDisabled || modalProps.disabled}
				/>
			}
		>
			{
				renderModalContent()
			}
		</Modal>
	)
}

export default UpdateOrderStatusModal

const ModalFooter = ({ loading, disabled, onCancel, onOk, okText = 'Ok', cancelText = 'Cancel' }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
			<Button
				disabled={loading}
				onClick={onCancel}
			>
				{cancelText}
			</Button>
			<Button
				type='primary'
				loading={loading}
				disabled={disabled}
				onClick={onOk}
			>
				{okText}
			</Button>
		</div>
	)
}
