import { DeliveryPlans } from '../../services/api/delivery-plans'
import {
	FETCH_DELIVERY_PLANS_SUCCESS,
	FETCH_DELIVERY_PLAN_DETAILS_SUCCESS,
	ASSIGN_DRIVER_SUCCESS,
	UPDATE_DELIVERY_PLAN_SUCCESS,
	CREATE_DELIVERY_PLAN_SUCCESS,
	UPDATE_DELIVERY_PLAN_SALES_ORDERS_SUCCESS,
	ASSIGN_HELPER_SUCCESS,
	UPDATE_VEHICLE_TYPE_SUCCESS,
	UPDATE_DELIVERY_PLAN_LOAD_STATE,
	UPDATE_DELIVERY_PLAN_ROUTES,
	UPDATE_VEHICLE_ASSIGN_SUCCESS,
	ASSIGN_VEHICLE_SUCCESS,
	FETCH_DELIVERY_PLAN_ITEM_DETAILS_SUCCESS,
	UPDATE_DELIVERY_PLAN_ITEM_STATUS_SUCCESS,
	UPDATE_DELIVERY_PLAN_ITEM_SUCCESS,
	ASSIGN_TRANSPORT_VENDOR_SUCCESS
} from './action-types'

export function fetchDeliveryPlans (status, page, searchParams) {
	return async (dispatch) => {
		const response = await DeliveryPlans.index({ status, page, ...searchParams })
		dispatch({ type: FETCH_DELIVERY_PLANS_SUCCESS, payload: { data: response.data, status } })
	}
}

export function fetchDeliveryPlanDetails (id, startLocationId) {
	return async (dispatch) => {
		const response = await DeliveryPlans.single(id, { startLocationId })
		dispatch({ type: FETCH_DELIVERY_PLAN_DETAILS_SUCCESS, payload: { data: { ...response.data }, id } })
		return response.data
	}
}

export function fetchDeliveryPlanItemDetails (id) {
	return async (dispatch) => {
		const response = await DeliveryPlans.singleDeliveryPlanItem(id)
		dispatch({ type: FETCH_DELIVERY_PLAN_ITEM_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function assignVehicleToDeliveryPlanItem (id, params) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanItem(id, params)
		dispatch({ type: ASSIGN_VEHICLE_SUCCESS, payload: response.data })
	}
}

export function assignTransportVendorToDeliveryPlanItem (id, params) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanItem(id, params)
		dispatch({ type: ASSIGN_TRANSPORT_VENDOR_SUCCESS, payload: response.data })
	}
}

export function assignDriverToDeliveryPlanItem (id, params) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanItem(id, params)
		dispatch({ type: ASSIGN_DRIVER_SUCCESS, payload: response.data })
	}
}

export function	updateDeliveryPlanItem (id, params) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanItem(id, params)
		dispatch({ type: UPDATE_DELIVERY_PLAN_ITEM_SUCCESS, payload: response.data })
	}
}

export function assignHelperToDeliveryPlanItem (id, params) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanItem(id, params)
		dispatch({ type: ASSIGN_HELPER_SUCCESS, payload: response.data })
	}
}

export function updateDeliveryPlanItemVehicleType (id, vehicleType) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanItem(id, { vehicleType })
		dispatch({ type: UPDATE_VEHICLE_TYPE_SUCCESS, payload: response.data })
	}
}
export function updateDeliveryPlanItemStatus (id, status, cancellationReason) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanItemStatus(id, { status, cancellationReason })
		dispatch({ type: UPDATE_DELIVERY_PLAN_ITEM_STATUS_SUCCESS, payload: response.data })
	}
}

export function updateDeliveryPlan (id, params) {
	return async (dispatch) => {
		const response = await DeliveryPlans.update(id, params)
		dispatch({ type: UPDATE_DELIVERY_PLAN_SUCCESS, payload: response.data })
	}
}

export function updateDeliveryPlanSalesOrders (id, params) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanSalesOrders(id, params)
		dispatch({ type: UPDATE_DELIVERY_PLAN_SALES_ORDERS_SUCCESS, payload: response.data })
	}
}

export function createCustomDeliveryPlan (payload) {
	return async (dispatch) => {
		const response = await DeliveryPlans.create(payload)
		dispatch({ type: CREATE_DELIVERY_PLAN_SUCCESS, payload: response.data })
		return response.data
	}
}

export function updateDeliveryPlanLoadState (id, loading) {
	return async (dispatch) => {
		dispatch({ type: UPDATE_DELIVERY_PLAN_LOAD_STATE, payload: { id, loading } })
	}
}

export function updateDeliveryPlanRoutes (id, { routes, vehicles, salesOrderIds }) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateDeliveryPlanRoutes(id, { routes, vehicles, salesOrderIds })
		dispatch({ type: UPDATE_DELIVERY_PLAN_ROUTES, payload: response.data })
		return response.data
	}
}

export function updateVehicleAssignmentRequestStatus (requestId, payload) {
	return async (dispatch) => {
		const response = await DeliveryPlans.updateVehicleAssignmentRequestStatus(requestId, payload)
		dispatch({ type: UPDATE_VEHICLE_ASSIGN_SUCCESS, payload: response.data })
		return response.data
	}
}
