import { Dashboard } from '../../services/api/dashboard'
import { Distributors } from '../../services/api/distributors'
import { Products } from '../../services/api/products'
import { ecommerceSubscriptionEvent, userLogEvent } from '../../utils/constants'

import {
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS,
	FETCH_TOP_E_COMMERCE_CUSTOMER,
	FETCH_TOP_SELLING_PRODUCTS,
	FETCH_TOP_SELLING_PRODUCTS_IN_TIME_RANGE,
	FETCH_DASHBOARD_SALES_ANALYTICS_PAYMENT_STATISTICS_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_SOURCE_SUCCESS,
	FETCH_DASHBOARD_SIGN_UP_TRENDS_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_CANCELLED_ORDER_REASON_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_RETURNED_ORDER_REASON_SUCCESS,
	FETCH_DASHBOARD_REGISTERED_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_ORDER_RATE_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_SALES_VALUE_SUCCESS,
	FETCH_DASHBOARD_PRODUCT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_CUSTOMER_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ORDER_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ORDER_AMOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ORDER_SOURCE_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ORDER_DELIVERY_PARTNER_TRENDS_SUCCESS,
	FETCH_DASHBOARD_LOCATION_TRENDS_SUCCESS,
	FETCH_DASHBOARD_PURCHASE_ORDER_TRENDS_SUCCESS,
	FETCH_DASHBOARD_PURCHASE_ORDER_AMOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_DISCOUNT_AND_ADVANCE_PAYMENT_ORDER_STATS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_TRIAL_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_LIVE_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_CHURNED_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_AVERAGE_BASKET_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_ALL_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ENTERPRISE_LIVE_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ENTERPRISE_CHURN_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_LIVE_CUMULATIVE_TREND_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_CUMULATIVE_ACTIVE_USER_TREND_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_SALES_ORDERS_MONTHLY_TREND_SUCCESS
} from './action-types'

export function fetchGeneralAnalyticsStatistics (interval, fromDate, toDate, statuses, locationIds) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsStatistics(interval, fromDate, toDate, statuses, locationIds)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS, payload: response.data })
	}
}

export function fetchSalesAnalyticsPaymentStatistics (interval, fromDate, toDate, locationIds) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsPaymentStatistics(interval, fromDate, toDate, locationIds)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_PAYMENT_STATISTICS_SUCCESS, payload: response.data })
	}
}

export function fetchGeneralAnalyticsOrderRate (interval, fromDate, toDate, locationIds) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsOrderRate(interval, fromDate, toDate, locationIds)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchGeneralAnalyticsOrderCycleTime ({ interval, fromDate, toDate, searchTerm, locationIds }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsOrderCycleTime(interval, fromDate, toDate, searchTerm, locationIds)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS, payload: { data: response.data, interval, fromDate, toDate, searchTerm } })
	}
}

export function fetchGeneralAnalyticsOrderSource ({ fromDate, toDate, statuses, locationIds }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsOrderSource(fromDate, toDate, statuses, locationIds)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_SOURCE_SUCCESS, payload: response.data })
	}
}

export function fetchGeneralAnalyticsOrderLeadTime ({ interval, leadTimeDate }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsOrderLeadTime(interval, leadTimeDate)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS, payload: { data: response.data, interval, leadTimeDate } })
	}
}

export function fetchGeneralAnalyticsCancelledOrderReason ({ fromDate, toDate, locationIds }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsCancelledOrderReason(fromDate, toDate, locationIds)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_CANCELLED_ORDER_REASON_SUCCESS, payload: response.data })
	}
}

export function fetchGeneralAnalyticsReturnedOrderReason ({ fromDate, toDate, locationIds }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsReturnedOrderReason(fromDate, toDate, locationIds)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_RETURNED_ORDER_REASON_SUCCESS, payload: response.data })
	}
}

export function fetchECommerceDeliveryPartnerAnalyticsOrderRate ({ fromDate, toDate, status, locationIds }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchECommerceDeliveryPartnerAnalyticsOrderRate(fromDate, toDate, status, locationIds)
		dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_ORDER_RATE_SUCCESS, payload: response.data })
	}
}

export function fetchECommerceDeliveryPartnerAnalyticsSalesValue ({ fromDate, toDate }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchECommerceDeliveryPartnerAnalyticsSalesValue(fromDate, toDate)
		dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_SALES_VALUE_SUCCESS, payload: response.data })
	}
}

export function fetchGeneralAnalyticsDiscountAndAdvancePaymentOrderStats ({ fromDate, toDate, locationIds }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsDiscountAndAdvancePaymentOrderStats(fromDate, toDate, locationIds)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_DISCOUNT_AND_ADVANCE_PAYMENT_ORDER_STATS_SUCCESS, payload: response.data })
	}
}

export function fetchSalesAnalyticsOrders (interval, timezone, status, locationIds) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsOrders(interval, timezone, status, locationIds)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsAverageBasket (interval, timezone, status, locationIds) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsAverageBasket(interval, timezone, status, locationIds)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_AVERAGE_BASKET_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsSales (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsSales(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsDistributorGrowth (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsDistributorGrowth(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsDivisionSales (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsDivisionSales(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsItemSales (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsItemSales(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsDistributorSales (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsDistributorSales(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchOrdersAssignedAndCompletedRatio (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchOrdersAssignedAndCompletedRatio(interval)
		dispatch({ type: FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchCollectedAmountRatio (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchCollectedAmountRatio(interval)
		dispatch({ type: FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchDeliveryPartnerByLocation (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchDeliveryPartnerByLocation(interval)
		dispatch({ type: FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchDistanceTraveledByDeliveryPartner (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchDistanceTraveledByDeliveryPartner(interval)
		dispatch({ type: FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchTopCustomers ({ filter, fromDate, toDate, status, locationIds }) {
	return async (dispatch) => {
		const response = await Distributors.topCustomers(filter, fromDate, toDate, status, locationIds)
		dispatch({ type: FETCH_TOP_E_COMMERCE_CUSTOMER, payload: { data: response.data } })
	}
}

export function fetchTopSellingProducts () {
	return async (dispatch) => {
		const response = await Products.topSeller()
		dispatch({ type: FETCH_TOP_SELLING_PRODUCTS, payload: { data: response.data } })
	}
}

export function fetchTopSellingProductsInTimeRange (params) {
	return async (dispatch) => {
		const response = await Products.topSeller(params)
		dispatch({ type: FETCH_TOP_SELLING_PRODUCTS_IN_TIME_RANGE, payload: { data: response.data } })
	}
}

export function fetchSignUpTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSignUpTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_SIGN_UP_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchEnterpriseAccountTrends ({ interval, industries, currentRange, event }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchEnterpriseAccountTrends({ interval, industries, currentRange, event })
		switch (event) {
			case userLogEvent.DISABLED:
				dispatch({ type: FETCH_DASHBOARD_ENTERPRISE_CHURN_ACCOUNT_TRENDS_SUCCESS, payload: response.data })
				break
			case userLogEvent.ENABLED:
				dispatch({ type: FETCH_DASHBOARD_ENTERPRISE_LIVE_ACCOUNT_TRENDS_SUCCESS, payload: response.data })
				break
			default:
				break
		}
	}
}

export function fetchRegisteredTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchRegisteredUserTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_REGISTERED_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchProductTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchProductTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_PRODUCT_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchCustomerTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchCustomerTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_CUSTOMER_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchLocationTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchLocationTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_LOCATION_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchAllOrderTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchAllOrderTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_ORDER_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchAllOrderAmountTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchAllOrderAmountTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_ORDER_AMOUNT_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchAllOrderSourceTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchAllOrderSourceTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_ORDER_SOURCE_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchAllOrderDeliveryPartnerTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchAllOrderDeliveryPartnerTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_ORDER_DELIVERY_PARTNER_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchPurchaseOrderTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchPurchaseOrderTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_PURCHASE_ORDER_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchPurchaseOrderAmountTrends ({ interval, industries, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchPurchaseOrderAmountTrends({ interval, industries, currentRange })
		dispatch({ type: FETCH_DASHBOARD_PURCHASE_ORDER_AMOUNT_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchECommerceAccountTrends ({ interval, currentRange, subscriptionStatus, subscriptionEvent }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchECommerceAccountTrends({ interval, currentRange, subscriptionStatus, subscriptionEvent })
		switch (subscriptionEvent) {
			case ecommerceSubscriptionEvent.TRIALING:
				dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_TRIAL_ACCOUNT_TRENDS_SUCCESS, payload: response.data })
				break
			case ecommerceSubscriptionEvent.LIVE:
				dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_LIVE_ACCOUNT_TRENDS_SUCCESS, payload: response.data })
				break
			case ecommerceSubscriptionEvent.CHURNED:
				dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_CHURNED_ACCOUNT_TRENDS_SUCCESS, payload: response.data })
				break
			default:
				break
		}
	}
}

export function fetchECommerceAllAccountTrends ({ interval, currentRange }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchAllECommerceAccountTrends({ interval, currentRange })
		dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_ALL_ACCOUNT_TRENDS_SUCCESS, payload: response.data })
	}
}

export function fetchECommerceLiveCumulativeTrend () {
	return async (dispatch) => {
		const response = await Dashboard.fetchEcommerceLiveCumulativeTrend()
		dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_LIVE_CUMULATIVE_TREND_SUCCESS, payload: response.data })
	}
}

export function fetchECommerceCumulativeActiveUserTrend () {
	return async (dispatch) => {
		const response = await Dashboard.fetchEcommerceCumulativeActiveUserTrend()
		dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_CUMULATIVE_ACTIVE_USER_TREND_SUCCESS, payload: response.data })
	}
}

export function fetchECommerceSalesOrdersMonthlyTrend () {
	return async (dispatch) => {
		const response = await Dashboard.fetchEcommerceSalesOrdersMonthlyTrend()
		dispatch({ type: FETCH_DASHBOARD_ECOMMERCE_SALES_ORDERS_MONTHLY_TREND_SUCCESS, payload: response.data })
	}
}
