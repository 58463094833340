import { Button, Modal, notification, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateDeliveryPlanItemVehicleType } from '../../store/delivery-plans/actions'
import { fetchVehicleTypes } from '../../store/vehicles/actions'

const { Option } = Select

const UpdateVehicleModal = ({
	visible,
	deliveryPlanItemId,
	vehicleType,
	onCancel
}) => {
	const dispatch = useDispatch()
	const { vehicleTypes } = useSelector(state => state.vehiclesReducer)
	const [type, setType] = useState()
	const [isUpdatingVehicleType, setIsUpdatingVehicleType] = useState(false)

	useEffect(() => {
		dispatch(fetchVehicleTypes())
	}, [])

	const updateVehicleType = async () => {
		setIsUpdatingVehicleType(true)
		try {
			await dispatch(updateDeliveryPlanItemVehicleType(deliveryPlanItemId, type))
			onCancel()
			notification.success({
				message: 'New Vehicle Type Assigned',
				description: 'New vehicle type assigned to delivery plan route.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Update Vehicle Type',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingVehicleType(false)
		}
	}

	const renderModalFooter = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
				<Button
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					type='primary'
					loading={isUpdatingVehicleType}
					disabled={!type}
					onClick={updateVehicleType}
				>
					Update
				</Button>
			</div>
		)
	}

	return (
		<Modal
			title='Update Vehicle Type'
			onCancel={onCancel}
			visible={visible}
			footer={renderModalFooter()}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Select
					onChange={option => setType(option)}
					value={type}
					placeholder='Select Vehicle Type'
					className='company-select'
				>
					{
						vehicleTypes.map(vt => {
							return (
								<Option
									key={vt.value}
									value={vt.value}
									disabled={vt.value === vehicleType}
								>
									{vt.label}
								</Option>
							)
						})
					}
				</Select>
			</div>
		</Modal>
	)
}

export default UpdateVehicleModal
