import { useContext, useEffect, useState } from 'react'
import ExpandableSidebarLink from '../expandable-sidebar-link'
import SidebarLink from '../sidebar-link'
import styles from './Sidebar.module.css'
import { useSelector } from 'react-redux'
import { industries } from '../../utils/constants'
import { useMemo } from 'react'
import { NotificationBarContext } from '../../contexts/NotificationBar'

const Sidebar = ({ menuItems }) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [hasExpanded, setHasExpanded] = useState(false)
	const isNotificationBarVisible = useContext(NotificationBarContext)
	const isECommerce = useMemo(() => {
		return companyDetails?.industry === industries.E_COMMERCE
	}, [companyDetails])

	const toggleExpand = () => setHasExpanded(!hasExpanded)

	useEffect(() => {
		const updateHasExpanded = ({ target }) => {
			const isToggleButton = target.closest(`.${styles.toggleButton}`)
			if (isToggleButton || !hasExpanded) {
				return
			}
			setHasExpanded(!!target.closest(`.${styles.sidebar}`))
		}

		window.addEventListener('click', updateHasExpanded)
		return () => window.removeEventListener('click', updateHasExpanded)
	}, [hasExpanded])

	return (
		<aside className={`${styles.sidebar} ${hasExpanded && styles.sidebarExpanded} sidebar`}>
			{
				isECommerce && isNotificationBarVisible &&
				<div style={{ height: '50px' }} />
			}
			<button
				className={styles.toggleButton}
				onClick={toggleExpand}
			>
				<span className={styles.logoContainer}>
					<img
						className={styles.menuIcon}
						src='/img/menu-2.svg'
						alt='Hamburger menu icon'
					/>
					<div style={{ width: 126 }} />
				</span>
			</button>
			<div className={styles.links}>
				{
					menuItems.map((link) => {
						return (
							link.children ?
								<ExpandableSidebarLink key={link.title} link={link} expanded={hasExpanded} /> :
								<SidebarLink key={link.title} link={link} expanded={hasExpanded} />
						)
					})
				}
			</div>
		</aside>
	)
}

export default Sidebar
