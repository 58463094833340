import { get, patch, post } from './index'
import moment from 'moment'

export const PurchaseOrders = {
	index: ({
		page,
		searchTerm,
		statuses,
		disabledStatuses,
		status,
		subStatuses,
		subStatus,
		fromDate,
		toDate,
		approvedFromDate,
		approvedToDate,
		receivedFromDate,
		receivedToDate,
		vendorId,
		locationId,
		inventory,
		accessToken
	} = { page: 0 }) =>
		get('/purchase-orders', {
			headers: {
				authorization: accessToken
			},
			params: {
				page,
				searchTerm,
				statuses,
				disabledStatuses,
				status,
				subStatuses,
				subStatus,
				fromDate,
				toDate,
				approvedFromDate,
				approvedToDate,
				receivedFromDate,
				receivedToDate,
				vendorId,
				locationId,
				inventory
			}
		}),
	create: (params) =>
		post('/purchase-orders', params),
	single: (id, {
		accessToken,
		inventory
	} = {}) =>
		get(`/purchase-orders/${id}`, {
			headers: {
				authorization: accessToken
			},
			params: {
				inventory
			}
		}),
	update: (id, data) =>
		patch(`/purchase-orders/${id}`, data),
	singlePurchaseOrderItem: (id) =>
		get(`/purchase-orders/purchase-order-items/${id}`),
	analytics: ({
		vendorId,
		fromDate,
		toDate,
		interval,
		timezone
	}) =>
		get('/purchase-orders/analytics', {
			params: {
				vendorId,
				fromDate,
				toDate,
				interval,
				timezone
			}
		}),
	downloadCSV: async ({ dateRangeType, fromDate, toDate, statuses, locationIds, timezone } = {}) => {
		const { data } = await get('/purchase-orders/csv', {
			params: {
				dateRangeType,
				fromDate,
				toDate,
				statuses,
				locationIds,
				timezone
			}
		})

		const binaryData = [data]
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }))
		const fileName = `purchase-orders${fromDate && toDate ? `_${moment(fromDate).format('MMM_DD_YYYY')}-${moment(toDate).format('MMM_DD_YYYY')}` : ''}.csv`
		link.download = fileName
		link.click()
		link.remove()
	},
	getCostTypes: ({ searchTerm, includeDefaultTypes, excludeTypes } = {}) =>
		get('/purchase-orders/purchase-order-cost-types', {
			params: {
				searchTerm,
				includeDefaultTypes,
				excludeTypes
			}
		}),
	createCost: (id, { typeId, paymentMethod, amount, notes } = {}) =>
		post(`/purchase-orders/${id}/purchase-order-costs`, {
			typeId,
			paymentMethod,
			amount,
			notes
		}),
	getCosts: (id, { page, pageSize } = {}) =>
		get(`/purchase-orders/${id}/purchase-order-costs`, {
			params: {
				page,
				pageSize
			}
		})
}
