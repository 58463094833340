import { Spin, Tooltip } from 'antd'
import styles from './TextInput.module.css'
import { LoadingOutlined } from '@ant-design/icons'

const TextInput = ({
	containerStyle,
	containerClassName,
	style,
	className,
	size = 'normal',
	title,
	tooltip,
	loading,
	placeholder,
	showSearch,
	maxLength,
	readOnly,
	disabled,
	error,
	value,
	onChange,
	allowClear,
	onFocus = () => {},
	onClick = () => {},
	onClear = () => {}
}) => {
	return (
		<div
			style={containerStyle}
			className={`${styles.textInput} ${containerClassName}`}
		>
			{
				(!title && showSearch) &&
				<img
					className={styles.searchIcon}
					src='/img/search-black.svg'
					alt='Search icon'
					width={24}
					height={24}
				/>
			}
			{
				(allowClear && value && !loading) &&
				<img
					className={styles.clearIcon}
					src='/img/close-3-fill.svg'
					alt='Clear icon'
					width={24}
					height={24}
					onClick={onClear}
				/>
			}
			{
				title &&
				<span className={`${styles.title} ${error && styles.titleError}`}>
					{title}
					{
						tooltip &&
						<Tooltip title={tooltip} placement='right'>
							<img
								className={styles.questionMarkIcon}
								src='/img/question-mark-circled.svg'
								alt='Question mark icon'
								width={16}
								height={16}
							/>
						</Tooltip>
					}
				</span>
			}
			{
				loading &&
					<div className={styles.loadingIcon}>
						<Spin
							indicator={
								<LoadingOutlined
									style={{ fontSize: 20 }}
									spin
								/>
							}
						/>
					</div>
			}
			<input
				style={style}
				className={
				`
					${styles.input}
					${size === 'small' ? styles.inputSmall : size === 'large' ? styles.inputLarge : ''}
					${!title && styles.inputWithoutTitle}
					${(!title && showSearch) && styles.inputWithSearch}
					${(readOnly || disabled) && styles.inputReadOnly}
					${error && styles.inputError}
					${className}
				`
				}
				type='text'
				placeholder={placeholder}
				maxLength={maxLength}
				readOnly={readOnly}
				disabled={disabled}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onClick={onClick}
			/>
		</div>
	)
}

export default TextInput
