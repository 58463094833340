import { useContext } from 'react'
import { GlobalFilterSetter } from '../../contexts/GlobalFilter'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './CustomEmptySecondary.module.css'
import { Button } from 'antd'

const CustomEmptySecondary = ({
	style,
	fallback,
	showFallbackAction,
	icon
}) => {
	const setShouldResetFilters = useContext(GlobalFilterSetter)
	const { clearSearchParams } = useSearchParams()

	const clearSearchAndFilter = () => {
		setShouldResetFilters(true)
		clearSearchParams()
	}

	return (
		<div style={style} className={styles.customEmptySecondary}>
			<span className={styles.iconContainer}>
				<img src={icon ? icon : '/img/file-search.svg'} alt='File search icon' />
			</span>
			<p className={styles.title}>{fallback?.title || 'No Data Found'}</p>
			<p className={styles.description}>{fallback?.description || 'Please, try again!'}</p>
			{
				showFallbackAction &&
					<Button className={styles.button} onClick={fallback?.action?.onClick || clearSearchAndFilter}>
						{fallback?.action?.text || 'Reset Search'}
					</Button>
			}
		</div>
	)
}

export default CustomEmptySecondary
