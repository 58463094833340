import { HYDRATE } from 'next-redux-wrapper'
import { TransactionType, orderStatuses } from '../../utils/constants'
import {
	ADD_SALES_ORDERS_SUCCESS,
	FETCH_SALES_ORDERS_SUCCESS,
	FETCH_SALES_ORDER_DETAILS_SUCCESS,
	UPDATE_SALES_ORDER_SUCCESS,
	ADD_TRANSACTION_SUCCESS,
	FETCH_SALES_ORDER_COUNTS_SUCCESS,
	SET_SALES_ORDER_COUNTS,
	RESET_SALES_ORDER_COUNTS,
	EDIT_TRANSACTION_SUCCESS,
	FETCH_SALES_ORDER_PAGINATION_SUCCESS,
	SELECT_SALES_ORDERS
} from './action-types'

const initialState = {
	salesOrderDetails: {},
	allSalesOrders: [],
	requestedSalesOrders: [],
	onHoldSalesOrders: [],
	flaggedSalesOrders: [],
	approvedSalesOrders: [],
	processingSalesOrders: [],
	completedSalesOrders: [],
	cancelledSalesOrders: [],
	shippedSalesOrders: [],
	inTransitSalesOrders: [],
	totalCount: 0,
	page: 0,
	salesOrderCounts: {},
	orderPagination: {},
	selectedSalesOrderIds: [],
	selectedSalesOrders: [],
	allSelectedSalesOrderIds: []
}

const salesOrdersReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_SALES_ORDERS_SUCCESS: {
			switch (payload.status) {
				case orderStatuses.SALES_ORDER_REQUESTED_STATUS:
					return {
						...state,
						requestedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case orderStatuses.SALES_ORDER_ON_HOLD_STATUS:
					return {
						...state,
						onHoldSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case orderStatuses.SALES_ORDER_FLAGGED_STATUS:
					return {
						...state,
						flaggedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case orderStatuses.SALES_ORDER_APPROVED_STATUS:
					return {
						...state,
						approvedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case orderStatuses.SALES_ORDER_PROCESSING_STATUS:
					return {
						...state,
						processingSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case orderStatuses.SALES_ORDER_COMPLETED_STATUS:
					return {
						...state,
						completedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case orderStatuses.SALES_ORDER_CANCELLED_STATUS:
					return {
						...state,
						cancelledSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case orderStatuses.SALES_ORDER_SHIPPED_STATUS:
					return {
						...state,
						shippedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS:
					return {
						...state,
						inTransitSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				default:
					return {
						...state,
						allSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
			}
		}
		case ADD_SALES_ORDERS_SUCCESS: {
			if (payload.status === orderStatuses.SALES_ORDER_REQUESTED_STATUS) {
				const requestedSalesOrders = [payload, ...state.requestedSalesOrders]
				return {
					...state,
					requestedSalesOrders
				}
			} else if (payload.status === orderStatuses.SALES_ORDER_APPROVED_STATUS) {
				const approvedSalesOrders = [payload, ...state.approvedSalesOrders]
				return {
					...state,
					approvedSalesOrders
				}
			} else {
				return state
			}
		}
		case FETCH_SALES_ORDER_DETAILS_SUCCESS: {
			const newState = { ...state }
			if (state.salesOrderDetails[payload.id]) {
				const salesOrderDetails = state.salesOrderDetails[payload.id]
				if (salesOrderDetails.status !== payload.data.status) {
					newState.selectedSalesOrderIds = newState.selectedSalesOrderIds.filter(id => id !== payload.id)
				} else if (salesOrderDetails.subStatus !== payload.data.subStatus) {
					newState.selectedSalesOrderIds = newState.selectedSalesOrderIds.filter(id => id !== payload.id)
				}
			}
			newState.salesOrderDetails[payload.id] = payload.data
			return newState
		}
		case UPDATE_SALES_ORDER_SUCCESS: {
			const newState = { ...state }
			const salesOrder = newState.salesOrderDetails[payload.id]
			if (salesOrder && payload) {
				if (salesOrder.status !== payload.status) {
					newState.selectedSalesOrderIds = newState.selectedSalesOrderIds.filter(id => id !== payload.id)
				}
				if (salesOrder.subStatus !== payload.subStatus) {
					newState.selectedSalesOrderIds = newState.selectedSalesOrderIds.filter(id => id !== payload.id)
				}
				const newSalesOrder = Object.assign(salesOrder, payload)
				newState.salesOrderDetails[payload.id] = newSalesOrder
			}
			if (payload) {
				newState.allSalesOrders.map(order => {
					if (order.id === payload?.id) {
						const updatedSalesOrder = Object.assign(order, payload)
						return updatedSalesOrder
					}
					return order
				})
			}
			return newState
		}
		case ADD_TRANSACTION_SUCCESS: {
			const newState = { ...state }
			const salesOrderDetails = newState.salesOrderDetails[payload.id]
			if (salesOrderDetails) {
				if (salesOrderDetails.transactions) {
					if (payload.data.type === TransactionType.ADVANCE) {
						const advanceIndex = salesOrderDetails.transactions.findIndex(transaction => transaction.type === TransactionType.ADVANCE)
						if (advanceIndex > -1) {
							salesOrderDetails.transactions.splice(advanceIndex, 1)
						}
					}
					salesOrderDetails.transactions.push(payload.data)
				}
			}
			return newState
		}
		case EDIT_TRANSACTION_SUCCESS: {
			const newState = { ...state }
			const salesOrderDetails = newState.salesOrderDetails[payload.id]
			if (salesOrderDetails) {
				if (salesOrderDetails.transactions) {
					if (payload.data.type === TransactionType.ADVANCE) {
						const advanceIndex = salesOrderDetails.transactions.findIndex(transaction => transaction.type === TransactionType.ADVANCE)
						if (advanceIndex > -1) {
							salesOrderDetails.transactions.splice(advanceIndex, 1)
						}
					}
					salesOrderDetails.transactions = salesOrderDetails.transactions.map(transaction => {
						if (transaction.id === payload.data.id) {
							return payload.data
						}
						return transaction
					})
				}
			}
			return newState
		}
		case FETCH_SALES_ORDER_COUNTS_SUCCESS: {
			return {
				...state,
				salesOrderCounts: payload
			}
		}
		case SET_SALES_ORDER_COUNTS: {
			return {
				...state,
				salesOrderCounts: {
					...payload
				}
			}
		}
		case RESET_SALES_ORDER_COUNTS: {
			return {
				...state,
				salesOrderCounts: {}
			}
		}
		case FETCH_SALES_ORDER_PAGINATION_SUCCESS: {
			return {
				...state,
				orderPagination: payload
			}
		}
		case SELECT_SALES_ORDERS: {
			return {
				...state,
				selectedSalesOrderIds: payload.ids || [],
				allSelectedSalesOrderIds: payload.ids || [],
				selectedSalesOrders: payload.orders || []
			}
		}
		default:
			return state
	}
}

export default salesOrdersReducer
