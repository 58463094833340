import styles from './TextArea.module.css'
import { Spin, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const TextArea = ({
	containerStyle,
	style,
	containerClassName,
	className,
	size = 'normal',
	title,
	tooltip,
	loading,
	placeholder,
	maxLength,
	readOnly,
	disabled,
	error,
	value,
	onChange
}) => {
	return (
		<div style={containerStyle} className={`${styles.textArea} ${containerClassName}`}>
			{
				title &&
				<div className={
				`
					${styles.whiteBar}
					${(disabled || readOnly) && styles.whiteBarDisabled}
					${error && styles.whiteBarError}
				`
				}
				/>
			}
			{
				title &&
				<span className={`${styles.title} ${error && styles.titleError}`}>
					{title}
					{
						tooltip &&
						<Tooltip title={tooltip} placement='right'>
							<img
								className={styles.questionMarkIcon}
								src='/img/question-mark-circled.svg'
								alt='Question mark icon'
								width={16}
								height={16}
							/>
						</Tooltip>
					}
				</span>
			}
			{
				loading &&
					<div className={styles.loadingIcon}>
						<Spin
							indicator={
								<LoadingOutlined
									style={{ fontSize: 20 }}
									spin
								/>
							}
						/>
					</div>
			}
			<textarea
				style={style}
				className={
				`
					${styles.input}
					${size === 'small' ? styles.inputSmall : size === 'large' ? styles.inputLarge : ''} 
					${!title && styles.inputWithoutTitle}
					${(readOnly || disabled) && styles.inputReadOnly}
					${error && styles.inputError}
					${className}
				`
				}
				placeholder={placeholder}
				maxLength={maxLength}
				readOnly={readOnly}
				disabled={disabled}
				value={value}
				onChange={onChange}
			/>
		</div>
	)
}

export default TextArea
