import { useSelector } from 'react-redux'
import { industries, paths } from '../../utils/constants'
import GeneralSalesOrdersPage from '../../components/pages/general-sales-orders-page'
import ECommerceSalesOrderPage from '../../components/pages/e-commerce-sales-orders-page'
import Page from '../../components/page'

export const SALES_ORDER_REQUESTED_STATUS = 'requested'
export const SALES_ORDER_ON_HOLD_STATUS = 'on_hold'
export const SALES_ORDER_FLAGGED_STATUS = 'flagged'
export const SALES_ORDER_APPROVED_STATUS = 'approved'
export const SALES_ORDER_PROCESSING_STATUS = 'processing'
export const SALES_ORDER_COMPLETED_STATUS = 'completed'
export const SALES_ORDER_CANCELLED_STATUS = 'cancelled'
export const SALES_ORDER_IN_TRANSIT_STATUS = 'in_transit'
export const SALES_ORDER_SHIPPED_STATUS = 'shipped'
export const SALES_ORDER_RETURNED_SUB_STATUS = 'returned'
export const SALES_ORDER_DAMAGED_SUB_STATUS = 'damaged'
export const SALES_ORDER_EXPIRED_SUB_STATUS = 'expired'
export const SALES_ORDER_SHIPPED_SUB_STATUS = 'shipped'
export const SALES_ORDER_IN_TRANSIT_SUB_STATUS = 'in_transit'
export const SALES_ORDER_UNLOADED_SUB_STATUS = 'unloaded'
export const SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS = 'payment_collected'
export const SALES_ORDER_PAYMENT_DUE_SUB_STATUS = 'payment_due'

export const SALES_ORDER_STATUS_BACKGROUND_COLORS = {
	[SALES_ORDER_REQUESTED_STATUS]: '#00171D',
	[SALES_ORDER_FLAGGED_STATUS]: '#FF7A7A',
	[SALES_ORDER_APPROVED_STATUS]: '#288EA5',
	[SALES_ORDER_PROCESSING_STATUS]: '#F6B44F',
	[SALES_ORDER_COMPLETED_STATUS]: '#288EA5',
	[SALES_ORDER_CANCELLED_STATUS]: '#FF5959'
}

export const SALES_ORDER_STATUS_COLORS = {
	[SALES_ORDER_REQUESTED_STATUS]: '#00171D',
	[SALES_ORDER_FLAGGED_STATUS]: '#FF7A7A',
	[SALES_ORDER_APPROVED_STATUS]: '#288EA5',
	[SALES_ORDER_PROCESSING_STATUS]: '#F6B44F',
	[SALES_ORDER_COMPLETED_STATUS]: '#288EA5',
	[SALES_ORDER_CANCELLED_STATUS]: '#FF5959'
}

export const SALES_ORDER_SUB_STATUS_COLORS = {
	[SALES_ORDER_SHIPPED_SUB_STATUS]: '#00C0C1',
	[SALES_ORDER_IN_TRANSIT_SUB_STATUS]: '#2D9CDB',
	[SALES_ORDER_UNLOADED_SUB_STATUS]: '#00BA88',
	[SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS]: '#18A188',
	[SALES_ORDER_PAYMENT_DUE_SUB_STATUS]: '#F6BA5F',
	[SALES_ORDER_RETURNED_SUB_STATUS]: '#8C1F28',
	[SALES_ORDER_DAMAGED_SUB_STATUS]: '#591C21',
	[SALES_ORDER_EXPIRED_SUB_STATUS]: '#D92525'
}

export const PROCESSING_SUB_STATUSES = [
	SALES_ORDER_SHIPPED_SUB_STATUS,
	SALES_ORDER_IN_TRANSIT_SUB_STATUS,
	SALES_ORDER_UNLOADED_SUB_STATUS
]

export const FLAGGED_SUB_STATUSES = [
	SALES_ORDER_RETURNED_SUB_STATUS,
	SALES_ORDER_DAMAGED_SUB_STATUS,
	SALES_ORDER_EXPIRED_SUB_STATUS
]

export const COMPLETED_SUB_STATUSES = [
	SALES_ORDER_PAYMENT_DUE_SUB_STATUS,
	SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS
]

export const tabs = [
	{
		title: 'Requested',
		status: SALES_ORDER_REQUESTED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_REQUESTED_STATUS}`
	},
	{
		title: 'Flagged',
		status: SALES_ORDER_FLAGGED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_FLAGGED_STATUS}`
	},
	{
		title: 'Approved',
		status: SALES_ORDER_APPROVED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_APPROVED_STATUS}`
	},
	{
		title: 'Processing',
		status: SALES_ORDER_PROCESSING_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_PROCESSING_STATUS}`
	},
	{
		title: 'Delivered',
		status: SALES_ORDER_COMPLETED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_COMPLETED_STATUS}`
	},
	{
		title: 'Cancelled',
		status: SALES_ORDER_CANCELLED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_CANCELLED_STATUS}`
	}
]

const SalesOrdersPage = () => {
	const { companyDetails } = useSelector(state => state.authReducer)

	if (!companyDetails) {
		return null
	}

	return companyDetails?.industry === industries.E_COMMERCE ? <ECommerceSalesOrderPage /> : <GeneralSalesOrdersPage />
}

SalesOrdersPage.getLayout = function getLayout (page) {
	return (
		<Page>
			{page}
		</Page>
	)
}

export default SalesOrdersPage
