import { useContext, useEffect, useState } from 'react'
import { Button, Checkbox } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import useSearchParams from '../../hooks/useSearchParams'
import { fetchVehicleTypes } from '../../store/vehicles/actions'
import styles from './VehicleFilter.module.css'
import { LocationTypes, paths } from '../../utils/constants'
import { VEHICLE_UNAVAILABLE_STATUS, VEHICLE_ACTIVE_STATUS, VEHICLE_UNASSIGNED_STATUS } from '../../utils/vehicles'
import DropdownOverlay from '../dropdown-overlay'
import CustomSelectSecondary from '../custom-select-secondary'
import { Locations } from '../../services/api/locations'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { convertEnumToString } from '../../utils'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'

const statusOptions = [
	{ label: 'Available', value: VEHICLE_ACTIVE_STATUS },
	{ label: 'Assigned', value: VEHICLE_UNAVAILABLE_STATUS },
	{ label: 'Unassigned', value: VEHICLE_UNASSIGNED_STATUS }
]

const VehicleFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen
}) => {
	const router = useRouter()
	const dispatch = useDispatch()
	const { userProfile } = useSelector(state => state.authReducer)
	const { vehicleTypes } = useSelector(state => state.vehiclesReducer)
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [statuses, setStatuses] = useState(searchParams.statuses || [])
	const [fromDate, setFromDate] = useState(searchParams.fromDate || null)
	const [toDate, setToDate] = useState(searchParams.toDate || null)
	const [type, setType] = useState(searchParams.type)
	const [locations, setLocations] = useState()
	const [location, setLocation] = useState()
	const [searchTerm, setSearchTerm] = useState('')
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)

	useEffect(() => {
		if (isFilterDropdownOpen) {
			dispatch(fetchVehicleTypes())
		}
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (isFilterDropdownOpen && userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setLocations(locations)
				if (locations.length === 1) {
					const location = locations[0]
					const { label, id } = location
					setLocation({ label, value: id, data: location })
				}
			} else {
				fetchInitialLocations()
			}
		}
	}, [isFilterDropdownOpen, userProfile])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!locations?.length || !searchParams.locationId) {
			return
		}
		const location = locations.find(({ id }) => id === searchParams.locationId)

		setLocation({
			id: location.id,
			key: location.id,
			label: location.label,
			data: location
		})
	}, [locations])

	const fetchInitialLocations = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		setLocations(response.data.results)
		setIsLoadingLocations(false)
	}

	const isFilterEnabled = () => {
		return searchTerm || (fromDate && toDate) || type || statuses.length > 0 || location
	}

	const handleApply = async () => {
		const filters = {
			searchTerm
		}

		if (fromDate && toDate) {
			filters.fromDate = fromDate
			filters.toDate = toDate
		}
		if (type) {
			filters.type = type
		}
		if (statuses.length > 0) {
			filters.statuses = statuses
		}
		if (location) {
			filters.locationId = location.value
		}
		applyFilter(filters)
		setIsFilterDropdownOpen(false)
		router.push(`${paths.FLEET}?view=list`)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setStatuses([])
		setType()
		clearFilter()
		setLocation()
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Status</p>
				<Checkbox.Group
					className={styles.inputGroup}
					value={[...statuses]}
					onChange={setStatuses}
				>
					{
						statusOptions.map(statusOption => {
							return (
								<Checkbox
									key={statusOption.value}
									className={styles.inputTitle}
									value={statusOption.value}
								>
									{statusOption.label}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Registration Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='From'
						type='date'
						value={fromDate ? moment(fromDate) : null }
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='To'
						type='date'
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Type</p>
				<CustomSelectSecondary
					style={{ background: '#F7F7F7', borderRadius: 0 }}
					onChange={option => setType(option)}
					value={type}
					title='Select Vehicle Type'
					options={vehicleTypes}
				/>
			</div>
			<div>
				<p className={styles.title}>Base Location</p>
				<CustomSearchableSelectSecondary
					isLoading={isLoadingLocations}
					defaultOptions={locations}
					customLabel={(data) => data.label ? data.label : convertEnumToString(data.type.toLowerCase())}
					valueIndex='id'
					descriptionIndex='address'
					placeholder='Select Base Location'
					onChange={setLocation}
					onClear={() => setLocation(null)}
					value={location}
					unclipOptions={true}
					showSearch={false}
				/>
			</div>

			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

export default VehicleFilter
