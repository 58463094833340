import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import moment from 'moment'
import { VIEW_MODE } from '../../pages/delivery-plans'
import styles from './DeliveryPlanFilter.module.css'
import useSearchParams from '../../hooks/useSearchParams'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import { deliveryPlanStatuses } from '../../utils/constants'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { useSelector } from 'react-redux'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import NumberInput from '../number-input'

const DeliveryPlanFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen
}) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const router = useRouter()
	const { query } = router
	const { userProfile } = useSelector(state => state.authReducer)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [locations, setLocations] = useState([])
	const [location, setLocation] = useState()
	const [searchTerm, setSearchTerm] = useState('')
	const [fromDate, setFromDate] = useState(searchParams.fromDate || null)
	const [toDate, setToDate] = useState(searchParams.toDate || null)
	const [minSalesOrderCount, setMinSalesOrderCount] = useState(searchParams.minSalesOrderCount)
	const [maxSalesOrderCount, setMaxSalesOrderCount] = useState(searchParams.maxSalesOrderCount)
	const [minOrderValue, setMinOrderValue] = useState(searchParams.minOrderValue)
	const [maxOrderValue, setMaxOrderValue] = useState(searchParams.maxOrderValue)
	const [minDistributorCount, setMinDistributorCount] = useState(searchParams.minDistributorCount)
	const [maxDistributorCount, setMaxDistributorCount] = useState(searchParams.maxDistributorCount)
	const [status, setStatus] = useState()
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)

	useEffect(() => {
		if (query.status) {
			setStatus(query.status)
		}
	}, [query.status])

	useEffect(() => {
		if (isFilterDropdownOpen && userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setLocations(locations)
				if (locations.length === 1) {
					const location = locations[0]
					const { label, id } = location
					setLocation({ label, value: id, data: location })
				}
			} else {
				fetchInitialWarehouses()
			}
		}
	}, [isFilterDropdownOpen, userProfile])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!locations.length || !searchParams.startLocationId) {
			return
		}
		const location = locations.find(({ id }) => id === searchParams.startLocationId)

		setLocation({
			id: location.id,
			key: location.id,
			label: location.label,
			data: location
		})
	}, [locations])

	const fetchInitialWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY], page: -1 })
		setLocations(response.data.results)
		setIsLoadingLocations(false)
	}

	const isFilterEnabled = () => {
		if (
			status ||
			searchTerm || (fromDate && toDate) ||
			(minSalesOrderCount !== undefined && maxSalesOrderCount !== undefined) ||
			(minDistributorCount !== undefined && maxDistributorCount !== undefined) ||
			(minOrderValue !== undefined && maxOrderValue !== undefined) ||
			location
		) {
			if (fromDate || toDate) {
				if (!(fromDate && toDate)) {
					return false
				}
			}
			if (minSalesOrderCount !== undefined || maxSalesOrderCount !== undefined) {
				if (!(minSalesOrderCount !== undefined && maxSalesOrderCount !== undefined)) {
					return false
				}
			}
			if (minDistributorCount !== undefined || maxDistributorCount !== undefined) {
				if (!(minDistributorCount !== undefined && maxDistributorCount !== undefined)) {
					return false
				}
			}
			if (minOrderValue !== undefined || maxOrderValue !== undefined) {
				if (!(minOrderValue !== undefined && maxOrderValue !== undefined)) {
					return false
				}
			}
			return true
		}

		return false
	}

	const handleApply = async () => {
		const filters = {}

		if (fromDate && toDate) {
			filters.fromDate = moment(fromDate).startOf('day').toDate()
			filters.toDate = moment(toDate).endOf('day').toDate()
		}
		if (!isNaN(minSalesOrderCount) && !isNaN(maxSalesOrderCount)) {
			filters.minSalesOrderCount = minSalesOrderCount
			filters.maxSalesOrderCount = maxSalesOrderCount
		}
		if (!isNaN(minOrderValue) && !isNaN(maxOrderValue)) {
			filters.minOrderValue = minOrderValue
			filters.maxOrderValue = maxOrderValue
		}
		if (!isNaN(minDistributorCount) && !isNaN(maxDistributorCount)) {
			filters.minDistributorCount = minDistributorCount
			filters.maxDistributorCount = maxDistributorCount
		}
		if (location) {
			filters.startLocationId = location.value
		}

		if (status) {
			router.push(`/delivery-plans?status=${status}&view=${VIEW_MODE.LIST}`)
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setMinSalesOrderCount()
		setMaxSalesOrderCount()
		setMinDistributorCount()
		setMaxDistributorCount()
		setMinOrderValue()
		setMaxOrderValue()
		setLocation(null)
		setStatus(query.status)
		clearFilter()
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Delivery Plan Status</p>
				<Radio.Group
					className={styles.inputGroup}
					value={status}
					onChange={e => setStatus(e.target.value)}
				>
					<Radio
						className={styles.inputTitle}
						value={deliveryPlanStatuses.DELIVERY_PLAN_PENDING_STATUS}
					>
						Pending
					</Radio>
					<Radio
						className={styles.inputTitle}
						value={deliveryPlanStatuses.DELIVERY_PLAN_SCHEDULED_STATUS}
					>
						Scheduled
					</Radio>
					<Radio
						className={styles.inputTitle}
						value={deliveryPlanStatuses.DELIVERY_PLAN_DELIVERED_STATUS}
					>
						Completed
					</Radio>
				</Radio.Group>
			</div>
			<div>
				<p className={styles.title}>Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='From'
						value={fromDate ? moment(fromDate).startOf('day') : null}
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='To'
						disabledDate={current => {
							return current && current.valueOf() < moment(fromDate).valueOf()
						}}
						value={toDate ? moment(toDate).endOf('day') : null}
						onChange={(date) => setToDate(date?.toDate())}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Total Order Count</p>
				<div className={styles.inputFlex}>
					<NumberInput
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='Min'
						value={minSalesOrderCount}
						onChange={(value) => setMinSalesOrderCount(Math.trunc(+value))}
					/>
					<NumberInput
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='Max'
						value={maxSalesOrderCount}
						onChange={(value) => setMaxSalesOrderCount(Math.trunc(+value))}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Total Order Value</p>
				<div className={styles.inputFlex}>
					<NumberInput
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='Min'
						value={minOrderValue}
						onChange={(value) => setMinOrderValue(+value)}
					/>
					<NumberInput
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='Max'
						value={maxOrderValue}
						onChange={(value) => setMaxOrderValue(+value)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Total Distributor Count</p>
				<div className={styles.inputFlex}>
					<NumberInput
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='Min'
						value={minDistributorCount}
						onChange={(value) => setMinDistributorCount(Math.trunc(+value))}
					/>
					<NumberInput
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='Max'
						value={maxDistributorCount}
						onChange={(value) => setMaxDistributorCount(Math.trunc(+value))}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Pick Up Location</p>
				<CustomSearchableSelectSecondary
					title='Pick Up Location'
					defaultOptions={locations}
					isLoading={isLoadingLocations}
					customLabel={(data) => data.label ? data.label : data.address}
					valueIndex='id'
					descriptionIndex='address'
					placeholder='Select Location'
					onChange={setLocation}
					onClear={() => setLocation(null)}
					value={location}
					unclipOptions={true}
					showSearch={false}
					allowClear={true}
				/>
			</div>

			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

DeliveryPlanFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func
}

export default DeliveryPlanFilter
