import { HYDRATE } from 'next-redux-wrapper'
import {
	ADD_TRANSPORT_VENDOR_SUCCESS,
	FETCH_TRANSPORT_VENDORS_SUCCESS,
	FETCH_TRANSPORT_VENDOR_DETAILS_SUCCESS,
	UPDATE_TRANSPORT_VENDOR_SUCCESS,
	UPDATE_TRANSPORT_VENDOR_VEHICLE_SUCCESS
} from './action-types'

const initialState = {
	totalCount: 0,
	page: 0,
	transportVendors: [],
	transportVendorDetails: {}
}

const transportVendorsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_TRANSPORT_VENDORS_SUCCESS: {
			return {
				...state,
				transportVendors: payload.results,
				totalCount: payload.count,
				page: +payload.page
			}
		}
		case ADD_TRANSPORT_VENDOR_SUCCESS: {
			const transportVendors = [payload, ...state.transportVendors]
			return {
				...state,
				transportVendors
			}
		}
		case UPDATE_TRANSPORT_VENDOR_SUCCESS: {
			const newState = { ...state }
			const transportVendor = newState.transportVendorDetails[payload.id]
			if (transportVendor) {
				const updatedTransportVendor = Object.assign(transportVendor, payload)
				newState.transportVendorDetails[payload.id] = updatedTransportVendor
			}
			return newState
		}
		case UPDATE_TRANSPORT_VENDOR_VEHICLE_SUCCESS: {
			const newState = { ...state }
			const transportVendor = newState.transportVendorDetails[payload.vendorId]
			if (transportVendor) {
				const updatedVehicles = transportVendor.vehicles.map(vehicle => {
					if (vehicle.id === payload.id) {
						return payload
					}
					return vehicle
				})
				transportVendor.vehicles = updatedVehicles
				newState.transportVendorDetails[payload.vendorId] = transportVendor
			}
			return newState
		}
		case FETCH_TRANSPORT_VENDOR_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.transportVendorDetails[payload.id] = payload.data
			return newState
		}
		default:
			return state
	}
}

export default transportVendorsReducer
