import { useEffect, useMemo, useState } from 'react'
import CustomTable from '../table'
import styles from './OrderQuantityForm.module.css'
import { useSelector } from 'react-redux'
import { InventoryEditions, orderStatuses } from '../../utils/constants'
import ItemQuantityCell from '../item-quantity-cell'
import { convertDiscountAmountToPercentage, getItemTotals, getQuantityField } from '../../utils/sales-orders'
import { getFormattedCurrency } from '../../utils'
import { SALES_ORDER_COMPLETED_STATUS } from '../../pages/sales-orders'

const useOrderQuantityForm = ({
	salesOrder = {},
	initialSelectedStatus = '',
	initialSelectedSubStatus = ''
} = {}) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [orderDetail, setOrderDetail] = useState({ ...salesOrder })
	const [salesOrderItems, setSalesOrderItems] = useState([])
	const [currentStatus, setCurrentStatus] = useState(null)
	const [selectedStatus, setSelectedStatus] = useState(initialSelectedStatus)
	const [selectedSubStatus, setSelectedSubStatus] = useState(initialSelectedSubStatus)
	const isAdvancedInventory = useMemo(() => companyDetails?.inventoryEdition === InventoryEditions.ADVANCED, [companyDetails])

	const salesOrderItemColumns = [
		{
			title: 'SKU Code',
			key: 'sku',
			className: styles.tableColumn,
			render: salesOrderItem => {
				let code = '-'
				if (salesOrderItem.product.sku) {
					code = salesOrderItem.product.sku
				} else if (salesOrderItem.product.internalId) {
					code = salesOrderItem.product.internalId
				}
				return code
			}
		},
		{
			title: 'Item Name',
			key: 'itemName',
			className: styles.tableColumn,
			render: salesOrderItem => {
				return salesOrderItem.product.name
			}
		},
		{
			title: 'Batch ID',
			key: 'batchNumber',
			className: styles.tableColumn,
			render: ({ inventoryBatchItem }) => {
				return inventoryBatchItem ? inventoryBatchItem.batchNumber : 'N/A'
			}
		},
		{
			title: 'Requested Qty',
			key: 'requestedQuantity',
			className: styles.tableColumn,
			render: ({ packaging, quantity, packageQuantity }) => {
				return (
					<ItemQuantityCell
						packaging={packaging}
						quantity={quantity}
						packageQuantity={packageQuantity}
					/>
				)
			}
		},
		{
			title: 'Approved Qty',
			key: 'approvedQuantity',
			className: styles.tableColumn,
			render: ({ packaging, approvedQuantity, approvedPackageQuantity }) => {
				return (
					<ItemQuantityCell
						packaging={packaging}
						quantity={approvedQuantity}
						packageQuantity={approvedPackageQuantity}
					/>
				)
			}
		}
	]

	const deliveredQuantityColumns = [
		{
			title: 'Previous Delivered Qty',
			key: 'previousDeliveredQuantity',
			className: styles.tableColumn,
			render: ({ packaging, previousDeliveredQuantity, previousDeliveredPackageQuantity }) => {
				return (
					<ItemQuantityCell
						packaging={packaging}
						quantity={previousDeliveredQuantity}
						packageQuantity={previousDeliveredPackageQuantity}
					/>
				)
			}
		},
		{
			title: 'Delivered Qty',
			key: 'deliveredQuantity',
			className: styles.tableColumn,
			render: (salesOrderItem) => {
				const {
					packaging,
					deliveredQuantity,
					deliveredPackageQuantity,
					quantity,
					packageQuantity,
					approvedQuantity,
					approvedPackageQuantity,
					previousDeliveredQuantity,
					previousDeliveredPackageQuantity
				} = salesOrderItem
				return (
					<ItemQuantityCell
						packaging={packaging}
						quantity={deliveredQuantity}
						minQuantity={1}
						maxQuantity={previousDeliveredQuantity || approvedQuantity || quantity}
						minPackageQuantity={1}
						maxPackageQuantity={previousDeliveredPackageQuantity || approvedPackageQuantity || packageQuantity}
						packageQuantity={deliveredPackageQuantity}
						showQuantityWithPackaging={!isAdvancedInventory}
						editable={true}
						onQuantityChange={(value) => onQuantityChange(salesOrderItem, value, 'deliveredQuantity')}
						onPackageQuantityChange={(value) => onQuantityChange(salesOrderItem, value, 'deliveredPackageQuantity')}
					/>
				)
			}
		},
		{
			title: 'Total Price',
			key: 'totalPrice',
			render: salesOrderItem => {
				const { discountedPrice } = getItemTotals(salesOrderItem, 'deliveredQuantity')
				return (
					<div>
						{getFormattedCurrency(discountedPrice)}
					</div>
				)
			}
		}
	]

	const flaggedQuantityColumns = [
		{
			title: 'Delivered Qty',
			key: 'deliveredQuantity',
			className: styles.tableColumn,
			render: ({ packaging, deliveredQuantity, deliveredPackageQuantity }) => {
				return (
					<ItemQuantityCell
						packaging={packaging}
						quantity={deliveredQuantity}
						packageQuantity={deliveredPackageQuantity}
					/>
				)
			}
		},
		{
			title: 'Flagged Qty',
			key: 'flaggedQuantity',
			className: styles.tableColumn,
			render: (salesOrderItem) => {
				const {
					packaging,
					deliveredQuantity,
					deliveredPackageQuantity,
					flaggedQuantity,
					flaggedPackageQuantity
				} = salesOrderItem
				return (
					<ItemQuantityCell
						packaging={packaging}
						quantity={flaggedQuantity}
						minQuantity={0}
						maxQuantity={deliveredQuantity}
						minPackageQuantity={0}
						maxPackageQuantity={deliveredPackageQuantity}
						packageQuantity={flaggedPackageQuantity}
						showQuantityWithPackaging={!isAdvancedInventory}
						editable={true}
						onQuantityChange={(value) => onQuantityChange(salesOrderItem, value, 'flaggedQuantity')}
						onPackageQuantityChange={(value) => onQuantityChange(salesOrderItem, value, 'flaggedPackageQuantity')}
					/>
				)
			}
		},
		{
			title: 'Total Price',
			key: 'totalPrice',
			render: salesOrderItem => {
				const { discountedPrice } = getItemTotals(salesOrderItem, 'flaggedQuantity')
				return (
					<div>
						{getFormattedCurrency(discountedPrice)}
					</div>
				)
			}
		}
	]

	const modifiedSalesOrderColumns = useMemo(() => {
		let quantityColumns = []
		if (selectedStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS) {
			quantityColumns = flaggedQuantityColumns
		} else if (selectedStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
			quantityColumns = deliveredQuantityColumns
		}
		const columns = [...salesOrderItemColumns, ...quantityColumns]
		const showPreviousDeliveredQuantity = isAdvancedInventory && currentStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS
		const showReturnQuantity = isAdvancedInventory && selectedSubStatus === orderStatuses.SALES_ORDER_RETURNED_STATUS
		const showDamagedQuantity = isAdvancedInventory && selectedSubStatus === orderStatuses.SALES_ORDER_DAMAGED_STATUS
		const showExpiredQuantity = isAdvancedInventory && selectedSubStatus === orderStatuses.SALES_ORDER_EXPIRED_STATUS
		return columns.filter((column) => {
			if (isAdvancedInventory) {
				if (column.key === 'itemName') {
					column.title = 'SKU Description'
				}
			}
			if (column.key === 'flaggedQuantity') {
				if (showReturnQuantity) {
					column.title = 'Returned Qty'
				} else if (showDamagedQuantity) {
					column.title = 'Damaged Qty'
				} else if (showExpiredQuantity) {
					column.title = 'Expired Qty'
				}
			}
			if (showPreviousDeliveredQuantity) {
				if (column.key === 'deliveredQuantity' && selectedStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
					column.title = 'Updated Delivered Qty'
				}
				if (column.key === 'requestedQuantity' || column.key === 'approvedQuantity') {
					return false
				}
			}
			if (column.key === 'previousDeliveredQuantity') {
				return showPreviousDeliveredQuantity
			}
			if (
				column.key === 'batchNumber' ||
				column.key === 'approvedQuantity' ||
				column.key === 'totalPrice'
			) {
				return isAdvancedInventory
			}

			return true
		})
	}, [salesOrderItems, isAdvancedInventory, currentStatus, selectedStatus, selectedSubStatus])

	useEffect(() => {
		if (orderDetail.salesOrderItems) {
			setCurrentStatus(orderDetail.status.toLowerCase())
			const sortedItems = [...orderDetail.salesOrderItems].sort((a, b) => a.product.id.localeCompare(b.product.id))
			sortedItems.forEach(salesOrderItem => {
				const {
					deliveredQuantity,
					deliveredPackageQuantity,
					approvedPackageQuantity,
					approvedQuantity
				} = salesOrderItem
				salesOrderItem.flaggedQuantity = 0
				salesOrderItem.flaggedPackageQuantity = 0
				salesOrderItem.previousDeliveredQuantity = deliveredQuantity || 0
				salesOrderItem.previousDeliveredPackageQuantity = deliveredPackageQuantity || 0
				if (currentStatus === SALES_ORDER_COMPLETED_STATUS) {
					salesOrderItem.deliveredQuantity = deliveredQuantity || 0
					salesOrderItem.deliveredPackageQuantity = deliveredPackageQuantity || 0
				} else {
					salesOrderItem.deliveredQuantity = approvedQuantity || 0
					salesOrderItem.deliveredPackageQuantity = approvedPackageQuantity || 0
				}
				if (salesOrderItem.discountAmount) {
					const quantityField = getQuantityField(salesOrder.status, isAdvancedInventory)
					const { totalPrice } = getItemTotals(salesOrderItem, quantityField)
					salesOrderItem.discount = convertDiscountAmountToPercentage(totalPrice, +salesOrderItem.discountAmount)
				}
			})
			setSalesOrderItems(sortedItems)
		}
	}, [orderDetail, selectedStatus, currentStatus, isAdvancedInventory])

	const onQuantityChange = (currentItem, quantity, quantityField = 'quantity') => {
		const newSalesOrderItems = [...salesOrderItems]
		const value = Math.trunc(+quantity) || 0
		setSalesOrderItems(newSalesOrderItems.map(item => {
			if (item.id === currentItem.id) {
				return { ...item, [quantityField]: value }
			}
			return item
		}))
	}

	const shouldCreateFlaggedOrder = () => {
		return salesOrderItems.some((item) => {
			if (selectedStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS) {
				return item.flaggedQuantity || item.flaggedPackageQuantity
			} else if (selectedStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
				const quantity = item.previousDeliveredQuantity
				const packageQuantity = item.previousDeliveredPackageQuantity
				const deliveredQuantity = item.deliveredQuantity || 0
				const deliveredPackageQuantity = item.deliveredPackageQuantity || 0

				return deliveredQuantity < quantity || deliveredPackageQuantity < packageQuantity
			}
		})
	}

	const isInvalidForm = useMemo(() => {
		return salesOrderItems.some((item) => {
			let quantity = 0
			let packageQuantity = 0
			let deliveredQuantity = 0
			let deliveredPackageQuantity = 0
			let flaggedQuantity = 0
			let flaggedPackageQuantity = 0
			if (selectedStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS) {
				quantity = item.deliveredQuantity || item.approvedQuantity || item.quantity || 0
				packageQuantity = item.deliveredPackageQuantity || item.approvedPackageQuantity || item.packageQuantity || 0
				flaggedQuantity = item.flaggedQuantity || 0
				flaggedPackageQuantity = item.flaggedPackageQuantity || 0
				return !item.packageId ? flaggedQuantity > quantity || flaggedQuantity < 1 : flaggedPackageQuantity > packageQuantity || flaggedPackageQuantity < 1
			} else if (selectedStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
				quantity = item.previousDeliveredQuantity || item.approvedQuantity || item.quantity || 0
				packageQuantity = item.previousDeliveredPackageQuantity || item.approvedPackageQuantity || item.packageQuantity || 0
				deliveredQuantity = item.deliveredQuantity || 0
				deliveredPackageQuantity = item.deliveredPackageQuantity || 0
				return !item.packageId ? deliveredQuantity > quantity || deliveredQuantity < 1 : deliveredPackageQuantity > packageQuantity || deliveredPackageQuantity < 1
			}
			return false
		})
	}, [salesOrderItems, selectedStatus])

	const getValues = () => {
		const flagged = selectedStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS
		const params = {
			salesOrderItems: salesOrderItems.map(item => ({
				...item,
				salesOrderItemId: item.id,
				deliveredQuantity: flagged ? item.deliveredQuantity - item.flaggedQuantity : item.deliveredQuantity,
				deliveredPackageQuantity: flagged ? item.deliveredPackageQuantity - item.flaggedPackageQuantity : item.deliveredPackageQuantity,
				flaggedQuantity: 0,
				flaggedPackageQuantity: 0
			}))
		}
		if (isAdvancedInventory && shouldCreateFlaggedOrder(salesOrderItems)) {
			if (selectedSubStatus === orderStatuses.SALES_ORDER_DAMAGED_STATUS) {
				params.createDamagedSalesOrder = true
			} else if (selectedSubStatus === orderStatuses.SALES_ORDER_EXPIRED_STATUS) {
				params.createExpiredSalesOrder = true
			} else {
				params.createReturnSalesOrder = true
			}
		}

		return params
	}

	const renderContent = () => {
		return (
			<div>
				<div className={styles.id}>
					{orderDetail.internalId}
				</div>
				<CustomTable
					style={{ marginTop: '20px' }}
					tableLayout='auto'
					columns={modifiedSalesOrderColumns}
					dataSource={salesOrderItems}
					scroll={{ y: 'calc(100vh - 340px)', x: 'max-content' }}
					pagination={false}
					size='small'
					rowKey='id'
				/>
			</div>
		)
	}

	return {
		renderContent,
		getValues,
		setOrderDetail,
		setSelectedStatus,
		setSelectedSubStatus,
		orderDetail,
		isInvalidForm
	}
}

export default useOrderQuantityForm
