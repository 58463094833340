import {
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_AVERAGE_BASKET_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS,
	FETCH_TOP_E_COMMERCE_CUSTOMER,
	FETCH_TOP_SELLING_PRODUCTS,
	FETCH_TOP_SELLING_PRODUCTS_IN_TIME_RANGE,
	FETCH_DASHBOARD_SALES_ANALYTICS_PAYMENT_STATISTICS_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_SOURCE_SUCCESS,
	FETCH_DASHBOARD_SIGN_UP_TRENDS_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_CANCELLED_ORDER_REASON_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_RETURNED_ORDER_REASON_SUCCESS,
	FETCH_DASHBOARD_REGISTERED_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_ORDER_RATE_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_SALES_VALUE_SUCCESS,
	FETCH_DASHBOARD_PRODUCT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_CUSTOMER_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ORDER_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ORDER_AMOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ORDER_SOURCE_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ORDER_DELIVERY_PARTNER_TRENDS_SUCCESS,
	FETCH_DASHBOARD_LOCATION_TRENDS_SUCCESS,
	FETCH_DASHBOARD_PURCHASE_ORDER_TRENDS_SUCCESS,
	FETCH_DASHBOARD_PURCHASE_ORDER_AMOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_DISCOUNT_AND_ADVANCE_PAYMENT_ORDER_STATS_SUCCESS,
	FETCH_DASHBOARD_ENTERPRISE_LIVE_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ENTERPRISE_CHURN_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_TRIAL_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_LIVE_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_CHURNED_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_ALL_ACCOUNT_TRENDS_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_LIVE_CUMULATIVE_TREND_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_CUMULATIVE_ACTIVE_USER_TREND_SUCCESS,
	FETCH_DASHBOARD_ECOMMERCE_SALES_ORDERS_MONTHLY_TREND_SUCCESS
} from './action-types'

const initialState = {
	generalAnalyticsStatistics: {
		orders: {
			total: {
				value: 0
			},
			new: {
				value: 0
			},
			open: {
				value: 0
			},
			cancelled: {
				value: 0
			}
		},
		sales: {
			total: {
				value: 0
			},
			new: {
				value: 0
			},
			open: {
				value: 0
			},
			cancelled: {
				value: 0
			}
		}
	},
	generalAnalyticsOrderRate: {
		todayOrderRate: {
			open: 0,
			cancelled: 0,
			approved: 0,
			flagged: 0,
			processing: 0,
			completed: 0
		},
		sevenDayOrderRate: {
			open: 0,
			cancelled: 0,
			approved: 0,
			flagged: 0,
			processing: 0,
			completed: 0
		},
		oneMonthOrderRate: {
			open: 0,
			cancelled: 0,
			approved: 0,
			flagged: 0,
			processing: 0,
			completed: 0
		}
	},
	generalAnalyticsOrderSource: [],
	generalAnalyticsDiscountAndAdvancePaymentOrderCount: [],
	generalAnalyticsDiscountAndAdvancePaymentOrderValue: [],
	generalAnalyticsOrderCycleTime: {
		orderCycleChart: {
			requestedSalesHours: 0,
			flaggedSalesHours: 0,
			processingSalesHours: 0,
			approvedSalesHours: 0
		},
		orderCycleChartWeekly: {
			requestedSalesHours: 0,
			flaggedSalesHours: 0,
			processingSalesHours: 0,
			approvedSalesHours: 0
		},
		orderCycleChartMonthly: {
			requestedSalesHours: 0,
			flaggedSalesHours: 0,
			processingSalesHours: 0,
			approvedSalesHours: 0
		}
	},
	generalAnalyticsOrderLeadTime: {
		salesOrderLeadTimeWeekly: [],
		salesOrderLeadTimeMonthly: []
	},
	generalAnalyticsCancelledOrderReason: [],
	generalAnalyticsReturnedOrderReason: [],
	salesAnalyticsOrders: {
		salesOrderCountTrend: [],
		salesOrderCountTrendMonthly: [],
		salesOrderCountTrendWeekly: [],
		salesOrderCountTrendYearly: []
	},
	salesAnalyticsSales: {
		salesOrderAmountTrend: [],
		salesOrderAmountTrendMonthly: [],
		salesOrderAmountTrendWeekly: [],
		salesOrderAmountTrendYearly: []
	},
	salesAnalyticsAverageBasket: {
		averageBasketTrend: [],
		averageBasketTrendMonthly: [],
		averageBasketTrendWeekly: [],
		averageBasketTrendYearly: []
	},
	salesAnalyticsDistributorGrowth: {
		distributorGrowthAmountDaily: [],
		distributorGrowthAmountMonthly: [],
		distributorGrowthAmountWeekly: []
	},
	salesAnalyticsDivisionSales: {
		divisionWiseSalesDaily: [],
		divisionWiseSalesMonthly: [],
		divisionWiseSalesWeekly: []
	},
	salesAnalyticsItemSales: {
		itemWiseSalesDaily: [],
		itemWiseSalesWeekly: [],
		itemWiseSalesYearly: []
	},
	salesAnalyticsDistributorSales: {
		distributorWiseSalesDaily: [],
		distributorWiseSalesWeekly: [],
		distributorWiseSalesYearly: []
	},
	ordersAssignedAndCompletedRatio: {
		ordersAssignedAndCompletedRatioDaily: [],
		ordersAssignedAndCompletedRatioWeekly: [],
		ordersAssignedAndCompletedRatioMonthly: []
	},
	driverWiseCollectedAmountRatio: {
		driverWiseCashCollectedAmountRatioDaily: [],
		driverWiseCashCollectedAmountRatioWeekly: [],
		driverWiseCashCollectedAmountRatioMonthly: []
	},
	deliveryPartnerByLocation: {
		deliveryPartnerByLocationDaily: [],
		deliveryPartnerByLocationWeekly: [],
		deliveryPartnerByLocationMonthly: []
	},
	distanceTraveledByDeliveryPartner: {
		distanceTraveledByDeliveryPartnerDaily: [],
		distanceTraveledByDeliveryPartnerWeekly: [],
		distanceTraveledByDeliveryPartnerMonthly: []
	},
	topECommerceCustomers: [],
	topSellingProducts:[],
	topSellingProductsInTimeRange: [],
	salesAnalyticsPaymentStatistics: {
		count: {
			due: 0,
			collected: 0,
			total: 0
		},
		amount: {
			due: 0,
			collected: 0,
			total: 0
		}
	},
	signUpCountTrend: {},
	enterpriseLiveAccountCountTrend: {},
	enterpriseChurnAccountCountTrend: {},
	ecommerceTrialAccountCountTrend: {},
	ecommerceLiveAccountCountTrend: {},
	ecommerceChurnedAccountCountTrend: {},
	ecommerceAllAccountCountTrend: {},
	registeredCountTrend: {},
	ecommerceDeliveryPartnerAnalyticsOrderRate: [],
	ecommerceDeliveryPartnerAnalyticsSalesValue: [],
	productCountTrend: {},
	customerCountTrend: {},
	locationCountTrend: {},
	allOrderCountTrend: {},
	allOrderAmountTrend: {},
	allOrderSourceTrend: {},
	allOrderDeliveryPartnerTrend: {},
	purchaseOrderCountTrend: {},
	purchaseOrderAmountTrend: {},
	liveAccountCumulativeTrend: {},
	activeUserCumulativeTrend: {},
	salesOrdersProcessedMonthlyTrend: {},
	salesOrdersValueMonthlyTrend: {}
}

const dashboardReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS: {
			return {
				...state,
				generalAnalyticsStatistics: payload
			}
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.generalAnalyticsOrderRate.oneMonthOrderRate = payload.data.orderRate
			} else if (interval === 'weekly') {
				newState.generalAnalyticsOrderRate.sevenDayOrderRate = payload.data.orderRate
			} else {
				newState.generalAnalyticsOrderRate.todayOrderRate = payload.data.orderRate
			}
			return newState
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.generalAnalyticsOrderCycleTime.orderCycleChartMonthly = payload.data.orderCycleTime
			} else if (interval === 'weekly') {
				newState.generalAnalyticsOrderCycleTime.orderCycleChartWeekly = payload.data.orderCycleTime
			} else {
				newState.generalAnalyticsOrderCycleTime.orderCycleChart = payload.data.orderCycleTime
			}
			return newState
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_SOURCE_SUCCESS: {
			const newState = { ...state }
			newState.generalAnalyticsOrderSource = payload
			return newState
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.generalAnalyticsOrderLeadTime.salesOrderLeadTimeMonthly = payload.data.orderLeadTime
			} else {
				newState.generalAnalyticsOrderLeadTime.salesOrderLeadTimeWeekly = payload.data.orderLeadTime
			}
			return newState
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_CANCELLED_ORDER_REASON_SUCCESS: {
			const newState = { ...state }
			newState.generalAnalyticsCancelledOrderReason = payload
			return newState
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_RETURNED_ORDER_REASON_SUCCESS: {
			const newState = { ...state }
			newState.generalAnalyticsReturnedOrderReason = payload
			return newState
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_DISCOUNT_AND_ADVANCE_PAYMENT_ORDER_STATS_SUCCESS: {
			const newState = { ...state }
			newState.generalAnalyticsDiscountAndAdvancePaymentOrderCount = payload.count
			newState.generalAnalyticsDiscountAndAdvancePaymentOrderValue = payload.value
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsOrders.salesOrderCountTrendMonthly = payload.data.orderCountTrend
			} else if (interval === 'weekly') {
				newState.salesAnalyticsOrders.salesOrderCountTrendWeekly = payload.data.orderCountTrend
			} else if (interval === 'annually') {
				newState.salesAnalyticsOrders.salesOrderCountTrendYearly = payload.data.orderCountTrend
			} else {
				newState.salesAnalyticsOrders.salesOrderCountTrend = payload.data.orderCountTrend
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_AVERAGE_BASKET_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsAverageBasket.averageBasketTrendMonthly = payload.data.averageBasketTrend
			} else if (interval === 'weekly') {
				newState.salesAnalyticsAverageBasket.averageBasketTrendWeekly = payload.data.averageBasketTrend
			} else if (interval === 'annually') {
				newState.salesAnalyticsAverageBasket.averageBasketTrendYearly = payload.data.averageBasketTrend
			} else {
				newState.salesAnalyticsAverageBasket.averageBasketTrend = payload.data.averageBasketTrend
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsSales.salesOrderAmountTrendMonthly = payload.data.orderAmountTrend
			} else if (interval === 'weekly') {
				newState.salesAnalyticsSales.salesOrderAmountTrendWeekly = payload.data.orderAmountTrend
			} else {
				newState.salesAnalyticsSales.salesOrderAmountTrend = payload.data.orderAmountTrend
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsDistributorGrowth.distributorGrowthAmountMonthly = payload.data.distributorGrowth
			} else if (interval === 'weekly') {
				newState.salesAnalyticsDistributorGrowth.distributorGrowthAmountWeekly = payload.data.distributorGrowth
			} else {
				newState.salesAnalyticsDistributorGrowth.distributorGrowthAmountDaily = payload.data.distributorGrowth
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsDivisionSales.divisionWiseSalesMonthly = payload.data.divisionSales
			} else if (interval === 'weekly') {
				newState.salesAnalyticsDivisionSales.divisionWiseSalesWeekly = payload.data.divisionSales
			} else {
				newState.salesAnalyticsDivisionSales.divisionWiseSalesDaily = payload.data.divisionSales
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsItemSales.itemWiseSalesYearly = payload.data.itemSales
			} else if (interval === 'weekly') {
				newState.salesAnalyticsItemSales.itemWiseSalesWeekly = payload.data.itemSales
			} else {
				newState.salesAnalyticsItemSales.itemWiseSalesDaily = payload.data.itemSales
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsDistributorSales.distributorWiseSalesYearly = payload.data.distributorSales
			} else if (interval === 'weekly') {
				newState.salesAnalyticsDistributorSales.distributorWiseSalesWeekly = payload.data.distributorSales
			} else {
				newState.salesAnalyticsDistributorSales.distributorWiseSalesDaily = payload.data.distributorSales
			}
			return newState
		}
		case FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioMonthly = payload.data.ordersAssignedAndCompletedRatio
			} else if (interval === 'weekly') {
				newState.ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioWeekly = payload.data.ordersAssignedAndCompletedRatio
			} else {
				newState.ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioDaily = payload.data.ordersAssignedAndCompletedRatio
			}
			return newState
		}
		case FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioMonthly = payload.data.driverWiseCollectedAmountRatio
			} else if (interval === 'weekly') {
				newState.driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioWeekly = payload.data.driverWiseCollectedAmountRatio
			} else {
				newState.driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioDaily = payload.data.driverWiseCollectedAmountRatio
			}
			return newState
		}
		case FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.deliveryPartnerByLocation.deliveryPartnerByLocationMonthly = payload.data.deliveryPartners
			} else if (interval === 'weekly') {
				newState.deliveryPartnerByLocation.deliveryPartnerByLocationWeekly = payload.data.deliveryPartners
			} else {
				newState.deliveryPartnerByLocation.deliveryPartnerByLocationDaily = payload.data.deliveryPartners
			}
			return newState
		}
		case FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerMonthly = payload.data.distanceTraveledByDeliveryPartner
			} else if (interval === 'weekly') {
				newState.distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerWeekly = payload.data.distanceTraveledByDeliveryPartner
			} else {
				newState.distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerDaily = payload.data.distanceTraveledByDeliveryPartner
			}
			return newState
		}
		case FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_ORDER_RATE_SUCCESS: {
			const newState = { ...state }
			newState.ecommerceDeliveryPartnerAnalyticsOrderRate = payload
			return newState
		}
		case FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_SALES_VALUE_SUCCESS: {
			const newState = { ...state }
			newState.ecommerceDeliveryPartnerAnalyticsSalesValue = payload
			return newState
		}
		case FETCH_TOP_E_COMMERCE_CUSTOMER: {
			return {
				...state,
				topECommerceCustomers: payload.data.results
			}
		}
		case FETCH_TOP_SELLING_PRODUCTS: {
			return {
				...state,
				topSellingProducts: payload.data.results
			}
		}
		case FETCH_TOP_SELLING_PRODUCTS_IN_TIME_RANGE: {
			return {
				...state,
				topSellingProductsInTimeRange: payload.data.results
			}
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_PAYMENT_STATISTICS_SUCCESS: {
			const data = payload.salesAnalyticsPaymentStatistics
			return {
				...state,
				salesAnalyticsPaymentStatistics: data
			}
		}
		case FETCH_DASHBOARD_SIGN_UP_TRENDS_SUCCESS: {
			return {
				...state,
				signUpCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ENTERPRISE_LIVE_ACCOUNT_TRENDS_SUCCESS: {
			return {
				...state,
				enterpriseLiveAccountCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ENTERPRISE_CHURN_ACCOUNT_TRENDS_SUCCESS: {
			return {
				...state,
				enterpriseChurnAccountCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ECOMMERCE_TRIAL_ACCOUNT_TRENDS_SUCCESS: {
			return {
				...state,
				ecommerceTrialAccountCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ECOMMERCE_LIVE_ACCOUNT_TRENDS_SUCCESS: {
			return {
				...state,
				ecommerceLiveAccountCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ECOMMERCE_CHURNED_ACCOUNT_TRENDS_SUCCESS: {
			return {
				...state,
				ecommerceChurnedAccountCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ECOMMERCE_ALL_ACCOUNT_TRENDS_SUCCESS: {
			return {
				...state,
				ecommerceAllAccountCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ECOMMERCE_LIVE_CUMULATIVE_TREND_SUCCESS: {
			return {
				...state,
				liveAccountCumulativeTrend: payload
			}
		}
		case FETCH_DASHBOARD_ECOMMERCE_CUMULATIVE_ACTIVE_USER_TREND_SUCCESS: {
			return {
				...state,
				activeUserCumulativeTrend: payload
			}
		}
		case FETCH_DASHBOARD_ECOMMERCE_SALES_ORDERS_MONTHLY_TREND_SUCCESS: {
			return {
				...state,
				salesOrdersProcessedMonthlyTrend: {
					total: payload.totalProcessed,
					trend: payload.processedTrend
				},
				salesOrdersValueMonthlyTrend: {
					total: payload.totalValue,
					trend: payload.valueTrend
				}
			}
		}
		case FETCH_DASHBOARD_REGISTERED_TRENDS_SUCCESS: {
			return {
				...state,
				registeredCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_PRODUCT_TRENDS_SUCCESS: {
			return {
				...state,
				productCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_CUSTOMER_TRENDS_SUCCESS: {
			return {
				...state,
				customerCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ORDER_TRENDS_SUCCESS: {
			return {
				...state,
				allOrderCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ORDER_AMOUNT_TRENDS_SUCCESS: {
			return {
				...state,
				allOrderAmountTrend: payload
			}
		}
		case FETCH_DASHBOARD_ORDER_SOURCE_TRENDS_SUCCESS: {
			return {
				...state,
				allOrderSourceTrend: payload
			}
		}
		case FETCH_DASHBOARD_ORDER_DELIVERY_PARTNER_TRENDS_SUCCESS: {
			return {
				...state,
				allOrderDeliveryPartnerTrend: payload
			}
		}
		case FETCH_DASHBOARD_LOCATION_TRENDS_SUCCESS: {
			return {
				...state,
				locationCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_PURCHASE_ORDER_TRENDS_SUCCESS: {
			return {
				...state,
				purchaseOrderCountTrend: payload
			}
		}
		case FETCH_DASHBOARD_PURCHASE_ORDER_AMOUNT_TRENDS_SUCCESS: {
			return {
				...state,
				purchaseOrderAmountTrend: payload
			}
		}
		default:
			return state
	}
}

export default dashboardReducer
