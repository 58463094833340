import { get, patch, post } from './index'

export const DeliveryPartners = {
	index: (
		{
			searchTerm,
			partnerId,
			warehouseId,
			warehouseIds,
			active,
			creationFromDate,
			creationToDate,
			page = 0,
			deleted
		} = {},
		abortSignal
	) => {
		return get('/delivery-partners', {
			params: {
				searchTerm,
				partnerId,
				warehouseId,
				warehouseIds,
				active,
				creationFromDate,
				creationToDate,
				page,
				deleted
			},
			signal: abortSignal
		})
	},
	create: (params) =>
		post('/delivery-partners', params),
	single: (id, abortSignal) =>
		get(`/delivery-partners/${id}`, { signal: abortSignal }),
	update: (id, params) => patch(`/delivery-partners/${id}`, params),
	updateDeliveryPartners: (deliveryPartners = []) =>
		patch('/delivery-partners/multiple', { deliveryPartners }),
	verifyCredentials: ({ apiKey, clientId, secretKey, username, password, type, accessToken } = {}) => {
		return post('/delivery-partners/credentials-verification', {
			apiKey,
			clientId,
			secretKey,
			username,
			password,
			type,
			accessToken
		})
	},
	getDeliveryPickupAddresses: id =>
		get(`/delivery-partners/${id}/delivery-pickup-addresses`),
	getDeliveryCities: (id) =>
		get(`/delivery-partners/${id}/delivery-cities`),
	getDeliveryZones: (id, params) =>
		get(`/delivery-partners/${id}/delivery-zones`, { params }),
	getDeliveryAreas: (id, params) =>
		get(`/delivery-partners/${id}/delivery-areas`, { params }),
	getDeliveryAreaSuggestion: (id, searchTerm) =>
		get(`/delivery-partners/${id}/delivery-areas/suggestion`, {
			params: {
				searchTerm
			}
		}),
	getDeliveryAreaSuggestions: (id, {
		searchTerm,
		cityId,
		zoneId
	}, abortSignal) =>
		get(`/delivery-partners/${id}/delivery-areas/suggestions`, {
			params: {
				searchTerm,
				cityId,
				zoneId
			},
			signal: abortSignal
		}),
	metrics: () =>
		get('/delivery-partners/metrics'),
	getCharge: (id, {
		warehouseId,
		locationId
	} = {}) =>
		get(`/delivery-partners/${id}/charges`, {
			params: {
				warehouseId,
				locationId
			}
		}),
	getBalance: id =>
		get(`/delivery-partners/${id}/balance`),
	getStores: id =>
		get(`/delivery-partners/${id}/stores`),
	getPaymentTerms: id =>
		get(`/delivery-partners/${id}/payment-terms`),
	getServiceTypes: id =>
		get(`/delivery-partners/${id}/service-types`)
}
