import { useEffect, useState } from 'react'
import styles from './PresenceIndicator.module.css'
import { usePageVisibility } from 'react-page-visibility'
import { Presence } from '../../services/api/firebase'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Dropdown, Menu, Tooltip } from 'antd'
import ColorHash from 'color-hash'
import { setPageName } from '../../store/auth/actions'

const colorHash = new ColorHash()

const PresenceIndicator = ({
	pageName
}) => {
	const dispatch = useDispatch()
	const [users, setUsers] = useState([])
	const { companyId, userProfile } = useSelector(state => state.authReducer)
	const isVisible = usePageVisibility()

	useEffect(() => {
		dispatch(setPageName(pageName))
		return () => {
			dispatch(setPageName(null))
		}
	}, [pageName])

	useEffect(() => {
		if (!companyId) {
			return
		}
		const unsubscribe = Presence.listenToPresence(companyId, pageName, users => {
			setUsers(users)
		})
		return () => {
			unsubscribe()
		}
	}, [companyId])

	useEffect(() => {
		if (!userProfile) {
			return
		}
		if (isVisible) {
			console.log(isVisible)
			Presence.setPresence(companyId, pageName, {
				name: userProfile.name,
				email: userProfile.email,
				phone: userProfile.phone,
				uid: userProfile.id
			})
		} else {
			Presence.removePresence(companyId, pageName, userProfile.id)
		}
		return () => {
			Presence.removePresence(companyId, pageName, userProfile.id)
		}
	}, [isVisible, userProfile])

	return (
		<div className={styles.indicatorContainer}>
			{
				users.length > 3 ?
					<Avatar.Group>
						{
							users.slice(0, 3).map(user => {
								return (
									<Tooltip title={user.name} key={user.uid}>
										<Avatar
											style={{ background: colorHash.hex(user.uid) }}
										>
											{user.name.charAt(0)}
										</Avatar>
									</Tooltip>
								)
							})
						}
						<Dropdown
							overlay={(
								<Menu>
									{
										users.slice(3).map(user => {
											return (
												<Menu.Item key={user.uid}>
													{user.name}
												</Menu.Item>
											)
										})
									}
								</Menu>
							)} trigger={['click']}
						>
							<Avatar style={{ cursor: 'pointer' }}>
								{`+${users.length - 3}`}
							</Avatar>
						</Dropdown>
					</Avatar.Group> :
					<Avatar.Group>
						{
							users.map(user => {
								return (
									<Tooltip title={user.name} key={user.uid}>
										<Avatar
											style={{ background: colorHash.hex(user.uid) }}
										>
											{user.name.charAt(0)}
										</Avatar>
									</Tooltip>
								)
							})
						}
					</Avatar.Group>
			}
		</div>
	)
}

export default PresenceIndicator
