import styles from './PhoneInput.module.css'
require('./PhoneInput.less')
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import { getCountryCallingCode } from 'react-phone-number-input'

const CustomPhoneInput = ({
	style,
	disabled,
	title,
	countryCodeEditable,
	disableDropdown,
	inputProps,
	loading,
	error,
	value,
	onChange,
	placeholder = 'Phone Number'
}) => {
	const country = localStorage?.getItem('country') || 'BD'

	useEffect(() => {
		if (!value) {
			const callingCode = getCountryCallingCode(country)
			onChange(`+${callingCode}`)
		}
	}, [value])

	return (
		<div
			style={style}
			className={`${styles.phoneInput} ${title && styles.phoneInputLarge} ${disabled && styles.disabled} ${error && styles.error}`}
		>
			{title && <span className={styles.title}>{title}</span>}
			{
				loading &&
					<div className={styles.chevronDown}>
						<Spin
							indicator={
								<LoadingOutlined
									style={{ fontSize: 20 }}
									spin
								/>
							}
						/>
					</div>
			}
			<PhoneInput
				className={disableDropdown && 'phone-input-dropdown-disabled'}
				placeholder={!title && placeholder ? placeholder : ''}
				disabled={disabled}
				countryCodeEditable={countryCodeEditable}
				disableDropdown={disableDropdown}
				inputProps={inputProps}
				value={value}
				onChange={(phoneNumber) => onChange(phoneNumber ? `+${phoneNumber}` : '')}
				country={country.toLowerCase()}
			/>
		</div>
	)
}

export default CustomPhoneInput
