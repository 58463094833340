import { HYDRATE } from 'next-redux-wrapper'

import {
	CREATE_REPORTS_SUCCESS,
	FETCH_REPORTS_SUCCESS,
	FETCH_REPORT_DETAIL_SUCCESS,
	UPDATE_REPORTS_SUCCESS
} from './action-types'

const initialState = {
	totalCount: 0,
	page: 0,
	pageSize: 0,
	reports: [],
	reportDetails: {}
}

const reportsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_REPORTS_SUCCESS: {
			return {
				...state,
				reports: payload.data.results,
				totalCount: payload.data.count,
				page: +payload.data.page
			}
		}
		case FETCH_REPORT_DETAIL_SUCCESS: {
			const newState = { ...state }
			newState.reportDetails[payload.id] = payload
			return newState
		}
		case CREATE_REPORTS_SUCCESS: {
			const newState = { ...state }
			if (payload.visible) {
				newState.reports = [payload, ...newState.reports]
			}
			newState.reportDetails[payload.id] = payload
			return newState
		}
		case UPDATE_REPORTS_SUCCESS: {
			const newState = { ...state }
			const report = newState.reportDetails[payload.id]
			if (payload.visible) {
				newState.reports = [payload, ...newState.reports]
			}
			if (report && payload) {
				const newReport = Object.assign(report, payload)
				newState.reportDetails[payload.id] = newReport
			}
			return newState
		}
		default:
			return state
	}
}

export default reportsReducer
