import { Button, Dropdown } from 'antd'
import Image from 'next/image'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { apps } from '../../utils/constants'
import styles from './Apps.module.css'

const Apps = ({
	onAppSelected
}) => {
	const { companyDetails } = useSelector(state => state.authReducer)

	const enabledApps = useMemo(() => {
		const apps = new Set(companyDetails?.apps || [])
		return apps
	}, [companyDetails])

	const modifiedApps = useMemo(() => {
		return apps.filter(app => enabledApps.has(app.value))
	}, [enabledApps])

	return (
		<div className={styles.apps} id='app'>
			<Dropdown
				trigger={['click']}
				getPopupContainer={() => document.getElementById('app')}
				overlay={
					<div className={styles.dropdown}>
						{
							modifiedApps.length > 0 ?
								modifiedApps.map(({ icon: { src, width, height, alt }, title, value }) => {
									return (
										<Button
											disabled={!enabledApps.has(value)}
											key={src}
											className={styles.button}
											onClick={() => onAppSelected(value)}
										>
											<div className={styles.iconContainer}>
												<Image src={src} width={width} height={height} alt={alt} />
											</div>
											<p className={styles.title}>{title}</p>
										</Button>
									)
								}) :
								<div className={styles.emptyApps}>
									No Enabled Apps
								</div>
						}
					</div>
				}
			>
				<Button className={styles.toggleButton}>
					<img src='/img/keypad.svg' alt='Keypad icon' />
				</Button>
			</Dropdown>

		</div>
	)
}

export default Apps
