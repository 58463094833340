import { Button } from 'antd'
import styles from './SubTab.module.css'
import { DownOutlined } from '@ant-design/icons'
require('./SubTab.less')

const SubTab = ({
	className,
	active,
	count,
	children,
	onClick,
	loading,
	hasChevron = false,
	onChevronClicked
}) => {
	return (
		<Button
			className={`${styles.subTab} ${active && styles.subTabActive} ${className}`}
			onClick={onClick}
		>
			{children}&nbsp;
			{
				loading && (count === undefined || count === 0) ?
					<div className='sub-tab-skeleton-box' style={{ width: 20 }} /> :
					count !== undefined ?
						<span className={`${styles.count} ${active && styles.countActive}` }>
							{count}
						</span> : null
			}
			{
				hasChevron &&
				<DownOutlined
					onClick={onChevronClicked}
					style={{ marginLeft: 12, color: 'black', width: 24 }}
				/>
			}
		</Button>
	)
}

export default SubTab
