import { HYDRATE } from 'next-redux-wrapper'
import {
	PURCHASE_ORDER_REQUESTED_STATUS,
	PURCHASE_ORDER_APPROVED_STATUS,
	PURCHASE_ORDER_RECEIVED_STATUS,
	PURCHASE_ORDER_FLAGGED_STATUS,
	PURCHASE_ORDER_CANCELLED_STATUS,
	PURCHASE_ORDER_RETURNED_SUB_STATUS,
	PURCHASE_ORDER_DAMAGED_SUB_STATUS,
	PURCHASE_ORDER_EXPIRED_SUB_STATUS,
	PURCHASE_ORDER_PAYMENT_DUE_SUB_STATUS,
	PURCHASE_ORDER_PAYMENT_PAID_SUB_STATUS
} from '../../utils/purchase-orders'
import {
	ADD_PURCHASE_ORDERS_SUCCESS,
	ADD_TRANSACTION_SUCCESS,
	FETCH_PURCHASE_ORDERS_SUCCESS,
	FETCH_PURCHASE_ORDER_DETAILS_SUCCESS,
	UPDATE_PURCHASE_ORDER_SUCCESS
} from './action-types'
import { TransactionType } from '../../utils/constants'

const initialState = {
	purchaseOrderDetails: {},
	allPurchaseOrders: [],
	requestedPurchaseOrders: [],
	approvedPurchaseOrders: [],
	receivedPurchaseOrders: [],
	flaggedPurchaseOrders: [],
	cancelledPurchaseOrders: [],
	totalCount: 0,
	counts: {
		[PURCHASE_ORDER_REQUESTED_STATUS]: 0,
		[PURCHASE_ORDER_FLAGGED_STATUS]: 0,
		[PURCHASE_ORDER_APPROVED_STATUS]: 0,
		[PURCHASE_ORDER_RECEIVED_STATUS]: 0,
		[PURCHASE_ORDER_CANCELLED_STATUS]: 0,
		[PURCHASE_ORDER_RETURNED_SUB_STATUS]: 0,
		[PURCHASE_ORDER_DAMAGED_SUB_STATUS]: 0,
		[PURCHASE_ORDER_EXPIRED_SUB_STATUS]: 0,
		[PURCHASE_ORDER_PAYMENT_DUE_SUB_STATUS]: 0,
		[PURCHASE_ORDER_PAYMENT_PAID_SUB_STATUS]: 0
	},
	page: 0
}

const purchaseOrdersReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_PURCHASE_ORDERS_SUCCESS: {
			const newState = { ...state }
			const countsMap = { ...initialState.counts }
			const counts = payload.data?.counts || []
			for (const count of counts) {
				if (count.status) {
					countsMap[count.status.toLowerCase()] = count._count
				}
				if (count.subStatus) {
					countsMap[count.subStatus.toLowerCase()] = count._count
				}
			}
			newState.counts = countsMap
			const statuses = payload.status ? [payload.status] : payload.statuses || []
			newState.allPurchaseOrders = payload.data.results || []
			newState.requestedPurchaseOrders = []
			newState.approvedPurchaseOrders = []
			newState.receivedPurchaseOrders = []
			newState.flaggedPurchaseOrders = []
			newState.cancelledPurchaseOrders = []
			newState.totalCount = +(payload.data.count || 0)
			newState.page = +(payload.data.page || 0)
			statuses.forEach(status => {
				switch (status) {
					case PURCHASE_ORDER_REQUESTED_STATUS:
						newState.requestedPurchaseOrders = newState.allPurchaseOrders.filter(po => po.status.toLowerCase() === PURCHASE_ORDER_REQUESTED_STATUS)
						break
					case PURCHASE_ORDER_APPROVED_STATUS:
						newState.approvedPurchaseOrders = newState.allPurchaseOrders.filter(po => po.status.toLowerCase() === PURCHASE_ORDER_APPROVED_STATUS)
						break
					case PURCHASE_ORDER_RECEIVED_STATUS:
						newState.receivedPurchaseOrders = newState.allPurchaseOrders.filter(po => po.status.toLowerCase() === PURCHASE_ORDER_RECEIVED_STATUS)
						break
					case PURCHASE_ORDER_FLAGGED_STATUS:
						newState.flaggedPurchaseOrders = newState.allPurchaseOrders.filter(po => po.status.toLowerCase() === PURCHASE_ORDER_FLAGGED_STATUS)
						break
					case PURCHASE_ORDER_CANCELLED_STATUS:
						newState.cancelledPurchaseOrders = newState.allPurchaseOrders.filter(po => po.status.toLowerCase() === PURCHASE_ORDER_CANCELLED_STATUS)
				}
			})
			return newState
		}
		case ADD_PURCHASE_ORDERS_SUCCESS: {
			if (payload.status === PURCHASE_ORDER_REQUESTED_STATUS) {
				const requestedPurchaseOrders = [payload, ...state.requestedPurchaseOrders]
				return {
					...state,
					requestedPurchaseOrders
				}
			} else if (payload.status === PURCHASE_ORDER_APPROVED_STATUS) {
				const approvedPurchaseOrders = [payload, ...state.approvedPurchaseOrders]
				return {
					...state,
					approvedPurchaseOrders
				}
			} else {
				return state
			}
		}
		case FETCH_PURCHASE_ORDER_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.purchaseOrderDetails[payload.id] = payload.data
			return newState
		}
		case UPDATE_PURCHASE_ORDER_SUCCESS: {
			const newState = { ...state }
			const purchaseOrder = newState.purchaseOrderDetails[payload.id]
			if (purchaseOrder && payload) {
				const newPurchaseOrder = Object.assign(purchaseOrder, payload)
				newState.purchaseOrderDetails[payload.id] = newPurchaseOrder
			}
			return newState
		}
		case ADD_TRANSACTION_SUCCESS: {
			const newState = { ...state }
			const purchaseOrderDetails = newState.purchaseOrderDetails[payload.id]
			if (purchaseOrderDetails) {
				if (purchaseOrderDetails.transactions) {
					if (payload.data.type === TransactionType.ADVANCE) {
						const advanceIndex = purchaseOrderDetails.transactions.findIndex(transaction => transaction.type === TransactionType.ADVANCE)
						if (advanceIndex > -1) {
							purchaseOrderDetails.transactions.splice(advanceIndex, 1)
						}
					}
					purchaseOrderDetails.transactions.push(payload.data)
				}
			}
			return newState
		}
		default:
			return state
	}
}

export default purchaseOrdersReducer
