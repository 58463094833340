import { Avatar, Dropdown, Menu } from 'antd'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { claimsRoles, industries } from '../../utils/constants'
import styles from './ProfileMenu.module.css'
import { UserOutlined, LogoutOutlined, QuestionOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons'
import ColorHash from 'color-hash'

const colorHash = new ColorHash()

const ProfileMenu = ({ logout, ...props }) => {
	const { userProfile, role, permissions, companyDetails } = useSelector(state => state.authReducer)

	const showSupportDesk = () => {
		if (window.FreshworksWidget) {
			const { FreshworksWidget } = window
			FreshworksWidget('open')
		}
	}

	const showLiveSupport = () => {
		if (window.fcWidget) {
			const { fcWidget } = window
			fcWidget.on('widget:closed', function () {
				fcWidget.hide()
			})
			fcWidget.show()
			fcWidget.open()
		}
	}

	const renderDropdownOverlay = () => {
		return (
			<Menu {...props}>
				{
					userProfile && role !== claimsRoles.ADMIN ?
						<Menu.Item key='info'>
							<Link href='/company'>
								<div className={styles.infoContainer}>
									{
										userProfile.company ?
											<div className={styles.companyName}>
												{userProfile.company.name}
											</div> : null
									}
									<div className={styles.name}>
										{userProfile.name}
									</div>
								</div>
							</Link>
						</Menu.Item> : null
				}
				{
					role !== claimsRoles.ADMIN &&
					<Menu.Item
						key='profile'
						style={{ padding: '10px 20px' }}
						icon={<UserOutlined style={{ fontSize: 16, color: 'var(--black-100)' }} />}
					>
						<Link href='/profile'>
						Profile
						</Link>
					</Menu.Item>
				}
				{
					role !== claimsRoles.ADMIN && permissions.viewCompanySettings &&
					<Menu.Item
						key='company-settings'
						style={{ padding: '10px 20px' }}
						icon={<SettingOutlined style={{ fontSize: 16, color: 'var(--black-100)' }} />}
					>
						<Link href='/company/settings'>
						Company Settings
						</Link>
					</Menu.Item>
				}
				{
					role !== claimsRoles.ADMIN && permissions.viewPayments &&
					<Menu.Item
						key='payments'
						style={{ padding: '10px 20px' }}
						icon={
							<svg style={{ width: 16, height: 16, fill: 'var(--black-100)' }}>
								<use href='/img/bank-card.svg#icon' />
							</svg>
						}
					>
						<Link href='/company/payments'>
							Payments
						</Link>
					</Menu.Item>
				}
				{
					role !== claimsRoles.ADMIN && companyDetails?.industry === industries.E_COMMERCE &&
					<Menu.Item
						key='help'
						style={{ padding: '10px 20px' }}
						icon={<QuestionOutlined style={{ fontSize: 16, color: 'var(--black-100)' }} />}
						onClick={showSupportDesk}
					>
					Help Desk
					</Menu.Item>
				}
				{
					role !== claimsRoles.ADMIN && companyDetails?.industry === industries.E_COMMERCE &&
					<Menu.Item
						key='support'
						style={{ padding: '10px 20px' }}
						icon={<MessageOutlined style={{ fontSize: 16, color: 'var(--black-100)' }} />}
						onClick={showLiveSupport}
					>
					Live Support
					</Menu.Item>
				}
				<Menu.Item
					key='logout'
					style={{ padding: '10px 20px' }}
					icon={<LogoutOutlined style={{ fontSize: 16, color: 'var(--black-100)' }} />}
					onClick={logout}
				>
					Logout
				</Menu.Item>
			</Menu>
		)
	}

	return (
		<div id='profile'>
			<Dropdown
				disabled={!userProfile}
				overlay={renderDropdownOverlay}
				trigger={['click']}
				overlayStyle={{ zIndex: 1102 }}
				getPopupContainer={() => document.getElementById('profile')}
			>
				<div className={styles.avatarContainer}>
					<span className={styles.avatar}>
						{
							userProfile ?
								<Avatar
									style={{ background: colorHash.hex(userProfile.id) }}
								>
									{userProfile.name.charAt(0)}
								</Avatar> :
								<UserOutlined color='#fff' style={{ color: '#fff', fontSize: 20 }} />
						}
					</span>
					<img className={styles.downIcon} src='/img/down.svg' alt='Down icon' />
				</div>
			</Dropdown>
		</div>
	)
}

export default ProfileMenu
