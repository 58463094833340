import { useMemo, useState } from 'react'
import Select from '../select'
import TextArea from '../text-area'
import { useSelector } from 'react-redux'
import { InventoryEditions, orderStatuses } from '../../utils/constants'

const flagReasonOptions = [
	{
		value: 'Item(s) unavailable',
		label: 'Item(s) unavailable'
	},
	{
		value: 'Client payment issues',
		label: 'Client payment issues'
	},
	{
		value: 'Transport issues',
		label: 'Transport issues'
	},
	{
		value: '',
		label: 'Other'
	}
]

const damagedReasonOptions = [
	{
		value: 'Inadequate Packaging',
		label: 'Inadequate Packaging'
	},
	{
		value: 'Rough Handling',
		label: 'Rough Handling'
	},
	{
		value: 'Manufacturing Defects',
		label: 'Manufacturing Defects'
	},
	{
		value: 'Environmental Factors',
		label: 'Environmental Factors'
	},
	{
		value: 'Other',
		label: 'Other'
	}
]

const returnedReasonOptions = [
	{
		value: 'Wrong Product',
		label: 'Wrong Product'
	},
	{
		value: 'Delay Delivery',
		label: 'Delay Delivery'
	},
	{
		value: 'Fraud Customer',
		label: 'Fraud Customer'
	},
	{
		value: 'Delivery man\'s carelessness',
		label: 'Delivery man\'s carelessness'
	},
	{
		value: 'Other',
		label: 'Other'
	}
]

const expiredReasonOptions = [
	{
		value: 'Shelf Life Exceeded',
		label: 'Shelf Life Exceeded'
	},
	{
		value: 'Other',
		label: 'Other'
	}
]

const cancelledReasonOptions = [
	{
		value: 'High Price',
		label: 'High Price'
	},
	{
		value: 'Short Time Delivery',
		label: 'Short Time Delivery'
	},
	{
		value: 'Out of Zone',
		label: 'Out of Zone'
	},
	{
		value: 'Other',
		label: 'Other'
	}
]

const FlagReasoningForm = ({ reason, setReason, flag }) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [flagReasonOption, setFlagReasonOption] = useState(null)
	const inventoryEdition = useMemo(() => companyDetails?.inventoryEdition, [companyDetails])
	const modifiedFlagReasonOptions = useMemo(() => {
		if (inventoryEdition === InventoryEditions.ADVANCED && flag) {
			switch (flag.toLowerCase()) {
				case orderStatuses.SALES_ORDER_RETURNED_STATUS:
					return returnedReasonOptions
				case orderStatuses.SALES_ORDER_DAMAGED_STATUS:
					return damagedReasonOptions
				case orderStatuses.SALES_ORDER_EXPIRED_STATUS:
					return expiredReasonOptions
				case orderStatuses.SALES_ORDER_CANCELLED_STATUS:
					return cancelledReasonOptions
				default:
					return []
			}
		}

		return flagReasonOptions
	}, [inventoryEdition, flag])

	return (
		<div>
			<Select
				value={flagReasonOption}
				onChange={value => {
					setFlagReasonOption(value)
					if (value === 'Other') {
						setReason('')
					} else {
						setReason(value)
					}
				}}
				placeholder='Select a reason'
				style={{ width: '100%' }}
				options={modifiedFlagReasonOptions}
			/>
			{
				flagReasonOption === 'Other' &&
				<TextArea
					containerStyle={{ marginTop: 24, width: '100%' }}
					showCount={true}
					value={reason}
					maxLength={1000}
					onChange={e => {
						setReason(e.target.value)
					}}
					autoSize={{ minRows: 3, maxRows: 6 }}
					placeholder='Other reason...'
				/>
			}
		</div>
	)
}

export default FlagReasoningForm
