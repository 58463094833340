import { Forecasts } from '../../services/api/forecasts'
import { FETCH_ORDER_AMOUNT_FORECAST_SUCCESS, FETCH_ORDER_COUNT_FORECAST_SUCCESS } from './action-types'


export function fetchOrderAmountForecast ({ interval, horizon }) {
	return async (dispatch) => {
		const response = await Forecasts.fetchOrderForecasts({ interval, horizon })
		dispatch({ type: FETCH_ORDER_AMOUNT_FORECAST_SUCCESS, payload: response.data })
	}
}

export function fetchOrderCountForecast ({ interval, horizon }) {
	return async (dispatch) => {
		const response = await Forecasts.fetchOrderForecasts({ interval, horizon })
		dispatch({ type: FETCH_ORDER_COUNT_FORECAST_SUCCESS, payload: response.data })
	}
}
