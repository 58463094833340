import { get, post } from '.'

export const Ccl = {
	fetchCclMonthlyInfoForCompany: (companyId) =>
		get(`/companies/${companyId}/ccl-monthly-info`),
	fetchCcl: (phone) =>
		get('/ccl', {
			params: {
				phone
			}
		}),
	checkCcl: (phone) =>
		post('/ccl', {
			phone
		})
}
