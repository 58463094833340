import { Button, Modal, Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { SalesOrders } from '../../services/api/sales-orders'
import moment from 'moment'
import PostalOrderForm from '../postal-order-form'
import { PartnerType } from '../../utils/constants'

const PrintSalesOrderPostalOrderFormsModal = ({ visible, salesOrderIds, onCancel }) => {
	const [message, setMessage] = useState('Rendering Invoices....')
	const [hasError, setHasError] = useState(false)
	const [formData, setFormData] = useState([])
	const printableRef = useRef()
	const printRef = useRef()

	useEffect(() => {
		if (visible) {
			getSalesOrders()
		}
	}, [visible])

	const getSalesOrders = async () => {
		try {
			const response = await SalesOrders.multiple(salesOrderIds)
			const salesOrders = response.data
			const formData = []
			if (salesOrders) {
				salesOrders.sort((a, b) => {
					if (a.createdAt < b.createdAt) {
						return -1
					}
					if (a.createdAt > b.createdAt) {
						return 1
					}
					return 0
				})
				for (const salesOrder of salesOrders) {
					if (salesOrder.isStockTransfer) {
						continue
					}
					if (salesOrder.deliveryPartner?.type !== PartnerType.PAKISTAN_POST) {
						continue
					}
					formData.push(salesOrder)
				}
				if (formData.length === 0) {
					setHasError(true)
					setMessage('No sales orders to print postal forms for.')
				} else {
					setFormData(formData)
					setTimeout(() => {
						printRef.current.handleClick()
					}, 1500)
				}
			}
		} catch (e) {
			console.error(e)
			setHasError(true)
			setMessage('An error has occurred when trying to print the selected orders.')
			onCancel()
		}
	}

	const onAfterPrint = async () => {
		onCancel()
	}

	const renderModalFooter = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
				{
					hasError &&
					<Button
						onClick={onCancel}
					>
						Cancel
					</Button>
				}
				{
					!hasError &&
					<Button
						type='primary'
						loading={true}
					>
						Printing...
					</Button>
				}
			</div>
		)
	}

	return (
		<Modal
			title='Print Postal Order Forms'
			visible={visible}
			onCancel={onCancel}
			footer={renderModalFooter()}
		>
			<div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', width: '100%' }}>
				{
					!hasError && <Spin style={{ marginRight: 12 }} />
				}
				{message}
			</div>
			<div ref={printableRef}>
				{
					formData.map((data, index) => {
						return (
							<PostalOrderForm
								key={index}
								data={data}
							/>
						)
					})
				}
			</div>
			<ReactToPrint
				ref={printRef}
				content={() => printableRef.current}
				onAfterPrint={onAfterPrint}
				documentTitle={`sales-orders_selected-${formData?.length || ''}_${moment().format('MMM_DD_YYYY')}`}
				fonts={[
					{
						family: 'NotoNastaliqUrdu',
						src: 'https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@400..700&display=swap'
					}
				]}
			/>
		</Modal>
	)
}

export default PrintSalesOrderPostalOrderFormsModal
