import { get } from '.'

export const Insights = {
	index: ({
		page = 0,
		type,
		fromDate,
		toDate,
		pageSize = 50
	} = {}) => get('/insights', {
		params: {
			page,
			type,
			fromDate,
			toDate,
			pageSize
		}
	})
}
