import { Button, ConfigProvider, Dropdown, Menu, Popover, Table } from 'antd'
import styles from './Table.module.css'
require('./Table.less')
import CustomEmpty from '../custom-empty'

const pageSizes = [
	{
		label: '20',
		key: '20',
		value: 20
	},
	{
		label: '50',
		key: '50',
		value: 50
	},
	{
		label: '100',
		key: '100',
		value: 100
	},
	{
		label: '150',
		key: '150',
		value: 150
	},
	{
		label: '200',
		key: '200',
		value: 200
	},
	{
		label: '300',
		key: '300',
		value: 300
	},
	{
		label: '500',
		key: '500',
		value: 500
	}
]

const CustomTable = (props) => {
	const {
		className,
		title,
		columns,
		dataSource,
		size,
		refresh,
		loading,
		pagination,
		onPageSizeChange,
		filter,
		filterTrigger,
		filterPlacement,
		filterVisible,
		filterContent,
		onFilterVisibleChange,
		headerChildren,
		renderEmpty,
		showTotal,
		fallback,
		showFallbackAction,
		showTableTitle = false,
		empty,
		isDataSourceEmpty
	} = props

	return (
		dataSource?.length || loading || showTableTitle || isDataSourceEmpty ?
			<div style={{ position: 'relative' }}>
				<div className={`${styles.buttons} ${(size === 'small' || size === 'middle') && styles.buttonsShort}`}>
					{/* Refresh button */}
					{
						refresh &&
						(pagination || title) &&
						dataSource?.length > 0 &&
						<Button
							className={`${styles.button} ${styles.noPadding}`}
							title='Refresh'
							onClick={refresh}
						>
							<img src='/img/refresh.svg' alt='Refresh icon' />
						</Button>
					}
					{/* Filter columns button */}
					{
						(pagination || title) &&
						dataSource?.length > 0 &&
						<>
							{
								filter &&
								<Popover
									trigger={filterTrigger}
									placement={filterPlacement}
									visible={filterVisible}
									onVisibleChange={onFilterVisibleChange}
									content={filterContent}
								>
									<Button className={styles.button}>
										<img src='/img/filter-grey.svg' alt='Filter icon' />
										Filter Column
									</Button>
								</Popover>
							}
							{headerChildren}
						</>
					}
					{/* Page size changer */}
					{
						(pagination || title) &&
						(dataSource?.length > 0 && onPageSizeChange) &&
						<div className={styles.pageSizeChanger}>
							<p>Show</p>
							<Dropdown
								trigger={['click']}
								overlay={() => {
									return (
										<Menu>
											{
												pageSizes.map(({ key, value, label }) => {
													return (
														<Menu.Item
															key={key}
															onClick={() => onPageSizeChange(value)}
														>
															{label}
														</Menu.Item>
													)
												})
											}
										</Menu>
									)
								}}
							>
								<Button
									className={`${styles.pageSizeSelect} ${styles.button}`}
									title='Results per page'
								>
									<p>{pagination.pageSize}</p>
									<img src='/img/arrow-down.svg' alt='Down arrow' />
								</Button>
							</Dropdown>
						</div>
					}
					{
						showTotal && pagination && pagination.total > 0 &&
						<div className={styles.totalContainer}>
							Total
							<div className={styles.total}>
								{pagination.total}
							</div>
						</div>
					}
				</div>
				<ConfigProvider renderEmpty={renderEmpty || null}>
					<Table
						className={`custom-table ${(size === 'small' || size === 'middle') && 'custom-table-small'} ${className}`}
						{...{ ...props, dataSource: columns.length ? dataSource : [] }}
					/>
				</ConfigProvider>
			</div> :
			empty || <CustomEmpty fallback={fallback} showFallbackAction={showFallbackAction} />
	)
}

export default CustomTable
