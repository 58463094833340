import { Button, notification, Modal, Collapse, Form } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { Users } from '../../services/api/users'
import { assignHelperToDeliveryPlanItem } from '../../store/delivery-plans/actions'
import CustomSearchableSelect from '../custom-searchable-select'
import { PlusOutlined } from '@ant-design/icons'
import CustomPhoneInput from '../phone-input'
import { claimsRoles } from '../../utils/constants'
import { Vehicles } from '../../services/api/vehicles'
import { VEHICLE_ACTIVE_STATUS, getVehicleTypeLabel } from '../../utils/vehicles'
import TextInput from '../text-input'

const { Panel } = Collapse

const AssignHelperModal = ({ visible = false, onCancel, deliveryPlanItemId }) => {
	const dispatch = useDispatch()
	const { userProfile } = useSelector(state => state.authReducer)
	const [isLoadingHelpers, setIsLoadingHelpers] = useState(false)
	const [isCreatingNewHelper, setIsCreatingNewHelper] = useState(false)
	const [isAssigningHelper, setIsAssigningHelper] = useState(false)
	const [helper, setHelper] = useState()
	const [helpers, setHelpers] = useState([])
	const [helperName, setHelperName] = useState('')
	const [helperPhone, setHelperPhone] = useState('')
	const [helperEmail, setHelperEmail] = useState('')
	const [driver, setDriver] = useState(null)
	const [vehicle, setVehicle] = useState(null)
	const [isLoadingVehicles, setIsLoadingVehicles] = useState(false)
	const [vehicles, setVehicles] = useState([])
	const [isLoadingDrivers, setIsLoadingDrivers] = useState(false)
	const [drivers, setDrivers] = useState([])
	const locationIds = useMemo(() => {
		const locationIds = userProfile ? userProfile.locations.map(({ id }) => id) : undefined
		return locationIds?.length ? locationIds : undefined
	}, [userProfile])

	useEffect(() => {
		fetchVehicles()
	}, [])

	useEffect(() => {
		if (userProfile) {
			fetchHelpers()
			fetchDrivers()
		}
	}, [userProfile])

	useEffect(() => {
		if (helper) {
			fetchHelperVehicle(helper.value)
		} else {
			setVehicle(null)
			setDriver(null)
		}
	}, [helper])

	const fetchHelperVehicle = async (driverId) => {
		try {
			const { data } = await Vehicles.fetchUserVehicle(driverId)
			setVehicle({ value: data.id, label: data.plateNumber })
			if (data.driver) {
				setDriver({ value: data.driver.id, label: data.driver.name })
			}
		} catch (e) {
			setVehicle(null)
			setDriver(null)
		}
	}

	const fetchHelpers = async () => {
		setIsLoadingHelpers(true)
		const response = await Users.index({
			role: claimsRoles.HELPER,
			locationIds,
			available: true
		})
		setHelpers(response.data.results)
		setIsLoadingHelpers(false)
	}

	const searchHelpers = value => {
		return Users.index({
			searchTerm: value,
			role: claimsRoles.HELPER,
			locationIds,
			available: true
		})
	}

	const fetchDrivers = async () => {
		setIsLoadingDrivers(true)
		const response = await Users.index({
			role: claimsRoles.DRIVER,
			locationIds,
			available: true
		})
		setDrivers(response.data.results)
		setIsLoadingDrivers(false)
	}

	const searchDrivers = value => {
		return Users.index({
			searchTerm: value,
			role: claimsRoles.DRIVER,
			locationIds,
			available: true
		})
	}

	const fetchVehicles = async () => {
		setIsLoadingVehicles(true)
		const { data } = await Vehicles.index({
			available: true,
			deliveryPlanItemId
		})
		setVehicles(data.results)
		setIsLoadingVehicles(false)
	}

	const searchVehicles = value => {
		return Vehicles.index({
			searchTerm: value,
			available: true,
			deliveryPlanItemId
		})
	}

	const createHelper = async () => {
		setIsCreatingNewHelper(true)
		try {
			const response = await Users.create({
				name: helperName,
				phone: helperPhone,
				email: helperEmail,
				role: 'helper'
			})
			const createdHelper = response.data
			setHelpers([createdHelper, ...helpers])
			setHelper({
				key: createdHelper.id,
				label: createdHelper.name,
				value: createdHelper.id
			})
			clearForm()
			notification.success({
				message: 'New Helper Created',
				description: 'New Helper successfully created!',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Create New Helper',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCreatingNewHelper(false)
		}
	}

	const assignHelper = async () => {
		setIsAssigningHelper(true)
		try {
			await dispatch(assignHelperToDeliveryPlanItem(deliveryPlanItemId, {
				helperId: helper.value,
				driverId: driver?.value,
				vehicleId: vehicle?.value
			}))
			onCancel()
			notification.success({
				message: 'Helper Assigned to Delivery Plan',
				description: 'Driver has been successfully assigned to a route.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Assign Helper to Route',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsAssigningHelper(false)
		}
	}

	const clearForm = () => {
		setHelperName('')
		setHelperPhone('')
		setHelperEmail('')
	}

	const isHelperInputValid = () => {
		if (!helperName) {
			return false
		}

		if (!helperPhone) {
			return false
		} else {
			if (!isValidPhoneNumber(helperPhone)) {
				return false
			}
		}

		if (!helperEmail) {
			return false
		} else {
			var re = /\S+@\S+\.\S+/
			if (!re.test(helperEmail)) {
				return false
			}
		}
		return true
	}

	const renderCustomUserLabel = (data) => {
		return (
			<div>
				<div>{`${data.name} (${data.lastUnassignedTripId ? 'Unassigned' : 'Available'})`}</div>
			</div>
		)
	}

	const renderCustomVehicleLabel = (data) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div>
					<div>{`${data.plateNumber} (${data.status === VEHICLE_ACTIVE_STATUS ? 'Available' : data.currentTripId ? 'Assigned' : 'Unassigned'})`}</div>
					<div>{data.location ? `${data.location.internalName ? `${data.location.label} (${data.location.internalName})` : data.location.label}` : 'No Base Location'}</div>
				</div>
				<div style={{ marginLeft: 'auto', fontSize: 12 }}>
					{getVehicleTypeLabel(data.type)}
				</div>
			</div>
		)
	}

	const renderModalFooter = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
				<Button
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					type='primary'
					loading={isAssigningHelper}
					disabled={!helper}
					onClick={assignHelper}
				>
					Assign Helper
				</Button>
			</div>
		)
	}

	return (
		<Modal
			title='Assign Helper'
			visible={visible}
			onCancel={onCancel}
			footer={renderModalFooter()}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Form.Item>
					<CustomSearchableSelect
						searchTask={searchHelpers}
						defaultOptions={helpers}
						isLoading={isLoadingHelpers}
						labelIndex='name'
						valueIndex='id'
						customLabel={renderCustomUserLabel}
						title='Helper'
						placeholder='Select Helper'
						onChange={option => setHelper(option)}
						onClear={() => setHelper(null)}
						allowClear={true}
						value={helper}
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<Form.Item>
					<CustomSearchableSelect
						searchTask={searchVehicles}
						defaultOptions={vehicles}
						isLoading={isLoadingVehicles}
						title='Vehicle'
						className='vehicle-select'
						labelIndex='plateNumber'
						valueIndex='id'
						customLabel={renderCustomVehicleLabel}
						placeholder='Select Vehicle'
						onChange={setVehicle}
						onClear={() => setVehicle(null)}
						value={vehicle}
						allowClear={true}
						popupContainer={() => document.querySelector('.ant-modal-body')}
					/>
				</Form.Item>
				<Form.Item>
					<CustomSearchableSelect
						searchTask={searchDrivers}
						defaultOptions={drivers}
						isLoading={isLoadingDrivers}
						labelIndex='name'
						valueIndex='id'
						title='Driver'
						placeholder='Select Driver'
						customLabel={renderCustomUserLabel}
						onChange={option => setDriver(option)}
						onClear={() => setDriver(null)}
						allowClear={true}
						value={driver}
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<Collapse style={{ marginTop: 24 }}>
					<Panel header='Create New Helper'>
						<TextInput
							title='Helper Name'
							value={helperName}
							onChange={e => {
								let text = e.target.value.toLowerCase()
								text = text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
								setHelperName(text)
							}}
						/>
						<div style={{ marginTop: 24 }} />
						<TextInput
							title='Email'
							value={helperEmail}
							onChange={e => {
								setHelperEmail(e.target.value)
							}}
						/>
						<div style={{ marginTop: 24 }} />
						<CustomPhoneInput
							title='Phone Number'
							value={helperPhone}
							onChange={setHelperPhone}
						/>
						<Button
							type='primary'
							onClick={createHelper}
							loading={isCreatingNewHelper}
							disabled={!isHelperInputValid()}
							style={{ alignSelf: 'flex-start', marginTop: 24 }}
							icon={<PlusOutlined />}
						>
							Create
						</Button>
					</Panel>
				</Collapse>
			</div>
		</Modal>
	)
}

export default AssignHelperModal
