import { Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard'
import styles from './CopyToClipboard.module.css'

const CopyToClipboard = ({
	style,
	className,
	withTooltip,
	text,
	onCopy,
	children
}) => {
	const [hasCopied, setHasCopied] = useState(false)

	useEffect(() => {
		if (!hasCopied) {
			return
		}
		setTimeout(() => setHasCopied(false), 1500)
	}, [hasCopied])

	const handleCopy = () => {
		setHasCopied(true)
		onCopy()
	}

	return (
		<ReactCopyToClipboard text={text} onCopy={handleCopy}>
			<Tooltip trigger={withTooltip ? ['hover', 'focus'] : []} title={hasCopied ? 'Copied' : 'Copy'}>
				{
					children ||
					<img
						src='/img/copy.svg'
						style={style} className={`${styles.icon} ${className}`}
						onClick={e => e.stopPropagation()}
					/>
				}
			</Tooltip>
		</ReactCopyToClipboard>
	)
}

CopyToClipboard.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
	withTooltip: PropTypes.bool,
	text: PropTypes.string,
	onCopy: PropTypes.func
}

CopyToClipboard.defaultProps = {
	withTooltip: true,
	text: '',
	onCopy: () => {}
}

export default CopyToClipboard
