import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { DeliveryPartners } from '../../services/api/delivery-partner'

const DeliveryAreaSelectBlueEx = ({ deliveryPartnerId, title, error, value, onChange }) => {
	const [isLoadingCities, setIsLoadingCities] = useState(false)
	const [isLoadingServiceTypes, setIsLoadingServiceTypes] = useState(false)
	const [cities, setCities] = useState([])
	const [serviceTypes, setServiceTypes] = useState([])

	useEffect(() => {
		if (deliveryPartnerId) {
			getCities(deliveryPartnerId)
			getServiceTypes(deliveryPartnerId)
		}
	}, [deliveryPartnerId])

	const getCities = async deliveryPartnerId => {
		setIsLoadingCities(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryCities(deliveryPartnerId)
			const cityOptions = data?.map(city => ({
				...city,
				value: city.CITY_CODE,
				label: city.CITY_NAME
			})) || []
			setCities(cityOptions)
		} catch (e) {
			onChange({ ...value, city: null })
			setCities([])
			notification.error({
				message: 'Unable To Get Cities',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingCities(false)
		}
	}

	const getServiceTypes = async deliveryPartnerId => {
		setIsLoadingServiceTypes(true)
		try {
			const { data } = await DeliveryPartners.getServiceTypes(deliveryPartnerId)
			const serviceTypeOptions = data?.map(serviceType => ({
				...serviceType,
				value: serviceType.service_code,
				label: serviceType.service_name
			})) || []
			setServiceTypes(serviceTypeOptions)
		} catch (e) {
			onChange({ ...value, serviceType: null })
			setServiceTypes([])
			notification.error({
				message: 'Unable To Get Service Types',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingServiceTypes(false)
		}
	}

	const onCityChange = (_, option) => {
		onChange({
			...value,
			city: option ? { value: option.value, label: option.label } : null
		})
	}

	const onServiceTypeChange = (_, option) => {
		onChange({
			...value,
			serviceType: option ? { value: option.value, label: option.label } : null
		})
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'city',
					placeholder: 'Destination City',
					loading: isLoadingCities,
					options: cities,
					value: value?.city || null,
					onChange: onCityChange,
					onClear: () => onCityChange(),
					style: { width: '50%' }
				},
				{
					id: 'serviceType',
					placeholder: 'Service Type',
					loading: isLoadingServiceTypes,
					options: serviceTypes,
					value: value?.serviceType || null,
					onChange: onServiceTypeChange,
					onClear: () => onServiceTypeChange(),
					style: { width: '50%' }
				}
			]}
		/>
	)
}

DeliveryAreaSelectBlueEx.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectBlueEx.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectBlueEx
