import { Select, Spin, Empty } from 'antd'
import styles from './CustomSearchableSelect.module.css'
require('./CustomSearchableSelect.less')
import { useState, useMemo, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select

const CustomSearchableSelect = ({
	style,
	value,
	onChange = () => {},
	onChangeData = () => {},
	onClear,
	placeholder,
	searchTask, // Should be a function that takes in a search constraint parameter and returns a Promise that resolves to an array of results
	labelIndex, // The field that should be the label when creating options asynchronously
	valueIndex, // The field that should be the value when creating options asynchronously
	descriptionIndex = -1, // The field that should be the description value when creating options
	disabledOptions = {}, // Map of options to disable.
	defaultOptions,
	dropdownConfig,
	isLoading,
	disabled,
	unclipOptions = false, // If true, allows word wrapping.
	title,
	showSearch = true,
	labelInValue = true,
	allowClear = false,
	customLabel, // A function in the form: (data) => {}, that can be used to formulate a label for the option.
	customDescription, // A function in the form: (data) => {}, that can be used to formulate a description for the option.
	popupContainer,
	notFoundContent,
	className = ''
}) => {
	const [loading, setLoading] = useState(isLoading ? isLoading : false)
	const [options, setOptions] = useState([])
	const [searchTerm, setSearchTerm] = useState()
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const selectRef = useRef(null)

	useEffect(() => {
		if (!notFoundContent) {
			return
		}
		const updateDropdownState = ({ target }) => !(target.closest('.ant-select') || target.closest('.ant-select-dropdown'))
			&& setIsDropdownOpen(false)
		window.addEventListener('click', updateDropdownState)
		return () => window.removeEventListener('click', updateDropdownState)
	}, [])

	useEffect(() => {
		setLoading(isLoading)
	}, [isLoading])

	useEffect(() => {
		if (defaultOptions) {
			const options = mapOptions(defaultOptions)
			setOptions(options)
		}
	}, [defaultOptions])

	const fetchRef = useRef(0)
	const debounceSearch = useMemo(() => {
		const loadOptions = searchParam => {
			fetchRef.current += 1
			const fetchId = fetchRef.current
			setOptions([])
			setLoading(true)
			setSearchTerm(searchParam)
			searchTask(searchParam)
				.then(response => {
					if (fetchId !== fetchRef.current) {
						return
					}
					const { data } = response
					const results = Array.isArray(data) ? data : data.results
					if (results.length) {
						const newOptions = mapOptions(results)
						setOptions(newOptions)
					} else {
						setOptions([])
					}
				})
				.finally(() => setLoading(false))
		}
		return debounce(loadOptions, 800)
	})

	const mapOptions = data => {
		return data.map(result => {
			let label = result[labelIndex]
			if (customLabel) {
				label = customLabel(result)
			}
			let value = result[valueIndex]
			let description = result[descriptionIndex]
			if (customDescription) {
				description = customDescription(result)
			}
			return {
				label,
				value,
				data: result,
				disabled: disabledOptions[value],
				description
			}
		})
	}

	const handleChange = value => {
		if (Object(value) === value) {
			onChange({ ...value, data: options.find((option) => option.value === value?.value)?.data })
		} else {
			onChange(value)
		}
		const option = options.find(option => value && option.value === value.value)
		if (option) {
			onChangeData(option.data)
		}
		setIsDropdownOpen(false)
		selectRef.current.blur()
	}

	const renderDropdown = () => {
		return (
			<div className={styles.customSearchableSelectDropdown}>
				{
					loading &&
					<div className={styles.customSearchableSelectDropdownSpinner}>
						<Spin size='small' />
					</div>
				}
				{!loading && !options.length && notFoundContent?.(setIsDropdownOpen, searchTerm)}
				{
					options.map((option) => {
						return (
							<div
								key={option.value}
								className={`${styles.customSearchableSelectDropdownOption} 
									${value?.value === option.value && styles.customSearchableSelectDropdownOptionActive}`}
								onClick={() => {
									handleChange(option)
								}}
							>
								{
									dropdownConfig.map((property) => <p key={property}>{option.data[property]}</p>)
								}
							</div>
						)
					})
				}
			</div>
		)
	}

	return (
		<div className='custom-searchable-select'>
			{
				title ?
					<div className={`custom-select-container ${title && styles.titleVisible}`} style={style}>
						{
							isLoading &&
						<div style={{ position: 'absolute', right: 18, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
						</div>
						}
						<h4 className={styles.title}>{title}</h4>
						<Select
							allowClear={allowClear}
							showSearch={showSearch}
							notFoundContent={
								loading ? <Spin size='small' /> :
									notFoundContent?.() || <Empty />
							}
							labelInValue={labelInValue}
							filterOption={false}
							placeholder={placeholder}
							className={`custom-select-small ${title && 'searchable-select-title-visible'} ${className}`}
							value={value}
							showArrow={false}
							onSearch={showSearch ? debounceSearch : undefined}
							onChange={handleChange}
							onClear={onClear}
							disabled={disabled}
							getPopupContainer={() => popupContainer ? popupContainer() : document.body}
							dropdownRender={dropdownConfig ? renderDropdown : null}
							{...(notFoundContent ? { open: isDropdownOpen } : {})}
							onFocus={() => setIsDropdownOpen(true)}
							ref={selectRef}
						>
							{
								options.map(option => {
									return (
										<Option
											className={unclipOptions ? 'full-custom-option' : ''}
											key={option.value}
											value={option.value}
											disabled={disabledOptions[option.value]}
										>
											<div className='custom-option'>
												<span className='custom-option-value'>{option.label}</span>
												<div className='custom-option-description'>{option.description}</div>
											</div>
										</Option>
									)
								})
							}
						</Select>
					</div> :
					<Select
						allowClear={allowClear}
						showSearch
						notFoundContent={
							loading ? <Spin size='small' /> :
								notFoundContent?.() || <Empty />
						}
						labelInValue={labelInValue}
						filterOption={false}
						options={options}
						placeholder={placeholder}
						className={`custom-select ${className}`}
						value={value}
						showArrow={false}
						onSearch={showSearch ? debounceSearch : undefined}
						onChange={handleChange}
						onClear={onClear}
						disabled={disabled}
						style={style}
						getPopupContainer={() => popupContainer ? popupContainer() : document.body}
						dropdownRender={dropdownConfig ? renderDropdown : null}
						{...(notFoundContent ? { open: isDropdownOpen } : {})}
						onFocus={() => setIsDropdownOpen(true)}
						ref={selectRef}
					>
						{
							options.map(option => {
								return (
									<Option
										className={unclipOptions ? 'full-custom-option' : ''}
										key={option.value}
										value={option.value}
										disabled={disabledOptions[option.value]}
									>{option.label}
									</Option>
								)
							})
						}
					</Select>
			}
		</div>
	)
}

CustomSearchableSelect.defaultProps = {
	defaultOptions: []
}

export default CustomSearchableSelect
