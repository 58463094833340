import styles from './DeliveryPlans.module.css'
require('./DeliveryPlans.less')
import Page from '../../components/page'
import { Layout, Collapse, Empty, Skeleton, Row, Tooltip, Popover, Spin, Dropdown, Menu, Avatar, notification, Checkbox, Modal, Badge, Tabs, Button } from 'antd'
import Map from '../../components/map'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { fetchDeliveryPlans, updateDeliveryPlanLoadState, fetchDeliveryPlanDetails, updateDeliveryPlanRoutes, fetchDeliveryPlanItemDetails, assignVehicleToDeliveryPlanItem, updateVehicleAssignmentRequestStatus, updateDeliveryPlan, updateDeliveryPlanItem } from '../../store/delivery-plans/actions'
import { fetchSalesOrderDetails } from '../../store/sales-orders/actions'
import moment from 'moment'
import Table from '../../components/table'
import Link from 'next/link'
import { convertEnumToString, getDistanceFromLatLonInMeters, getFormattedCurrency, removeURLParameter } from '../../utils'
import { CarOutlined, CloseOutlined, MoreOutlined, UserOutlined, ReloadOutlined, EditTwoTone, LoadingOutlined, SyncOutlined, PlusOutlined, DownOutlined, EyeOutlined, EyeInvisibleOutlined, SettingOutlined, SaveOutlined, InfoCircleFilled, ArrowRightOutlined } from '@ant-design/icons'
import ColorHash from 'color-hash'
import CreateDeliveryPlanModal from '../../components/create-delivery-plan-modal'
import EditDeliveryPlanModal from '../../components/edit-delivery-plan-modal'
import AssignDriverModal from '../../components/assign-driver-modal'
import AssignHelperModal from '../../components/assign-helper-modal'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import useSearchParams from '../../hooks/useSearchParams'
import { DeliveryPlans } from '../../services/api/delivery-plans'
import Splitter from '../../components/splitter'
import ExpandButton from '../../components/expand-button'
import { Locations, Vehicles } from '../../services/api/firebase'
import { Locations as LocationsApi } from '../../services/api/locations'
import { Routing } from '../../services/api/routing'
import AddRoutePointsModal from '../../components/add-route-points-modal'
import { usePrevious } from '../../hooks/usePrevious'
import { claimsRoles, deliveryPlanItemStatuses, localStorageKeys, orderStatuses, paths, vehicleRequestStatuses } from '../../utils/constants'
import AssignVehicleModal from '../../components/assign-vehicle-modal'
import { VehicleMarker } from '../../components/fleet-map-view'
import StartLocationModal from '../../components/start-location-modal'
import { getVehicleTypeLabel, VEHICLE_UNAVAILABLE_STATUS } from '../../utils/vehicles'
import { ROUTE_STATUS_COLORS } from './[id]'
import HeaderTab from '../../components/header-tab'
import { DELIVERY_PLAN_CANCELLED_STATUS, DELIVERY_PLAN_DELIVERED_STATUS, DELIVERY_PLAN_PENDING_STATUS, DELIVERY_PLAN_SCHEDULED_STATUS, DELIVERY_PLAN_STATUS_COLORS, getDeliveryPlanStatusLabel, statusMenuItems } from '../../utils/delivery-plans'
import InternalNotesPopover from '../../components/internal-notes-popover'
import AssignTransportVendorModal from '../../components/assign-transport-vendor-modal'
import DeliveryPlanPreferencesModal from '../../components/delivery-plan-preferences-modal'
import CustomEmptySecondary from '../../components/custom-empty-secondary'
import TextInput from '../../components/text-input'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import MapLayersControl from '../../components/map-layers-control'

const { Content, Sider } = Layout
const { Panel } = Collapse

export const VIEW_MODE = {
	LIST: 'list',
	MAP: 'map'
}

const tabs = [
	{
		title: 'Pending',
		status: DELIVERY_PLAN_PENDING_STATUS,
		path: `/delivery-plans?status=${DELIVERY_PLAN_PENDING_STATUS.toLowerCase()}&view=${VIEW_MODE.LIST}`
	},
	{
		title: 'Scheduled',
		status: DELIVERY_PLAN_SCHEDULED_STATUS,
		path: `/delivery-plans?status=${DELIVERY_PLAN_SCHEDULED_STATUS.toLowerCase()}&view=${VIEW_MODE.LIST}`
	},
	{
		title: 'Completed',
		status: DELIVERY_PLAN_DELIVERED_STATUS,
		path: `/delivery-plans?status=${DELIVERY_PLAN_DELIVERED_STATUS.toLowerCase()}&view=${VIEW_MODE.LIST}`
	},
	{
		title: 'Cancelled',
		status: DELIVERY_PLAN_CANCELLED_STATUS,
		path: `/delivery-plans?status=${DELIVERY_PLAN_CANCELLED_STATUS.toLowerCase()}&view=${VIEW_MODE.LIST}`
	}
]

const deliveryPlanColumns = [
	{
		title: 'Date',
		key: 'deliveryDate',
		render: deliveryPlan => {
			return moment(deliveryPlan.deliveryDate).format('MMM D, YYYY')
		}
	},
	{
		title: 'Delivery Plan #',
		key: 'deliveryPlanID',
		render: deliveryPlan => {
			const { loadingDeliveryPlans } = useSelector(state => state.deliveryPlansReducer)
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Link href={`/delivery-plans/${deliveryPlan.id}`}>
						<a className={styles.link}>{`DP-${deliveryPlan.shortId}`}</a>
					</Link>
					{
						loadingDeliveryPlans[deliveryPlan.id] ?
							<Tooltip title='Generating delivery plan...'>
								<Spin
									style={{ marginLeft: 12 }}
									indicator={<LoadingOutlined />}
								/>
							</Tooltip> : null
					}
				</div>
			)
		}
	},
	{
		title: 'Order Count',
		key: 'salesOrderCount',
		render: deliveryPlan => {
			if (deliveryPlan.status === DELIVERY_PLAN_CANCELLED_STATUS) {
				return deliveryPlan.deliveryPlanItems.reduce((count, item) => {
					return count + (item.route.length ? item.route.length - 1 : 0)
				}, 0)
			}
			return deliveryPlan._count.salesOrders
		}
	},
	{
		title: 'Status',
		key: 'status',
		render: deliveryPlan => {
			return <span className={styles.status}>{getDeliveryPlanStatusLabel(deliveryPlan.status)}</span>
		}
	}
]

const colorHash = new ColorHash()

// Object used to hold references to unsubscribe functions.
const cleanUp = {}

const DeliveryPlansPage = () => {
	const router = useRouter()
	const dispatch = useDispatch()
	const {
		totalCount,
		page,
		deliveryPlans: allDeliveryPlans,
		pendingDeliveryPlans,
		approvedDeliveryPlans,
		enRouteDeliveryPlans,
		scheduledDeliveryPlans,
		deliveredDeliveryPlans,
		cancelledDeliveryPlans,
		loadingDeliveryPlans,
		deliveryPlanDetails
	} = useSelector(state => state.deliveryPlansReducer)
	const { companyDetails, permissions } = useSelector(state => state.authReducer)
	const { status, view, createDeliveryPlan, createDeliveryPlanDate, expandedId } = router.query
	const [currentStatus, setCurrentStatus] = useState(DELIVERY_PLAN_PENDING_STATUS)
	const [isLoading, setIsLoading] = useState(false)
	const [expandedDeliveryPlanId, setExpandedDeliveryPlanId] = useState(null)
	const [map, setMap] = useState()
	const [maps, setMaps] = useState()
	const [deliveryPlanItemPolylines, setDeliveryPlanItemPolylines] = useState({})
	const [returnRoutePolylines, setReturnRoutePolylines] = useState({})
	const [deliveryPlanItemPolylineBounds, setDeliveryPlanItemPolylineBounds] = useState({})
	const [deliveryPlanItemVisibilities, setDeliveryPlanItemVisibilities] = useState({})
	const [userVisibilities, setUserVisibilities] = useState({})
	const [center, setCenter] = useState()
	const [selectedDeliveryPlanItem, setSelectedDeliveryPlanItem] = useState()
	const [selectedVehicleType, setSelectedVehicleType] = useState()
	const [hoveredPointId, setHoveredPointId] = useState()
	const [isCreateDeliveryPlanModalVisible, setIsCreateDeliveryPlanModalVisible] = useState(false)
	const [isAddRoutePointsModalVisible, setIsAddRoutePointsModalVisible] = useState(false)
	const [isAssignVehicleModalVisible, setIsAssignVehicleModalVisible] = useState(false)
	const [isAssignDriverModalVisible, setIsAssignDriverModalVisible] = useState(false)
	const [isAssignHelperModalVisible, setIsAssignHelperModalVisible] = useState(false)
	const [isAssignTransportVendorModalVisible, setIsAssignTransportVendorModalVisible] = useState(false)
	const [selectedDeliveryPlanId, setSelectedDeliveryPlanId] = useState(null)
	const [editableDeliveryPlan, setEditableDeliveryPlan] = useState(null)
	const [isUpdatingRoutes, setIsUpdatingRoutes] = useState(false)
	const [isUpdating, setIsUpdating] = useState(false)
	const { searchParams, isSearchApplied } = useSearchParams()
	const [isSplitViewEnabled, setIsSplitViewEnabled] = useState(false)
	const [salesOrderDialog, setSalesOrderDialog] = useState()
	const [salesOrderDialogZIndex, setSalesOrderDialogZIndex] = useState(0)
	const [lastClickedSalesOrderDialog, setLastClickedSalesOrderDialog] = useState()
	const [routeDialog, setRouteDialog] = useState()
	const [expandableDeliveryPlan, setExpandableDeliveryPlan] = useState(null)
	const [addedSalesOrders, setAddedSalesOrders] = useState([])
	const [loadingDeliveryPlanId, setLoadingDeliveryPlanId] = useState(null)
	const [loadingVehiclesDeliveryPlanId, setLoadingVehiclesDeliveryPlanId] = useState(null)
	const [hoveredRouteId, setHoveredRouteId] = useState(null)
	const [userLocations, setUserLocations] = useState({})
	const [userLocationsPolylines, setUserLocationsPolylines] = useState({})
	const [deliveryPlanDate, setDeliveryPlanDate] = useState(null)
	const [showUnassigned, setShowUnassigned] = useState(false)
	const [assignedVehicleIds, setAssignedVehicleIds] = useState([])
	const [vehiclesMap, setVehiclesMap] = useState({})
	const [isFilterControlVisible, setIsFilterControlVisible] = useState(false)
	const [isDeliveryRouteChecked, setIsDeliveryRouteChecked] = useState(true)
	const [isReturnRouteChecked, setIsReturnRouteChecked] = useState(false)
	const [isAssignedVehiclesChecked, setIsAssignedVehiclesChecked] = useState(true)
	const [isUnassignedVehiclesChecked, setIsUnassignedVehiclesChecked] = useState(true)
	const [clickedMarkers, setClickedMarkers] = useState({})
	const [isPickUpLocationModalVisible, setIsPickUpLocationModalVisible] = useState(false)
	const [disableVehicleStatusActionButtons, setDisableVehicleStatusActionButtons] = useState([])
	const [expandedTrips, setExpandedTrips] = useState([])
	const [expandedPlanItems, setExpandedPlanItems] = useState([])
	const [trafficLayer, setTrafficLayer] = useState(null)
	const [nonGeneratedSalesOrders, setNonGeneratedSalesOrders] = useState([])
	const [isDeliveryPlanPreferencesModalVisible, setIsDeliveryPlanPreferencesModalVisible] = useState(false)
	const companyWiseTrafficLayerKey = useMemo(() => `${companyDetails?.id}_${localStorageKeys.IS_MAP_TRAFFIC_LAYER_ENABLED}`, [companyDetails])
	const [isTrafficLayerEnabled, setIsTrafficLayerEnabled] = useLocalStorage(companyWiseTrafficLayerKey, true)
	const cancellationReasonInputRef = useRef('')
	const confirmModalRef = useRef('')
	const expandedViewRef = useRef()
	const isListMode = view === VIEW_MODE.LIST

	useEffect(() => {
		let unsubscribeVehicles
		if (companyDetails) {
			if (companyDetails.companyId) {
				async () => {
					unsubscribeVehicles = Vehicles.listenToVehicles(companyDetails.companyId,
						(createdVehicle) => {
							setVehiclesMap(previousVehiclesMap => ({ ...previousVehiclesMap, [createdVehicle.id]: createdVehicle }))
						},
						(updatedVehicle) => {
							setVehiclesMap(previousVehiclesMap => ({ ...previousVehiclesMap, [updatedVehicle.id]: updatedVehicle }))
						}
					)
				}
			}
		}
		return () => {
			if (unsubscribeVehicles) {
				unsubscribeVehicles()
			}
		}
	}, [companyDetails])

	useEffect(() => {
		if (expandedDeliveryPlanId) {
			const deliveryPlan = allDeliveryPlans.find(dp => dp.id === expandedDeliveryPlanId)
			if (deliveryPlan) {
				const vehicleIds = []
				const deliveryPlanItemVisibilities = {}
				deliveryPlan.deliveryPlanItems?.forEach((item) => {
					if (item.vehicleId) {
						vehicleIds.push(item.vehicleId)
					}
					deliveryPlanItemVisibilities[item.id] = true
				})
				setDeliveryPlanItemVisibilities(deliveryPlanItemVisibilities)
				setAssignedVehicleIds(vehicleIds)
				setIsAssignedVehiclesChecked(false)
				setIsUnassignedVehiclesChecked(false)
			}
		} else {
			setDeliveryPlanItemVisibilities({})
			setAssignedVehicleIds([])
			setIsAssignedVehiclesChecked(true)
			setIsUnassignedVehiclesChecked(true)
			setNonGeneratedSalesOrders([])
		}
	}, [expandedDeliveryPlanId])

	useEffect(() => {
		return () => {
			Object.keys(cleanUp).forEach(cleanUpFunctionName => {
				cleanUp[cleanUpFunctionName]()
			})
		}
	}, [])

	useEffect(() => {
		if (!companyDetails || !permissions || !permissions.viewDeliveryPlans || companyDetails?.isDeliveryPlanEnabled === false) {
			return
		}
		let deliveryPlanStatus
		switch (status?.toUpperCase()) {
			case DELIVERY_PLAN_PENDING_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_PENDING_STATUS
				break
			case DELIVERY_PLAN_SCHEDULED_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_SCHEDULED_STATUS
				break
			case DELIVERY_PLAN_DELIVERED_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_DELIVERED_STATUS
				break
			case DELIVERY_PLAN_CANCELLED_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_CANCELLED_STATUS
				break
			default:
				deliveryPlanStatus = undefined
				break
		}
		setCurrentStatus(deliveryPlanStatus)
		getDeliveryPlans(deliveryPlanStatus)
	}, [
		status,
		searchParams,
		companyDetails,
		permissions
	])

	useEffect(() => {
		setIsCreateDeliveryPlanModalVisible(createDeliveryPlan === 'true')
	}, [createDeliveryPlan])

	useEffect(() => {
		if (createDeliveryPlanDate) {
			const dateParts = createDeliveryPlanDate.split('-')
			const date = new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2])
			setDeliveryPlanDate(date)
			setShowUnassigned(true)
		}
	}, [createDeliveryPlanDate])

	useEffect(() => {
		if (expandableDeliveryPlan) {
			getDeliveryPlanDetails(expandableDeliveryPlan.id)
		}
	}, [expandableDeliveryPlan])

	useEffect(() => {
		if (expandedDeliveryPlanId) {
			drawRoutes(expandedDeliveryPlanId)
		}
	}, [
		status,
		pendingDeliveryPlans,
		approvedDeliveryPlans,
		enRouteDeliveryPlans,
		scheduledDeliveryPlans,
		deliveredDeliveryPlans,
		cancelledDeliveryPlans,
		allDeliveryPlans
	])

	const previousLoadingDeliveryPlan = usePrevious(loadingDeliveryPlans[expandedDeliveryPlanId])
	useEffect(() => {
		/**
		 * In the edge case where a delivery plan is expanded and the delivery plan is updated,
		 * we need to re-draw the routes.
		 */
		if (previousLoadingDeliveryPlan === true && loadingDeliveryPlans[expandedDeliveryPlanId] === false) {
			drawRoutes(expandedDeliveryPlanId)
		}
	}, [loadingDeliveryPlans[expandedDeliveryPlanId]])

	const previousLoadingDeliveryPlanId = usePrevious(loadingDeliveryPlanId)
	useEffect(() => {
		if (loadingDeliveryPlanId && previousLoadingDeliveryPlanId !== loadingDeliveryPlanId) {
			getDeliveryPlanDetails(loadingDeliveryPlanId)
			setLoadingDeliveryPlanId(null)
		}
	}, [loadingDeliveryPlanId])

	useEffect(() => {
		expandDeliveryPlanItem(expandedId)
		setIsDeliveryRouteChecked(true)
	}, [expandedId])

	const previousMaps = usePrevious(maps)
	useEffect(() => {
		if (!previousMaps && maps) {
			if (expandedId) {
				expandDeliveryPlanItem(expandedId)
			}
		}
	}, [maps])

	useEffect(() => {
		const ids = Object.keys(deliveryPlanItemVisibilities)
		const newUserVisibilities = {}
		for (const id of ids) {
			const polyline = deliveryPlanItemPolylines[id]
			const { trip } = Object.values(userLocations).find(item => item.trip.id === id) || {}
			const uid = trip?.driverId || trip?.helperId
			if (polyline) {
				if (deliveryPlanItemVisibilities[id] === true || deliveryPlanItemVisibilities[id] === undefined) {
					polyline.setVisible(true)
					if (trip) {
						newUserVisibilities[uid] = true
						userLocationsPolylines[uid]?.setVisible(true)
					}
				} else {
					polyline.setVisible(false)
					if (trip) {
						newUserVisibilities[uid] = false
						userLocationsPolylines[uid]?.setVisible(false)
					}
				}
			}
		}
		setUserVisibilities(newUserVisibilities)
	}, [deliveryPlanItemVisibilities])

	useEffect(() => {
		for (const item of expandedPlanItems) {
			const polyline = deliveryPlanItemPolylines[item.id]
			if (polyline) {
				polyline.setVisible(isDeliveryRouteChecked)
			}
		}
	}, [isDeliveryRouteChecked])

	useEffect(() => {
		for (const item of expandedPlanItems) {
			const polyline = returnRoutePolylines[item.id]
			if (polyline) {
				polyline.setVisible(isReturnRouteChecked)
			}
		}
	}, [isReturnRouteChecked])

	const previousEditableDeliveryPlan = usePrevious(editableDeliveryPlan)
	useEffect(() => {
		if (previousEditableDeliveryPlan && editableDeliveryPlan) {
			const routes = {}
			const previousDeliveryPlanItems = previousEditableDeliveryPlan.deliveryPlanItems || []
			const deliveryPlanItems = editableDeliveryPlan.deliveryPlanItems || []
			if (JSON.stringify(previousDeliveryPlanItems) !== JSON.stringify(deliveryPlanItems)) {
				deliveryPlanItems.forEach(deliveryPlanItem => {
					const {
						id,
						route
					} = deliveryPlanItem
					const ids = route.slice(1).map(point => point.id)
					routes[id] = ids
				})
				getVehicleRecommendations(routes)
			}
		}
	}, [editableDeliveryPlan])

	useEffect(() => {
		if (trafficLayer) {
			if (isTrafficLayerEnabled) {
				trafficLayer.setMap(map)
			} else {
				trafficLayer.setMap(null)
			}
		}
	}, [trafficLayer, isTrafficLayerEnabled])

	const setNonGeneratedOrders = (deliveryPlan) => {
		const deliveryPlanItemOrderIds = []
		for (const item of deliveryPlan.deliveryPlanItems || []) {
			for (const order of item.salesOrders) {
				deliveryPlanItemOrderIds.push(order.id)
			}
		}
		setNonGeneratedSalesOrders(deliveryPlan.salesOrders.filter(o => !deliveryPlanItemOrderIds.includes(o.id)))
	}

	const getVehicleRecommendations = async (routes) => {
		try {
			if (editableDeliveryPlan) {
				setLoadingVehiclesDeliveryPlanId(editableDeliveryPlan.id)
				const response = await Routing.fetchVehicleRecommendation(routes)
				const { routeVehicleTypes } = response.data
				const newEditableDeliveryPlan = { ...editableDeliveryPlan }
				const deliveryPlanItems = newEditableDeliveryPlan.deliveryPlanItems || []
				deliveryPlanItems.forEach(deliveryPlanItem => {
					const vehicles = routeVehicleTypes[deliveryPlanItem.id]
					if (vehicles) {
						if (vehicles.length > 1) {
							deliveryPlanItem.recommendedVehicle = null
							deliveryPlanItem.error = 'Too many orders in this route.'
						} else if (vehicles.length === 1) {
							deliveryPlanItem.recommendedVehicle = vehicles[0]
						} else {
							deliveryPlanItem.recommendedVehicle = null
							deliveryPlanItem.error = 'Could not find a vehicle that can fit this route.'
						}
					}
				})
				setEditableDeliveryPlan(newEditableDeliveryPlan)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to get vehicle recommendations.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setLoadingVehiclesDeliveryPlanId(null)
		}
	}

	const getDeliveryPlanDetails = async (deliveryPlanId) => {
		try {
			const deliveryPlan = await dispatch(fetchDeliveryPlanDetails(deliveryPlanId, searchParams.startLocationId))
			setNonGeneratedOrders(deliveryPlan)
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Delivery Plan Details.',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		}
	}

	const getDeliveryPlans = async (deliveryPlanStatus) => {
		setIsLoading(true)
		await dispatch(fetchDeliveryPlans(deliveryPlanStatus, 0, searchParams))
		setIsLoading(false)
	}

	const filterVehicle = (vehicle) => {
		if (!vehicle.tracker || !vehicle.tracker.position) {
			return false
		}
		if (isAssignedVehiclesChecked && isUnassignedVehiclesChecked) {
			return true
		}
		if (isAssignedVehiclesChecked) {
			return !!vehicle.currentTripId
		}
		if (isUnassignedVehiclesChecked) {
			return !vehicle.currentTripId
		}
		if (assignedVehicleIds.includes(vehicle.id)) {
			return true
		}
		return false
	}
	const getSalesOrders = (deliveryPlanId) => {
		const deliveryPlan = deliveryPlanDetails[deliveryPlanId]
		const salesOrders = {}
		if (deliveryPlan) {
			const deliveryPlanSalesOrders = [...deliveryPlan.salesOrders, ...addedSalesOrders]
			deliveryPlanSalesOrders.forEach(salesOrder => {
				salesOrders[salesOrder.id] = salesOrder
			})
		}
		return salesOrders
	}

	const setListView = () => {
		router.push(tabs[0].path)
	}

	const setMapView = () => {
		router.push('/delivery-plans')
	}

	const onMapsLoaded = (map, maps) => {
		setMap(map)
		setMaps(maps)
		map.data.loadGeoJson('https://storage.googleapis.com/nuport-dev.appspot.com/public/divisions.geojson')
		map.data.setStyle({
			strokeWeight: 1,
			strokeColor: '#278ea585',
			fillColor: 'transparent'
		})
		if (!trafficLayer) {
			setTrafficLayer(new maps.TrafficLayer())
		}
	}

	const showCreateDeliveryPlanModal = (visible) => {
		setIsCreateDeliveryPlanModalVisible(visible)
		let route = `/delivery-plans?createDeliveryPlan=${visible}`
		if (isListMode) {
			if (currentStatus) {
				route = `${route}&view=list&status=${currentStatus}`
			} else {
				route = `${route}&view=list`
			}
		}
		router.push(route)
	}

	const onExpandDeliveryItem = (key) => {
		const path = router.asPath
		if (key) {
			// Add key
			if (path.includes('expandedId=')) {
				const updatedPath = removeURLParameter(path, 'expandedId')
				if (updatedPath === paths.DELIVERY_PLANS) {
					router.push(`${paths.DELIVERY_PLANS}?expandedId=${key}`, undefined, { shallow: true })
				} else {
					router.push(`${updatedPath}&expandedId=${key}`, undefined, { shallow: true })
				}
			} else {
				if (path === paths.DELIVERY_PLANS) {
					router.push(`${paths.DELIVERY_PLANS}?expandedId=${key}`, undefined, { shallow: true })
				} else {
					router.push(`${path}&expandedId=${key}`, undefined, { shallow: true })
				}
			}
		} else {
			// Remove key
			const updatedPath = removeURLParameter(path, 'expandedId')
			router.push(updatedPath, undefined, { shallow: true })
		}
	}

	const expandDeliveryPlanItem = (id) => {
		drawRoutes(id)
		setExpandedDeliveryPlanId(id)
		setLoadingDeliveryPlanId(id)
		setUserLocations({})
		setSalesOrderDialog(null)
		setRouteDialog(null)
		if (editableDeliveryPlan) {
			setEditableDeliveryPlan(null)
		}
		if (id) {
			setTimeout(() => {
				if (expandedViewRef && expandedViewRef.current) {
					expandedViewRef.current.scrollIntoView({ block: 'center' })
				}
			}, 250)
		}
	}

	const sortItemsById = (a, b) => {
		if (a.id > b.id) {
			return 1
		} else if (a.id < b.id) {
			return -1
		} else {
			return 0
		}
	}

	const drawRoutes = (expandedId, deliveryPlan) => {
		if (!maps) {
			return
		}
		const polylines = Object.values(deliveryPlanItemPolylines)
		polylines.forEach(polyline => {
			polyline.setMap(null)
		})
		const bounds = new maps.LatLngBounds()
		const newDeliveryPlanPolylines = {}
		const newDeliveryPlanPolylineBounds = {}
		const deliveryPlans = deliveryPlan ? [deliveryPlan] : allDeliveryPlans
		const dp = deliveryPlans.find(deliveryPlan => expandedId === deliveryPlan.id)
		const trips = []
		if (dp) {
			dp.deliveryPlanItems?.forEach(async deliveryPlanItem => {
				const path = []
				const polylineBounds = new maps.LatLngBounds()
				deliveryPlanItem.route.map(point => {
					const latLng = {
						lat: point.lat,
						lng: point.lon
					}
					path.push(latLng)
					bounds.extend(latLng)
					polylineBounds.extend(latLng)
				})
				const polylineOptions = {
					geodesic: true,
					strokeOpacity: 1.0,
					strokeWeight: 4,
					strokeColor: '#4B5766'
				}
				if (deliveryPlanItem.directions && deliveryPlanItem.directions.routes && deliveryPlanItem.directions.routes.length > 0) {
					const routes = deliveryPlanItem.directions.routes[0]
					polylineOptions.path = maps.geometry.encoding.decodePath(routes.overview_polyline.points)
				} else {
					polylineOptions.path = path
				}
				if (deliveryPlanItem.driverId || deliveryPlanItem.helperId) {
					trips.push(deliveryPlanItem)
					polylineOptions.strokeColor = colorHash.hex(deliveryPlanItem.driverId || deliveryPlanItem.helperId)
				} else {
					polylineOptions.strokeColor = '#4B5766'
				}
				const polyline = new maps.Polyline(polylineOptions)
				maps.event.addListener(polyline, 'click', () => {
					setSelectedDeliveryPlanItem(deliveryPlanItem)
				})
				polyline.setMap(map)
				newDeliveryPlanPolylines[deliveryPlanItem.id] = polyline
				newDeliveryPlanPolylineBounds[deliveryPlanItem.id] = polylineBounds
			})
		}
		drawReturnRoutes(dp)

		setExpandedTrips(trips.sort(sortItemsById))

		if (Object.values(newDeliveryPlanPolylines).length > 0) {
			map.fitBounds(bounds)
		}
		setDeliveryPlanItemPolylines(newDeliveryPlanPolylines)
		setDeliveryPlanItemPolylineBounds(newDeliveryPlanPolylineBounds)
	}

	const drawReturnRoutes = (deliveryPlan) => {
		if (!maps) {
			return
		}
		Object.values(returnRoutePolylines).forEach(polyline => {
			polyline.setMap(null)
		})
		const newReturnRoutePolylines = {}
		setExpandedPlanItems(deliveryPlan?.deliveryPlanItems || [])
		deliveryPlan?.deliveryPlanItems?.forEach(async deliveryPlanItem => {
			const polylineOptions = {
				geodesic: true,
				strokeOpacity: 1.0,
				strokeWeight: 3,
				strokeColor: colorHash.hex(deliveryPlanItem.id)
			}
			const directions = deliveryPlanItem.returnDirections
			if (directions && directions.routes && directions.routes.length > 0) {
				const routes = directions.routes[0]
				polylineOptions.path = maps.geometry.encoding.decodePath(routes.overview_polyline.points)
			}
			const polyline = new maps.Polyline(polylineOptions)
			polyline.setVisible(isReturnRouteChecked)
			polyline.setMap(map)
			newReturnRoutePolylines[deliveryPlanItem.id] = polyline
		})

		setReturnRoutePolylines(newReturnRoutePolylines)
	}

	const listenToUserLocation = async trips => {
		const newUserLocationsPolylines = { ...userLocationsPolylines }
		const newUserVisibilities = { ...userVisibilities }
		Object.values(newUserLocationsPolylines).forEach(polyline => {
			polyline.setMap(null)
		})
		setUserLocations({})
		for (const trip of trips) {
			const uid = trip.driverId || trip.helperId
			const locations = await Locations.fetchUserLocationsOnce(uid, trip.id)
			const polylineOptions = {
				geodesic: true,
				strokeOpacity: 0,
				strokeWeight: 2,
				strokeColor: colorHash.hex(uid),
				icons: [{
					icon: {
						path: 'M 0,-1 0,1',
						strokeOpacity: 1,
						scale: 2
					},
					offset: '0',
					repeat: '10px'
				}],
				path: locations.map(data => new maps.LatLng(data.latitude, data.longitude))
			}
			if (locations.length > 0) {
				setUserLocations(previousUserLocations => {
					const newUserLocations = { ...previousUserLocations }
					newUserLocations[uid] = { location: locations[locations.length - 1], trip }
					return newUserLocations
				})
			}
			const polyline = new maps.Polyline(polylineOptions)
			polyline.setMap(map)
			newUserLocationsPolylines[uid] = polyline
			newUserVisibilities[uid] = true
			if (cleanUp[`${uid}-location`]) {
				cleanUp[`${uid}-location`]()
			}
			cleanUp[`${uid}-location`] = Locations.listenToUserLocation(uid, trip, (data, tripParam) => {
				const userId = tripParam.driverId || tripParam.helperId
				const polyline = newUserLocationsPolylines[userId]
				const path = polyline.getPath()
				path.push(new maps.LatLng(data.latitude, data.longitude))
				polyline.setPath(path)
				newUserLocationsPolylines[userId] = polyline
				setUserLocations(previousUserLocations => {
					const newUserLocations = { ...previousUserLocations }
					newUserLocations[userId] = { location: data, trip: tripParam }
					return newUserLocations
				})
				setUserLocationsPolylines(newUserLocationsPolylines)
			})
		}
		setUserVisibilities(newUserVisibilities)
		setUserLocationsPolylines(newUserLocationsPolylines)
	}

	useMemo(() => {
		listenToUserLocation(expandedTrips)
	}, [expandedTrips.map(item => item.id).join('_')])

	const onDriverAssigned = () => {
		drawRoutes(expandedDeliveryPlanId)
	}

	const onDeliveryPlanItemHovered = (deliveryPlanItemId, hovered) => {
		if (selectedDeliveryPlanItem) {
			const previousPolyline = deliveryPlanItemPolylines[selectedDeliveryPlanItem.id]
			if (previousPolyline) {
				previousPolyline.setOptions({ strokeWeight: 3, strokeColor: '#4B5766' })
			}
		}
		const polyline = deliveryPlanItemPolylines[deliveryPlanItemId]
		if (polyline) {
			if (hovered) {
				polyline.setOptions({ strokeWeight: 5, strokeColor: '#278EA5' })
				const polylineBounds = deliveryPlanItemPolylineBounds[deliveryPlanItemId]
				if (polylineBounds) {
					map.fitBounds(polylineBounds, { right: 280, bottom: 280, top: 10, left: 10 })
				}
			} else {
				polyline.setOptions({ strokeWeight: 3, strokeColor: '#4B5766' })
			}
		}
	}

	const onPageChanged = async (page) => {
		setIsLoading(true)
		await dispatch(fetchDeliveryPlans(currentStatus, page - 1, searchParams))
		setIsLoading(false)
	}

	const onRegenerateDeliveryPlan = async deliveryPlanId => {
		try {
			await DeliveryPlans.regenerateDeliveryPlan(deliveryPlanId)
			dispatch(updateDeliveryPlanLoadState(deliveryPlanId, true))
		} catch (e) {
			notification.error({
				message: 'Unable to regenerate delivery plan.',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const onRefreshDeliveryPlan = async deliveryPlanId => {
		try {
			await dispatch(fetchDeliveryPlanDetails(deliveryPlanId))
		} catch (e) {
			notification.error({
				message: 'Unable to refresh delivery plan.',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const getDeliveryPlanGroupings = (deliveryPlans = []) => {
		const groupings = deliveryPlans.reduce((acc, deliveryPlan) => {
			const date = moment(deliveryPlan.deliveryDate)
			const week = `${date.startOf('isoWeek').isoWeek()}`
			const yearWeekKey = `${date.startOf('isoWeek').year()}-${week.length > 1 ? week : `0${week}`}`
			if (!acc[yearWeekKey]) {
				acc[yearWeekKey] = []
			}
			acc[yearWeekKey].push(deliveryPlan)
			return acc
		}, {})
		const dateKeys = Object.keys(groupings)
		dateKeys.sort((a, b) => b.localeCompare(a))
		const deliveryPlanWeeklyGroupings = dateKeys.map(key => {
			return {
				dateKey: key,
				deliveryPlans: groupings[key]
			}
		})
		return deliveryPlanWeeklyGroupings
	}

	const getWeekHeading = (dateKey) => {
		const currentDate = new Date()
		const currentYear = moment(currentDate).year()
		const currentWeek = moment(currentDate).isoWeek()
		const [year, week] = dateKey.split('-').map(val => +val)
		const date = moment().year(year).isoWeek(week)
		if (currentYear === year && currentWeek === week) {
			return 'This Week'
		} else if (currentYear === year && currentWeek + 1 === week) {
			return 'Next Week'
		} else if (currentYear === year && currentWeek - 1 === week) {
			return 'Last Week'
		} else {
			return `${date.startOf('isoWeek').format('MMM Do')} - ${date.endOf('isoWeek').format('MMM Do')}`
		}
	}

	const getListStyle = (isDraggingOver) => {
		return isDraggingOver ? { background: '#278EA522' } : {}
	}

	const onDragEnd = ({ source, destination, draggableId }) => {
		if (!destination || source.droppableId === destination.droppableId) {
			return
		}
		const sourceItemIndex = editableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === source.droppableId)
		const destinationItemIndex = editableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === destination.droppableId)
		const sourceItem = editableDeliveryPlan.deliveryPlanItems[sourceItemIndex]
		const destinationItem = editableDeliveryPlan.deliveryPlanItems[destinationItemIndex]
		if (sourceItem && destinationItem) {
			if (sourceItem.startLocationId !== destinationItem.startLocationId) {
				Modal.confirm({
					title: 'Update Pick Up Location',
					content: 'Pick up locations will be updated for selected orders, are you sure you want to continue?',
					okText: 'Yes',
					cancelText: 'No',
					closable: true,
					onOk: () => handleRouteUpdate({
						sourceItemIndex,
						destinationItemIndex,
						draggableId
					})
				})
			} else {
				handleRouteUpdate({
					sourceItemIndex,
					destinationItemIndex,
					draggableId
				})
			}
		}
	}

	const handleRouteUpdate = ({ sourceItemIndex, destinationItemIndex, draggableId }) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlan }
		const sourceItem = editableDeliveryPlan.deliveryPlanItems[sourceItemIndex]
		const destinationItem = editableDeliveryPlan.deliveryPlanItems[destinationItemIndex]
		if (sourceItem && destinationItem) {
			const point = sourceItem.route.find(point => point.id === draggableId)
			sourceItem.route = sourceItem.route.filter(point => point.id !== draggableId)
			destinationItem.route.push(point)
			newEditableDeliveryPlan.deliveryPlanItems[sourceItemIndex] = sourceItem
			newEditableDeliveryPlan.deliveryPlanItems[destinationItemIndex] = destinationItem
		}
		setEditableDeliveryPlan(newEditableDeliveryPlan)
	}

	const onRemoveRoutePoint = (deliveryPlanItem, point) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlan }
		const deliveryPlanItemIndex = newEditableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === deliveryPlanItem.id)
		const newDeliveryPlanItem = { ...deliveryPlanItem }
		newDeliveryPlanItem.route = newDeliveryPlanItem.route.filter(p => p.id !== point.id)
		newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] = newDeliveryPlanItem
		setEditableDeliveryPlan(newEditableDeliveryPlan)
	}

	const addRoutePoints = (salesOrders) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlan }
		const deliveryPlanItemIndex = newEditableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === selectedDeliveryPlanItem.id)
		const newDeliveryPlanItem = { ...newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] }
		const route = newDeliveryPlanItem.route
		const firstPoint = route[0]
		const startLat = +firstPoint.lat
		const startLon = +firstPoint.lon
		const newRoute = []
		const newSalesOrders = []
		salesOrders.forEach(salesOrder => {
			const lat = +salesOrder.location.latitude
			const lon = +salesOrder.location.longitude
			const point = {
				distance: getDistanceFromLatLonInMeters(startLat, startLon, lat, lon),
				id: salesOrder.id,
				lat,
				lon
			}
			newRoute.push(point)
			const newSalesOrder = { ...salesOrder }
			newSalesOrder.pickUpLocationId = firstPoint.locationId
			newSalesOrder.pickUpLocation = {
				id: firstPoint.locationId,
				address: firstPoint.address,
				latitude: +firstPoint.lat,
				longitude: +firstPoint.lon
			}
			newSalesOrders.push(newSalesOrder)
		})
		newDeliveryPlanItem.route = [...route, ...newRoute]
		newEditableDeliveryPlan.salesOrders = [...newEditableDeliveryPlan.salesOrders, ...newSalesOrders]
		newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] = newDeliveryPlanItem
		setEditableDeliveryPlan(newEditableDeliveryPlan)
		setAddedSalesOrders(newSalesOrders)
	}

	const onAddNewRoute = (location) => {
		const newDeliveryPlanItem = {
			id: `new_${editableDeliveryPlan.deliveryPlanItems.length}_${location.id}`,
			route: [
				{
					id: `vehicle_${editableDeliveryPlan.deliveryPlanItems.length}_0-start`,
					address: location.address,
					locationId: location.id,
					lat: +location.latitude,
					lon: +location.longitude,
					distance: 0
				}
			]
		}
		const newEditableDeliveryPlan = { ...editableDeliveryPlan }
		newEditableDeliveryPlan.deliveryPlanItems.push(newDeliveryPlanItem)
		setEditableDeliveryPlan(newEditableDeliveryPlan)
	}

	const onEdit = (e, deliveryPlan) => {
		e.stopPropagation()
		const editableDeliveryPlan = JSON.parse(JSON.stringify(deliveryPlan))
		setEditableDeliveryPlan(editableDeliveryPlan)
		setIsSplitViewEnabled(true)
	}

	const onCancelEdit = (e) => {
		e.stopPropagation()
		setEditableDeliveryPlan(null)
		setAddedSalesOrders([])
	}

	const onExpandedViewClick = (deliveryPlan) => {
		if (expandableDeliveryPlan) {
			setExpandableDeliveryPlan(null)
			onExpandDeliveryItem(undefined)
		} else {
			setExpandableDeliveryPlan(deliveryPlan)
			if (!expandedDeliveryPlanId) {
				onExpandDeliveryItem(deliveryPlan.id)
			}
		}
	}

	const isExpandedView = () => !!expandableDeliveryPlan

	const onSaveRoute = async (e) => {
		e.stopPropagation()
		try {
			if (editableDeliveryPlan) {
				const deliveryPlanItems = editableDeliveryPlan.deliveryPlanItems
				const routes = {}
				const vehicles = {}
				deliveryPlanItems.forEach(deliveryPlanItem => {
					routes[deliveryPlanItem.id] = deliveryPlanItem.route
					vehicles[deliveryPlanItem.id] = deliveryPlanItem.recommendedVehicle?.value
				})
				setIsUpdatingRoutes(true)
				const payload = {
					routes,
					vehicles,
					salesOrderIds: addedSalesOrders.map(so => so.id)
				}
				const updatedDeliveryPlan = await dispatch(updateDeliveryPlanRoutes(editableDeliveryPlan.id, payload))
				if (isExpandedView()) {
					setExpandableDeliveryPlan(updatedDeliveryPlan)
				}
				drawRoutes(editableDeliveryPlan.id, updatedDeliveryPlan)
				setEditableDeliveryPlan(null)
			}
		} catch (err) {
			notification.error({
				message: 'Unable to reroute.',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingRoutes(false)
		}
	}

	const confirmVehicleAssignment = async (status, deliveryPlanItem) => {
		try {
			if (deliveryPlanItem) {
				setIsUpdating(true)
				const payload = {
					status
				}
				const requestId = deliveryPlanItem.requests[deliveryPlanItem.requests.length - 1].id
				await dispatch(updateVehicleAssignmentRequestStatus(requestId, payload))
				notification.success({
					message: 'Vehicle request status has been updated',
					description: 'Vehicle request status has been updated successfully!',
					placement: 'bottomLeft'
				})
			}
			setDisableVehicleStatusActionButtons([...disableVehicleStatusActionButtons, deliveryPlanItem.id])
		} catch (err) {
			notification.error({
				message: 'Unable to update request status for the vehicle.',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdating(false)
		}
	}

	const onUnassignVehicle = deliveryPlanItem => {
		Modal.confirm({
			title: 'Unassign Vehicle',
			content: 'Are you sure you want to unassign the vehicle from this route?',
			onOk: async () => {
				await dispatch(assignVehicleToDeliveryPlanItem(deliveryPlanItem.id, {
					vehicleId: deliveryPlanItem.vehicleId,
					unassign: 'true'
				}))
			}
		})
	}

	const onUnassignDriver = deliveryPlanItem => {
		Modal.confirm({
			title: 'Unassign Driver',
			content: 'Are you sure you want to unassign the driver from this route?',
			onOk: async () => {
				await dispatch(assignVehicleToDeliveryPlanItem(deliveryPlanItem.id, {
					driverId: deliveryPlanItem.driverId,
					unassign: 'true'
				}))
			}
		})
	}

	const onUnassignHelper = deliveryPlanItem => {
		Modal.confirm({
			title: 'Unassign Helper',
			content: 'Are you sure you want to unassign the helper from this route?',
			onOk: async () => {
				await dispatch(assignVehicleToDeliveryPlanItem(deliveryPlanItem.id, {
					helperId: deliveryPlanItem.helperId,
					unassign: 'true'
				}))
			}
		})
	}

	const openSalesOrderDialog = (point, deliveryPlanItem) => {
		setSalesOrderDialog({
			point,
			deliveryPlanItem
		})
		onClickSalesOrderDialog(point.id)
	}

	const onClickSalesOrderDialog = (id) => {
		if (lastClickedSalesOrderDialog !== id) {
			setLastClickedSalesOrderDialog(id)
			setSalesOrderDialogZIndex(salesOrderDialogZIndex + 1)
		}
	}

	const onToggleRouteVisibility = (e, deliveryPlanItemId) => {
		e.stopPropagation()
		setDeliveryPlanItemVisibilities({
			...deliveryPlanItemVisibilities,
			[deliveryPlanItemId]: !deliveryPlanItemVisibilities[deliveryPlanItemId]
		})
		if (salesOrderDialog) {
			closeSalesOrderDialog(salesOrderDialog.point.id)
		}
		setRouteDialog(null)
	}

	const getDeliveryPlanTableColumns = () => {
		return currentStatus === DELIVERY_PLAN_PENDING_STATUS ?
			[
				...deliveryPlanColumns,
				{
					title: 'Action',
					key: 'action',
					render: deliveryPlan => {
						return (
							<span
								onClick={() => setSelectedDeliveryPlanId(deliveryPlan.id)}
								className={styles.actionIcon}
							>
								<EditTwoTone twoToneColor='#278EA5' />
							</span>
						)
					}
				}
			]
			:
			deliveryPlanColumns
	}

	const openRouteDialog = (point, deliveryPlanItem) => {
		setRouteDialog({
			point,
			deliveryPlanItem
		})
	}

	const closeSalesOrderDialog = (id) => {
		setSalesOrderDialog(null)
		setClickedMarkers({ ...clickedMarkers, [id]: false })
	}

	const getSortedRoutePointsByDistance = (points) => {
		return points.sort((point1, point2) => {
			return point1.distance - point2.distance
		})
	}

	const renderCancellationConfirmContent = (deliveryPlan) => {
		return (
			<div>
				<div style={{ marginBottom: 8 }}>{`Are you sure you want to override the status of the delivery Plan DP-${deliveryPlan.shortId} to Cancelled?`}</div>
				<TextInput
					title='Cancelled Reason'
					onChange={(e) => {
						const value = e.target.value
						cancellationReasonInputRef.current = value
						confirmModalRef.current?.update({ okButtonProps: { disabled: !value?.trim() } })
					}}
				/>
			</div>
		)
	}

	const onUpdateStatus = (id, status, deliveryPlan) => {
		if (status === DELIVERY_PLAN_DELIVERED_STATUS) {
			Modal.confirm({
				title: 'Update Delivery Plan\'s Status',
				content: `Are you sure you want to override the status of the delivery Plan DP-${deliveryPlan.shortId} to Completed?`,
				okText: 'Yes',
				cancelText: 'No',
				closable: true,
				onOk: () => updateDeliveryPlanStatus(id, status)
			})
		} else if (status === DELIVERY_PLAN_CANCELLED_STATUS) {
			confirmModalRef.current = Modal.confirm({
				title: 'Update Delivery Plan\'s Status',
				content: renderCancellationConfirmContent(deliveryPlan),
				okText: 'Yes',
				okButtonProps: { disabled: true },
				cancelText: 'No',
				closable: true,
				onOk: () => updateDeliveryPlanStatus(id, status, cancellationReasonInputRef.current)
			})
		} else {
			updateDeliveryPlanStatus(id, status)
		}
	}

	const updateDeliveryPlanStatus = async (id, status, cancellationReason = '') => {
		try {
			await dispatch(updateDeliveryPlan(id, {
				status,
				cancellationReason
			}))
			await getDeliveryPlanDetails(id)
		} catch (e) {
			notification.error({
				message: 'Unable to Update Delivery Plan\'s Status.',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		}
	}

	const updateInternalNotes = async (id, notes) => {
		await dispatch(updateDeliveryPlanItem(id, {
			internalNotes: notes
		}))
	}

	const deliveryPlans = useMemo(() => {
		switch (status?.toUpperCase()) {
			case DELIVERY_PLAN_PENDING_STATUS:
				return pendingDeliveryPlans
			case DELIVERY_PLAN_SCHEDULED_STATUS:
				return scheduledDeliveryPlans
			case DELIVERY_PLAN_DELIVERED_STATUS:
				return deliveredDeliveryPlans
			case DELIVERY_PLAN_CANCELLED_STATUS:
				return cancelledDeliveryPlans
			default:
				return allDeliveryPlans
		}
	}, [
		status,
		pendingDeliveryPlans,
		scheduledDeliveryPlans,
		deliveredDeliveryPlans,
		cancelledDeliveryPlans,
		allDeliveryPlans
	])

	const expandedDeliveryPlanItems = useMemo(() => {
		if (!deliveryPlans) {
			return []
		}
		const expandedDeliveryPlan = deliveryPlans
			.find(deliveryPlan => expandedDeliveryPlanId === deliveryPlan.id)
		if (expandedDeliveryPlan) {
			return expandedDeliveryPlan.deliveryPlanItems.filter(deliveryPlanItem => {
				return deliveryPlanItemVisibilities[deliveryPlanItem.id] === true || deliveryPlanItemVisibilities[deliveryPlanItem.id] === undefined
			})
		} else {
			return []
		}
	}, [expandedDeliveryPlanId, deliveryPlanItemVisibilities])

	const getDeliveryPlanStatusMenuItems = (deliveryPlan) => {
		switch (deliveryPlan.status) {
			case DELIVERY_PLAN_PENDING_STATUS:
				return [DELIVERY_PLAN_PENDING_STATUS, DELIVERY_PLAN_SCHEDULED_STATUS, DELIVERY_PLAN_CANCELLED_STATUS]
			case DELIVERY_PLAN_SCHEDULED_STATUS: {
				const showAllItems = deliveryPlan.deliveryPlanItems.every(item => {
					return [deliveryPlanItemStatuses.ROUTE_GENERATED_STATUS, deliveryPlanItemStatuses.ROUTE_SHIPPED_STATUS, deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS].includes(item.status.toLowerCase())
				})
				return showAllItems ? statusMenuItems : [DELIVERY_PLAN_SCHEDULED_STATUS, DELIVERY_PLAN_DELIVERED_STATUS]
			}
			case DELIVERY_PLAN_DELIVERED_STATUS:
				return [DELIVERY_PLAN_DELIVERED_STATUS]
			case DELIVERY_PLAN_CANCELLED_STATUS:
				return [DELIVERY_PLAN_CANCELLED_STATUS]
			default:
				return []
		}
	}

	const renderVehicleAssignmentCard = (deliveryPlanItem) => {
		if (
			deliveryPlanItem.driver &&
			deliveryPlanItem.requests &&
			deliveryPlanItem.requests.length &&
			deliveryPlanItem.requests[deliveryPlanItem.requests.length - 1].status === vehicleRequestStatuses.VEHICLE_REQUEST_PENDING_STATUS
		) {
			return (
				<div style={{ marginLeft: 26, display: 'flex', flexDirection: 'column' }}>
					<div>
						<p style={{ fontColor: '#4B5766', fontSize: 13, marginBottom: 3 }}>Vehicle Report</p>
						<p style={{ fontColor: '#828282', fontSize: 11 }}>{deliveryPlanItem.requests && deliveryPlanItem.requests[deliveryPlanItem.requests.length - 1].remark}</p>
					</div>
					<div>
						<p style={{ fontColor: '#4B5766', fontSize: 13, marginBottom: 3 }}>Requested Vehicle</p>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
							<p style={{ fontColor: '#828282', fontSize: 11 }}>{deliveryPlanItem.vehicle?.plateNumber}</p>
							<div style={{ display: 'flex', marginTop: -7 }}>
								<Button
									style={{ marginRight: 5, borderRadius: 10 }}
									disabled={disableVehicleStatusActionButtons.includes(deliveryPlanItem.id)}
									loading={isUpdating}
									onClick={() => confirmVehicleAssignment(vehicleRequestStatuses.VEHICLE_REQUEST_DENIED_STATUS, deliveryPlanItem)}
								>
									Decline
								</Button>
								<Button
									style={{ marginRight: 5, borderRadius: 10 }}
									disabled={disableVehicleStatusActionButtons.includes(deliveryPlanItem.id)}
									loading={isUpdating}
									onClick={() => confirmVehicleAssignment(vehicleRequestStatuses.VEHICLE_REQUEST_APPROVED_STATUS, deliveryPlanItem)}
								>
									Accept
								</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}

	const renderDeliveryPlanItem = (deliveryPlan, deliveryPlanItem, mainIndex) => {
		const salesOrders = getSalesOrders(deliveryPlan.id)
		const sortedRoute = getSortedRoutePointsByDistance(deliveryPlanItem.route.slice(1))
		return (
			<div>
				<div
					onClick={() => {
						if (selectedDeliveryPlanItem?.id === deliveryPlanItem.id) {
							setSelectedDeliveryPlanItem(null)
							onDeliveryPlanItemHovered(deliveryPlanItem.id, false)
						} else {
							setSelectedDeliveryPlanItem(deliveryPlanItem)
							onDeliveryPlanItemHovered(deliveryPlanItem.id, true)
							openRouteDialog(deliveryPlanItem.route[0], deliveryPlanItem)
						}
					}}
					key={deliveryPlanItem.id}
					className={mainIndex % 2 === 0 ? styles.routeContainerAlt : styles.routeContainer}
				>
					<div className={styles.routeTopSection}>
						{
							deliveryPlanItem.recommendedVehicle &&
							<div className={styles.vehicleRecommendation}>{`${deliveryPlanItem.recommendedVehicle.maxWeight} Tonnes ${deliveryPlanItem.recommendedVehicle.cbm} CBM  ${deliveryPlanItem.recommendedVehicle.label}`}</div>
						}
						<div onClick={(e) => e.stopPropagation()} className={styles.internalNotesContainer}>
							<InternalNotesPopover
								id={deliveryPlanItem.id}
								notes={deliveryPlanItem.internalNotes}
								onSave={updateInternalNotes}
							/>
						</div>
					</div>
					<div style={{ display: 'flex', width: '100%' }}>
						{
							deliveryPlanItem.route[0] &&
							<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginRight: 16 }}>
								<div
									onMouseEnter={() => {
										setCenter({ lat: deliveryPlanItem.route[0].lat, lng: deliveryPlanItem.route[0].lon })
										setHoveredPointId(deliveryPlanItem.route[0].id)
									}}
									onMouseLeave={() => setHoveredPointId(null)}
									onClick={() => {
										openRouteDialog(deliveryPlanItem.route[0], deliveryPlanItem)
									}}
									className={styles.routeName}
								>
									{deliveryPlanItem.internalId}
								</div>
								<Button
									style={{ marginTop: 6 }}
									icon={deliveryPlanItemVisibilities[deliveryPlanItem.id] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
									size='small'
									onClick={e => onToggleRouteVisibility(e, deliveryPlanItem.id)}
								/>
							</div>
						}
						<div
							className={styles.routeDotContainer}
						>
							{
								sortedRoute.map((point, index) => {
									return (
										<div
											key={point.id}
											className={styles.dotItemContainer}
										>
											<div
												className={styles.dotItem}
												key={index}
												onClick={() => {
													openSalesOrderDialog(point, deliveryPlanItem)
													setDeliveryPlanItemVisibilities({
														...deliveryPlanItemVisibilities,
														[deliveryPlanItem.id]: true
													})
												}}
											>
												<div
													onMouseEnter={() => {
														setCenter({ lat: point.lat, lng: point.lon })
														setHoveredPointId(point.id)
													}}
													onMouseLeave={() => setHoveredPointId(null)}
													className={
														point.id.includes('location') ? styles.pickUpDot :
															index % 2 === 0 ? styles.routeDotAlt : styles.routeDot
													}
												/>
												{
													!isExpandedView() ?
														<>
															{

																salesOrders[point.id] &&
															salesOrders[point.id].distributor &&
															salesOrders[point.id].distributor.internalName ?
																	<div className={styles.salesOrderNumber}>
																		{salesOrders[point.id].distributor.internalName}
																	</div> :
																	<div className={styles.salesOrderNumber}>&nbsp;</div>
															}
															{
																salesOrders[point.id] &&
															salesOrders[point.id].location &&
															salesOrders[point.id].location.internalId ?
																	<div className={styles.salesOrderNumber}>
																		{salesOrders[point.id].location.internalId}
																	</div> :
																	<div className={styles.salesOrderNumber}>&nbsp;</div>
															}
														</> : null
												}
												{
													isExpandedView() && salesOrders[point.id] &&
												<div className={styles.salesOrderNumber}>{salesOrders[point.id].internalId}</div>
												}
											</div>
										</div>
									)
								})
							}
						</div>
						<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
							<Assignees deliveryPlanItem={deliveryPlanItem} />
							{
								isExpandedView() || isSplitViewEnabled ?
									<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
										{
											deliveryPlanItem.status &&
										<Button
											type='primary'
											style={{
												marginLeft: 24,
												background: ROUTE_STATUS_COLORS[deliveryPlanItem.status?.toLowerCase()],
												borderColor: ROUTE_STATUS_COLORS[deliveryPlanItem.status?.toLowerCase()],
												fontSize: 12,
												padding: '2px 8px',
												marginBottom: 4
											}}
										>
											{convertEnumToString(deliveryPlanItem.status?.toLowerCase())}
										</Button>
										}
										<div className={styles.travelInfo}>
											{
												isDeliveryRouteChecked &&
												<div className={styles.travelInfoContainer}>
													<span>{`${((+deliveryPlanItem.travelDistance || 0) / 1000).toFixed(2)} km`}</span>
													<span>{`${moment.duration((+deliveryPlanItem.travelTime || 0) * 1000).humanize()}`}</span>
												</div>
											}
											{
												isReturnRouteChecked &&
												<div style={{ color: colorHash.hex(deliveryPlanItem.id) }} className={styles.travelInfoContainer}>
													<span>{`${((+deliveryPlanItem.returnTravelDistance || 0) / 1000).toFixed(2)} km`}</span>
													<span>{`${moment.duration((+deliveryPlanItem.returnTravelTime || 0) * 1000).humanize()}`}</span>
												</div>
											}
										</div>
									</div> : null
							}
							<Dropdown
								overlay={(
									<Menu>
										{
											deliveryPlanItem.assignedDriverId ?
												<Menu.Item
													onClick={menuInfo => {
														menuInfo.domEvent.stopPropagation()
														onUnassignDriver(deliveryPlanItem)
													}}
													key='1'
													disabled={
														deliveryPlan.status === DELIVERY_PLAN_PENDING_STATUS ||
														deliveryPlanItem.status.toLowerCase() === deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
													}
												>
													Unassign Driver
												</Menu.Item> :
												<Menu.Item
													onClick={menuInfo => {
														menuInfo.domEvent.stopPropagation()
														setIsAssignDriverModalVisible(true)
														setSelectedDeliveryPlanItem(deliveryPlanItem)
													}}
													key='2'
													disabled={
														deliveryPlan.status === DELIVERY_PLAN_PENDING_STATUS ||
														deliveryPlanItem.status.toLowerCase() === deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
													}
												>
													Assign Driver
												</Menu.Item>
										}
										{
											deliveryPlanItem.assignedHelperId ?
												<Menu.Item
													onClick={menuInfo => {
														menuInfo.domEvent.stopPropagation()
														onUnassignHelper(deliveryPlanItem)
													}}
													key='3'
													disabled={
														deliveryPlan.status === DELIVERY_PLAN_PENDING_STATUS ||
														deliveryPlanItem.status.toLowerCase() === deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
													}
												>
													Unassign Helper
												</Menu.Item> :
												<Menu.Item
													onClick={menuInfo => {
														menuInfo.domEvent.stopPropagation()
														setIsAssignHelperModalVisible(true)
														setSelectedDeliveryPlanItem(deliveryPlanItem)
													}}
													key='4'
													disabled={
														deliveryPlan.status === DELIVERY_PLAN_PENDING_STATUS ||
														deliveryPlanItem.status.toLowerCase() === deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
													}
												>
													Assign Helper
												</Menu.Item>

										}
										{
											deliveryPlanItem.currentVehicle ?
												<Menu.Item
													onClick={menuInfo => {
														menuInfo.domEvent.stopPropagation()
														onUnassignVehicle(deliveryPlanItem)
													}}
													key='5'
													disabled={deliveryPlanItem.vehicle.status !== VEHICLE_UNAVAILABLE_STATUS}
												>
													Unassign Vehicle
												</Menu.Item> :
												<Menu.Item
													onClick={menuInfo => {
														menuInfo.domEvent.stopPropagation()
														setIsAssignVehicleModalVisible(true)
														setSelectedDeliveryPlanItem(deliveryPlanItem)
														setSelectedVehicleType(deliveryPlanItem.vehicleType)
													}}
													key='6'
													disabled={
														deliveryPlan.status === DELIVERY_PLAN_PENDING_STATUS ||
														deliveryPlanItem.status.toLowerCase() === deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
													}
												>
													Assign Vehicle
												</Menu.Item>
										}
										{
											companyDetails?.is3plIntegrationsEnabled &&
											<Menu.Item
												onClick={menuInfo => {
													menuInfo.domEvent.stopPropagation()
													setIsAssignTransportVendorModalVisible(true)
													setSelectedDeliveryPlanItem(deliveryPlanItem)
												}}
												key='7'
												disabled={deliveryPlan.status === DELIVERY_PLAN_PENDING_STATUS}
											>
												Assign Transport Vendors
											</Menu.Item>
										}
									</Menu>
								)} trigger={['click']}
							>
								<MoreOutlined
									onClick={e => e.stopPropagation()}
									style={{ marginLeft: 12, alignItems: 'center', display: 'flex' }}
								/>
							</Dropdown>
						</div>
					</div>
				</div>
				{renderVehicleAssignmentCard(deliveryPlanItem)}
			</div>
		)
	}

	const renderEditableDeliveryPlanItem = (deliveryPlan, deliveryPlanItem, mainIndex) => {
		const salesOrders = getSalesOrders(deliveryPlan.id)
		const sortedRoute = getSortedRoutePointsByDistance(deliveryPlanItem.route.slice(1))
		return (
			<div
				onMouseEnter={() => {
					setHoveredRouteId(deliveryPlanItem.id)
				}}
				onMouseLeave={() => {
					setHoveredRouteId(null)
				}}
				onClick={() => {
					if (selectedDeliveryPlanItem?.id === deliveryPlanItem.id) {
						setSelectedDeliveryPlanItem(null)
						onDeliveryPlanItemHovered(deliveryPlanItem.id, false)
					} else {
						setSelectedDeliveryPlanItem(deliveryPlanItem)
						onDeliveryPlanItemHovered(deliveryPlanItem.id, true)
						openRouteDialog(deliveryPlanItem.route[0], deliveryPlanItem)
					}
				}}
				key={deliveryPlanItem.id}
				className={mainIndex % 2 === 0 ? styles.routeContainerAlt : styles.routeContainer}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{
						deliveryPlanItem.recommendedVehicle &&
						<div className={styles.vehicleRecommendation}>{`${deliveryPlanItem.recommendedVehicle.maxWeight} Tonnes ${deliveryPlanItem.recommendedVehicle.cbm} CBM  ${deliveryPlanItem.recommendedVehicle.label}`}</div>
					}
					{
						deliveryPlanItem.error &&
						<div className={styles.routeError}>{deliveryPlanItem.error}</div>
					}
				</div>
				<div style={{ display: 'flex', width: '100%' }}>
					{
						deliveryPlanItem.route[0] &&
						<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginRight: 16 }}>
							<div
								onMouseEnter={() => {
									setCenter({ lat: deliveryPlanItem.route[0].lat, lng: deliveryPlanItem.route[0].lon })
									setHoveredPointId(deliveryPlanItem.route[0].id)
								}}
								onMouseLeave={() => setHoveredPointId(null)}
								onClick={() => {
									openRouteDialog(deliveryPlanItem.route[0], deliveryPlanItem)
								}}
								className={styles.routeName}
							>
								{deliveryPlanItem.internalId ? deliveryPlanItem.internalId : mainIndex + 1}
							</div>
						</div>
					}
					<Droppable droppableId={deliveryPlanItem.id} direction='horizontal'>
						{
							(provided, snapshot) => {
								return (
									<div
										ref={provided.innerRef}
										className={styles.routeDotContainer}
										style={getListStyle(snapshot.isDraggingOver)}
									>
										{
											sortedRoute.map((point, index) => {
												return (
													<Draggable
														isDragDisabled={!editableDeliveryPlan}
														key={point.id}
														draggableId={point.id}
														index={index}
													>
														{
															(provided) => {
																return (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		className={styles.dotItem}
																		key={index}
																		onClick={() => {
																			openSalesOrderDialog(point, deliveryPlanItem)
																		}}
																	>
																		<Dropdown
																			overlay={
																				<Menu>
																					<Menu.Item
																						style={{ color: 'red' }}
																						onClick={() => onRemoveRoutePoint(deliveryPlanItem, point)}
																						key='remove'
																					>
																					Remove
																					</Menu.Item>
																				</Menu>
																			}
																			trigger={['contextMenu']}
																		>
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																					flexDirection: 'column'
																				}}
																			>
																				<div
																					onMouseEnter={() => {
																						setCenter({ lat: point.lat, lng: point.lon })
																						setHoveredPointId(point.id)
																					}}
																					onMouseLeave={() => setHoveredPointId(null)}
																					className={
																						point.id.includes('location') ? styles.pickUpDot :
																							index % 2 === 0 ? styles.routeDotAlt : styles.routeDot
																					}
																				/>
																				{
																					isExpandedView() && salesOrders[point.id] &&
																					<div className={styles.salesOrderNumber}>{salesOrders[point.id].internalId}</div>
																				}
																			</div>
																		</Dropdown>
																	</div>
																)
															}
														}
													</Draggable>
												)
											})
										}
										{provided.placeholder}
									</div>
								)
							}

						}
					</Droppable>
					{
						hoveredRouteId === deliveryPlanItem.id ?
							<div className={styles.addRoutePoints}>
								<PlusOutlined
									onClick={() => {
										setIsAddRoutePointsModalVisible(true)
										setSelectedDeliveryPlanItem(deliveryPlanItem)
									}}
									className={styles.addRoutePointsButton}
								/>
							</div> : null
					}
					<div style={{ marginLeft: 'auto', display: 'flex' }}>
						{
							isExpandedView() || isSplitViewEnabled ?
								<>
									<div className={styles.travelInfoContainer}>
										<span>{`${((+deliveryPlanItem.travelDistance || 0) / 1000).toFixed(2)} km`}</span>
										<span>{`${moment.duration((+deliveryPlanItem.travelTime || 0) * 1000).humanize()}`}</span>
									</div>
								</> : null
						}
					</div>
				</div>
			</div>
		)
	}

	const renderDeliveryPlanAccordionHeader = (deliveryPlan) => {
		return (
			<div className={styles.routeHeader} ref={deliveryPlan.id === expandedDeliveryPlanId ? expandedViewRef : null}>
				<div style={{ display: 'flex' }}>
					{
						isExpandedView() ?
							null :
							<img
								className={expandedDeliveryPlanId === deliveryPlan.id ? styles.chevronExpanded : styles.chevron}
								src='/img/chevron.svg'
							/>
					}
					<div className={styles.id}>
						{`DP-${deliveryPlan.shortId}`}
					</div>
					<Link
						onClick={e => e.stopPropagation()}
						href={`/delivery-plans/${deliveryPlan.id}`}
					>
						<img
							onClick={e => e.stopPropagation()}
							src='/img/external-link.svg'
							style={{ alignSelf: 'center', marginLeft: 12, width: 12 }}
						/>
					</Link>
					{
						isExpandedView() || isSplitViewEnabled ?
							<Tooltip title='Update delivery plan status'>
								<Dropdown
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													getDeliveryPlanStatusMenuItems(deliveryPlan).map(status => {
														return (
															<Menu.Item
																key={status}
																onClick={() => onUpdateStatus(deliveryPlan.id, status, deliveryPlan)}
																disabled={status === deliveryPlan.status}
															>
																<Badge
																	text={getDeliveryPlanStatusLabel(status)}
																	color={DELIVERY_PLAN_STATUS_COLORS[status]}
																/>
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<Button
										disabled={!permissions.editDeliveryPlans}
										type='primary'
										icon={<DownOutlined />}
										style={{
											marginLeft: 24,
											background: DELIVERY_PLAN_STATUS_COLORS[deliveryPlan.status],
											borderColor: DELIVERY_PLAN_STATUS_COLORS[deliveryPlan.status],
											fontSize: 12,
											padding: '2px 8px'
										}}
										onClick={e => e.stopPropagation()}
									>
										{getDeliveryPlanStatusLabel(deliveryPlan.status)}
									</Button>
								</Dropdown>
							</Tooltip> : null
					}
					{
						expandedDeliveryPlanId === deliveryPlan.id && deliveryPlan.status === DELIVERY_PLAN_PENDING_STATUS &&
						<>
							{
								!editableDeliveryPlan ?
									<Tooltip
										title='Edit'
									>
										<img
											onClick={e => onEdit(e, deliveryPlan)}
											className={styles.editIcon}
											src='/img/edit.svg'
										/>
									</Tooltip> :
									<div
										style={{ display: 'flex', marginLeft: 12, alignItems: 'center' }}
									>
										<Tooltip
											title='New Route'
										>
											<Button
												style={{ marginLeft: '10px' }}
												size='small'
												icon={<PlusOutlined />}
												onClick={e => {
													e.stopPropagation()
													setIsPickUpLocationModalVisible(true)
												}}
											/>
										</Tooltip>
										<Tooltip
											title='Save'
										>
											<Button
												style={{ marginLeft: '10px' }}
												size='small'
												type='primary'
												loading={isUpdatingRoutes}
												icon={<SaveOutlined />}
												onClick={onSaveRoute}
											/>
										</Tooltip>
										<Tooltip
											title='Cancel'
										>
											<Button
												style={{ marginLeft: '10px' }}
												size='small'
												onClick={onCancelEdit}
												icon={<CloseOutlined />}
											/>
										</Tooltip>
									</div>
							}
						</>
					}
				</div>
				<div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
					{
						loadingDeliveryPlans[deliveryPlan.id] ?
							<Tooltip
								title='Generating delivery plan...'
							>
								<Spin
									style={{ marginRight: 12 }}
									indicator={<LoadingOutlined />}
								/>
							</Tooltip> : null
					}
					<ExpandButton
						isExpanded={isExpandedView()}
						onClick={(e) => {
							e.stopPropagation()
							onExpandedViewClick(deliveryPlan)
						}}
						style={{ marginRight: 16 }}
					/>
					<div className={styles.date}>
						{moment(deliveryPlan.deliveryDate).format('MMMM DD, YYYY')}
					</div>
					<Dropdown
						overlay={(
							<Menu>
								<Menu.Item
									onClick={(e) => {
										e.domEvent.stopPropagation()
										onRefreshDeliveryPlan(deliveryPlan.id)
									}}
									key='1'
									disabled={deliveryPlan.status !== DELIVERY_PLAN_PENDING_STATUS}
								>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<ReloadOutlined
											style={{ marginRight: 12 }}
										/>
										Refresh
									</div>
								</Menu.Item>
								{
									+deliveryPlan._count.salesOrders > 0 && !loadingDeliveryPlans[deliveryPlan.id] ?
										<Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation()
												onRegenerateDeliveryPlan(deliveryPlan.id)
											}}
											key='2'
											disabled={deliveryPlan.status !== DELIVERY_PLAN_PENDING_STATUS}
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<SyncOutlined
													style={{ marginRight: 12 }}
												/>
												Regenerate Delivery Plan
											</div>
										</Menu.Item> : null
								}
							</Menu>
						)} trigger={['click']}
					>
						<MoreOutlined
							onClick={e => e.stopPropagation()}
							style={{ marginLeft: 12, alignItems: 'center', display: 'flex' }}
						/>
					</Dropdown>
				</div>
			</div>
		)
	}

	const renderNonGeneratedOrders = () => {
		return (
			<div className={styles.routeContainer}>
				<div className={styles.nonGeneratedOrdersTop}>
					<div className={styles.nonGeneratedOrdersPrompt}>
						<span>
							<Tooltip
								title='System is unable to generate the delivery plan route for the orders as the order&apos;s weight/volume exceeds the vehicle capacity'
							>
								<InfoCircleFilled
									style={{
										color: '#FF5959',
										fontSize: '16px',
										cursor: 'pointer',
										marginRight: '6px'
									}}
								/>
							</Tooltip>
						</span>
						<span className={styles.heading}>Unable to generate routes</span>
					</div>
					<div>
						<Button
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							onClick={() => router.push(`/delivery-plans/${expandedDeliveryPlanId}`)}
							type='primary'
							icon={<ArrowRightOutlined />}
						/>
					</div>
				</div>
				<div
					className={styles.routeDotContainer}
				>
					{
						nonGeneratedSalesOrders.map((order, index) => {
							const { location } = order
							return (
								<div
									key={order.id}
									className={styles.dotItemContainer}
								>
									<div
										className={styles.dotItem}
										key={index}
										onClick={() => {
											openSalesOrderDialog({
												id: order.id,
												lat: +location.latitude,
												lon: +location.longitude
											})
										}}
									>
										<div
											onMouseEnter={() => {
												setCenter({ lat: +location.latitude, lng: +location.longitude })
												setHoveredPointId(order.id)
											}}
											onMouseLeave={() => setHoveredPointId(null)}
											className={styles.routeDotBlack}
										/>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}

	const renderDeliveryPlanDetailPanel = (deliveryPlan) => {
		const deliveryPlanItems = editableDeliveryPlan ? editableDeliveryPlan.deliveryPlanItems : deliveryPlan.deliveryPlanItems
		return (
			<Panel
				collapsible={true}
				header={renderDeliveryPlanAccordionHeader(deliveryPlan)}
				showArrow={false}
				key={deliveryPlan.id}
				className={isExpandedView() ? 'expanded-custom-panel' : 'custom-panel'}
				style={isExpandedView() ? { borderBottom: '0px' } : {}}
			>
				{
					loadingDeliveryPlanId || loadingVehiclesDeliveryPlanId ?
						<div className={styles.requestProgress}>
							<div className={styles.bar} />
						</div> : null
				}
				{
					deliveryPlanItems && deliveryPlanItems.length >= 1 ?
						deliveryPlanItems.map((deliveryPlanItem, index) => editableDeliveryPlan ? renderEditableDeliveryPlanItem(editableDeliveryPlan, deliveryPlanItem, index) : renderDeliveryPlanItem(deliveryPlan, deliveryPlanItem, index)) :
						<Empty
							description={
								+deliveryPlan._count.salesOrders <= 1 ?
									'No route data as the delivery plan only contains one order. Try adding more orders for this delivery date.' :
									'Unable to generate a valid delivery plan. Please try again later.'
							}
						/>
				}
				{
					nonGeneratedSalesOrders.length > 0 ?
						renderNonGeneratedOrders()
						: null
				}
			</Panel>
		)
	}

	const renderMapContent = () => {
		return (
			<Content className={styles.mapContainer}>
				<Map
					onMapsLoaded={onMapsLoaded}
					containerStyle={{
						width: '100%',
						height: isExpandedView() ? 'calc(60vh - 64px)' : 'calc(100vh - 64px)',
						borderRadius: '0'
					}}
					center={center}
				>
					{
						expandedDeliveryPlanItems?.map((deliveryPlanItem) => {
							return deliveryPlanItem.route?.map((point, index) => {
								return (
									<LocationMarker
										index={index}
										hoveredPointId={hoveredPointId}
										id={point.id}
										key={point.id}
										lat={point.lat}
										lng={point.lon}
										onMouseLeave={() => {
											if (!clickedMarkers[point.id]) {
												closeSalesOrderDialog(point.id)
											}
											if (!clickedMarkers['start']) {
												setRouteDialog(null)
											}
										}}
										onMouseEnter={() => {
											if (point.id.includes('start')) {
												openRouteDialog(point, deliveryPlanItem)
											} else {
												openSalesOrderDialog(point, deliveryPlanItem)
											}
										}}
										onMarkerClick={() => {
											if (point.id.includes('start')) {
												openRouteDialog(point, deliveryPlanItem)
												setClickedMarkers({ ...clickedMarkers, 'start': true })
											} else {
												openSalesOrderDialog(point, deliveryPlanItem)
												setClickedMarkers({ ...clickedMarkers, [point.id]: true })
											}
											setCenter({ lat: point.lat, lng: point.lon })
										}}
									/>
								)
							})
						})
					}
					{
						nonGeneratedSalesOrders.map((order, index) => {
							const { location } = order
							return (
								<LocationMarker
									index={index}
									id={order.id}
									key={order.id}
									lat={+location.latitude}
									lng={+location.longitude}
									dotColor='black'
									onMouseLeave={() => {
										closeSalesOrderDialog(order.id)
									}}
									onMouseEnter={() => {
										openSalesOrderDialog({
											id: order.id,
											lat: +location.latitude,
											lon: +location.longitude
										})
									}}
									onMarkerClick={() => {
										openSalesOrderDialog({
											id: order.id,
											lat: +location.latitude,
											lon: +location.longitude
										})
										setCenter({ lat: +location.latitude, lng: +location.longitude })
									}}
								/>
							)
						})
					}
					{
						Object.keys(userLocations).map(uid => {
							const { location, trip } = userLocations[uid] || {}
							return (
								userVisibilities[uid] &&
								location &&
								<UserMarker
									userId={uid}
									trip={trip}
									location={location}
									key={uid}
									lat={location.latitude}
									lng={location.longitude}
								/>
							)
						})
					}
					{
						salesOrderDialog &&
						<SalesOrderDialog
							id={salesOrderDialog.point.id}
							deliveryPlanItem={salesOrderDialog.deliveryPlanItem}
							lat={salesOrderDialog.point.lat}
							lng={salesOrderDialog.point.lon}
							onClose={() => closeSalesOrderDialog(salesOrderDialog.point.id)}
							zIndex={salesOrderDialogZIndex}
							lastClickedSalesOrderDialog={lastClickedSalesOrderDialog}
							onClick={() => onClickSalesOrderDialog(salesOrderDialog.point.id)}
						/>
					}
					{
						routeDialog && routeDialog.point ?
							<RouteDialog
								id={routeDialog.point.id}
								deliveryPlanItem={routeDialog.deliveryPlanItem}
								lat={routeDialog.point.lat}
								lng={routeDialog.point.lon}
								onClose={() => setRouteDialog(null)}
							/> : null
					}
					{
						Object.values(vehiclesMap).filter(filterVehicle).map(vehicle => {
							const position = vehicle.tracker.position
							return (
								<VehicleMarker
									vehicle={vehicle}
									key={vehicle.id}
									lat={position.latitude}
									lng={position.longitude}
								/>
							)
						})
					}

				</Map>
				<div className={styles.buttonContainer}>
					<div
						onClick={setMapView}
						style={{ marginRight: '16px' }}
						className={!isListMode ? styles.filterButtonSelected : styles.filterButton}
					>
						<img src='/img/map.svg' width={20} height={20} />
					</div>
					<div
						onClick={() => {
							setIsSplitViewEnabled(isEnabled => !isEnabled)
						}}
						style={{ marginRight: '20px' }}
						className={isSplitViewEnabled ? styles.filterButtonSelected : styles.filterButton}
					>
						<img src='/img/split.svg' />
					</div>
					<div
						onClick={() => {
							setListView()
							setExpandedDeliveryPlanId(null)
						}}
						style={{ marginRight: '20px' }}
						className={isListMode ? styles.filterButtonSelected : styles.filterButton}
					>
						<img src='/img/list.svg' />
					</div>
					<Button
						style={{ marginRight: '24px' }}
						onClick={() => setIsDeliveryPlanPreferencesModalVisible(true)}
					>
						<SettingOutlined />
						Preferences
					</Button>
					<div style={{ marginRight: '32px' }} className={styles.createPlanButton}>
						<Button
							type='primary'
							disabled={!permissions.addDeliveryPlans || companyDetails?.isDeliveryPlanEnabled === false}
							onClick={() => showCreateDeliveryPlanModal(true)}
						>
							Create Delivery Plan
						</Button>
					</div>
				</div>
				<div className={styles.layersControl}>
					<MapLayersControl checked={isTrafficLayerEnabled} onChange={setIsTrafficLayerEnabled} />
				</div>
				<div className={styles.filterControl}>
					<Popover
						content={
							<div className={styles.filterOptions}>
								<div className={styles.filterOption}>
									<Checkbox
										checked={isDeliveryRouteChecked}
										onChange={(e) => setIsDeliveryRouteChecked(e.target.checked) }
									>
										Delivery Route
									</Checkbox>
								</div>
								<div className={styles.filterOption}>
									<Checkbox
										checked={isReturnRouteChecked}
										onChange={(e) => setIsReturnRouteChecked(e.target.checked)}
									>
										Return Route
									</Checkbox>
								</div>
								<div className={styles.filterOption}>
									<Checkbox
										className='assigned-vehicles'
										checked={isAssignedVehiclesChecked}
										onChange={(e) => setIsAssignedVehiclesChecked(e.target.checked) }
									>
										Assigned Vehicles
									</Checkbox>
								</div>
								<div className={styles.filterOption}>
									<Checkbox
										className='unassigned-vehicles'
										checked={isUnassignedVehiclesChecked}
										onChange={(e) => setIsUnassignedVehiclesChecked(e.target.checked)}
									>
										Unassigned Vehicles
									</Checkbox>
								</div>
							</div>
						}
						trigger='click'
						visible={isFilterControlVisible}
						onVisibleChange={setIsFilterControlVisible}
					>
						<img
							className={styles.filterControlIcon}
							src='/img/control.svg'
							alt='...'
						/>
					</Popover>
				</div>
			</Content>
		)
	}

	const deliveryPlanWeeklyGroupings = useMemo(() => getDeliveryPlanGroupings(deliveryPlans), [deliveryPlans])
	return (
		<>
			{
				isListMode ?
					<Layout className={styles.layout}>
						<Content className={styles.mapContainer} style={{ padding: '0 24px' }}>
							<div className={styles.contentHeader}>
								<div className={styles.tabContainer}>
									{
										tabs.map(tab => {
											return (
												<HeaderTab
													key={tab.title}
													title={tab.title}
													isSelected={() => currentStatus === tab.status}
													onClick={() => router.push(tab.path)}
												/>
											)
										})
									}
								</div>
								<div style={{ display: 'flex', marginLeft: 'auto', marginTop: '24px' }}>
									<div
										onClick={setMapView}
										style={{ marginRight: '16px' }}
										className={!isListMode ? styles.filterButtonSelected : styles.filterButton}
									>
										<img src='/img/map.svg' width={20} height={20} />
									</div>
									<div
										onClick={() => {
											setListView()
											setExpandedDeliveryPlanId(null)
										}}
										style={{ marginRight: '24px' }}
										className={isListMode ? styles.filterButtonSelected : styles.filterButton}
									>
										<img src='/img/list.svg' />
									</div>
									<Button
										style={{ marginRight: '24px' }}
										onClick={() => setIsDeliveryPlanPreferencesModalVisible(true)}
									>
										<SettingOutlined />
										Preferences
									</Button>
									<div className={styles.createPlanButton}>
										<Button
											type='primary'
											disabled={!permissions.addDeliveryPlans || companyDetails?.isDeliveryPlanEnabled === false}
											onClick={() => showCreateDeliveryPlanModal(true)}
										>
											Create Delivery Plan
										</Button>
									</div>
								</div>
							</div>
							<Table
								refresh={() => onPageChanged(page)}
								style={{ marginTop: '24px' }}
								loading={isLoading && deliveryPlans.length === 0}
								columns={getDeliveryPlanTableColumns()}
								dataSource={deliveryPlans}
								pagination={{
									pageSize: 50,
									total: totalCount,
									position: ['topRight'],
									onChange: onPageChanged,
									showSizeChanger: false,
									current: page + 1
								}}
								scroll={{ y: 'calc(100vh - 340px)' }}
								rowKey='id'
								empty={isSearchApplied ? <CustomEmptySecondary showFallbackAction /> : null}
							/>
						</Content>
					</Layout> :
					<Layout
						className={styles.layout}
						style={{ flexDirection: isExpandedView() ? 'column' : 'row' }}
					>
						<Splitter disabled={!isSplitViewEnabled}>
							<Sider
								className={styles.sideMenu}
								style={{ display: isExpandedView() ? 'none' : 'block' }}
							>
								<DragDropContext
									onDragEnd={onDragEnd}
								>
									{
										deliveryPlanWeeklyGroupings.length > 0 ?
											deliveryPlanWeeklyGroupings.map(grouping => {
												return (
													<div key={grouping.dateKey} style={{ display: 'flex', flexDirection: 'column' }}>
														<div className={styles.weekHeading}>{getWeekHeading(grouping.dateKey)}</div>
														<Collapse
															activeKey={expandedDeliveryPlanId}
															className={styles.deliveryPlansContainer}
															bordered={false}
															accordion={true}
															onChange={onExpandDeliveryItem}
														>
															{
																grouping.deliveryPlans.map(renderDeliveryPlanDetailPanel)
															}
														</Collapse>
													</div>
												)
											}) :
											isLoading && deliveryPlanWeeklyGroupings.length === 0 ?
												<Spin
													size='large'
													style={{ width: '100%', marginTop: '128px' }}
												/> :
												<CustomEmptySecondary showFallbackAction={!!isSearchApplied} />
									}
								</DragDropContext>
							</Sider>
							{
								renderMapContent()
							}
						</Splitter>
						{
							isExpandedView() &&
								<div className={styles.expandedDeliveryPlanContainer}>
									<DragDropContext
										onDragEnd={onDragEnd}
									>
										<Collapse
											activeKey={expandableDeliveryPlan.id}
											bordered={false}
											accordion={true}
											className={styles.deliveryPlansContainer}
											style={{ height: '100%' }}
										>
											{
												renderDeliveryPlanDetailPanel(expandableDeliveryPlan)
											}
										</Collapse>
									</DragDropContext>
								</div>
						}
					</Layout>
			}
			{
				isAssignVehicleModalVisible ?
					<AssignVehicleModal
						vehicleType={selectedVehicleType}
						deliveryPlanItemId={selectedDeliveryPlanItem?.id}
						deliveryPlanItemInternalId={selectedDeliveryPlanItem?.internalId}
						visible={isAssignVehicleModalVisible}
						onCancel={() => {
							setIsAssignVehicleModalVisible(false)
							setSelectedDeliveryPlanItem(null)
							setSelectedVehicleType(null)
						}}
					/> : null
			}
			{
				isAssignDriverModalVisible ?
					<AssignDriverModal
						onDriverAssigned={onDriverAssigned}
						deliveryPlanItemId={selectedDeliveryPlanItem.id}
						visible={isAssignDriverModalVisible}
						onCancel={() => {
							setIsAssignDriverModalVisible(false)
							setSelectedDeliveryPlanItem(null)
						}}
					/> : null
			}
			{
				isAssignHelperModalVisible ?
					<AssignHelperModal
						deliveryPlanItemId={selectedDeliveryPlanItem.id}
						visible={isAssignHelperModalVisible}
						onCancel={() => {
							setIsAssignHelperModalVisible(false)
							setSelectedDeliveryPlanItem(null)
						}}
					/> : null
			}
			{
				isAssignTransportVendorModalVisible ?
					<AssignTransportVendorModal
						deliveryPlan={deliveryPlanDetails[expandedDeliveryPlanId]}
						deliveryPlanItemId={selectedDeliveryPlanItem.id}
						deliveryPlanItemInternalId={selectedDeliveryPlanItem.internalId}
						assignedTransportVendors={selectedDeliveryPlanItem.transportVendors}
						visible={isAssignTransportVendorModalVisible}
						onCancel={() => {
							setIsAssignTransportVendorModalVisible(false)
							setSelectedDeliveryPlanItem(null)
						}}
					/> : null
			}
			{
				isUpdatingRoutes ?
					<div className={styles.reroutingScreen}>
						<div className={styles.reroutingSpinContainer}>
							<img src={'/img/tracking.svg'} />
							<h2 className={styles.rerouting}>
								Rerouting
								<span>.</span>
								<span>.</span>
								<span>.</span>
							</h2>
						</div>
					</div> : null
			}
			{
				isAddRoutePointsModalVisible ?
					<AddRoutePointsModal
						onCancel={() => setIsAddRoutePointsModalVisible(false)}
						deliveryPlanId={editableDeliveryPlan.id}
						visible={isAddRoutePointsModalVisible}
						onAddClicked={addRoutePoints}
					/> : null
			}
			{
				isCreateDeliveryPlanModalVisible ?
					<CreateDeliveryPlanModal
						onCancel={() => {
							showCreateDeliveryPlanModal(false)
							setDeliveryPlanDate(null)
							setShowUnassigned(false)
						}}
						shouldShowUnassigned={showUnassigned}
						date={deliveryPlanDate}
						visible={isCreateDeliveryPlanModalVisible}
					/> : null
			}
			{
				selectedDeliveryPlanId ?
					<EditDeliveryPlanModal
						onCancel={() => setSelectedDeliveryPlanId(null)}
						visible={!!selectedDeliveryPlanId}
						deliveryPlanId={selectedDeliveryPlanId}
					/> : null
			}
			{
				isPickUpLocationModalVisible ?
					<StartLocationModal
						onCancel={() => setIsPickUpLocationModalVisible(false)}
						visible={isPickUpLocationModalVisible}
						onUseLocation={location => {
							setIsPickUpLocationModalVisible(false)
							onAddNewRoute(location)
						}}
					/> : null
			}
			{
				isDeliveryPlanPreferencesModalVisible ?
					<DeliveryPlanPreferencesModal
						visible={isDeliveryPlanPreferencesModalVisible}
						onCancel={() => setIsDeliveryPlanPreferencesModalVisible(false)}
					/> : null
			}
		</>
	)
}

DeliveryPlansPage.getLayout = function getLayout (page) {
	return (
		<Page fullPage>
			{page}
		</Page>
	)
}

export default DeliveryPlansPage

const UserMarker = ({ userId, trip }) => {
	const user = trip.driver || trip.helper || {}
	return (
		<div className={styles.userMarkerContainer}>
			<Tooltip
				title={(
					<div>
						<div>
							<span>User:</span>
							<span>{` ${user ? user.name : ''}`}</span>
						</div>
						<div>
							<span>Assigned To:</span>
							<span>{` ${trip.internalId}`}</span>
						</div>
					</div>
				)}
			>
				<Avatar
					className={styles.userMarker}
					size={28}
					icon={<UserOutlined />}
					style={{ background: colorHash.hex(userId) }}
				/>
			</Tooltip>
		</div>
	)
}

const SalesOrderDialog = ({
	id,
	deliveryPlanItem,
	onClose,
	zIndex,
	lastClickedSalesOrderDialog,
	onClick
}) => {
	const dispatch = useDispatch()
	const { salesOrderDetails } = useSelector(state => state.salesOrdersReducer)
	const salesOrderDetail = salesOrderDetails[id]
	const [currentZIndex, setCurrentZIndex] = useState(zIndex)
	const [location, setLocation] = useState()

	useEffect(() => {
		if (!id.includes('start') && !id.includes('end') && !id.includes('location')) {
			dispatch(fetchSalesOrderDetails(id))
		} else if (id.includes('location')) {
			const locationId = id.split('_')[1]
			getLocation(locationId)
		}
	}, [])

	useEffect(() => {
		if (id === lastClickedSalesOrderDialog) {
			setCurrentZIndex(zIndex)
		}
	}, [lastClickedSalesOrderDialog])

	const getLocation = async (locationId) => {
		const response = await LocationsApi.single(locationId)
		if (response.data) {
			setLocation(response.data)
		}
	}

	const getSalesOrderStatus = () => {
		const status = salesOrderDetail.status.toLowerCase()
		const subStatus = salesOrderDetail.subStatus?.toLowerCase()

		if (subStatus === orderStatuses.SALES_ORDER_UNLOADED_STATUS) {
			return convertEnumToString(orderStatuses.SALES_ORDER_ARRIVED_STATUS)
		} else if (
			subStatus === orderStatuses.SALES_ORDER_SHIPPED_STATUS ||
			subStatus === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS
		) {
			return convertEnumToString(subStatus)
		} else if (status === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
			return convertEnumToString(orderStatuses.SALES_ORDER_DELIVERED_STATUS)
		} else {
			return convertEnumToString(status)
		}
	}

	return (
		<div className={styles.markerDialog} onClick={onClick} style={{ zIndex: currentZIndex }}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Button
					onClick={e => {
						e.stopPropagation()
						onClose()
					}}
					size='small'
					icon={<CloseOutlined style={{ fontSize: 10 }} />}
				/>
				{
					deliveryPlanItem ?
						<>
							<div className={styles.dialogTitle}>{deliveryPlanItem.internalId}</div>
							{
								deliveryPlanItem.status &&
								<Button
									type='primary'
									style={{
										marginLeft: 12,
										background: ROUTE_STATUS_COLORS[deliveryPlanItem.status.toLowerCase()],
										borderColor: ROUTE_STATUS_COLORS[deliveryPlanItem.status.toLowerCase()],
										fontSize: 12,
										padding: '2px 8px',
										marginBottom: 4,
										alignSelf: 'center'
									}}
								>
									{convertEnumToString(deliveryPlanItem.status)}
								</Button>
							}
						</> : null
				}
			</div>
			{
				salesOrderDetail ?
					<div className={styles.dialogInfoContainer}>
						<div style={{ display: 'flex', alignItems: 'center', margin: '16px 0 12px 0' }}>
							<h1 style={{ margin: 0 }}>{salesOrderDetail.internalId}</h1>
							<Link
								onClick={e => e.stopPropagation()}
								href={`${paths.SALES_ORDERS}/${id}`}
							>
								<a target='_blank'>
									<img
										onClick={e => e.stopPropagation()}
										src='/img/external-link.svg'
										style={{ alignSelf: 'center', marginLeft: 12, width: 12 }}
									/>
								</a>
							</Link>
							<span className={styles.popupStatus}>Status: {getSalesOrderStatus()}</span>
						</div>
						{
							salesOrderDetail.distributor &&
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Customer Name:</span>
								<span className={styles.value}>{`${salesOrderDetail.distributor.name}${salesOrderDetail.distributor.internalName ? ` (${salesOrderDetail.distributor.internalName})` : ''}`}</span>
							</Row>
						}
						{
							salesOrderDetail.distributor && salesOrderDetail.distributor.internalId &&
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Dealer ID:</span>
								<span className={styles.value}>{salesOrderDetail.distributor.internalId}</span>
							</Row>
						}
						{
							salesOrderDetail.location ?
								<Row style={{ margin: '3px 0' }}>
									<span className={styles.valueTitle}>Drop Off Point:</span>
									<span className={styles.value}>{`${salesOrderDetail.location.address}${salesOrderDetail.location.internalId ? ` (${salesOrderDetail.location.internalId})` : ''}`}</span>
								</Row> : null
						}
						<Row style={{ margin: '3px 0' }}>
							<span className={styles.valueTitle}>Order Amount:</span>
							<span className={styles.value}>{getFormattedCurrency(salesOrderDetail.totalAmount)}</span>
						</Row>
						{
							!salesOrderDetail.isStockTransfer &&
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Payment:</span>
								<span className={styles.value}>{convertEnumToString(salesOrderDetail.paymentMethod)}</span>
							</Row>
						}
					</div> :
					id.includes('location') && location ?
						<div className={styles.dialogInfoContainer}>
							<h1 className={styles.heading}>Pick Up Location</h1>
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Address:</span>
								<span className={styles.value}>{`${location.address}${location.internalId ? ` (${location.internalId})` : ''}`}</span>
							</Row>
						</div> :
						<Skeleton active />
			}
		</div>
	)
}

const RouteDialog = ({
	id,
	deliveryPlanItem,
	onClose
}) => {
	const dispatch = useDispatch()
	const { deliveryPlanItemDetails } = useSelector(state => state.deliveryPlansReducer)
	const [lastSalesOrder, setLastSalesOrder] = useState()

	useEffect(() => {
		if (id.includes('start') && !deliveryPlanItem.id.includes('new')) {
			dispatch(fetchDeliveryPlanItemDetails(deliveryPlanItem.id))
		}
	}, [deliveryPlanItem])

	const deliveryPlanItemDetail = deliveryPlanItemDetails[deliveryPlanItem.id]
	useEffect(() => {
		if (deliveryPlanItemDetail) {
			const salesOrders = deliveryPlanItemDetail.salesOrders
			setLastSalesOrder(salesOrders[salesOrders.length - 1])
		}
	}, [deliveryPlanItemDetail])

	return (
		<div className={styles.routeMarkerDialog}>
			<div style={{ marginBottom: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
				<h1 className={styles.heading} style={{ margin: 0, fontWeight: 'bold', color: '#278EA5', fontSize: 16 }}>{deliveryPlanItem.internalId}</h1>
				{
					deliveryPlanItem.status &&
					<Button
						type='primary'
						style={{
							marginLeft: 12,
							background: ROUTE_STATUS_COLORS[deliveryPlanItem.status.toLowerCase()],
							borderColor: ROUTE_STATUS_COLORS[deliveryPlanItem.status.toLowerCase()],
							fontSize: 12,
							padding: '2px 8px',
							marginBottom: 4
						}}
					>
						{convertEnumToString(deliveryPlanItem.status)}
					</Button>
				}
				<Button
					style={{ marginLeft: 'auto' }}
					onClick={e => {
						e.stopPropagation()
						onClose()
					}}
					size='small'
					icon={<CloseOutlined style={{ fontSize: 10 }} />}
				/>
			</div>
			{
				deliveryPlanItemDetail ?
					<div>
						<h1 className={styles.heading}>Route Details</h1>
						<Row style={{ margin: '3px 0' }}>
							<div className={styles.valueTitle}>Starting Point:
								{
									deliveryPlanItemDetail.startLocation &&
									<div>
										<b className={styles.value}>{deliveryPlanItemDetail.startLocation.label}</b>
										<div className={styles.value}>{`${deliveryPlanItemDetail.startLocation.address}${deliveryPlanItemDetail.startLocation.internalId ? ` (${deliveryPlanItemDetail.startLocation.internalId})` : ''}`}</div>
									</div>
								}
							</div>
						</Row>
						{
							lastSalesOrder &&
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>End Point:</span>
								{lastSalesOrder.location && <span className={styles.value}>{`${lastSalesOrder.location.address}${lastSalesOrder.location.internalId ? ` (${lastSalesOrder.location.internalId})` : ''}`}</span>}
							</Row>
						}
						<Row style={{ margin: '3px 0' }}>
							<span className={styles.valueTitle}>Distance:</span>
							<span className={styles.value}>{`${(+deliveryPlanItem.travelDistance / 1000).toFixed(2)} km`}</span>
						</Row>
						<Row style={{ margin: '3px 0' }}>
							<span className={styles.valueTitle}>Duration:</span>
							<span className={styles.value}>{`${moment.duration(+deliveryPlanItem.travelTime * 1000).humanize()}`}</span>
						</Row>
					</div> : <Skeleton active />
			}
		</div>
	)
}

export const LocationMarker = ({
	id = '',
	index,
	hoveredPointId,
	onMarkerClick,
	onMouseLeave,
	onMouseEnter,
	dotColor = ''
}) => {
	const [isHovered, setIsHovered] = useState(false)

	useEffect(() => {
		if (hoveredPointId === id) {
			setIsHovered(true)
		} else {
			setIsHovered(false)
		}
	}, [hoveredPointId])

	return (
		<div
			style={{
				backgroundColor: dotColor ? dotColor : id.includes('location') ? '#1890ff' :
					index % 2 === 0 ? '#278EA5' : '#A0AABD'
			}}
			onClick={onMarkerClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={
				isHovered ?
					id.includes('location') ? styles.pickUpMarkerHovered : styles.distributorMarkerHovered :
					id.includes('location') ? styles.pickUpMarker : styles.distributorMarker
			}
		>
			{
				id.includes('start') && index === 0 ?
					<div className={styles.startDot}>
						Start
					</div> : null
			}
		</div>
	)
}

LocationMarker.defaultProps = {
	onMouseLeave: () => {},
	onMouseEnter: () => {},
	onMarkerClick: () => {}
}

export const Assignees = ({ deliveryPlanItem }) => {
	const [isDriverDetailVisible, setIsDriverDetailVisible] = useState(false)
	const [isHelperDetailVisible, setIsHelperDetailVisible] = useState(false)
	const [isVehicleDetailVisible, setIsVehicleDetailVisible] = useState(false)
	const [isTransportVendorDetailVisible, setIsTransportVendorDetailVisible] = useState(false)

	const renderAssigneeDetail = (assignee, role) => {
		return (
			<>
				<div className={styles.assigneeDetail}>
					<Avatar
						size={48}
						icon={<UserOutlined />}
						style={{ background: colorHash.hex(assignee.id) }}
					/>
					<div className={styles.assigneeInfo}>
						<h3 style={{ whiteSpace: 'nowrap' }}>{assignee.name}</h3>
					</div>
				</div>
				<div className={styles.tripDetail}>
					{
						role === claimsRoles.DRIVER ? !deliveryPlanItem.assignedDriverId ?
							<div>Unassigned</div> : <div>Assigned</div> : null
					}
					{
						role === claimsRoles.HELPER ? !deliveryPlanItem.assignedHelperId ?
							<div>Unassigned</div> : <div>Assigned</div> : null
					}
					<div>Estimate Trip Distance: {`${((+deliveryPlanItem.travelDistance || 0) / 1000).toFixed(2)} km`}</div>
					<div>Working Hours: {`${moment.duration((+deliveryPlanItem.travelTime || 0) * 1000).humanize()}`}</div>
				</div>
			</>
		)
	}

	const renderVehicleDetail = (vehicle, currentVehicle) => {
		return (
			<>
				<div className={styles.assigneeDetail}>
					<Avatar
						size={48}
						icon={<CarOutlined />}
						style={{ background: colorHash.hex(vehicle.id) }}
					/>
					<div className={styles.assigneeInfo}>
						<h3 style={{ whiteSpace: 'nowrap' }}>{vehicle.plateNumber}</h3>
					</div>
				</div>
				<div className={styles.tripDetail}>
					<div>Status: {currentVehicle ? 'Assigned' : 'Unassigned'}</div>
					<div>Brand: {vehicle.brand}</div>
					<div>Type: {getVehicleTypeLabel(vehicle.type)}</div>
				</div>
			</>
		)
	}

	const renderTransportVendorDetail = (transportVendors) => {
		return (
			<Tabs
				size='small'
			>
				{
					transportVendors.map(transportVendor => {
						return (
							<Tabs.TabPane
								key={transportVendor.id}
								tab={transportVendor.name}
							>
								<div>
									<div>3PL Integration: {convertEnumToString(transportVendor.thirdPartyLogisticsType)}</div>
								</div>
							</Tabs.TabPane>
						)
					})
				}
			</Tabs>
		)
	}

	return (
		<Avatar.Group>
			{
				deliveryPlanItem.vehicle ?
					<Tooltip title={`${deliveryPlanItem.currentVehicle ? 'Assigned to' : 'Unassigned from'} vehicle: ${deliveryPlanItem.vehicle?.plateNumber}`}>
						<Popover
							content={renderVehicleDetail(deliveryPlanItem.vehicle, deliveryPlanItem.currentVehicle)}
							trigger='click'
							placement='left'
							visible={isVehicleDetailVisible}
							onVisibleChange={(isVisible) => setIsVehicleDetailVisible(isVisible)}
						>
							<Avatar
								onClick={() => setIsVehicleDetailVisible(true)}
								icon={<CarOutlined />}
								style={{ background: colorHash.hex(deliveryPlanItem.vehicle.id) }}
							/>
						</Popover>
					</Tooltip> : null
			}
			{
				deliveryPlanItem.driver ?
					<Tooltip title={`${deliveryPlanItem.assignedDriverId ? 'Assigned to' : 'Unassigned from'} driver: ${deliveryPlanItem.driver.name}`}>
						<Popover
							content={renderAssigneeDetail(deliveryPlanItem.driver, claimsRoles.DRIVER)}
							trigger='click'
							placement='left'
							visible={isDriverDetailVisible}
							onVisibleChange={(isVisible) => setIsDriverDetailVisible(isVisible)}
						>
							<Avatar
								onClick={() => setIsDriverDetailVisible(true)}
								icon={<UserOutlined />}
								style={{ background: colorHash.hex(deliveryPlanItem.driver.id) }}
							/>
						</Popover>
					</Tooltip> : null
			}
			{
				deliveryPlanItem.helper ?
					<Tooltip title={`${deliveryPlanItem.assignedHelperId ? 'Assigned to' : 'Unassigned from'} helper: ${deliveryPlanItem.helper.name}`}>
						<Popover
							content={renderAssigneeDetail(deliveryPlanItem.helper, claimsRoles.HELPER)}
							trigger='click'
							placement='left'
							visible={isHelperDetailVisible}
							onVisibleChange={(isVisible) => setIsHelperDetailVisible(isVisible)}
						>
							<Avatar
								onClick={() => setIsHelperDetailVisible(true)}
								icon={<UserOutlined />}
								style={{ background: colorHash.hex(deliveryPlanItem.helper.id) }}
							/>
						</Popover>
					</Tooltip> : null
			}
			{
				deliveryPlanItem.transportVendors?.length > 0 ?
					<Tooltip title='Transport Vendors'>
						<Popover
							content={renderTransportVendorDetail(deliveryPlanItem.transportVendors)}
							trigger='click'
							placement='left'
							visible={isTransportVendorDetailVisible}
							onVisibleChange={(isVisible) => setIsTransportVendorDetailVisible(isVisible)}
						>
							<Avatar
								onClick={() => setIsTransportVendorDetailVisible(true)}
								icon={<img src='/img/menu/transport-vendor.svg#icon' className={styles.transportVendorIcon} />}
							/>
						</Popover>
					</Tooltip> : null
			}
		</Avatar.Group>
	)
}
