import moment from 'moment'
import { get } from './index'

export const Dashboard = {
	fetchGeneralAnalyticsStatistics: (interval, fromDate, toDate, statuses, locationIds) =>
		get('/dashboard/general-analytics/statistics', {
			params: {
				interval, fromDate, toDate, statuses, locationIds
			}
		}),
	fetchGeneralAnalyticsOrderRate: (interval, fromDate, toDate, locationIds) =>
		get('/dashboard/general-analytics/order-rate', {
			params: {
				interval, fromDate, toDate, locationIds
			}
		}),
	fetchGeneralAnalyticsOrderCycleTime: (interval, fromDate, toDate, searchTerm, locationIds) =>
		get('/dashboard/general-analytics/order-cycle-time', {
			params: {
				interval, fromDate, toDate, searchTerm, locationIds
			}
		}),
	fetchGeneralAnalyticsOrderSource: (fromDate, toDate, statuses, locationIds) =>
		get('/dashboard/general-analytics/order-source', {
			params: {
				fromDate, toDate, statuses, locationIds
			}
		}),
	downloadGeneralAnalyticsOrderSourcesCsv: async ({
		fromDate,
		toDate,
		statuses,
		locationIds
	} = {}) => {
		const response = await get('/dashboard/general-analytics/order-source/csv', {
			responseType: 'blob',
			params: {
				fromDate,
				toDate,
				statuses,
				locationIds
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `order_sources_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	fetchGeneralAnalyticsOrderLeadTime: (interval, leadTimeDate) =>
		get('/dashboard/general-analytics/lead-time', {
			params: {
				interval, leadTimeDate
			}
		}),
	fetchGeneralAnalyticsCancelledOrderReason: (fromDate, toDate, locationIds) =>
		get('/dashboard/general-analytics/cancelled-orders-reason', {
			params: {
				fromDate, toDate, locationIds
			}
		}),
	fetchGeneralAnalyticsReturnedOrderReason: (fromDate, toDate, locationIds) =>
		get('/dashboard/general-analytics/returned-orders-reason', {
			params: {
				fromDate, toDate, locationIds
			}
		}),
	fetchGeneralAnalyticsDiscountAndAdvancePaymentOrderStats: (fromDate, toDate, locationIds) =>
		get('/dashboard/general-analytics/discount-and-advance-order-stats', {
			params: {
				fromDate, toDate, locationIds
			}
		}),
	fetchSalesAnalyticsOrders: (interval, timezone, status, locationIds) =>
		get('/dashboard/sales-analytics/orders', {
			params: {
				interval,
				timezone,
				status,
				locationIds
			}
		}),
	fetchSalesAnalyticsAverageBasket: (interval, timezone, status, locationIds) =>
		get('/dashboard/sales-analytics/average-basket', {
			params: {
				interval,
				timezone,
				status,
				locationIds
			}
		}),
	fetchSalesAnalyticsSales: (interval) =>
		get('/dashboard/sales-analytics/sales', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsDistributorGrowth: (interval) =>
		get('/dashboard/sales-analytics/distributor-growth', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsDivisionSales: (interval) =>
		get('/dashboard/sales-analytics/division-sales', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsItemSales: (interval) =>
		get('/dashboard/sales-analytics/item-sales', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsDistributorSales: (interval) =>
		get('/dashboard/sales-analytics/distributor-sales', {
			params: {
				interval
			}
		}),
	fetchOrdersAssignedAndCompletedRatio: (interval) =>
		get('/dashboard/delivery-partner-analytics/orders-assigned-completed-ratio', {
			params: {
				interval
			}
		}),
	fetchCollectedAmountRatio: (interval) =>
		get('/dashboard/delivery-partner-analytics/cash-collected-ratio', {
			params: {
				interval
			}
		}),
	fetchDeliveryPartnerByLocation: (interval) =>
		get('/dashboard/delivery-partner-analytics/delivery-partner-location', {
			params: {
				interval
			}
		}),
	fetchDistanceTraveledByDeliveryPartner: (interval) =>
		get('/dashboard/delivery-partner-analytics/delivery-partner-distance-traveled', {
			params: {
				interval
			}
		}),
	fetchECommerceDeliveryPartnerAnalyticsOrderRate: (fromDate, toDate, status, locationIds) =>
		get('/dashboard/delivery-partner-analytics/order-rate', {
			params: {
				fromDate,
				toDate,
				status,
				locationIds
			}
		}),
	fetchECommerceDeliveryPartnerAnalyticsSalesValue: (fromDate, toDate) =>
		get('/dashboard/delivery-partner-analytics/sales-value', {
			params: {
				fromDate, toDate
			}
		}),
	fetchSalesAnalyticsPaymentStatistics: (interval, fromDate, toDate, locationIds) =>
		get('/dashboard/sales-analytics/payment-statistics', {
			params: {
				interval, fromDate, toDate, locationIds
			}
		}),
	fetchSignUpTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/users/sign-up-trend', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchEnterpriseAccountTrends: ({ interval, industries, currentRange, event }) =>
		get('/dashboard/enterprise/account-trend', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange,
				userLogEvent: event
			}
		}),
	fetchRegisteredUserTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/users/registered-trend', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchProductTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/products', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchCustomerTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/customers', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchLocationTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/locations', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchAllOrderTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/all-orders', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchAllOrderAmountTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/all-orders/amount', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchPurchaseOrderTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/purchase-orders', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchPurchaseOrderAmountTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/purchase-orders/amount', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchAllOrderSourceTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/all-orders/source', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchAllOrderDeliveryPartnerTrends: ({ interval, industries, currentRange }) =>
		get('/dashboard/all-orders/delivery-partner', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				industries,
				currentRange
			}
		}),
	fetchOverviewCounts: ({ industries, fromDate, toDate, overviewType, getPreviousPeriod }) =>
		get('/dashboard/overview/counts', {
			params: {
				overviewType,
				fromDate,
				toDate,
				industries,
				getPreviousPeriod
			}
		}),
	fetchECommerceAccountTrends: ({ interval, currentRange, subscriptionStatus, subscriptionEvent }) =>
		get('/dashboard/e-commerce/account-trend', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				currentRange,
				subscriptionStatus,
				subscriptionEvent
			}
		}),
	fetchAllECommerceAccountTrends: ({ interval, currentRange }) =>
		get('/dashboard/e-commerce/all-account-trend', {
			params: {
				interval,
				timezone: moment.tz.guess(),
				currentRange
			}
		}),
	fetchEcommerceLiveCumulativeTrend: () =>
		get('/dashboard/e-commerce/cumulative-live-trend', {
			params: {
				timezone: moment.tz.guess()
			}
		}),
	fetchEcommerceCumulativeActiveUserTrend: () =>
		get('/dashboard/e-commerce/cumulative-active-user-trend', {
			params: {
				timezone: moment.tz.guess()
			}
		}),
	fetchEcommerceSalesOrdersMonthlyTrend: () =>
		get('/dashboard/e-commerce/sales-orders-monthly-trend', {
			params: {
				timezone: moment.tz.guess()
			}
		})
}
