import { useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './Input.module.css'
require('./Input.less')
import { Tooltip, DatePicker, Spin } from 'antd'
import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'

export const currencyFormatter = currency => {
	if (!currency) {
		return ''
	}
	return `${currency.toUpperCase()} `
}

const Input = ({
	title,
	disabled,
	min,
	max,
	value,
	onChange,
	optional,
	type,
	tooltip,
	readOnly,
	highlighted,
	highlightOnSelect,
	disabledNormalStyle,
	loading,
	style,
	small,
	labelClassName,
	inputClassName,
	autoFocus,
	textAreaHeight = 82,
	autoResize,
	popupContainer,
	showTime = false,
	error,
	currencyOverride,
	...restProps
}) => {
	const inputContainerRef = useRef(null)
	const textAreaRef = useRef(null)
	const currency = currencyOverride ? currencyOverride : localStorage.getItem('currency') || 'BDT'

	const determineStyle = () => {
		if (disabled) {
			if (disabledNormalStyle) {
				if (small) {
					return styles.smallInputContainer
				} else {
					return styles.inputContainer
				}
			} else {
				return styles.disabledInputContainer
			}
		} else {
			if (small) {
				if (highlighted) {
					return styles.highlightedSmallInputContainer
				} else {
					return styles.smallInputContainer
				}
			} else {
				if (highlighted) {
					return styles.highlightedInputContainer
				} else {
					return styles.inputContainer
				}
			}
		}
	}

	return (
		<div
			style={{
				height: type === 'textarea' && autoResize && textAreaRef.current?.value ?
					'auto' : type === 'textarea' ?
						textAreaHeight : null,
				border: error(value) ? '1px solid #f5222d' : null,
				...style
			}}
			ref={inputContainerRef}
			className={determineStyle()}
			tabIndex='1'
		>
			<div className={`${styles.title} ${labelClassName}`}>
				{title}
				{
					optional ?
						<div className={styles.optional}>(Optional)</div> : null
				}
				{
					tooltip ?
						<Tooltip placement='right' title={tooltip} className={styles.tooltip}>
							<QuestionCircleOutlined
								size='small'
							/>
						</Tooltip> : null
				}
			</div>
			{
				type === 'date' ?
					<DatePicker
						style={style}
						value={value}
						disabled={disabled}
						onChange={onChange}
						showTime={showTime}
						className={`date-picker ${inputClassName}`}
						{...restProps}
						autoFocus={autoFocus}
						getPopupContainer={() => popupContainer ? popupContainer() : document.body}
					/> : null
			}
            			{
				type === 'textarea' ?
					<textarea
						value={value}
						disabled={disabled}
						className={`${styles.input} ${inputClassName}`}
						ref={textAreaRef}
						onChange={(e) => {
							onChange?.(e)
							autoResize && (textAreaRef.current.style.height = `${e.target.scrollHeight}px`)
						}}
					/> : null
			}
			{
				type === 'currency' ?
					<div className='currency-container'>
						<NumberFormat
							className={small ? `currency-input-small ${inputClassName}` : `currency-input ${inputClassName}`}
							style={style}
							value={value}
							onValueChange={values => onChange(values.floatValue)}
							disabled={disabled}
							readOnly={readOnly}
							thousandSeparator={true}
							isNumericString={true}
							prefix={currencyFormatter(currency)}
							placeholder={`${currencyFormatter(currency)} 0.00`}
							decimalScale={2}
							fixedDecimalScale={true}
							onFocus={e => highlightOnSelect && e.target.select()}
							max={max}
							min={min}
							isAllowed={values => {
								const value = values.floatValue
								if (value < min) {
									return false
								}
								if (value > max) {
									return false
								}
								return true
							}}
							autoFocus={autoFocus}
						/>
					</div> : null
			}
			{
				loading ?
					<div style={{ position: 'absolute', right: 16, top: '50%', transform: 'translateY(-50%)' }}>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
					</div> : null
			}
		</div>
	)
}

Input.propTypes = {
	title: PropTypes.string,
	disabled: PropTypes.bool,
	min: PropTypes.number,
	max: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
	onChange: PropTypes.func,
	optional: PropTypes.bool,
	type: PropTypes.string,
	tooltip: PropTypes.string,
	readOnly: PropTypes.bool,
	highlighted: PropTypes.bool,
	highlightOnSelect: PropTypes.bool,
	disabledNormalStyle: PropTypes.bool,
	loading: PropTypes.bool,
	style: PropTypes.object,
	small: PropTypes.bool,
	labelClassName: PropTypes.string,
	inputClassName: PropTypes.string,
	autoFocus: PropTypes.bool,
	popupContainer: PropTypes.func,
	error: PropTypes.func
}

Input.defaultProps = {
	onChange: () => {},
	highlighted: false,
	highlightOnSelect: true,
	error: () => false
}

export default Input
