import styles from './TrackDeliveryStatusButton.module.css'
import Link from 'next/link'
import Image from 'next/image'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd'
import { convertEnumToString } from '../../utils'
import { getDeliveryPartnerOrderTrackingURL } from '../../utils/delivery-partners'

const TrackDeliveryStatusButton = ({ status, partnerType, trackingCode, trackingUrl, phone, disabled }) => {
	return (
		disabled ?
			<Button size='small'>
				<div className={styles.buttonContent}>
					<Image
						src='/img/map-pin.png'
						width={16}
						height={16}
						alt='Map'
					/>
					{convertEnumToString(status)}
				</div>
			</Button> :
			<Link href={getDeliveryPartnerOrderTrackingURL(partnerType, { trackingCode, trackingUrl, phone })}>
				<a target='_blank'>
					<Tooltip title='Track Order'>
						<Button size='small'>
							<div className={styles.buttonContent}>
								<Image
									src='/img/map-pin.png'
									width={16}
									height={16}
									alt='Map'
								/>
								{convertEnumToString(status)}
							</div>
						</Button>
					</Tooltip>
				</a>
			</Link>
	)
}

TrackDeliveryStatusButton.propTypes = {
	status: PropTypes.string,
	partnerType: PropTypes.string,
	trackingCode: PropTypes.string,
	phone: PropTypes.string,
	disabled: PropTypes.bool
}

TrackDeliveryStatusButton.defaultProps = {
	status: '',
	partnerType: '',
	trackingCode: '',
	phone: '',
	disabled: false
}

export default TrackDeliveryStatusButton
