import { useDispatch, useSelector } from 'react-redux'
import { setSearchParams as setSearchParamsAction } from '../store/common/actions'
import { isEmptyObject } from '../utils'

const useSearchParams = () => {
	const dispatch = useDispatch()
	const { searchParams } = useSelector(state => state.commonReducer)

	const isSearchApplied = !isEmptyObject(searchParams)

	const applySearch = (searchTerm = '') => {
		const newSearchParams = {
			...searchParams,
			searchTerm
		}
		!searchTerm && delete newSearchParams.searchTerm
		dispatch(setSearchParamsAction(newSearchParams))
	}

	const applyFilter = (filters = {}) => {
		dispatch(setSearchParamsAction({
			searchTerm: searchParams.searchTerm || '',
			...filters
		}))
	}

	const setSearchParams = (params = {}) => {
		dispatch(setSearchParamsAction(params))
	}

	const clearSearch = () => {
		const newSearchParams = {
			...searchParams
		}
		delete newSearchParams.searchTerm
		// Fix API's returning wrong results bug when the searchTerm parameter is not passed in
		!isEmptyObject(newSearchParams) && (newSearchParams.searchTerm = '')
		dispatch(setSearchParamsAction(newSearchParams))
	}

	const clearFilter = () => {
		const newSearchParams = {}
		if (searchParams.searchTerm) {
			newSearchParams.searchTerm = searchParams.searchTerm
		}
		dispatch(setSearchParamsAction(newSearchParams))
	}

	const clearSearchParams = () => {
		dispatch(setSearchParamsAction({}))
	}

	return {
		searchParams,
		isSearchApplied,
		applySearch,
		applyFilter,
		setSearchParams,
		clearSearch,
		clearFilter,
		clearSearchParams
	}
}

export default useSearchParams
