import { Permissions } from '../../services/api/permissions'
import { Users } from '../../services/api/users'
import { FETCH_PERMISSION_GROUPS_SUCCESS, CREATE_PERMISSION_GROUP_SUCCESS, FETCH_PERMISSION_GROUP_DETAILS_SUCCESS, FETCH_PERMISSION_GROUP_USERS_SUCCESS, EDIT_PERMISSION_GROUP_SUCCESS } from './action-types'

export function fetchPermissionGroups () {
	return async (dispatch) => {
		const response = await Permissions.fetchPermissionGroups({ includeDefaultGroups: true })
		dispatch({ type: FETCH_PERMISSION_GROUPS_SUCCESS, payload: response.data })
	}
}

export function fetchPermissionGroupDetails (id) {
	return async (dispatch) => {
		const response = await Permissions.fetchPermissionGroupDetails(id)
		dispatch({ type: FETCH_PERMISSION_GROUP_DETAILS_SUCCESS, payload: response.data })
	}
}

export function createPermissionGroup (data) {
	return async (dispatch) => {
		const response = await Permissions.createPermissionGroup(data)
		dispatch({ type: CREATE_PERMISSION_GROUP_SUCCESS, payload: response.data })
	}
}

export function editPermissionGroup (id, data) {
	return async (dispatch) => {
		const response = await Permissions.updatePermissionGroup(id, data)
		dispatch({ type: EDIT_PERMISSION_GROUP_SUCCESS, payload: response.data })
	}
}

export function fetchPermissionGroupUsers (page, permissionGroupId, role) {
	return async (dispatch) => {
		const response = await Users.index({ page, permissionGroupId, role })
		dispatch({ type: FETCH_PERMISSION_GROUP_USERS_SUCCESS, payload: { id: permissionGroupId || role, data: response.data } })
	}
}
