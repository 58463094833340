import { HYDRATE } from 'next-redux-wrapper'
import {
	FETCH_GEOFENCES_SUCCESS,
	CREATE_GEOFENCE_SUCCESS,
	UPDATE_GEOFENCE_SUCCESS,
	DELETE_GEOFENCE_SUCCESS
} from './action-types'

const initialState = {
	geofences: []
}

const geofencesReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_GEOFENCES_SUCCESS: {
			return {
				...state,
				geofences: payload.data
			}
		}
		case CREATE_GEOFENCE_SUCCESS: {
			const geofences = [payload.data, ...state.geofences]
			return {
				...state,
				geofences
			}
		}
		case UPDATE_GEOFENCE_SUCCESS: {
			const geofences = state.geofences.map(geofence => {
				if (geofence.id === payload.data.id) {
					return payload.data
				} else {
					return geofence
				}
			})
			return {
				...state,
				geofences
			}
		}
		case DELETE_GEOFENCE_SUCCESS: {
			const geofences = state.geofences.filter(geofence => geofence.id !== payload.id)
			return {
				...state,
				geofences
			}
		}
		default:
			return state
	}
}

export default geofencesReducer
