import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio } from 'antd'
import Input from '../input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import { tabs } from '../../pages/sales-orders'
import styles from './GeneralSalesOrderFilter.module.css'
import { roles } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import { convertEnumToString } from '../../utils'
import { Users } from '../../services/api/users'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import NumberInput from '../number-input'

export const OrderType = {
	SALES_ORDER: 'sales_order',
	STOCK_TRANSFER_ORDER: 'stock_transfer_order'
}

const GeneralSalesOrderFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen,
	onFilter
}) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { companyDetails, userProfile } = useSelector(state => state.authReducer)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [pickUpLocations, setPickUpLocations] = useState([])
	const [pickUpLocation, setPickUpLocation] = useState(null)
	const [accountManagers, setAccountManagers] = useState([])
	const [accountManager, setAccountManager] = useState(null)
	const [status, setStatus] = useState(searchParams.status || null)
	const [fromDate, setFromDate] = useState(searchParams.fromDate || null)
	const [toDate, setToDate] = useState(searchParams.toDate || null)
	const [isStockTransfer, setIsStockTransfer] = useState(searchParams.isStockTransfer)
	const [minOrderValue, setMinOrderValue] = useState(searchParams.minOrderValue)
	const [maxOrderValue, setMaxOrderValue] = useState(searchParams.maxOrderValue)
	const [isLoadingPickupLocations, setIsLoadingPickupLocations] = useState(false)
	const [isLoadingAccountManagers, setIsLoadingAccountManagers] = useState(false)
	const orderStatusFlow = companyDetails?.orderStatusFlow

	useEffect(() => {
		if (!isFilterDropdownOpen) {
			return
		}
		getInitialAccountManagers()
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (isFilterDropdownOpen && userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setPickUpLocations(locations)
				if (locations.length === 1) {
					const location = locations[0]
					const { label, id } = location
					setPickUpLocation({ label, value: id, data: location })
				}
			} else {
				fetchPickUpLocations()
			}
		}
	}, [isFilterDropdownOpen, userProfile])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!pickUpLocations.length || !searchParams.pickUpLocationIds?.length) {
			return
		}
		const pickUpLocation = pickUpLocations.find(({ id }) => id === searchParams.pickUpLocationIds[0])

		setPickUpLocation({
			id: pickUpLocation.id,
			key: pickUpLocation.id,
			label: pickUpLocation.label,
			data: pickUpLocation
		})
	}, [pickUpLocations])

	useEffect(() => {
		if (!accountManagers.length || !searchParams.accountManagerId) {
			return
		}
		const accountManager = accountManagers.find(({ id }) => id === searchParams.accountManagerId)

		setAccountManager({
			id: accountManager.id,
			key: accountManager.id,
			label: accountManager.name,
			data: accountManager
		})
	}, [accountManagers])

	const getInitialAccountManagers = async () => {
		setIsLoadingAccountManagers(true)
		const response = await Users.index({ role: roles.SALES_MANAGER, disabled: false })
		const accountManagers = response.data.results
		setAccountManagers(accountManagers)
		setIsLoadingAccountManagers(false)
	}

	const fetchPickUpLocations = async () => {
		setIsLoadingPickupLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY], page: -1 })
		setPickUpLocations(response.data.results)
		setIsLoadingPickupLocations(false)
	}

	const searchAccountManagers = value => {
		return Users.index({ searchTerm: value, role: roles.SALES_MANAGER, disabled: false })
	}

	const handleApply = async () => {
		const filters = {}

		if (fromDate && toDate) {
			filters.fromDate = moment(fromDate).startOf('day').toDate()
			filters.toDate = moment(toDate).endOf('day').toDate()
		}
		if (!isNaN(minOrderValue) && !isNaN(maxOrderValue)) {
			filters.minOrderValue = minOrderValue
			filters.maxOrderValue = maxOrderValue
		}
		if (isStockTransfer !== undefined) {
			filters.isStockTransfer = isStockTransfer
		}
		if (pickUpLocation) {
			filters.pickUpLocationIds = [pickUpLocation.value]
		}
		if (accountManager && !isStockTransfer) {
			filters.accountManagerId = accountManager.value
		}
		if (status) {
			filters.status = status
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
		onFilter?.(filters)
	}

	const isFilterEnabled = () => {
		return (fromDate && toDate) || status ||
			(minOrderValue !== undefined && maxOrderValue !== undefined) ||
			isStockTransfer !== undefined ||
			pickUpLocation !== null ||
			accountManager !== null && !isStockTransfer
	}

	const resetFilter = () => {
		setFromDate(null)
		setToDate(null)
		setIsStockTransfer(undefined)
		setPickUpLocation(null)
		setAccountManager(null)
		setAccountManagers([])
		setMinOrderValue()
		setMaxOrderValue()
		setStatus(null)
		clearFilter()
	}

	const modifiedTabs = orderStatusFlow ? tabs.filter(tab => orderStatusFlow.statuses.includes(tab.status.toUpperCase())) : tabs

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Order Type</p>
				<Radio.Group
					className={styles.inputGroup}
					value={isStockTransfer}
					onChange={e => setIsStockTransfer(e.target.value)}
				>
					<Radio className={styles.inputTitle} value={false}>Sales Order</Radio>
					<Radio className={styles.inputTitle} value={true}>Stock Transfer Order</Radio>
				</Radio.Group>
			</div>
			<div>
				<p className={styles.title}>Order Status</p>
				<Radio.Group
					className={styles.inputGroup}
					value={status}
					onChange={e => setStatus(e.target.value)}
				>
					{
						modifiedTabs.map((tab) => {
							return (
								<Radio className={styles.inputTitle} key={tab.status} value={tab.status}>{tab.title}</Radio>
							)
						})
					}
				</Radio.Group>
			</div>
			<div>
				<p className={styles.title}>Shipping Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						title='From'
						type='date'
						value={fromDate ? moment(fromDate) : null}
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						title='To'
						type='date'
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Total Order Value</p>
				<div className={styles.inputFlex}>
					<NumberInput
						title='Min'
						value={minOrderValue}
						onChange={(value) => setMinOrderValue(+value)}
					/>
					<NumberInput
						title='Max'
						value={maxOrderValue}
						onChange={(value) => setMaxOrderValue(+value)}
					/>
				</div>
			</div>
			<div>
				<div className={styles.inputGrid}>
					<div>
						<p className={styles.title}>Pick Up Location</p>
						<CustomSearchableSelectSecondary
							placeholder='Select Pick Up Location'
							valueIndex='id'
							descriptionIndex='address'
							customLabel={(data) => data.internalId ? data.internalId : data.label ? data.label : convertEnumToString(data.type.toLowerCase())}
							allowClear={true}
							showSearch={false}
							isLoading={isLoadingPickupLocations}
							defaultOptions={pickUpLocations}
							value={pickUpLocation}
							onChange={setPickUpLocation}
							onClear={() => setPickUpLocation(null)}
						/>
					</div>
					{
						!isStockTransfer &&
						<div>
							<p className={styles.title}>Area Manager</p>
							<CustomSearchableSelectSecondary
								placeholder='Select Area Manager'
								labelIndex='name'
								valueIndex='id'
								allowClear={true}
								isLoading={isLoadingAccountManagers}
								defaultOptions={accountManagers}
								value={accountManager}
								onChange={option => {
									if (option?.value) {
										setAccountManager(option)
									}
								}}
								onClear={() => setAccountManager(null)}
								searchTask={searchAccountManagers}
							/>
						</div>
					}
				</div>
			</div>
			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

GeneralSalesOrderFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func,
	onFilter: PropTypes.func
}

export default GeneralSalesOrderFilter
