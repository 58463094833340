export const FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS = 'fetch_dashboard_general_analytics_statistics_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS = 'fetch_dashboard_general_order_rate_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS = 'fetch_dashboard_general_order_cycle_time_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS = 'fetch_dashboard_general_order_lead_time_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_SOURCE_SUCCESS = 'fetch_dashboard_general_order_source_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_CANCELLED_ORDER_REASON_SUCCESS = 'fetch_dashboard_general_cancelled_order_reason_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_RETURNED_ORDER_REASON_SUCCESS = 'fetch_dashboard_general_returned_order_reason_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_DISCOUNT_AND_ADVANCE_PAYMENT_ORDER_STATS_SUCCESS = 'fetch_dashboard_general_discount_and_advanced_payment_order_stats_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS = 'fetch_dashboard_sales_analytics_orders_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_AVERAGE_BASKET_SUCCESS = 'fetch_dashboard_sales_analytics_average_basket_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS = 'fetch_dashboard_sales_analytics_sales_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS = 'fetch_dashboard_sales_analytics_distributor_growth_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS = 'fetch_dashboard_sales_analytics_division_sales_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS = 'fetch_dashboard_sales_analytics_item_sales_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS = 'fetch_dashboard_sales_analytics_distributor_sales_success'
export const FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS = 'fetch_dashboard_delivery_partner_analytics_order_assigned_and_completed_ratio_success'
export const FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS = 'fetch_dashboard_delivery_partner_analytics_total_and_collected_amount_ratio_success'
export const FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS = 'fetch_dashboard_delivery_partner_analytics_delivery_partner_by_location_success'
export const FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS = 'fetch_dashboard_delivery_partner_analytics_distance_traveled_by_delivery_partner_success'
export const FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_ORDER_RATE_SUCCESS = 'fetch_dashboard_ecommerce_delivery_partner_analytics_order_rate_success'
export const FETCH_DASHBOARD_ECOMMERCE_DELIVERY_PARTNER_ANALYTICS_SALES_VALUE_SUCCESS = 'fetch_dashboard_ecommerce_delivery_partner_analytics_sales_value_success'
export const FETCH_TOP_E_COMMERCE_CUSTOMER = 'fetch_top_e_commerce_customers'
export const FETCH_TOP_SELLING_PRODUCTS = 'fetch_top_selling_products'
export const FETCH_TOP_SELLING_PRODUCTS_IN_TIME_RANGE = 'fetch_top_selling_products_in_time_range'
export const FETCH_DASHBOARD_SALES_ANALYTICS_PAYMENT_STATISTICS_SUCCESS = 'fetch_dashboard_sales_analytics_payment_statistics_success'
export const FETCH_DASHBOARD_SIGN_UP_TRENDS_SUCCESS = 'fetch_dashboard_sign_up_trends_success'
export const FETCH_DASHBOARD_ENTERPRISE_LIVE_ACCOUNT_TRENDS_SUCCESS = 'fetch_dashboard_enterprise_live_account_trends_success'
export const FETCH_DASHBOARD_ENTERPRISE_CHURN_ACCOUNT_TRENDS_SUCCESS = 'fetch_dashboard_churn_enterprise_account_trends_success'
export const FETCH_DASHBOARD_REGISTERED_TRENDS_SUCCESS = 'fetch_dashboard_registered_trends_success'
export const FETCH_DASHBOARD_PRODUCT_TRENDS_SUCCESS = 'fetch_dashboard_product_trends_success'
export const FETCH_DASHBOARD_CUSTOMER_TRENDS_SUCCESS = 'fetch_dashboard_customer_trends_success'
export const FETCH_DASHBOARD_LOCATION_TRENDS_SUCCESS = 'fetch_dashboard_location_trends_success'
export const FETCH_DASHBOARD_ORDER_TRENDS_SUCCESS = 'fetch_dashboard_order_trends_success'
export const FETCH_DASHBOARD_ORDER_AMOUNT_TRENDS_SUCCESS = 'fetch_dashboard_order_amount_trends_success'
export const FETCH_DASHBOARD_ORDER_SOURCE_TRENDS_SUCCESS = 'fetch_dashboard_order_source_trends_success'
export const FETCH_DASHBOARD_ORDER_DELIVERY_PARTNER_TRENDS_SUCCESS = 'fetch_dashboard_order_delivery_partner_trends_success'
export const FETCH_DASHBOARD_PURCHASE_ORDER_TRENDS_SUCCESS = 'fetch_dashboard_purchase_order_trends_success'
export const FETCH_DASHBOARD_PURCHASE_ORDER_AMOUNT_TRENDS_SUCCESS = 'fetch_dashboard_purchase_order_amount_trends_success'
export const FETCH_DASHBOARD_ECOMMERCE_TRIAL_ACCOUNT_TRENDS_SUCCESS = 'fetch_dashboard_ecommerce_trial_account_trends_success'
export const FETCH_DASHBOARD_ECOMMERCE_LIVE_ACCOUNT_TRENDS_SUCCESS = 'fetch_dashboard_ecommerce_live_account_trends_success'
export const FETCH_DASHBOARD_ECOMMERCE_CHURNED_ACCOUNT_TRENDS_SUCCESS = 'fetch_dashboard_ecommerce_churned_account_trends_success'
export const FETCH_DASHBOARD_ECOMMERCE_ALL_ACCOUNT_TRENDS_SUCCESS = 'fetch_dashboard_ecommerce_all_account_trends_success'
export const FETCH_DASHBOARD_ECOMMERCE_LIVE_CUMULATIVE_TREND_SUCCESS = 'fetch_dashboard_ecommerce_live_cumulative_trend_success'
export const FETCH_DASHBOARD_ECOMMERCE_CUMULATIVE_ACTIVE_USER_TREND_SUCCESS = 'fetch_dashboard_ecommerce_cumulative_active_user_trend_success'
export const FETCH_DASHBOARD_ECOMMERCE_SALES_ORDERS_MONTHLY_TREND_SUCCESS = 'fetch_dashboard_ecommerce_sales_orders_monthly_trend_success'
