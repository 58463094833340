export const DELIVERY_PLAN_PENDING_STATUS = 'PENDING'
export const DELIVERY_PLAN_SCHEDULED_STATUS = 'SCHEDULED'
export const DELIVERY_PLAN_DELIVERED_STATUS = 'DELIVERED'
export const DELIVERY_PLAN_CANCELLED_STATUS = 'CANCELLED'

export const deliveryPlanScalars = {
	WEIGHT: 'WEIGHT',
	VOLUME: 'VOLUME'
}

export const DELIVERY_PLAN_STATUS_COLORS = {
	[DELIVERY_PLAN_PENDING_STATUS]: '#1890ff',
	[DELIVERY_PLAN_SCHEDULED_STATUS]: '#1890ff',
	[DELIVERY_PLAN_DELIVERED_STATUS]: '#278EA5',
	[DELIVERY_PLAN_CANCELLED_STATUS]: '#6e7c91'
}

export const statusMenuItems = [
	DELIVERY_PLAN_PENDING_STATUS,
	DELIVERY_PLAN_SCHEDULED_STATUS,
	DELIVERY_PLAN_DELIVERED_STATUS,
	DELIVERY_PLAN_CANCELLED_STATUS
]

export const getDeliveryPlanStatusLabel = (status) => {
	switch (status) {
		case DELIVERY_PLAN_PENDING_STATUS:
			return 'Pending'
		case DELIVERY_PLAN_SCHEDULED_STATUS:
			return 'Scheduled'
		case DELIVERY_PLAN_DELIVERED_STATUS:
			return 'Completed'
		case DELIVERY_PLAN_CANCELLED_STATUS:
			return 'Cancelled'
		default:
			return status
	}
}

export const getLoadedCapacityInPercentage = (weight = 0, weightLimit) => {
	if (!weightLimit) {
		return 0
	}
	return Math.ceil((weight * 100) / weightLimit)
}
