import { useState, useEffect } from 'react'

function getStorageValue (key, defaultValue) {
	if (typeof window !== 'undefined') {
		const savedItem = sessionStorage.getItem(key)
		let parsedItem
		try {
			parsedItem = JSON.parse(savedItem)
		} catch (e) {
			parsedItem = JSON.parse(JSON.stringify(savedItem))
		}
		return typeof parsedItem === 'boolean' ? parsedItem : parsedItem || defaultValue
	}
}

export function useSessionStorage (key, defaultValue) {
	const [value, setValue] = useState(() => {
		return getStorageValue(key, defaultValue)
	})

	useEffect(() => {
		let valueToSave
		if (typeof value === 'string') {
			valueToSave = value
		} else {
			valueToSave = JSON.stringify(value)
		}
		sessionStorage.setItem(key, valueToSave)
	}, [key, value])

	return [value, setValue]
}
