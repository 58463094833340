import { HYDRATE } from 'next-redux-wrapper'
import {
	ADD_TRANSPORT_QUOTATION_SUCCESS,
	FETCH_TRANSPORT_QUOTATIONS_SUCCESS,
	FETCH_TRANSPORT_QUOTATION_DETAILS_SUCCESS,
	UPDATE_TRANSPORT_QUOTATION_SUCCESS
} from './action-types'

const initialState = {
	totalCount: 0,
	page: 0,
	transportQuotations: [],
	transportQuotationDetails: {}
}

const transportQuotationsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_TRANSPORT_QUOTATIONS_SUCCESS: {
			return {
				...state,
				transportQuotations: payload.results,
				totalCount: payload.count,
				page: +payload.page
			}
		}
		case ADD_TRANSPORT_QUOTATION_SUCCESS: {
			const transportQuotations = [payload, ...state.transportQuotations]
			return {
				...state,
				transportQuotations
			}
		}
		case UPDATE_TRANSPORT_QUOTATION_SUCCESS: {
			const newState = { ...state }
			const transportQuotation = newState.transportQuotationDetails[payload.id]
			if (transportQuotation) {
				const updatedTransportQuotation = Object.assign(transportQuotation, payload)
				newState.transportQuotationDetails[payload.id] = updatedTransportQuotation
			}
			newState.transportQuotations = newState.transportQuotations.map(quotation => {
				return quotation.id === payload.id ? { ...quotation, ...payload } : quotation
			})
			return newState
		}
		case FETCH_TRANSPORT_QUOTATION_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.transportQuotationDetails[payload.id] = payload.data
			return newState
		}
		default:
			return state
	}
}

export default transportQuotationsReducer
