export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS'
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS'
export const FETCH_VEHICLE_TYPES_SUCCESS = 'FETCH_VEHICLE_TYPES_SUCCESS'
export const FETCH_VEHICLE_DETAIL_SUCCESS = 'FETCH_VEHICLE_DETAIL_SUCCESS'
export const FETCH_VEHICLE_TRIPS_SUCCESS = 'FETCH_VEHICLE_TRIPS_SUCCESS'
export const FETCH_MORE_VEHICLE_TRIPS_SUCCESS = 'FETCH_MORE_VEHICLE_TRIPS_SUCCESS'
export const FETCH_VEHICLE_METRICS_SUCCESS = 'FETCH_VEHICLE_METRICS_SUCCESS'
export const FETCH_TRACKER_TYPES_SUCCESS = 'FETCH_TRACKER_TYPES_SUCCESS'
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS'
export const FETCH_VEHICLE_REFUELS_SUCCESS = 'FETCH_VEHICLE_REFUELS_SUCCESS'
export const CREATE_VEHICLE_REFUEL_SUCCESS = 'CREATE_VEHICLE_REFUEL_SUCCESS'
export const FETCH_VEHICLE_REFUEL_DETAIL_SUCCESS = 'FETCH_VEHICLE_REFUEL_DETAIL_SUCCESS'
export const UPDATE_VEHICLE_REFUEL_SUCCESS = 'UPDATE_VEHICLE_REFUEL_SUCCESS'
