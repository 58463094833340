import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './DeliveryAreaSelect.module.css'
import { Button, Dropdown, Menu, Spin, Tag } from 'antd'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { usePrevious } from '../../hooks/usePrevious'
import { RightOutlined } from '@ant-design/icons'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import axios from 'axios'

const DeliveryAreaSelect = ({
	title,
	deliveryPartnerId,
	value,
	onChange,
	disabled,
	style,
	address,
	popupContainer
}) => {
	const [isLoadingDeliveryAreaSuggestion, setIsLoadingDeliveryAreaSuggestion] = useState(false)
	const [deliveryAreaSuggestion, setDeliveryAreaSuggestion] = useState()
	const [areaSearchTerm, setAreaSearchTerm] = useState('')
	const debouncedAreaSearchTerm = useDebounceValue(areaSearchTerm, 750)
	const [isLoadingDeliveryAreaSuggestions, setIsLoadingDeliveryAreaSuggestions] = useState(false)
	const [deliveryCitySuggestions, setDeliveryCitySuggestions] = useState([])
	const [selectedCity, setSelectedCity] = useState(value?.city || null)
	const [selectedZone, setSelectedZone] = useState(value?.zone || null)
	const [selectedArea, setSelectedArea] = useState(value?.area || null)
	const previousValue = usePrevious(value)
	let controller = new AbortController()

	useEffect(() => {
		if (deliveryPartnerId) {
			getDeliveryAreaSuggestions()
		}
	}, [deliveryPartnerId])

	useEffect(() => {
		onChange({
			city: selectedCity,
			zone: selectedZone,
			area: selectedArea
		})
		getDeliveryAreaSuggestions()
	}, [selectedArea, selectedZone, selectedCity])

	useEffect(() => {
		if (
			previousValue?.city?.value !== value?.city?.value
			|| previousValue?.zone?.value !== value?.zone?.value
			|| previousValue?.area?.value !== value?.area?.value
		) {
			setSelectedCity(value?.city || null)
			setSelectedZone(value?.city ? value?.zone || null : null)
			setSelectedArea(value?.zone ? value?.area || null : null)
		}
	}, [value])

	useEffect(() => {
		if (address) {
			getDeliveryAreaSuggestion()
		}
	}, [address, deliveryPartnerId])

	useEffect(() => {
		if (debouncedAreaSearchTerm) {
			if (!debouncedAreaSearchTerm?.trim() || debouncedAreaSearchTerm.length < 3) {
				getDeliveryAreaSuggestions()
				return
			}
			getDeliveryAreaSuggestions(debouncedAreaSearchTerm)
		} else {
			getDeliveryAreaSuggestions()
		}
	}, [debouncedAreaSearchTerm])

	const getSuggestionValue = (suggestion) => {
		let value = ''
		if (suggestion.city_id) {
			value += suggestion.city_id
		}
		if (suggestion.zone_id) {
			value += `-${suggestion.zone_id}`
		}
		if (suggestion.area_id) {
			value += `-${suggestion.area_id}`
		}
		return value
	}

	const getDeliveryAreaSuggestion = async () => {
		try {
			if (!address) {
				return
			}
			if (!deliveryPartnerId) {
				setDeliveryAreaSuggestion(null)
			}
			setIsLoadingDeliveryAreaSuggestion(true)
			const { data } = await DeliveryPartners.getDeliveryAreaSuggestion(deliveryPartnerId, address)
			setDeliveryAreaSuggestion(data)
		} catch (e) {
			setDeliveryAreaSuggestion(null)
		} finally {
			setIsLoadingDeliveryAreaSuggestion(false)
		}
	}

	const onApplyDeliveryAreaSuggestion = () => {
		if (!deliveryAreaSuggestion) {
			return
		}
		if (deliveryAreaSuggestion.district_id) {
			const city = { value: deliveryAreaSuggestion?.district_id, label: deliveryAreaSuggestion?.district_name }
			setSelectedCity(city)
		} else {
			setSelectedCity(null)
		}
		if (deliveryAreaSuggestion.zone_id) {
			const zone = { value: deliveryAreaSuggestion?.zone_id, label: deliveryAreaSuggestion?.zone_name }
			setSelectedZone(zone)
		} else {
			setSelectedZone(null)
		}
		if (deliveryAreaSuggestion.area_id) {
			const area = { value: deliveryAreaSuggestion?.area_id, label: deliveryAreaSuggestion?.area_name }
			setSelectedArea(area)
		} else {
			setSelectedArea(null)
		}
	}

	const getDeliveryAreaSuggestions = async (searchTerm) => {
		try {
			if (!deliveryPartnerId) {
				return
			}

			controller.abort()
			controller = new AbortController()

			setIsLoadingDeliveryAreaSuggestions(true)
			const { data } = await DeliveryPartners.getDeliveryAreaSuggestions(deliveryPartnerId, {
				searchTerm,
				cityId: selectedCity?.value,
				zoneId: selectedZone?.value
			}, controller.signal)
			const formattedData = data.map(datum => {
				return {
					city_id: selectedCity?.value,
					city_name: selectedCity?.label,
					zone_id: selectedZone?.value,
					zone_name: selectedZone?.label,
					...datum
				}
			})
			setDeliveryCitySuggestions(formattedData)
		} catch (e) {
			if (!axios.isCancel(e)) {
				setDeliveryCitySuggestions([])
			}
			console.error(e)
		} finally {
			setIsLoadingDeliveryAreaSuggestions(false)
		}
	}

	const onAreaSelected = (suggestion) => {
		if (suggestion.city_id) {
			setSelectedCity({ value: suggestion.city_id, label: suggestion.city_name })
		}
		if (suggestion.zone_id) {
			setSelectedZone({ value: suggestion.zone_id, label: suggestion.zone_name })
		}
		if (suggestion.area_id) {
			setSelectedArea({ value: suggestion.area_id, label: suggestion.area_name })
		}
		setAreaSearchTerm('')
	}

	const renderAreaSuggestions = () => {
		return (
			<Menu className={styles.suggestions}>
				{
					deliveryCitySuggestions.map((suggestion) => {
						const value = getSuggestionValue(suggestion)
						return (
							<Menu.Item
								key={value}
								onClick={() => onAreaSelected(suggestion)}
							>
								<div className={styles.deliveryAreaOption}>
									{
										suggestion.city_id &&
										<div className={styles.deliveryAreaOptionValue}>
											{suggestion.city_name}
										</div>
									}
									{
										suggestion.zone_id &&
										<div className={styles.deliveryAreaOptionValue}>
											{
												suggestion.city_id &&
												<RightOutlined />
											}
											{suggestion.zone_name}
										</div>
									}
									{
										suggestion.area_id &&
										<div className={styles.deliveryAreaOptionValue}>
											{
												suggestion.zone_id &&
												<RightOutlined />
											}
											{suggestion.area_name}
										</div>
									}
								</div>
							</Menu.Item>
						)
					})
				}
			</Menu>
		)
	}

	return (
		<div>
			{
				deliveryAreaSuggestion && deliveryAreaSuggestion?.district_name &&
				<div className={styles.deliveryAreaSuggestionContainer}>
					Delivery Area Suggestion
					<div className={styles.deliveryAreaSuggestions}>
						{
							(isLoadingDeliveryAreaSuggestion || isLoadingDeliveryAreaSuggestions) &&
							<Spin size='small' style={{ marginRight: 8 }} />
						}
						{
							deliveryAreaSuggestion?.district_name &&
						<div className={styles.deliveryAreaSuggestion}>
							{deliveryAreaSuggestion?.district_name}
						</div>
						}
						{
							deliveryAreaSuggestion?.zone_name &&
						<div className={styles.deliveryAreaSuggestion}>
							<RightOutlined />
							{deliveryAreaSuggestion?.zone_name}
						</div>
						}
						{
							deliveryAreaSuggestion?.area_id &&
						<div className={styles.deliveryAreaSuggestion}>
							<RightOutlined />
							{deliveryAreaSuggestion?.area_name}
						</div>
						}
						<Button
							size='small'
							style={{ marginLeft: 12 }}
							loading={isLoadingDeliveryAreaSuggestion}
							onClick={onApplyDeliveryAreaSuggestion}
						>
							Apply
						</Button>
					</div>
				</div>
			}
			<div className={styles.deliverySelectContainer} style={style}>
				{
					title ?
						<div className={styles.title}>{title}</div> : null
				}
				<Dropdown
					overlay={renderAreaSuggestions()}
					trigger={['click']}
					getPopupContainer={popupContainer ? popupContainer : () => document.querySelector(`.${styles.deliverySelectContainer}`)}
					placement='bottomCenter'
					disabled={disabled}
				>
					<div className={styles.selectsContainer}>
						<div
							className={styles.inputContainer}
						>
							{
								selectedCity && selectedCity?.value &&
								<Tag
									style={{ marginRight: 0 }}
									closable
									onClose={() => {
										setSelectedCity(null)
										setSelectedZone(null)
										setSelectedArea(null)
									}}
								>
									{selectedCity.label}
								</Tag>
							}
							{
								selectedZone && selectedZone?.value &&
								<RightOutlined />
							}
							{
								selectedZone && selectedZone?.value &&
								<Tag
									style={{ marginRight: 0 }}
									closable
									onClose={() => {
										setSelectedZone(null)
										setSelectedArea(null)
									}}
								>
									{selectedZone.label}
								</Tag>
							}
							{
								selectedArea && selectedArea?.value &&
								<RightOutlined />
							}
							{
								selectedArea && selectedArea?.value &&
								<Tag
									style={{ marginRight: 0 }}
									closable
									onClose={() => setSelectedArea(null)}
								>
									{selectedArea.label}
								</Tag>
							}
							<input
								className={styles.input}
								onChange={(e) => setAreaSearchTerm(e.target.value)}
								value={areaSearchTerm}
								disabled={disabled}
							/>
						</div>
					</div>
				</Dropdown>
			</div>
		</div>
	)
}

DeliveryAreaSelect.propTypes = {
	title: PropTypes.node,
	deliveryPartnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
	value: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	address: PropTypes.string,
	popupContainer: PropTypes.func
}

DeliveryAreaSelect.defaultProps = {
	title: '',
	deliveryPartnerId: null,
	value: null,
	onChange: () => {},
	disabled: false,
	style: {},
	address: null,
	popupContainer: null
}

export default DeliveryAreaSelect
