import moment from 'moment'
import { get, post } from '.'

export const Forecasts = {
	initiateOrderForecasts: () =>
		post('/forecasts/orders', {
			timezone: moment.tz.guess()
		}),
	fetchOrderForecasts: ({
		horizon,
		interval
	}) => get('/forecasts/orders', {
		params: {
			timezone: moment.tz.guess(),
			horizon,
			interval
		}
	})
}
