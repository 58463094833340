import axios from 'axios'

let autocompleteService
const getAutocompleteService = () => {
	if (!autocompleteService) {
		autocompleteService = new window.google.maps.places.AutocompleteService()
	}
	return autocompleteService
}

let geocoder
const getGeocoder = () => {
	if (!geocoder) {
		geocoder = new window.google.maps.Geocoder()
	}
	return geocoder
}

const LOCATION_IQ_BASE_URL = 'https://api.locationiq.com/v1'
const locationIQApiClient = axios.create({
	baseURL: LOCATION_IQ_BASE_URL
})

const BARIKOI_BASE_URL = 'https://barikoi.xyz/v1/api/search'
const barikoiApiClient = axios.create({
	baseURL: BARIKOI_BASE_URL
})

export const Maps = {
	autocompleteV2: async (searchTerm, country, tag) => {
		try {
			if (country === 'BD') {
				try {
					const { data } = await barikoiApiClient.get(`/autocomplete/${process.env.NEXT_PUBLIC_BARIKOI_API_KEY}/place`, {
						params: {
							q: searchTerm,
							bangla: true
						}
					})
					if (data.status === 200) {
						const places = data.places || []
						return places.map(place => {
							return {
								display_name: place.address,
								place_id: `${place.id}`,
								address: null,
								lat: +place.latitude,
								lon: +place.longitude
							}
						})
					} else {
						return []
					}
				} catch (e) {
					const { data } = await locationIQApiClient.get('/autocomplete', {
						params: {
							key: process.env.NEXT_PUBLIC_LOCATION_IQ_API_KEY,
							q: searchTerm,
							countrycodes: country,
							tag
						}
					})
					return data
				}
			} else {
				const { data } = await locationIQApiClient.get('/autocomplete', {
					params: {
						key: process.env.NEXT_PUBLIC_LOCATION_IQ_API_KEY,
						q: searchTerm,
						countrycodes: country,
						tag
					}
				})
				return data
			}
		} catch (e) {
			return []
		}
	},
	autocomplete: (searchTerm, country, types = []) => {
		return new Promise(resolve => {
			if (!searchTerm || searchTerm.trim().length === 0) {
				resolve([])
				return
			}
			getAutocompleteService().getPlacePredictions(
				{
					input: searchTerm,
					componentRestrictions: { country },
					types
				},
				(predictions, status) => {
					if (status === window.google.maps.places.PlacesServiceStatus.OK) {
						resolve(predictions)
					} else {
						resolve([])
					}
				}
			)
		})
	},
	getPlaceDetailsV2: async (lat, lng) => {
		try {
			const { data } = await barikoiApiClient.get(`/reverse/${process.env.NEXT_PUBLIC_BARIKOI_API_KEY}/geocode`, {
				params: {
					latitude: lat,
					longitude: lng,
					district: true,
					division: true,
					post_code: true
				}
			})
			if (data.place) {
				return data.place
			} else {
				return null
			}
		} catch (e) {
			return null
		}
	},
	getPlaceDetails: (placeId) => {
		return new Promise(resolve => {
			getGeocoder().geocode(
				{
					placeId
				},
				(results, status) => {
					if (status === window.google.maps.GeocoderStatus.OK) {
						resolve(results[0])
					} else {
						resolve()
					}
				}
			)
		})
	},
	reverseGeocode: (lat, lng) => {
		return new Promise(resolve => {
			getGeocoder().geocode({
				location: { lat, lng }
			}, (results, status) => {
				if (status === window.google.maps.GeocoderStatus.OK) {
					resolve(results[0].formatted_address)
				} else {
					resolve()
				}
			})
		})
	},
	getGeometry: (address) => {
		return new Promise(resolve => {
			getGeocoder().geocode(
				{ address },
				(results) => {
					if (results.length) {
						resolve(results[0].geometry)
					} else {
						resolve()
					}
				}
			)
		})
	}
}
