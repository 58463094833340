import { get, post, patch } from '.'

export const Expenses = {
	index: ({
		page = 0,
		status,
		searchTerm,
		expenseTypeId,
		assigneeId,
		creationFromDate,
		creationToDate
	} = {}) => get('/expenses', {
		params: {
			page,
			status,
			searchTerm,
			expenseTypeId,
			assigneeId,
			creationFromDate,
			creationToDate
		}
	}),
	create: (params) =>
		post('/expenses', params),
	single: (
		id
	) =>
		get(`/expenses/${id}`),
	update: (id, data) => patch(`/expenses/${id}`, data),
	updateExpenses: (expenses = []) =>
		patch('/expenses/multiple', { expenses }),
	counts: () => get('/expenses/counts'),
	expenseTypes: ({ authorization } = {}) =>
		get('/expenses/expense-types', { headers: { authorization } }),
	createExpenseType: params => post('/expenses/expense-types', params),
	updateExpenseTypes: params => patch('/expenses/expense-types', params),
	downloadCSV: async ({ timezone } = {}) => {
		const { data } = await get('/expenses/csv', { params: { timezone } })
		const binaryData = [data]
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }))
		const fileName = `expenses_${new Date().toISOString().slice(0, 10)}.csv`
		link.download = fileName
		link.click()
		link.remove()
	}
}
