import { HYDRATE } from 'next-redux-wrapper'
import {
	FETCH_PARTNERS_SUCCESS,
	ADD_PARTNER_SUCCESS,
	UPDATE_PARTNER_SUCCESS,
	FETCH_PARTNER_DETAILS_SUCCESS,
	FETCH_PARTNER_METRICS_SUCCESS
} from './action-types'

const initialState = {
	deliveryPartners: [],
	totalCount: 0,
	page: 0,
	deliveryPartnerDetails: {},
	metrics: {
		total: 0,
		active: 0
	}
}

const deliveryPartnersReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_PARTNERS_SUCCESS: {
			if (payload.deleted) {
				return {
					...state,
					deletedDeliveryPartners: payload.data.results,
					totalCount: payload.data.count,
					page: payload.data.page
				}
			} else {
				return {
					...state,
					deliveryPartners: payload.data.results,
					totalCount: payload.data.count,
					page: payload.data.page
				}
			}
		}
		case ADD_PARTNER_SUCCESS: {
			const partner = [payload, ...state.deliveryPartner]
			return {
				...state,
				partner
			}
		}
		case UPDATE_PARTNER_SUCCESS: {
			const newState = { ...state }
			const deliveryPartner = newState.deliveryPartnerDetails[payload.id]
			if (deliveryPartner) {
				const updatedDeliveryPartner = Object.assign(deliveryPartner, payload)
				newState.deliveryPartnerDetails[payload.id] = updatedDeliveryPartner
			}
			newState.deliveryPartners = newState.deliveryPartners.map(deliveryPartner => deliveryPartner.id === payload.id ?
				{
					...deliveryPartner,
					...payload
				} :
				deliveryPartner
			)
			return newState
		}
		case FETCH_PARTNER_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.deliveryPartnerDetails[payload.id] = payload
			return newState
		}
		case FETCH_PARTNER_METRICS_SUCCESS: {
			return {
				...state,
				metrics: payload
			}
		}
		default:
			return state
	}
}

export default deliveryPartnersReducer
