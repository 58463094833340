require('./VendorFilter.less')
import styles from './VendorFilter.module.css'
import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import Input from '../../components/input'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { Locations } from '../../services/api/locations'
import { Button } from 'antd'

const VendorFilter = ({ isFilterDropdownOpen, setIsFilterDropdownOpen }) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [searchTerm, setSearchTerm] = useState('')
	const [isDivisionsLoading, setIsDivisionsLoading] = useState(false)
	const [divisions, setDivisions] = useState([])
	const [selectedDivision, setSelectedDivision] = useState(null)
	const [isDistrictsLoading, setIsDistrictsLoading] = useState(false)
	const [districts, setDistricts] = useState([])
	const [selectedDistrict, setSelectedDistrict] = useState(null)
	const [fromDate, setFromDate] = useState(searchParams.fromDate)
	const [toDate, setToDate] = useState(searchParams.toDate)

	useEffect(() => {
		if (!isFilterDropdownOpen) {
			return
		}
		getDivisions(selectedDistrict?.value)
		getDistricts(selectedDivision?.value)
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	const getDivisions = async district => {
		setIsDivisionsLoading(true)
		const { data } = await Locations.divisions({ district, havingVendorsOnly: true })
		setDivisions(data?.length ? data : [])
		setIsDivisionsLoading(false)
	}

	const searchDivisions = async searchTerm => await Locations.divisions({ searchTerm, havingVendorsOnly: true })

	const onSelectedDivisionChange = selectedDivision => {
		setSelectedDivision(selectedDivision)
		getDistricts(selectedDivision?.value)
	}

	const getDistricts = async division => {
		setIsDistrictsLoading(true)
		const { data } = await Locations.districts({ division, havingVendorsOnly: true })
		setDistricts(data?.length ? data : [])
		setIsDistrictsLoading(false)
	}

	const searchDistricts = async searchTerm => await Locations.districts({ searchTerm, division: selectedDivision?.value, havingVendorsOnly: true })

	const onSelectedDistrictChange = selectedDistrict => {
		setSelectedDistrict(selectedDistrict)
		getDivisions(selectedDistrict?.value)
	}

	const handleApply = async () => {
		const division = selectedDivision?.value
		const district = selectedDistrict?.value
		const filters = {
			searchTerm,
			division,
			district
		}

		if (fromDate && toDate) {
			filters.fromDate = moment(fromDate).toDate()
			filters.toDate = moment(toDate).toDate()
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		clearFilter()
		setSearchTerm('')
		setSelectedDistrict(null)
		setSelectedDivision(null)
		setFromDate(null)
		setToDate(null)
		getDivisions()
		getDistricts()
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Division</p>
				<CustomSearchableSelectSecondary
					searchTask={searchDivisions}
					defaultOptions={divisions}
					valueIndex='value'
					labelIndex='label'
					placeholder='Select Division'
					onChange={onSelectedDivisionChange}
					onClear={() => setSelectedDivision(null)}
					value={selectedDivision}
					allowClear={true}
					isLoading={isDivisionsLoading}
				/>
			</div>
			<div>
				<p className={styles.title}>District</p>
				<CustomSearchableSelectSecondary
					searchTask={searchDistricts}
					defaultOptions={districts}
					valueIndex='value'
					labelIndex='label'
					placeholder='Select District'
					onChange={onSelectedDistrictChange}
					onClear={() => setSelectedDistrict(null)}
					value={selectedDistrict}
					allowClear={true}
					isLoading={isDistrictsLoading}
				/>
			</div>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='Start Date'
						value={fromDate ? moment(fromDate) : null }
						onChange={date => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='End Date'
						value={toDate ? moment(toDate) : null }
						onChange={date => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>

			<div className={styles.buttons}>
				<Button
					className={`${styles.button} ${styles.resetButton}`}
					onClick={resetFilter}
				>
					<span className={styles.buttonText}>
						Reset All
					</span>
				</Button>
				<Button
					type='primary'
					className={styles.button}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

VendorFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func
}

VendorFilter.defaultProps = {
	isFilterDropdownOpen: false,
	setIsFilterDropdownOpen: () => null
}

export default VendorFilter
