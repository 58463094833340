import { useEffect, useMemo, useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import styles from './CustomerDataPopover.module.css'
import BarSpinner from '../bar-spinner'
import CustomerTag from '../customer-tag'
import { Spin, Tooltip, notification } from 'antd'
import Link from 'next/link'
import { LocationTypes, orderStatuses, paths } from '../../utils/constants'
import moment from 'moment'
import { convertEnumToString, getFormattedCurrency } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDistributorDetails } from '../../store/distributors/actions'
import SalesOrders from '../../services/api/sales-orders'
import CclDetails from '../ccl-details'

const CustomerDataPopover = ({ customerId }) => {
	const dispatch = useDispatch()
	const { distributorDetails } = useSelector(state => state.distributorsReducer)
	const [isLoading, setIsLoading] = useState(false)
	const [ongoingOrders, setOngoingOrders] = useState([])
	const [isLoadingOngoingOrders, setIsLoadingOngoingOrders] = useState(false)

	const customerLocation = useMemo(() => {
		if (!distributorDetails[customerId] || !distributorDetails[customerId].locations) {
			return null
		}
		if (distributorDetails[customerId].locations?.length === 1) {
			return distributorDetails[customerId].locations[0]
		} else {
			return distributorDetails[customerId].locations?.find(location => location.type === LocationTypes.BILLING)
		}
	}, [distributorDetails])

	useEffect(() => {
		if (!customerId) {
			return
		}
		getDistributorDetails()
		getCustomerOngoingOrders(customerId)
	}, [customerId])

	const getDistributorDetails = async () => {
		setIsLoading(true)
		await dispatch(fetchDistributorDetails(customerId))
		setIsLoading(false)
	}

	const getCustomerOngoingOrders = async (id) => {
		try {
			setIsLoadingOngoingOrders(true)
			const { data } = await SalesOrders.index({
				distributorId: id,
				salesOrderItemsWithProduct: true,
				statuses: [
					orderStatuses.SALES_ORDER_REQUESTED_STATUS,
					orderStatuses.SALES_ORDER_APPROVED_STATUS,
					orderStatuses.SALES_ORDER_PROCESSING_STATUS,
					orderStatuses.SALES_ORDER_SHIPPED_STATUS,
					orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS,
					orderStatuses.SALES_ORDER_ON_HOLD_STATUS
				]
			})
			setOngoingOrders(data?.results || [])
		} catch (e) {
			notification.error({
				title: 'Couldn\'t get ongoing orders.',
				message: 'Please refresh the page.'
			})
		} finally {
			setIsLoadingOngoingOrders(false)
		}
	}

	const getOrderStatusLabel = status => {
		if (status.toLowerCase() === orderStatuses.SALES_ORDER_REQUESTED_STATUS) {
			return 'Pending'
		} else if (status.toLowerCase() === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
			return 'Delivered'
		} else {
			return convertEnumToString(status.toLowerCase())
		}
	}

	const getCustomerAddress = (name, label = null, address) => {
		if (!name) {
			return ''
		}
		return name !== label && label ? label : address
	}

	const renderSpinner = () => {
		return (
			<div style={{ textAlign: 'center', padding: '50px 0px' }}>
				<Spin size='large' />
			</div>
		)
	}

	const renderOrders = (orders, withTooltip = false) => {
		return orders.map(({ id, status, internalId, author, salesOrderItems, createdAt, totalAmount }) => {
			const orderStatus = status.toLowerCase()
			const orderTooltip = () => {
				return (
					<div className={styles.tooltip}>
						{
							salesOrderItems.map(({ productId, price, product: { name } }, i) => {
								return <p key={productId}>{`${i + 1}. ${name} - ${getFormattedCurrency(price)}`}</p>
							})
						}
					</div>
				)
			}
			const dateTooltip = () => {
				return (
					<div className={styles.tooltip}>
						<p>
							The sales order {internalId} was created by {author.name} at {moment(createdAt).format('h:mm A [on] DD MMM, YYYY')}
						</p>
					</div>
				)
			}

			return (
				<div key={id} className={`${styles.orderDetail} ${withTooltip && styles.orderDetailRounded}`}>
					<div className={styles.orderTitleContainer}>
						<div className={styles.orderLinkContainer}>
							<Link href={`${paths.SALES_ORDERS}/${id}`}>
								<a className={styles.orderTitle} target='_blank'>
									{internalId}
								</a>
							</Link>
							{
								withTooltip &&
								<Tooltip title={orderTooltip}>
									<img src='/img/info-fill.svg' alt='Info icon' />
								</Tooltip>
							}
						</div>
						<div className={`
								${styles.orderStatusContainer}
								${orderStatus === orderStatuses.SALES_ORDER_REQUESTED_STATUS && styles.orderStatusPending}
								${orderStatus === orderStatuses.SALES_ORDER_APPROVED_STATUS && styles.orderStatusApproved}
								${orderStatus === orderStatuses.SALES_ORDER_PROCESSING_STATUS && styles.orderStatusProcessing}
								${orderStatus === orderStatuses.SALES_ORDER_SHIPPED_STATUS && styles.orderStatusShipped}
								${orderStatus === orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS && styles.orderStatusInTransit}
								${orderStatus === orderStatuses.SALES_ORDER_COMPLETED_STATUS && styles.orderStatusDelivered}
								${orderStatus === orderStatuses.SALES_ORDER_FLAGGED_STATUS && styles.orderStatusFlagged}
								${orderStatus === orderStatuses.SALES_ORDER_CANCELLED_STATUS && styles.orderStatusCanceled}
                                ${orderStatus === orderStatuses.SALES_ORDER_ON_HOLD_STATUS && styles.orderStatusOnHold}
							`}
						>
							<p className={styles.orderStatus}>{getOrderStatusLabel(status)}</p>
						</div>
					</div>
					<div className={styles.orderPriceContainer}>
						<p className={styles.orderPrice}>{getFormattedCurrency(totalAmount)}</p>
						<p className={styles.orderDate}>{moment(createdAt).format('MMM D, YYYY, h:mm A')}</p>
						{
							withTooltip &&
								<Tooltip title={dateTooltip}>
									<InfoCircleOutlined style={{ fontSize: 12 }} className={styles.infoIcon} />
								</Tooltip>
						}
					</div>
				</div>
			)
		})
	}

	return (
		<div>
			{
				isLoading ?
					<div style={{ textAlign: 'center' }}>
						<Spin size='large' />
					</div> :
					<div className={styles.customerDataSection}>
						{/* Customer's personal info */}
						<div className={styles.customerData}>
							<div className={styles.editButtonContainer}>
								<div>
									<p className={styles.customerLabel}>Customer ID</p>
									<p className={styles.customerDetail}>
										{distributorDetails[customerId]?.internalId || `C-${distributorDetails[customerId]?.shortId}` || ''}
										<CustomerTag name={distributorDetails[customerId]?.customerTag?.name} />
									</p>
								</div>
							</div>
							<div>
								<p className={styles.customerLabel}>Customer Name</p>
								<p className={styles.customerDetail}>{distributorDetails[customerId]?.name}</p>
							</div>
							<div>
								<p className={styles.customerLabel}>Mobile Number</p>
								<p className={styles.customerDetail}>{distributorDetails[customerId]?.phone}</p>
							</div>
							<div>
								<p className={styles.customerLabel}>Customer Address</p>
								<p className={styles.customerDetail}>{getCustomerAddress(distributorDetails[customerId]?.name, customerLocation?.label, customerLocation?.address)}</p>
							</div>
							<div>
								<p className={styles.customerLabel}>Map Location</p>
								<p className={styles.customerDetail}>{customerLocation?.address || customerLocation?.label}</p>
							</div>
						</div>
						<div style={{ border: '1px solid #ebebeb', borderRadius: 8, padding: 16 }}>
							<CclDetails
								phone={distributorDetails[customerId]?.phone}
							/>
						</div>
						{/* Customer's ongoing orders */}
						{
							ongoingOrders.length || isLoadingOngoingOrders ?
								<div className={`${styles.customerData} ${styles.customerDataOngoingOrders}`}>
									<div className={styles.customerDataTitleContainer}>
										<p className={styles.customerDataTitle}>Ongoing Orders</p>
										<span className={`${styles.customerDataCount} ${styles.customerDataCountOrange}`}>{ongoingOrders.length}</span>
										<span className={styles.barSpinnerContainer}>
											<BarSpinner />
										</span>
									</div>
									<div className={styles.results}>
										{
											isLoadingOngoingOrders ? renderSpinner() : renderOrders(ongoingOrders, true)
										}
									</div>
								</div> :
								null
						}
					</div>
			}
		</div>
	)
}

export default CustomerDataPopover
