import { HYDRATE } from 'next-redux-wrapper'
import { FETCH_ORDER_AMOUNT_INSIGHTS_SUCCESS, FETCH_ORDER_COUNT_INSIGHTS_SUCCESS } from './action-types'

const initialState = {
	orderCountInsights: [],
	orderCountInsightsTotalCount: 0,
	orderCountInsightsPage: 0,
	orderAmountInsights: [],
	orderAmountInsightsTotalCount: 0,
	orderAmountInsightsPage: 0
}

const insightsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_ORDER_COUNT_INSIGHTS_SUCCESS: {
			return {
				...state,
				orderCountInsights: payload.data.results,
				orderCountInsightsTotalCount: payload.data.count,
				orderCountInsightsPage: payload.data.page
			}
		}
		case FETCH_ORDER_AMOUNT_INSIGHTS_SUCCESS: {
			return {
				...state,
				orderAmountInsights: payload.data.results,
				orderAmountInsightsTotalCount: payload.data.count,
				orderAmountInsightsPage: payload.data.page
			}
		}
		default:
			return state
	}
}

export default insightsReducer
