import { Select, Spin } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './PlacesAutocomplete.module.css'
import debounce from 'lodash/debounce'
require('./PlacesAutocomplete.less')
import { Maps } from '../../services/maps'
import { getCountryCode } from '../../utils'

const { Option } = Select
const ENGLISH_REGEX = /^[A-Za-z0-9\s]*$/

const PlacesAutocomplete = ({
	defaultValue,
	placeholder,
	value,
	onLocationSelected,
	onClear,
	className,
	country,
	tag,
	style,
	initialOptions = [],
	disabled,
	loading,
	popupContainer,
	title,
	searchTextSelectable
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [options, setOptions] = useState([])
	const fetchRef = useRef(0)

	useEffect(() => {
		if (initialOptions && initialOptions.length > 0) {
			setOptions(initialOptions)
		}
	}, [initialOptions])

	useEffect(() => {
		if (options.length === 0) {
			if (initialOptions && initialOptions.length > 0) {
				setOptions(initialOptions)
			}
		}
	}, [options])

	const debounceAutocomplete = useMemo(() => {
		const loadOptions = value => {
			fetchRef.current += 1
			const fetchId = fetchRef.current
			setOptions([])
			setIsLoading(true)
			const countryCode = country ? country : getCountryCode()
			Maps.autocompleteV2(value, countryCode, tag)
				.then(predictions => {
					if (fetchId !== fetchRef.current) {
						return
					}
					const newOptions = predictions.map(prediction => {
						return {
							label: prediction.display_name,
							value: prediction.place_id,
							address: prediction.address,
							lat: +prediction.lat,
							lng: +prediction.lon
						}
					})
					if (searchTextSelectable && value?.trim()) {
						setOptions([{ label: value.trim(), value: Date.now().toString(), key: 'custom' }, ...newOptions])
					} else {
						setOptions(newOptions)
					}
				})
				.finally(() => setIsLoading(false))
		}
		return debounce(loadOptions, 800)
	})

	const getPlaceDetails = async (option, optionData) => {
		try {
			if (optionData.location) {
				const location = optionData.location
				if (onLocationSelected) {
					onLocationSelected({
						id: location.id,
						address: location.address,
						label: location.label,
						lat: location.latitude,
						lng: location.longitude,
						postCode: location.postCode ? `${location.postCode}` : location.postCode
					})
				}
			} else if (searchTextSelectable && optionData.key === 'custom') {
				onLocationSelected({
					address: optionData.label,
					placeId: optionData.key,
					lat: 0,
					lng: 0,
					district: null,
					division: null,
					postCode: null
				})
			} else {
				const placeId = option.value
				const optionData = options.find(option => option.value === placeId)
				if (optionData && onLocationSelected) {
					let district
					let division
					let postCode
					if (optionData.address) {
						district = optionData.address.county || optionData.address.city || ''
						division = optionData.address.state || ''
						postCode = optionData.address.postcode ? `${optionData.address.postcode}` : ''
					} else {
						setIsLoading(true)
						const placeDetails = await Maps.getPlaceDetailsV2(optionData.lat, optionData.lng)
						district = placeDetails.district ? `${placeDetails.district} District` : ''
						division = placeDetails.division ? `${placeDetails.division} Division` : ''
						postCode = placeDetails.postCode ? `${placeDetails.postCode}` : ''
					}
					setIsLoading(false)
					onLocationSelected({
						placeId,
						address: option.label,
						lat: optionData.lat,
						lng: optionData.lng,
						district: district && ENGLISH_REGEX.test(district) ? district : null,
						division: division && ENGLISH_REGEX.test(division) ? division : null,
						postCode: postCode && ENGLISH_REGEX.test(postCode) ? `${postCode}` : null
					})
				}
			}
		} catch (e) {
			/** No-op, invalid place ID */
		}
	}

	return (
		<div className={styles.placesAutocomplete}>
			{
				title && <p className={styles.customSelectTitle}>{title}</p>
			}
			<Select
				className={className ? className : `places-autocomplete${disabled ? '-disabled' : ''} ${title && 'select-title-visible'}`}
				disabled={disabled}
				style={style}
				showSearch
				showArrow={false}
				labelInValue
				filterOption={false}
				options={options}
				placeholder={placeholder}
				onSearch={debounceAutocomplete}
				onClear={onClear}
				notFoundContent={isLoading ? <Spin size='small' /> : null}
				defaultValue={defaultValue}
				value={value}
				onChange={(option, optionData) => getPlaceDetails(option, optionData)}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
			>
				{
					options.map(option => {
						return (
							<Option
								key={option.value}
								value={option.value}
							>
								{option.label}
							</Option>
						)
					})
				}
			</Select>
			{
				loading ?
					<div style={{ position: 'absolute', right: 18, top: '50%', transform: 'translateY(-50%)' }}>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
					</div> :
					<img src='/img/search-2.svg' className={disabled ? styles.disabledSearchIcon : styles.searchIcon} />
			}
		</div>
	)
}

export default PlacesAutocomplete
