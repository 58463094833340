import { useEffect } from 'react'
import { createContext, useState } from 'react'

export const GlobalFilterContext = createContext(false)
export const GlobalFilterSetter = createContext(() => {})

const GlobalFilterProvider = ({ children }) => {
	const [shouldResetFilter, setShouldResetFilters] = useState(false)

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		setShouldResetFilters(false)
	}, [shouldResetFilter])

	return (
		<GlobalFilterContext.Provider value={shouldResetFilter}>
			<GlobalFilterSetter.Provider value={setShouldResetFilters}>
				{children}
			</GlobalFilterSetter.Provider>
		</GlobalFilterContext.Provider>
	)
}

export default GlobalFilterProvider
