import UploadCsvModal from '../upload-csv-modal'
import { Upload } from '../../services/api/upload'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { customerTypeOptions, customerTypes, industries, LocationTypes } from '../../utils/constants'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { notification } from 'antd'
import { Locations } from '../../services/api/locations'

const UploadOrdersModal = ({ visible, onCancel, onComplete }) => {
	const { companyDetails } = useSelector(state => state.authReducer)
	const [isLoading, setIsLoading] = useState(false)
	const [fields, setFields] = useState([])

	useEffect(() => {
		if (companyDetails?.industry === industries.E_COMMERCE) {
			const newFields = [
				{ label: 'Invoice No.', key: 'id', validators: [{ validate: 'required' }] },
				{ label: 'Creation Date', key: 'createdAt' },
				{ label: 'Shipping Date', key: 'orderDate', validators: [{ validate: 'required' }] },
				{ label: 'Delivery Date', key: 'deliveryDate' },
				{ label: 'Delivery Address', key: 'deliveryAddress', validators: [{ validate: 'required' }] },
				{ label: 'Warehouse Name', key: 'from', type: 'select', validators: [{ validate: 'required' }] },
				{ label: 'Customer Name', key: 'customerName', validators: [{ validate: 'required' }] },
				{ label: 'Customer Phone Number', key: 'customerPhone', validators: [{ validate: 'required' }] },
				{ label: 'Customer Type', key: 'customerType', validators: [{ validate: 'required' }], type: 'select',
					options: customerTypeOptions.filter(option => option.value !== customerTypes.DEALER)
				},
				{ label: 'Product ID', key: 'productId', validators: [{ validate: 'required' }] },
				{ label: 'Quantity', key: 'quantity', validators: [{ validate: 'required' }] },
				{ label: 'Product Amount', key: 'discount' },
				{ label: 'Status', key: 'status', type: 'select',
					options: [
						{
							value: 'requested',
							label: 'Pending'
						},
						{
							value: 'on_hold',
							label: 'On Hold'
						},
						{
							value: 'approved',
							label: 'Approved'
						},
						{
							value: 'processing',
							label: 'Processing'
						},
						{
							value: 'shipped',
							label: 'Shipped'
						},
						{
							value: 'in_transit',
							label: 'In-Transit'
						},
						{
							value: 'completed',
							label: 'Delivered'
						},
						{
							value: 'flagged',
							label: 'Flagged'
						},
						{
							value: 'cancelled',
							label: 'Cancelled'
						}
					]
				},
				{ label: 'Sub-Status', key: 'subStatus', type: 'select',
					options: [
						{
							value: 'returned',
							label: 'Returned'
						},
						{
							value: 'damaged',
							label: 'Damaged'
						},
						{
							value: 'payment_due',
							label: 'Payment Due'
						},
						{
							value: 'payment_collected',
							label: 'Payment Collected'
						}
					]
				},
				{ label: 'Order Source', key: 'source', type: 'select',
					options: [
						{
							value: 'FACEBOOK',
							label: 'Facebook'
						},
						{
							value: 'MESSENGER',
							label: 'Messenger'
						},
						{
							value: 'WHATSAPP',
							label: 'Whatsapp'
						},
						{
							value: 'INSTAGRAM',
							label: 'Instagram'
						},
						{
							value: 'TIKTOK',
							label: 'TikTok'
						},
						{
							value: 'WEBSITE',
							label: 'Website'
						},
						{
							value: 'PHONE_CALL',
							label: 'Phone Call'
						},
						{
							value: 'OFFLINE',
							label: 'Offline'
						},
						{
							value: 'UP_SELL',
							label: 'Up Sell'
						},
						{
							value: 'OTHER',
							label: 'Other'
						}
					]
				},
				{ label: 'Courier Partner', key: 'preferredDeliveryPartner', type: 'select', validators: [{ validate: 'required' }],
					options: []
				},
				{ label: 'Payment Method', key: 'paymentMethod', type: 'select',
					options: [
						{
							value: 'CREDIT',
							label: 'Credit'
						},
						{
							value: 'CASH_ON_DELIVERY',
							label: 'Cash On Delivery'
						},
						{
							value: 'BKASH',
							label: 'Bkash'
						},
						{
							value: 'NAGAD',
							label: 'Nagad'
						},
						{
							value: 'ROCKET',
							label: 'Rocket'
						}
					]
				},
				{
					label: 'Order Discount', key: 'orderDiscount'
				},
				{
					label: 'Payments', key: 'payments'
				}
			]
			setFields(newFields)
			getInitialData(newFields)
		} else {
			setFields([
				{ label: 'ID', key: 'id' },
				{ label: 'Customer ID', key: 'customerId' },
				{ label: 'From', key: 'from' },
				{ label: 'To', key: 'to' },
				{ label: 'Order Type', key: 'orderType' },
				{ label: 'Delivery Date', key: 'deliveryDate' },
				{ label: 'Product ID', key: 'productId' },
				{ label: 'Quantity', key: 'quantity' },
				{ label: 'Package Quantity', key: 'packageQuantity' }
			])
		}
	}, [companyDetails])

	const getInitialData = async (fields) => {
		setIsLoading(true)
		await Promise.allSettled[
			getDeliveryPartners(fields),
			getWarehouses(fields)
		]
		setIsLoading(false)
	}

	const getDeliveryPartners = async (fields) => {
		try {
			const { data: { results } } = await DeliveryPartners.index({
				page: -1
			})
			const deliveryPartners = results.map(deliveryPartner => ({ value: deliveryPartner.id, label: deliveryPartner.name }))
			const newFields = [...fields]
			const index = newFields.findIndex(field => field.key === 'preferredDeliveryPartner')
			if (index > -1) {
				newFields[index].options = deliveryPartners
			}
			setFields(newFields)
		} catch (e) {
			notification.error({
				message: 'Unable to Get Delivery Partner Options',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const getWarehouses = async (fields) => {
		try {
			const { data: { results } } = await Locations.index({
				types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY],
				page: -1
			})
			const warehouses = results.map(location => ({ value: location.id, label: location.label || location.address }))
			const newFields = [...fields]
			const index = newFields.findIndex(field => field.key === 'from')
			if (index > -1) {
				newFields[index].options = warehouses
			}
			setFields(newFields)
		} catch (e) {
			notification.error({
				message: 'Unable to Get Warehouse Options',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	return (
		<UploadCsvModal
			title='Upload Orders'
			type='orders'
			fields={fields}
			firstStepPrompt='Download an example CSV file. Do not modify the first row of the CSV. This CSV will inform you on how to structure your data.'
			secondStepPrompt='Edit the CSV file by adding new rows. Rows will be uploaded as new orders into the platform.'
			visible={visible}
			onCancel={onCancel}
			onComplete={onComplete}
			downloadTask={Upload.downloadOrdersCsv}
			uploadTask={Upload.uploadOrdersCsv}
			loading={isLoading}
		/>
	)
}

export default UploadOrdersModal
