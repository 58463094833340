import { HYDRATE } from 'next-redux-wrapper'
import { FETCH_INVOICES_SUCCESS, FETCH_PAYMENTS_SUCCESS, FETCH_UNPAID_ONE_TIME_INVOICE_SUCCESS, REMOVE_INVOICE, SET_INVOICE_TO_EDIT } from './action-types'

const initialState = {
	invoices: {
		records: [],
		totalCount: 0,
		page: 0
	},
	payments: {
		records: [],
		totalCount: 0,
		page: 0
	},
	invoiceToEdit: {},
	unpaidOneTimeInvoice: {}
}

const billingAndPaymentReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_INVOICES_SUCCESS: {
			return {
				...state,
				invoices: {
					...state.invoices,
					records: payload.data.results,
					totalCount: payload.data.count,
					page: +payload.data.page
				}
			}
		}
		case FETCH_PAYMENTS_SUCCESS: {
			return {
				...state,
				payments: {
					...state.payments,
					records: payload.data.results,
					totalCount: payload.data.count,
					page: +payload.data.page
				}
			}
		}
		case SET_INVOICE_TO_EDIT: {
			return {
				...state,
				invoiceToEdit: payload.data
			}
		}
		case REMOVE_INVOICE: {
			return {
				...state,
				invoices: {
					...state.invoices,
					records: state.invoices.records.filter((invoice) => invoice.id !== payload.data),
					count: state.invoices.count - 1
				}
			}
		}
		case FETCH_UNPAID_ONE_TIME_INVOICE_SUCCESS: {
			return {
				...state,
				unpaidOneTimeInvoice: payload.data
			}
		}
		default:
			return state
	}
}

export default billingAndPaymentReducer
