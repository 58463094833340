import { Locations } from '../../services/api/locations'
import { FETCH_LOCATIONS_SUCCESS, FETCH_WAREHOUSE_LOCATIONS_SUCCESS } from './action-types'

export function fetchLocations ({
	type,
	types,
	page,
	fromDate,
	searchTerm,
	toDate,
	usages,
	deleted
} = {}) {
	return async (dispatch) => {
		const response = await Locations.index({
			type,
			types,
			page,
			fromDate,
			toDate,
			searchTerm,
			usages,
			deleted
		})
		dispatch({ type: FETCH_LOCATIONS_SUCCESS, payload: { data: response.data, deleted } })
	}
}

export function fetchWarehouses ({
	page
} = {}) {
	return async (dispatch) => {
		const response = await Locations.index({
			type: 'WAREHOUSE',
			page,
			inventory: true
		})
		dispatch({ type: FETCH_WAREHOUSE_LOCATIONS_SUCCESS, payload: { data: response.data } })
	}
}
