import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox } from 'antd'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
require('./DeliveryPartnerFilter.less')
import styles from './DeliveryPartnerFilter.module.css'
import DropdownOverlay from '../dropdown-overlay'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../../utils/constants'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import Input from '../../components/input'
import { convertEnumToString, isEmptyObject } from '../../utils'
import DeliveryPartnerOption from '../delivery-partner-option'

const DeliveryPartnerFilter = ({
	setIsFilterDropdownOpen
}) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [searchTerm, setSearchTerm] = useState('')
	const [active, setActive] = useState(true)
	const [inactive, setInactive] = useState(true)
	const [isLoadingDeliveryPartners, setIsLoadingDeliveryPartners] = useState(false)
	const [deliveryPartners, setDeliveryPartners] = useState([])
	const [deliveryPartner, setDeliveryPartner] = useState(null)
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [locations, setLocations] = useState([])
	const [location, setLocation] = useState(null)
	const [creationFromDate, setCreationFromDate] = useState(searchParams.creationFromDate)
	const [creationToDate, setCreationToDate] = useState(searchParams.creationToDate)

	useEffect(() => {
		getDeliveryPartners()
		getWarehouses()
	}, [])

	useEffect(() => {
		if (isEmptyObject(searchParams)) {
			setActive(true)
			setInactive(true)
		} else if (searchParams.all === true) {
			setActive(true)
			setInactive(true)
		}
	}, [searchParams])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	const handleApply = async () => {
		const partnerId = deliveryPartner?.data?.partnerId
		const warehouseId = location?.data?.id
		const filters = {
			searchTerm,
			partnerId,
			warehouseId
		}

		if ((active && inactive) || (!active && !inactive)) {
			filters.all = true
		} else if (active) {
			filters.active = true
		} else if (inactive) {
			filters.active = false
		}

		if (creationFromDate && creationToDate) {
			filters.creationFromDate = moment(creationFromDate).startOf('day').toDate()
			filters.creationToDate = moment(creationToDate).endOf('day').toDate()
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setDeliveryPartner(null)
		setLocation(null)
		setActive(true)
		setInactive(true)
		setCreationFromDate(null)
		setCreationToDate(null)
		clearFilter()
		getDeliveryPartners()
		getWarehouses()
	}

	const getDeliveryPartners = async (params = {}) => {
		setIsLoadingDeliveryPartners(true)
		const response = await DeliveryPartners.index(params)
		if (response.data) {
			setDeliveryPartners(response.data.results)
		}
		setIsLoadingDeliveryPartners(false)
	}

	const searchDeliveryPartners = async value => {
		return DeliveryPartners.index({ searchTerm: value })
	}

	const getWarehouses = async (params = {}) => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ ...params, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const getSingleWarehouse = async (id) => {
		setIsLoadingLocations(true)
		if (!id) {
			setLocations([])
			setIsLoadingLocations(false)
			return
		}
		const response = await Locations.single(id)
		if (response.data) {
			setLocations([response.data])
		}
		setIsLoadingLocations(false)
	}

	const searchLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Warehouse</p>
				<CustomSearchableSelectSecondary
					searchTask={searchLocations}
					defaultOptions={locations}
					isLoading={isLoadingLocations}
					customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
					valueIndex='id'
					descriptionIndex='address'
					placeholder='Select Warehouse'
					onChange={location => {
						setLocation(location)
						getDeliveryPartners({ warehouseId: location?.data?.id })
					}}
					onClear={() => setLocation(null)}
					value={location}
					allowClear={true}
				/>
			</div>
			<div>
				<p className={styles.title}>Delivery Partner</p>
				<CustomSearchableSelectSecondary
					searchTask={searchDeliveryPartners}
					defaultOptions={deliveryPartners}
					isLoading={isLoadingDeliveryPartners}
					customLabel={(data) => data.name ? data.name : convertEnumToString(data.type)}
					valueIndex='id'
					descriptionIndex='type'
					placeholder='Select Delivery Partner'
					onChange={deliveryPartner => {
						setDeliveryPartner(deliveryPartner)
						if (deliveryPartner) {
							getSingleWarehouse(deliveryPartner?.data?.warehouseId)
							return
						}
						getWarehouses()
					}}
					onClear={() => setDeliveryPartner(null)}
					value={deliveryPartner}
					allowClear={true}
					renderOption={(option) => <DeliveryPartnerOption value={option?.value} label={option?.label} type={option?.data?.type} />}
				/>
			</div>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='Start Date'
						value={creationFromDate ? moment(creationFromDate) : null }
						onChange={(date) => setCreationFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='End Date'
						value={creationToDate ? moment(creationToDate) : null }
						onChange={(date) => setCreationToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(creationFromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Status</p>
				<div className={styles.inputGroup}>
					<Checkbox
						className={styles.inputTitle}
						checked={active}
						onChange={e => setActive(e.target.checked)}
					>
						Active
					</Checkbox>
					<Checkbox
						className={styles.inputTitle}
						checked={inactive}
						onChange={e => setInactive(e.target.checked)}
					>
						Inactive
					</Checkbox>
				</div>
			</div>

			<div className={styles.buttons}>
				<Button
					className={`${styles.button} ${styles.resetButton}`}
					onClick={resetFilter}
				>
					<span className={styles.buttonText}>
						Reset All
					</span>
				</Button>
				<Button
					type='primary'
					className={styles.button}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

DeliveryPartnerFilter.propTypes = {
	setIsFilterDropdownOpen: PropTypes.func
}

export default DeliveryPartnerFilter
