/* eslint-disable react/jsx-wrap-multilines */
import { Menu, Radio, Empty, Spin, Progress, notification, Popconfirm, Button } from 'antd'
import { CheckSquareOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import styles from './NotificationsTray.module.css'
require('./NotificationsTray.less')
import moment from 'moment'
import { readNotification } from '../../store/notifications/actions'
import { useEffect, useState } from 'react'
import { Notifications } from '../../services/api/firebase'
import { useRouter } from 'next/router'
import { paths } from '../../utils/constants'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import TextInput from '../text-input'

const NotificationTypes = {
	NOTIFICATIONS: 'notifications',
	UNREAD_NOTIFICATIONS: 'unread_notifications'
}

const notificationOptions = [
	{
		label: 'All',
		value: NotificationTypes.NOTIFICATIONS
	},
	{
		label: 'Unread',
		value: NotificationTypes.UNREAD_NOTIFICATIONS
	}
]

const NotificationOptions = {
	ACTION_REQUEST: 'action_request',
	INSIGHTS: 'insights',
	INFORMATION: 'information'
}

const notificationTypeOptions = [
	{
		label: 'Action Request',
		value: NotificationOptions.ACTION_REQUEST
	},
	{
		label: 'Insights',
		value: NotificationOptions.INSIGHTS
	},
	{
		label: 'Information',
		value: NotificationOptions.INFORMATION
	}
]

const actionRequestNotificationKeys = [
	'sales-orders_staleness_requested_2',
	'sales-orders_staleness_requested_4',
	'sales-orders_staleness_requested_7',
	'sales-orders_staleness_requested_30',
	'sales-orders_staleness_approved_2',
	'sales-orders_staleness_approved_4',
	'sales-orders_staleness_approved_7',
	'sales-orders_staleness_approved_30',
	'sales-orders_sales_orders_unassigned',
	'invoices_one_time_billing_invoice_payable'
]

const insightsNotificationKeys = [
	'insights_created'
]

const informationNotificationKeys = [
	'sales-orders_status_update',
	'sales-orders_created_sub',
	'delivery-plans_updated'
]

export const getNotificationArgs = notification => {
	const notificationArgs = {
		message: notification.message.en,
		description: notification.description.en,
		placement: 'bottomLeft',
		duration: 3
	}
	// Customize notifications based on the event that occurred for the notification.
	switch (notification.key) {
		case 'delivery-plans_finished_processing_succeeded':
		case 'delivery-plans_finished_processing_failed': {
			notificationArgs.duration = 5
			notificationArgs.icon =
				<Progress
					width={28}
					percent={100}
					status={notification.key === 'delivery-plans_finished_processing_failed' ? 'exception' : undefined}
					type='circle'
					style={{ marginRight: 12 }}
				/>
			break
		}
	}
	return notificationArgs
}

const NotificationsTray = (props) => {
	const dispatch = useDispatch()
	const router = useRouter()
	const { companyDetails, authUser } = useSelector(state => state.authReducer)
	const [notificationOption, setNotificationOption] = useState(notificationOptions[0].value)
	const [notificationTypeOption, setNotificationTypeOption] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')
	const [notificationItems, setNotificationItems] = useState()
	const [allNotifications, setAllNotifications] = useState([])
	const [unreadNotifications, setUnreadNotifications] = useState([])
	const debouncedSearchTerm = useDebounceValue(searchQuery, 300)

	useEffect(() => {
		if (notificationOption === NotificationTypes.NOTIFICATIONS) {
			getAllNotifications()
		} else {
			getUnreadNotifications()
		}
	}, [authUser, notificationOption, notificationTypeOption])

	useEffect(() => {
		const itemsToBeSearched = notificationOption === NotificationTypes.NOTIFICATIONS ? allNotifications : unreadNotifications
		if (searchQuery && itemsToBeSearched) {
			const filteredResults = itemsToBeSearched?.filter(
				item => getNotificationArgs(item).description.toLowerCase().includes(searchQuery.toLowerCase()) ||
				item.message.en.toLowerCase().includes(searchQuery.toLowerCase())
			)
			setNotificationItems(filteredResults)
		} else {
			setNotificationItems(itemsToBeSearched)
		}
	}, [debouncedSearchTerm])

	useEffect(() => {
		if (searchQuery) {
			setSearchQuery('')
		}
	}, [notificationOption])

	const errorNotificationHandler = (error, message) => {
		notification.error({
			message: message,
			description: error.message,
			placement: 'bottomLeft'
		})
	}

	const getUnreadNotifications = async () => {
		try {
			setIsLoading(true)
			const response = await Notifications.fetchUnreadNotificationsOnce(authUser?.uid, undefined, getFilters())
			setUnreadNotifications(response)
			setNotificationItems(response)
		} catch (error) {
			errorNotificationHandler(error, 'Unable to get notifications')
		} finally {
			setIsLoading(false)
		}
	}

	const getAllNotifications = async () => {
		try {
			setIsLoading(true)
			const response = await Notifications.fetchNotificationsOnce(authUser?.uid, undefined, getFilters())
			setAllNotifications(response)
			setNotificationItems(response)
		} catch (error) {
			errorNotificationHandler(error, 'Unable to get notifications')
		} finally {
			setIsLoading(false)
		}
	}

	const getFilters = () => {
		if (notificationTypeOption === NotificationOptions.ACTION_REQUEST) {
			return actionRequestNotificationKeys
		} else if (notificationTypeOption === NotificationOptions.INSIGHTS) {
			return insightsNotificationKeys
		} else if (notificationTypeOption === NotificationOptions.INFORMATION) {
			return informationNotificationKeys
		} else {
			return undefined
		}
	}

	const onLoadMoreNotifications = async () => {
		try {
			setIsLoading(true)
			if (notificationOption === NotificationTypes.UNREAD_NOTIFICATIONS) {
				const lastItem = unreadNotifications[unreadNotifications.length - 1]
				const lastItemId = lastItem?.id
				const response = await Notifications.fetchUnreadNotificationsOnce(authUser?.uid, lastItemId, getFilters())
				const notifications = [...unreadNotifications, ...response]
				setUnreadNotifications(notifications)
				setNotificationItems(notifications)
			} else {
				const lastItem = allNotifications[allNotifications.length - 1]
				const lastItemId = lastItem?.id
				const response = await Notifications.fetchNotificationsOnce(authUser?.uid, lastItemId, getFilters())
				const notifications = [...allNotifications, ...response]
				setAllNotifications(notifications)
				setNotificationItems(notifications)
			}
		} catch (error) {
			errorNotificationHandler(error, 'Unable to get more notifications')
		} finally {
			setIsLoading(false)
		}
	}

	const renderHighlightText = (text, query) => {
		if (!query.trim()) {
			return <>{text}</>
		}
		const regex = new RegExp(`(${query})`, 'gi')
		const parts = text.split(regex)
		return (
			<div>
				{parts.map((part, index) => regex.test(part) ? <span style={{ background:'yellow' }} key={index}>{part}</span> : part)}
			</div>
		)
	}

	const onTabChanged = (e) => {
		setNotificationOption(e.target.value)
	}

	const onTypeTabChanged = (value) => {
		if (value === notificationTypeOption) {
			setNotificationTypeOption(undefined)
		} else {
			setNotificationTypeOption(value)
		}
	}

	const onReadNotification = async (id) => {
		try {
			await dispatch(readNotification(id))
		} catch (error) {
			errorNotificationHandler(error, 'Unable to get notifications')
		} finally {
			notificationOption === NotificationTypes.UNREAD_NOTIFICATIONS ? getUnreadNotifications : getAllNotifications()
		}
	}

	const onReadNotificationBulk = async () => {
		if (unreadNotifications.length > 0) {
			const allUnreadIds = unreadNotifications?.map((item) => item.id)
			try {
				await Notifications.readNotificationsBulk(authUser?.uid, allUnreadIds)
			} catch (error) {
				errorNotificationHandler(error, 'Unable to read notifications')
			}	finally	{
				if (notificationOption === NotificationTypes.UNREAD_NOTIFICATIONS) {
					getUnreadNotifications()
				} else {
					getAllNotifications()
					setUnreadNotifications([])
				}
				if (searchQuery) {
					setSearchQuery('')
				}
				notification.success({
					message: 'All notifications marked as read',
					description: 'All notifications marked as read successfully!',
					placement: 'bottomLeft'
				})
			}
		}
	}

	const onNotificationClick = notification => {
		if (notification.isRead === false) {
			onReadNotification(notification.id)
		}
		if (searchQuery.length !== 0) {
			setSearchQuery('')
		}
		if (notification.key.startsWith('delivery-plans')) {
			router.push(`${paths.DELIVERY_PLANS}/${notification.data.id}`)
		} else if (notification.key.startsWith('sales-orders_sales_orders_follow_up')) {
			const date = notification.data.date.toDate()
			router.push(`${paths.SALES_ORDERS}?status=all&followUpDate=${date.toISOString()}`)
		} else if (notification.key.startsWith('sales-orders_staleness') || notification.key.startsWith('sales-orders_stock_transfer_staleness')) {
			router.push(`${paths.SALES_ORDERS}?status=${notification.data.status.toLowerCase()}`)
		} else if (notification.key.startsWith('sales-orders_sales_orders_unassigned') || notification.key.startsWith('sales-orders_stock_transfer_sales_orders_unassigned')) {
			const date = notification.data.date.toDate()
			const dateStr = date.toISOString().split('T')[0]
			router.push(`${paths.DELIVERY_PLANS}?createDeliveryPlan=true&createDeliveryPlanDate=${dateStr}`)
		} else if (notification.key.startsWith('sales-orders_')) {
			if (notification.data.id) {
				router.push(`${paths.SALES_ORDERS}/${notification.data.id}`)
			}
		} else if (notification.key.startsWith('vehicles_')) {
			if (notification.data.id) {
				router.push(`${paths.FLEET}/${notification.data.id}`)
			}
		} else if (notification.key.startsWith('requests_requested_vehicle')) {
			if (notification.data.deliveryPlanId) {
				router.push(`${paths.DELIVERY_PLANS}?expandedId=${notification.data.deliveryPlanId}`)
			}
		} else if (notification.key.startsWith('insights_')) {
			if (companyDetails?.isInsightsEnabled === true) {
				router.push(`${paths.INSIGHTS}`)
			}
		} else if (notification.key.startsWith('invoices_one_time')) {
			router.push(`${paths.PAYMENTS}`)
		}
	}

	const renderTag = (key) => {
		if (actionRequestNotificationKeys.includes(key) || key.startsWith('sales-orders_sales_orders_unassigned')) {
			return (
				<div
					style={{
						backgroundColor: '#FFEEEE',
						color: '#FF5959'
					}}
					className={styles.tag}
				>
                    Action Request
				</div>
			)
		}
		if (insightsNotificationKeys.includes(key)) {
			return (
				<div
					style={{
						backgroundColor: '#E3F3F6',
						color: '#288EA5'
					}}
					className={styles.tag}
				>
                    Insights
				</div>
			)
		}
		if (informationNotificationKeys.includes(key)) {
			return (
				<div
					style={{
						backgroundColor: '#FEF7ED',
						color: '#C48F3F'
					}}
					className={styles.tag}
				>
                    Information
				</div>
			)
		}
		return null
	}

	return (
		<Menu {...props} className={styles.notificationsTray}>
			<>
				<div className={styles.notificationHeader}>
					<div className={styles.topContainer}>
						<Radio.Group
							size='small'
							buttonStyle='solid'
							optionType='button'
							className='notification-filter'
							options={notificationOptions}
							value={notificationOption}
							onChange={onTabChanged}
						/>
						{
							(unreadNotifications?.length > 0) &&
								<Popconfirm
									placement='bottomRight'
									title='Do you want to read all your notifications?'
									description='Delete'
									okText='Confirm'
									cancelText='No'
									onConfirm={onReadNotificationBulk}
								>
									<Button
										className='all-read-button'
										icon={<CheckSquareOutlined />}
									>
										Mark all as read
									</Button>
								</Popconfirm>
						}
					</div>
					<Radio.Group
						size='small'
						buttonStyle='solid'
						optionType='button'
						className='notification-type-filter'
						onChange={e => onTypeTabChanged(e.target.value)}
						value={notificationTypeOption}
					>
						{notificationTypeOptions.map(option =>
							<Radio.Button
								key={option.value}
								value={option.value}
								onClick={() => onTypeTabChanged(option.value)}
							>
								{option.label}
							</Radio.Button>
						)}
					</Radio.Group>
					<div className={styles.searchContainer}>
						<TextInput
							showSearch={true}
							loading={isLoading}
							placeholder='Search here...'
							allowClear
							value={searchQuery}
							onClear={() => setSearchQuery('')}
							onChange={e => setSearchQuery(e.target.value)}
							className={styles.textInput}
						/>
					</div>
				</div>
				{
					notificationItems?.length > 0 ?
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{
								notificationItems.map(notification => {
									return (
										<Menu.Item
											onClick={() => onNotificationClick(notification)}
											key={notification.id} className={styles.notification}
										>
											<div
												onClick={(e) => {
													e.stopPropagation()
													onReadNotification(notification.id)
												}}
												style={{ visibility: notification.isRead ? 'hidden' : 'visible' }}
												className={styles.unreadIndicator}
											/>
											<div className={styles.notificationContainer}>
												{renderTag(notification.key)}
												<div className={styles.message}>
													{renderHighlightText(notification.message.en, searchQuery)}
												</div>
												<div className={styles.description}>
													{getNotificationArgs(notification).icon && getNotificationArgs(notification).icon}
													{renderHighlightText(getNotificationArgs(notification).description, searchQuery)}
												</div>
												<div className={styles.date}>
													{moment(notification.createdAt).fromNow()}
												</div>
											</div>
										</Menu.Item>
									)
								})
							}
							{
								notificationItems?.length >= 50 &&
										<Button
											size='small'
											style={{ margin: 12 }}
											onClick={onLoadMoreNotifications}
											loading={isLoading}
										>
											Load More
										</Button>
							}
						</div> :
						isLoading ?
							<div className={styles.loadingContainer}>
								<Spin size='large' />
							</div> :
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{
									notificationOption === NotificationTypes.UNREAD_NOTIFICATIONS ?
										<Empty style={{ width: 350, padding: 12 }} description='No Unread Notifications' /> :
										<Empty style={{ width: 350, padding: 12 }} description='No Notifications' />
								}
							</div>
				}
			</>
		</Menu>
	)
}

export default NotificationsTray
