import { Button, Modal, Table, Tooltip } from 'antd'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { paths } from '../../utils/constants'
require('./ECommerceUpdateOrderDisabledPartnerModal.module.css')
import styles from './ECommerceUpdateOrderDisabledPartnerModal.module.css'
import Link from 'next/link'

const ECommerceUpdateOrderDisabledPartnerModal = ({
	visible,
	setIsInactiveModalVisible,
	salesOrders: salesOrders = [],
	setDisabledPartnerOrders,
	disabledPartnerOrders,
	onCancel,
	onChange
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const processingTableColumns = [
		{
			title: 'Invoice Number',
			key: 'invoiceNumber',
			width: 150,
			render: ({ internalId, id }) => {
				return (
					<Link href={`${paths.SALES_ORDERS}/${id}`}>
						<a>{internalId}</a>
					</Link>
				)
			}
		},
		{
			title: 'Customer',
			key: 'distributor',
			width: 280,
			render: ({ distributor }) => {
				return (
					`${distributor.name || ''}`
				)
			}
		},
		{
			title: 'Location',
			key: 'location',
			width: 280,
			render: ({ location }) => {
				return (
					`${location.label || location.address}`
				)
			}
		},
		{
			title: 'Delivery Partner',
			key: 'deliveryPartner',
			width: 200,
			className: styles.deliveryPartnerCell,
			render: ({ deliveryPartner }) => {
				return (
					`${deliveryPartner?.name || ''}`
				)
			}
		},
		{
			title: 'Delivery Partner Status',
			key: 'deliveryPartnerStatus',
			className: styles.deliveryPartnerCell,
			render: ({ deliveryPartner }) => {
				if (deliveryPartner) {
					return deliveryPartner.disabled === true ? 'Inactive' : 'Active'
				} else {
					return null
				}
			}
		},
		{
			title: 'Delivery Fee',
			key: 'deliveryCharge',
			width: 200,
			render: ({ deliveryCharge }) => {
				return (
					`${deliveryCharge}`
				)
			}
		}
	]

	const changeActivePartnerOrders = () => {
		setIsLoading(true)
		const newSelectedOrders = salesOrders.filter(order => {
			const isInDisabledPartnerOrders = disabledPartnerOrders.find(disablePartnerOrder => disablePartnerOrder.id === order.id)
			return !isInDisabledPartnerOrders
		})
		setDisabledPartnerOrders([])
		onChange(newSelectedOrders)
		setIsInactiveModalVisible(false)
		setIsLoading(false)
	}

	const renderChangeStatusView = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>Change Order Status</p>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div style={{ padding: '0 24px 24px 24px' }}>
					<div style={{ marginBottom: 32 }}>
						<Table
							loading={isLoading}
							columns={processingTableColumns}
							dataSource={salesOrders}
							pagination={false}
							scroll={{ y: 'calc(100vh - 375px)' }}
							size='small'
							rowKey='id'
						/>
						<div className={styles.info} style={{ marginTop: '20px' }}>
							At least one of the Delivery Partners of selected orders is inactive. Note that the orders with inactive delivery partners will not be placed automatically in the delivery partner’s merchant panel.
							<Tooltip title={disabledPartnerOrders.map((order) => {
								return (
									<div key={order.id}>{order.internalId}</div>
								)
							})} placement='top'
							>
								<img src='/img/info.svg' style={{ marginLeft: 12 }} alt='Alert icon' />
							</Tooltip>
						</div>
					</div>
					<div className={styles.actionButtons}>
						<Button
							key={1}
							className={styles.modalButton}
							type='default'
							onClick={() => {
								onCancel()
								setIsInactiveModalVisible(false)
							}}
						>
							Cancel
						</Button>
						<Button
							key={2}
							className={styles.modalButton}
							type='primary'
							loading={isLoading}
							disabled={isLoading}
							onClick={changeActivePartnerOrders}
						>
							Change Active Partner&apos;s Order
						</Button>
						<Button
							key={3}
							className={styles.modalButton}
							type='primary'
							loading={isLoading}
							disabled={isLoading}
							onClick={() => {
								onChange()
								setDisabledPartnerOrders([])
							}}
						>
							Change
						</Button>
					</div>
				</div>
			</>
		)
	}


	return (
		<Modal
			style={{ maxWidth: 1500	}}
			width='calc(100vw - 100px)'
			destroyOnClose
			visible={visible}
			modalRender={() => {
				return (
					<div className={`${styles.modal} ant-modal-content`}>
						{
							renderChangeStatusView()
						}
					</div>
				)
			}}
		/>
	)
}

ECommerceUpdateOrderDisabledPartnerModal.prototype = {
	visible: PropTypes.bool,
	setIsInactiveModalVisible: PropTypes.bool,
	salesOrders: PropTypes.arrayOf(PropTypes.object),
	setDisabledPartnerOrders: PropTypes.arrayOf(PropTypes.object),
	disabledPartnerOrders: PropTypes.arrayOf(PropTypes.object),
	onCancel: PropTypes.func,
	onChange: PropTypes.func
}

ECommerceUpdateOrderDisabledPartnerModal.defaultProps = {
	disabledPartnerOrders: []
}

export default ECommerceUpdateOrderDisabledPartnerModal
