import Select from '../select'
import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Modal, notification, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { SALES_ORDER_APPROVED_STATUS } from '../../pages/sales-orders'
import { convertEnumToString, getFormattedCurrency } from '../../utils'
import Table from '../table'
import moment from 'moment'
import Link from 'next/link'
import styles from './CreateDeliveryPlanModal.module.css'
require('./CreateDeliveryPlanModal.less')
import PlacesAutocomplete from '../places-autocomplete'
import { createCustomDeliveryPlan } from '../../store/delivery-plans/actions'
import { fetchUserProfile } from '../../store/auth/actions'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import { Locations } from '../../services/api/locations'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { fetchVehicleTypes } from '../../store/vehicles/actions'
import { deliveryPlanStatuses, paths } from '../../utils/constants'
import Map from '../map'
import { LocationTypes } from '../location-modal'
import { SalesOrders } from '../../services/api/sales-orders'
import { DeliveryPlans } from '../../services/api/delivery-plans'
import DatePicker from '../date-picker'
import TextInput from '../text-input'

const salesOrderColumns = [
	{
		title: 'Order Date',
		key: 'orderDate',
		render: salesOrder => moment(salesOrder.orderDate).format('MMM D, YYYY')
	},
	{
		title: 'Order #',
		key: 'salesOrderID',
		render: salesOrder => {
			return (
				<Link href={`${paths.SALES_ORDERS}/${salesOrder.id}`}>
					<a className={styles.link}>{salesOrder.internalId}</a>
				</Link>
			)
		}
	},
	{
		title: 'Assigned To',
		key: 'assignedDeliveryPlanID',
		render: salesOrder => {
			return (
				salesOrder.deliveryPlan ?
					<Link href={`/delivery-plans/${salesOrder.deliveryPlan.id}`}>
						<a className={styles.link}>
							{`DP-${salesOrder.deliveryPlan.shortId}`}
						</a>
					</Link> :
					<div className={styles.unassigned}>
						Unassigned
					</div>
			)
		}
	},
	{
		title: 'Area Manager',
		key: 'areaManager',
		render: salesOrder => {
			if (salesOrder.distributor && salesOrder.distributor.accountManager) {
				return `${salesOrder.distributor.accountManager.name}`
			} else {
				return (
					<div className={styles.unassigned}>
						Unassigned
					</div>
				)
			}
		}
	},
	{
		title: 'Pick Up',
		key: 'from',
		render: salesOrder => {
			const {
				pickUpLocation
			} = salesOrder
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						pickUpLocation &&
						<div>
							<div>
								<div className={styles.badge}>
									{convertEnumToString(pickUpLocation.type.toLowerCase())}
								</div>
							</div>
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE, LocationTypes.FACTORY].includes(pickUpLocation.type) && !pickUpLocation.deletedAt ?
									<Link href={`${paths.WAREHOUSES}/${pickUpLocation.id}`}>
										<a>
											{pickUpLocation.label}
											<div className={styles.location}>
												{`${pickUpLocation.address}${pickUpLocation.internalId ? ` (${pickUpLocation.internalId})` : ''}`}
											</div>
										</a>
									</Link> :
									<div>
										{pickUpLocation.label}
										<div className={styles.location}>
											{`${pickUpLocation.address}${pickUpLocation.internalId ? ` (${pickUpLocation.internalId})` : ''}`}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Drop Off',
		key: 'to',
		render: salesOrder => {
			const {
				distributor,
				location
			} = salesOrder
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						distributor ?
							<div style={{ marginBottom: 8 }}>
								<div className={styles.badge}>
									{convertEnumToString(distributor.type.toLowerCase())}
								</div>
								<Link href={`${paths.CUSTOMERS}/${distributor.id}`}>
									<a style={{ color: '#278EA5' }}>
										<div>
											{`${distributor.name}${distributor.internalName ? ` (${distributor.internalName})` : ''}`}
											<div style={{ color: '#278EA5', fontSize: 12 }}>{distributor.internalId}</div>
										</div>
									</a>
								</Link>
							</div> : null
					}
					{
						location &&
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{
								!distributor &&
								<div>
									<div className={styles.badge}>
										{convertEnumToString(location.type.toLowerCase())}
									</div>
								</div>
							}
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE].includes(location.type) && !location.deletedAt ?
									<Link href={`${paths.WAREHOUSES}/${location.id}`}>
										<a>
											{location.label}
											<div className={styles.location}>
												{`${location.address}${location.internalId ? ` (${location.internalId})` : ''}`}
											</div>
										</a>
									</Link> :
									<div>
										{location.label}
										<div className={styles.location}>
											{`${location.address}${location.internalId ? ` (${location.internalId})` : ''}`}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Sales Amount',
		key: 'salesAmount',
		render: salesOrder => {
			return `${getFormattedCurrency(salesOrder.totalAmount)}`
		}
	}
]

const newDeliveryPlanValue = 'newDeliveryPlan'

const CreateDeliveryPlanModal = ({
	onCancel,
	visible,
	date,
	shouldShowUnassigned
}) => {
	const dispatch = useDispatch()
	const { userProfile } = useSelector(state => state.authReducer)
	const [approvedSalesOrders, setApprovedSalesOrders] = useState([])
	const [approvedSalesOrdersPage, setApprovedSalesOrdersPage] = useState(0)
	const [approvedSalesOrdersTotalCount, setApprovedSalesOrdersTotalCount] = useState(0)
	const [loadingLocation, setLoadingLocation] = useState(null)
	const [locationOptions, setLocationOptions] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [selectedSalesOrderIds, setSelectedSalesOrderIds] = useState([])
	const [selectedSalesOrders, setSelectedSalesOrders] = useState([])
	const [pickUpLocationOptions, setPickUpLocationOptions] = useState([])
	const [shouldDisableLoadingLocation, setShouldDisableLoadingLocation] = useState(false)
	const [deliveryDate, setDeliveryDate] = useState(null)
	const [selectedDeliveryPlan, setSelectedDeliveryPlan] = useState()
	const [deliveryPlans, setDeliveryPlans] = useState([])
	const [isLoadingDeliveryPlans, setIsLoadingDeliveryPlans] = useState(false)
	const [step, setStep] = useState(1)
	const [isCreating, setIsCreating] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const [fromDate, setFromDate] = useState(date)
	const [toDate, setToDate] = useState(date ? new Date(date.setHours(23, 59, 59, 999)) : null)
	const [showUnassigned, setShowUnassigned] = useState(shouldShowUnassigned)
	const [showAssigned, setShowAssigned] = useState(false)
	const [showStockTransferOrders, setShowStockTransferOrders] = useState(false)
	const [showSalesOrders, setShowSalesOrders] = useState(false)
	const [map, setMap] = useState()
	const [maps, setMaps] = useState()
	const [isLoadingPickupLocations, setIsLoadingPickupLocations] = useState(false)
	const [pickUpLocations, setPickUpLocations] = useState([])
	const [selectedPickUpLocationId, setSelectedPickUpLocationId] = useState(null)
	const [pageSize, setPageSize] = useState(50)
	const debouncedSearchTerm = useDebounceValue(searchTerm, 500)
	const [filteredTableColumns, setFilteredTableColumns] = useState([])
	const [filterColumnsMap, setFilterColumnsMap] = useState({})
	const deliveryPlanOptions = useMemo(() => {
		const options = [
			{ value: newDeliveryPlanValue, label: 'New Delivery Plan' },
			...deliveryPlans.map(({ id, shortId }) => ({ value: id, label: `DP-${shortId}` }))
		]
		return options
	}, [deliveryPlans])
	const [isFilterControlVisible, setIsFilterControlVisible] = useState(false)

	useEffect(() => {
		getSalesOrders()
	},
	[
		debouncedSearchTerm,
		toDate,
		fromDate,
		showAssigned,
		showUnassigned,
		showSalesOrders,
		showStockTransferOrders,
		selectedPickUpLocationId,
		pageSize
	])

	useEffect(() => {
		dispatch(fetchUserProfile())
		fetchLocations()
		dispatch(fetchVehicleTypes())
		setFilterColumnsMap(getFilterColumns())
	}, [])

	useEffect(() => {
		if (userProfile) {
			setLoadingLocation({
				placeId: 'place_id',
				address: userProfile.company.address,
				lat: userProfile.company.latitude,
				lng: userProfile.company.longitude
			})
		}
	}, [userProfile])

	useEffect(() => {
		updateMapBounds()
	}, [loadingLocation])

	useEffect(() => {
		if (userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setPickUpLocationOptions(locations)
				if (locations.length === 1) {
					const location = locations[0]
					setSelectedPickUpLocationId(location.id)
				}
			} else {
				fetchPickUpLocations()
			}
		}
	}, [userProfile])

	useEffect(() => {
		setFilteredTableColumns(salesOrderColumns.filter(({ key }) => filterColumnsMap?.[key]))
	}, [filterColumnsMap])

	const getSalesOrders = async (page) => {
		try {
			setIsLoading(true)
			const response = await SalesOrders.index(getParams(page))
			if (response.data) {
				const { results, page, count } = response.data
				setApprovedSalesOrders(results)
				setApprovedSalesOrdersPage(page)
				setApprovedSalesOrdersTotalCount(count)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to fetch orders.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const getDeliveryPlansByDate = async (moment) => {
		setIsLoadingDeliveryPlans(true)
		const start = moment.clone().startOf('day')
		const end = moment.clone().endOf('day')
		const { data } = await DeliveryPlans.index({
			status: deliveryPlanStatuses.DELIVERY_PLAN_PENDING_STATUS,
			fromDate: start.toDate(),
			toDate: end.toDate()
		})
		setSelectedDeliveryPlan(null)
		setDeliveryPlans(data.results)
		setIsLoadingDeliveryPlans(false)
	}

	const getParams = (page = 0) => {
		const params = {
			status: SALES_ORDER_APPROVED_STATUS,
			page: page,
			salesOrderItems: true
		}
		if (debouncedSearchTerm) {
			params.searchTerm = debouncedSearchTerm
		}
		if (fromDate && toDate) {
			params.searchTerm = debouncedSearchTerm
			params.fromDate = moment(fromDate).startOf('day').toDate()
			params.toDate = moment(toDate).endOf('day').toDate()
		}
		if (showAssigned) {
			params.unassigned = false
		}
		if (showUnassigned) {
			params.unassigned = true
		}
		if (showAssigned && showUnassigned) {
			delete params.unassigned
		}
		if (showSalesOrders) {
			params.isStockTransfer = false
		}
		if (showStockTransferOrders) {
			params.isStockTransfer = true
		}
		if (showSalesOrders && showStockTransferOrders) {
			delete params.isStockTransfer
		}
		if (pageSize) {
			params.pageSize = pageSize
		}
		if (selectedPickUpLocationId) {
			params.pickUpLocationIds = [selectedPickUpLocationId]
		} else {
			delete params.pickUpLocationIds
		}
		return params
	}

	const fetchPickUpLocations = async () => {
		setIsLoadingPickupLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY], page: -1 })
		setPickUpLocationOptions(response.data.results)
		setIsLoadingPickupLocations(false)
	}

	const fetchLocations = async () => {
		const response = await Locations.index()
		const results = response.data.results
		const locationOptions = results.map(result => {
			return {
				label: result.address,
				value: result.id,
				location: result
			}
		})
		setLocationOptions(locationOptions)
	}

	const onPageChanged = async (page) => {
		await getSalesOrders(page - 1)
	}

	const onSelectChange = (selectedRowKeys, selectedRows) => {
		setSelectedSalesOrderIds(selectedRowKeys)
		setSelectedSalesOrders(selectedRows)
	}

	const createDeliveryPlan = async () => {
		try {
			if (deliveryDate && selectedSalesOrderIds.length !== 0) {
				const payload = {
					deliveryDate: deliveryDate,
					salesOrderIds: selectedSalesOrderIds,
					deliveryPlanId: selectedDeliveryPlan?.id
				}
				if (loadingLocation.placeId !== 'place_id') {
					payload.loadingLocation = {
						address: loadingLocation.address,
						latitude: loadingLocation.lat,
						longitude: loadingLocation.lng
					}
				}
				setIsCreating(true)
				const createdDeliveryPlanResponse = await dispatch(createCustomDeliveryPlan(payload))
				setIsCreating(false)
				notification.success({
					message: `Added to Delivery Plan DP-${createdDeliveryPlanResponse.shortId}`,
					description: `Orders successfully added to Delivery Plan DP-${createdDeliveryPlanResponse.shortId}`,
					placement: 'bottomLeft'
				})
				onClose()
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Create Delivery Plan',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCreating(false)
		}
	}

	const resetStates = () => {
		setDeliveryDate(null)
		setSelectedSalesOrderIds([])
		setStep(1)
		if (userProfile) {
			setLoadingLocation({
				placeId: 'place_id',
				address: userProfile.company.address,
				lat: userProfile.company.latitude,
				lng: userProfile.company.longitude
			})
		}
	}

	const onClose = () => {
		resetStates()
		onCancel()
	}

	const onContinue = () => {
		setStep(2)
		const disableLoadingLocation = selectedSalesOrders.every(salesOrder => salesOrder.pickUpLocation)
		const pickUpLocations = selectedSalesOrders.map(({ pickUpLocation }) => pickUpLocation)
		setShouldDisableLoadingLocation(disableLoadingLocation)
		setPickUpLocations(pickUpLocations)
		updateMapBounds()
	}

	const onMapsLoaded = (map, maps) => {
		setMap(map)
		setMaps(maps)
		updateMapBounds()
	}

	const updateMapBounds = () => {
		if (!maps) {
			return
		}
		if (!map) {
			return
		}
		const bounds = new maps.LatLngBounds()
		pickUpLocations.forEach(location => {
			bounds.extend({
				lat: +location.latitude,
				lng: +location.longitude
			})
		})
		if (loadingLocation) {
			bounds.extend({
				lat: +loadingLocation.lat,
				lng: +loadingLocation.lng
			})
			if (pickUpLocations.length === 0) {
				map.panTo({
					lat: +loadingLocation.lat,
					lng: +loadingLocation.lng
				})
			}
		}
		if (pickUpLocations.length > 0) {
			map.fitBounds(bounds)
		}
	}

	const onDeliveryDateSelected = (moment) => {
		if (moment) {
			setDeliveryDate(moment.toDate())
			getDeliveryPlansByDate(moment)
		} else {
			setDeliveryDate(null)
			setSelectedDeliveryPlan(null)
			setDeliveryPlans([])
		}
	}

	const getFilterColumns = (isChecked = true) =>
		salesOrderColumns.reduce((acc, { key }) => ({ ...acc, [key]: isChecked }), {})

	const isAllColumnChecked = () => Object.values(filterColumnsMap).every((value) => value === true)

	const onDeliveryPlanChange = (value) => {
		if (value === newDeliveryPlanValue) {
			return setSelectedDeliveryPlan(null)
		}

		const deliveryPlan = deliveryPlans.find(({ id }) => id === value)
		setSelectedDeliveryPlan(deliveryPlan)
	}

	const renderStepOne = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>Create Delivery Plan</p>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div className={styles.inputGrid}>
					<TextInput
						containerClassName={styles.searchInput}
						placeholder='Search Order Number, Customer Name'
						showSearch={true}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<DatePicker
						title='From (Shipping Date)'
						placeholder='Select Date'
						allowClear={true}
						disabledDate={current => current && toDate && current.valueOf() >= moment(toDate).valueOf()}
						value={fromDate ? moment(fromDate) : null}
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<DatePicker
						title='To (Shipping Date)'
						placeholder='Select Date'
						allowClear={true}
						disabledDate={current => current && current.valueOf() <= moment(fromDate).valueOf()}
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
					/>
					<Select
						containerClassName={styles.pickUpLocationSelect}
						title='Pick Up Location'
						placeholder='Select'
						allowClear={true}
						isLoading={isLoadingPickupLocations}
						options={pickUpLocationOptions.map(({ id, internalId, label, type, address }) => {
							return {
								key: id,
								value: id,
								label: internalId || label || convertEnumToString(type.toLowerCase()),
								address
							}
						})}
						optionLabelProp='label'
						optionRender={({ label, address }) => {
							return (
								<div>
									<div>{label}</div>
									<div>{address}</div>
								</div>
							)
						}}
						value={selectedPickUpLocationId}
						onChange={setSelectedPickUpLocationId}
					/>
				</div>
				<div className={styles.checkboxesContainer}>
					<div className={styles.checkboxes}>
						<Checkbox
							checked={showSalesOrders}
							onChange={(e) => setShowSalesOrders(e.target.checked)}
						>
							Sales Orders
						</Checkbox>
						<Checkbox
							checked={showStockTransferOrders}
							onChange={(e) => setShowStockTransferOrders(e.target.checked)}
						>
							Stock Transfer Orders
						</Checkbox>
						<Checkbox
							checked={showAssigned}
							onChange={(e) => setShowAssigned(e.target.checked)}
						>
							Show assigned orders
						</Checkbox>
						<Checkbox
							checked={showUnassigned}
							onChange={(e) => setShowUnassigned(e.target.checked)}
						>
							Show unassigned orders
						</Checkbox>
					</div>
					{
						selectedSalesOrderIds.length > 0 &&
						<p className={styles.rowSelectionText}>
							You have selected {selectedSalesOrderIds.length} item{selectedSalesOrderIds.length > 1 && 's'}.&nbsp;
							<span
								onClick={() => setSelectedSalesOrderIds([])}
							>
								Clear Selection
							</span>
						</p>
					}
				</div>
				<Table
					loading={isLoading}
					columns={filteredTableColumns}
					dataSource={approvedSalesOrders}
					pagination={{
						pageSize,
						total: approvedSalesOrdersTotalCount,
						position: ['topRight'],
						onChange: onPageChanged,
						showSizeChanger: false,
						current: approvedSalesOrdersPage + 1
					}}
					scroll={{ y: 700 }}
					rowKey='id'
					rowSelection={{
						preserveSelectedRowKeys: true,
						selectedRowKeys: selectedSalesOrderIds,
						onChange: onSelectChange
					}}
					refresh={() => onPageChanged(approvedSalesOrdersPage + 1)}
					onPageSizeChange={setPageSize}
					filter
					filterTrigger='click'
					filterPlacement='bottomLeft'
					filterVisible={isFilterControlVisible}
					onFilterVisibleChange={setIsFilterControlVisible}
					filterContent={
						<div className={styles.columnCheckboxes}>
							<Checkbox
								checked={isAllColumnChecked()}
								value='isAllChecked'
								onChange={(e) => {
									if (e.target.checked) {
										setFilterColumnsMap(getFilterColumns(true))
									} else {
										setFilterColumnsMap(getFilterColumns(false))
									}
								}}
							>
								Select All
							</Checkbox>
							{
								salesOrderColumns.map(({ key, title }) => {
									return (
										<div key={key}>
											<Checkbox
												checked={filterColumnsMap[key]}
												value={key}
												onChange={(e) => setFilterColumnsMap({
													...filterColumnsMap, [key]: e.target.checked
												})}
											>
												{title}
											</Checkbox>
										</div>
									)
								})
							}
						</div>
					}
				/>
				<div className={styles.buttons}>
					<Button
						type='primary'
						disabled={!selectedSalesOrderIds.length}
						onClick={onContinue}
					>
						Continue
					</Button>
				</div>
			</>
		)
	}

	const renderStepTwo = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>Create Delivery Plan</p>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div style={{ display: 'grid', gap: 16 }}>
					<DatePicker
						title='Delivery Date'
						allowClear
						disabledDate={current => current && current.valueOf() < moment().subtract(1, 'd')}
						value={deliveryDate ? moment(deliveryDate) : null}
						onChange={moment => onDeliveryDateSelected(moment)}
					/>
					<Select
						title='Delivery Plan'
						placeholder='Select Delivery Plan'
						tooltip='Select whether to create a new delivery plan or add the orders to an existing delivery plan on the same date.'
						loading={isLoadingDeliveryPlans}
						options={deliveryPlanOptions}
						value={selectedDeliveryPlan?.id}
						onChange={onDeliveryPlanChange}
					/>
					{
						!shouldDisableLoadingLocation &&
						<div className='custom-location-select-container'>
							<label style={{ fontSize: 12 }}>
								<span>
									<span style={{ color: '#ff4d4f', marginRight: 12, fontSize: 14 }}>*</span>
									Loading Location
									<Tooltip title='Every delivery plan needs a starting location.'>
										<QuestionCircleOutlined style={{ marginLeft: 12, color: '#00000073', fontSize: 10 }} />
									</Tooltip>
								</span>
							</label>
							<PlacesAutocomplete
								onClear={() => setLoadingLocation(null)}
								disabledNormalStyle={true}
								placeholder='Loading Location'
								className='custom-location-select'
								initialOptions={locationOptions}
								value={
									loadingLocation ? {
										value: loadingLocation.placeId,
										label: loadingLocation.address
									} : null
								}
								onLocationSelected={setLoadingLocation}
							/>
						</div>
					}
					<Map
						containerStyle={{
							width: '100%',
							height: '200px'
						}}
						onMapsLoaded={onMapsLoaded}
						hideable={true}
					>
						{
							pickUpLocations.map(location => {
								return (
									<PickupLocationMarker
										key={location.id}
										lat={location.latitude}
										lng={location.longitude}
									/>
								)
							})
						}
					</Map>
					<div className={styles.buttons}>
						<Button onClick={() => setStep(1)}>
							Previous
						</Button>
						<Button
							type='primary'
							loading={isCreating}
							disabled={!selectedSalesOrderIds.length || !deliveryDate}
							onClick={createDeliveryPlan}
						>
							Create Plan
						</Button>
					</div>
				</div>
			</>
		)
	}

	return (
		<Modal
			style={{ maxWidth: step === 1 ? 1196 : 396 }}
			width='calc(100vw - 122px)'
			destroyOnClose={true}
			visible={visible}
			modalRender={() => {
				return (
					<div className={`${styles.modal} ant-modal-content`}>
						{step === 1 ? renderStepOne() : renderStepTwo()}
					</div>
				)
			}}
			onCancel={onClose}
			ofterClose={resetStates}
		/>
	)
}

const PickupLocationMarker = () => {
	return (
		<img
			style={{ border: '2px solid #fff', borderRadius: '50%' }}
			src='/img/warehouse-marker.svg'
			alt='Warehouse icon'
			width={30}
			height={30}
		/>
	)
}

CreateDeliveryPlanModal.propTypes = {
	onCancel: PropTypes.func,
	visible: PropTypes.bool,
	date: PropTypes.object,
	shouldShowUnassigned: PropTypes.bool
}

CreateDeliveryPlanModal.defaultProps = {
	onCancel: () => {},
	visible: false,
	shouldShowUnassigned: false
}

export default CreateDeliveryPlanModal
