import { Button, Modal, notification } from 'antd'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete from '../places-autocomplete'
import Map from '../map'
import styles from './LocationModal.module.css'
import { Locations } from '../../services/api/locations'
import { convertEnumToString, getCountryCode } from '../../utils'
import CustomPhoneInput from '../phone-input'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import TextInput from '../text-input'
import Select from '../select'

export const LocationTypes = {
	GENERAL:'GENERAL',
	LOADING: 'LOADING',
	UNLOADING: 'UNLOADING',
	BILLING: 'BILLING',
	SHIPPING: 'SHIPPING',
	HEADQUARTERS: 'HEADQUARTERS',
	WAREHOUSE: 'WAREHOUSE',
	PICKUP: 'PICKUP',
	FACTORY: 'FACTORY',
	VIRTUAL_WAREHOUSE: 'VIRTUAL_WAREHOUSE',
	SITE: 'SITE',
	BRANCH: 'BRANCH'
}

export const WarehouseDescriptions = {
	CENTRAL_WAREHOUSE: 'central_warehouse',
	FINISHED_GOODS_WAREHOUSE: 'finished_goods_warehouse',
	RETURN_WAREHOUSE: 'return_warehouse',
	DELIVERY_PIPELINE: 'delivery_pipeline'
}

const LocationModal = ({
	create,
	disableLabel,
	visible,
	onCancel,
	initialLocation,
	showInitialLocations,
	country,
	onLocationAdded,
	onLocationUpdated,
	editableLocation,
	selectableTypes,
	title,
	okText
}) => {
	const countryCode = getCountryCode()
	const [location, setLocation] = useState(initialLocation)
	const [locationOptions, setLocationOptions] = useState([])
	const [center, setCenter] = useState()
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [locationType, setLocationType] = useState(LocationTypes.GENERAL)
	const [locationDescription, setLocationDescription] = useState()
	const [label, setLabel] = useState('')
	const [customAddress, setCustomAddress] = useState('')
	const [internalId, setInternalId] = useState('')
	const [contactPerson, setContactPerson] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [postCode, setPostCode] = useState('')
	const [isWritingLocation, setIsWritingLocation] = useState(false)

	useEffect(() => {
		if (visible) {
			initialize()
		}
	}, [visible])

	const initialize = async () => {
		if (editableLocation) {
			setLocationType(editableLocation.type)
			setLabel(editableLocation.label)
			setPostCode(editableLocation.postCode)
			setInternalId(editableLocation.internalId)
			setContactPerson(editableLocation.contactPerson || '')
			setPhoneNumber(editableLocation.phone || '')
			setLocationDescription(editableLocation.description)
			setCenter({ lat: +editableLocation.latitude, lng: +editableLocation.longitude })
			setLocation({ value: editableLocation.id, label: editableLocation.address, address: editableLocation.address })
		} else {
			if (showInitialLocations) {
				setIsLoadingLocations(true)
				const response = await Locations.index({ types: selectableTypes })
				const results = response.data.results
				const locationOptions = results.map(result => {
					return {
						label:
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{result.address}
							{
								result.label && <div style={{ fontWeight: 'bold' }}>{result.label}</div>
							}
							<div style={{ fontWeight: 'bold', fontSize: 11 }}>{convertEnumToString(result.type)}</div>
						</div>,
						value: result.id,
						location: result
					}
				})
				setLocationOptions(locationOptions)
				setIsLoadingLocations(false)
			}
			if (selectableTypes.length > 0) {
				setLocationType(selectableTypes[0])
			}
		}
	}

	const onLocationSelected = location => {
		if (editableLocation) {
			setLocation({ value: location.placeId, label: location.address, address: location.address })
		} else {
			setLocation(location)
			setLabel(location.label || '')
		}
		if (location.address !== null) {
			setCustomAddress(location.address)
			setPostCode(location.postCode || '')
		}
		setCenter({ lat: location.lat, lng: location.lng })
	}

	const onOk = async () => {
		if (editableLocation) {
			await onUpdate()
		} else {
			await onCreateOrSelect()
		}
	}

	const onUpdate = async () => {
		let address = customAddress.trim()
		if (!address) {
			address = location.address || location.label
		}
		const data = {
			label: label,
			description: locationDescription,
			internalId: internalId,
			address,
			postCode
		}
		if (contactPerson) {
			data.contactPerson = contactPerson
		}
		if (isValidPhoneNumber(phoneNumber)) {
			data.phone = phoneNumber
		}
		try {
			setIsWritingLocation(true)
			const response = await Locations.update(editableLocation.id, data)
			const updatedLocation = response.data
			notification.success({
				message: 'Location Updated',
				description: <div>Location with address <b>{label ? label : editableLocation.address}</b> has been updated.</div>,
				placement: 'bottomLeft'
			})
			if (onLocationUpdated) {
				onLocationUpdated(updatedLocation)
			}
			if (onCancel) {
				onCancel()
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Update Location',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsWritingLocation(false)
		}
	}

	const onCreateOrSelect = async () => {
		let address = customAddress.trim()
		if (!address) {
			address = location.address || location.label
		}
		const data = {
			address,
			latitude: location.lat,
			longitude: location.lng,
			postCode: postCode,
			label: label,
			internalId: internalId,
			type: locationType,
			description: locationDescription,
			id: location.id,
			district: location.district,
			division: location.division
		}
		if (contactPerson) {
			data.contactPerson = contactPerson.trim()
		}
		if (isValidPhoneNumber(phoneNumber)) {
			data.phone = phoneNumber
		}
		if (create && !location.id) {
			try {
				setIsWritingLocation(true)
				const response = await Locations.create(data)
				const addedLocation = response.data
				setLocationOptions([
					{
						label: addedLocation.address,
						value: addedLocation.id,
						location: addedLocation
					},
					...locationOptions
				])
				notification.success({
					message: 'New Location Added',
					description: <div>Location with address <b>{response.data.address}</b> has been added.</div>,
					placement: 'bottomLeft'
				})
				if (onLocationAdded) {
					onLocationAdded(addedLocation, true)
				}
				if (onCancel) {
					onCancel()
				}
			} catch (e) {
				notification.error({
					message: 'Unable to Add New Location',
					description: e.message,
					placement: 'bottomLeft'
				})
			} finally {
				setIsWritingLocation(false)
			}
		} else {
			// Do not create location. Instead, pass on the data for further processing.
			if (onLocationAdded) {
				onLocationAdded(data, false)
			}
			if (onCancel) {
				onCancel()
			}
		}
	}

	const getLocationTitle = () => {
		if (editableLocation) {
			return 'Edit Location'
		} else {
			if (selectableTypes.length === 1 && selectableTypes[0] !== LocationTypes.GENERAL) {
				const selectableType = selectableTypes[0]
				return `Add New ${convertEnumToString(selectableType.toLowerCase())} Location`
			} else {
				return 'Add New Location'
			}
		}
	}

	const getLocation = () => {
		if (editableLocation) {
			return location ? location : null
		} else {
			return location ? { value: location.placeId, label: location.address } : null
		}
	}

	const isValid = () => {
		if (!editableLocation) {
			if (!location) {
				return false
			}
		}
		const phoneObject = parsePhoneNumber(phoneNumber)
		if (phoneObject?.nationalNumber) {
			if (!isValidPhoneNumber(phoneNumber)) {
				return false
			}
		}

		return true
	}

	const setContactPersonName = (value) => {
		let text = !contactPerson || !value.trim() ? value.toLowerCase().trim() : value.toLowerCase()
		text = text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
		setContactPerson(text)
	}

	const locationTypeOptions = Object.keys(LocationTypes).map(type => ({
		label: convertEnumToString(type),
		value: type
	}))

	const warehouseTypeOptions = Object.keys(WarehouseDescriptions).map(type => ({
		label: convertEnumToString(type.toLowerCase()),
		value: type
	}))

	const renderModalContent = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<p className={styles.modalTitle}>{title ? title : getLocationTitle()}</p>
					<svg className={styles.closeIcon} onClick={onCancel}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				</div>
				<div className={styles.inputGrid}>
					<PlacesAutocomplete
						style={{ width: '100%' }}
						country={country}
						onClear={() => setLocation(null)}
						placeholder='Address'
						value={getLocation()}
						initialOptions={locationOptions}
						loading={isLoadingLocations}
						disabled={isLoadingLocations}
						onLocationSelected={onLocationSelected}
						popupContainer={() => document.querySelector(`.${styles.modal}`)}
					/>
					{
						location &&
						<TextInput
							title='Address'
							tooltip='Customize the address'
							placeholder={location && location.address ? location.address : 'Custom Address'}
							value={customAddress}
							onChange={e => setCustomAddress(e.target.value)}
						/>
					}
					{
						countryCode === 'PK' &&
						<TextInput
							title='Post Code'
							placeholder='Post Code'
							value={postCode}
							onChange={e => setPostCode(e.target.value)}
						/>
					}
					{
						disableLabel ?
							<div style={{ marginTop: 24 }} /> :
							<TextInput
								title='Label'
								placeholder='Label'
								value={label}
								onChange={e => setLabel(e.target.value)}
							/>
					}
					<TextInput
						title='Internal ID'
						placeholder='Internal ID'
						value={internalId}
						onChange={e => setInternalId(e.target.value)}
					/>
					<TextInput
						title='Contact Person'
						placeholder='Contact Person'
						value={contactPerson}
						onChange={({ target: { value } }) => {
							setContactPersonName(value)
						}}
					/>
					<CustomPhoneInput
						title='Phone Number (Optional)'
						value={phoneNumber}
						onChange={setPhoneNumber}
					/>
					{
						selectableTypes.length > 0 &&
						<Select
							title='Type'
							placeholder='Select Type'
							popupContainer={() => document.querySelector(`.${styles.modal}`)}
							options={selectableTypes.map(type => ({
								label: convertEnumToString(type.toLowerCase()),
								value: type
							}))}
							value={locationType}
							onChange={setLocationType}
						/>
					}
					{
						selectableTypes.length === 0 &&
						<Select
							title='Type'
							placeholder='Select Type'
							disabled={!!editableLocation}
							popupContainer={() => document.querySelector(`.${styles.modal}`)}
							options={locationTypeOptions}
							value={locationType}
							onChange={setLocationType}
						/>
					}
					{
						locationType === LocationTypes.WAREHOUSE &&
						<Select
							title='Warehouse Type'
							options={warehouseTypeOptions}
							placeholder='Select Warehouse Type'
							onChange={setLocationDescription}
							value={locationDescription}
							popupContainer={() => document.querySelector(`.${styles.modal}`)}
						/>
					}
					<div>
						<Map
							containerStyle={{
								width: '100%',
								height: '250px'
							}}
							center={center}
							hideable={true}
						>
							{
								center ?
									<LocationMarker
										lat={center.lat}
										lng={center.lng}
									/> : null
							}
						</Map>
					</div>
				</div>
				<div className={styles.buttons}>
					<Button onClick={onCancel}>
						Cancel
					</Button>
					<Button
						type='primary'
						loading={isWritingLocation}
						disabled={!isValid()}
						onClick={onOk}
					>
						{okText ? okText : editableLocation ? 'Update' : 'Add'}
					</Button>
				</div>
			</>
		)
	}

	return (
		<Modal
			style={{ maxWidth: 520 }}
			width='calc(100vw - 122px)'
			destroyOnClose={true}
			visible={visible}
			modalRender={() => {
				return (
					<div className={`${styles.modal} ant-modal-content`}>
						{renderModalContent()}
					</div>
				)
			}}
			onCancel={onCancel}
		/>
	)
}

LocationModal.propTypes = {
	create: PropTypes.bool,
	disableLabel: PropTypes.bool,
	visible: PropTypes.bool,
	onCancel: PropTypes.func,
	initialLocation: PropTypes.object,
	showInitialLocations: PropTypes.bool,
	country: PropTypes.string,
	onLocationAdded: PropTypes.func,
	onLocationUpdated: PropTypes.func,
	editableLocation: PropTypes.object,
	selectableTypes: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string,
	okText: PropTypes.string
}

LocationModal.defaultProps = {
	create: true,
	disableLabel: false,
	showInitialLocations: true,
	selectableTypes: []
}

export default LocationModal

const LocationMarker = () => {
	return (
		<div className={styles.locationMarker} />
	)
}
