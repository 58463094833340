export const MeasurementUnits = {
	MM: 'mm',
	CM: 'cm',
	M: 'm',
	IN: 'in',
	FT: 'ft',
	YD: 'yd'
}

export const WeightUnits = {
	KG: 'kg',
	G: 'g',
	LBS: 'lbs',
	LB: 'lb',
	OZ: 'oz',
	TONNE: 'tonne'
}

export const VolumeUnits = {
	M3: 'm3',
	L: 'l',
	CC: 'cc',
	CUIN: 'cuin',
	CUFT: 'cuft',
	ML: 'ml'
}

export const specificationTypeOptions = [
	{
		value: 'NUMBER',
		label: 'Number Input'
	},
	{
		value: 'STRING',
		label: 'Text Input'
	},
	{
		value: 'BOOLEAN',
		label: 'Checkbox'
	},
	{
		value: 'WEIGHT',
		label: 'Weight Input'
	},
	{
		value: 'VOLUME',
		label: 'Volume Input'
	},
	{
		value: 'LENGTH',
		label: 'Measurement Input'
	}
]

export const measurementUnitOptions = [
	{
		value: 'mm',
		label: 'Millimetres'
	},
	{
		value: 'cm',
		label: 'Centimetres'
	},
	{
		value: 'm',
		label: 'Metres'
	},
	{
		value: 'in',
		label: 'Inches'
	},
	{
		value: 'ft',
		label: 'Feet'
	},
	{
		value: 'yd',
		label: 'Yards'
	}
]

export const weightUnitOptions = [
	{
		value: 'g',
		label: 'Grams'
	},
	{
		value: 'kg',
		label: 'Kilograms'
	},
	{
		value: 'lbs',
		label: 'Pounds'
	},
	{
		value: 'oz',
		label: 'Ounces'
	},
	{
		value: 'tonne',
		label: 'Tonnes'
	}
]

export const volumeUnitOptions = [
	{
		value: 'm3',
		label: 'Cubic Metres'
	},
	{
		value: 'l',
		label: 'Litres'
	},
	{
		value: 'ml',
		label: 'Millilitres'
	},
	{
		value: 'cc',
		label: 'Cubic Centimetres'
	},
	{
		value: 'cuin',
		label: 'Cubic Inches'
	},
	{
		value: 'cuft',
		label: 'Cubic Feet'
	}
]

export const packageTypeOptions = [
	{
		value: 'CARTON',
		label: 'Carton'
	},
	{
		value: 'BAG',
		label: 'Bag'
	},
	{
		value: 'BOX',
		label: 'Box'
	},
	{
		value: 'CRATE',
		label: 'Crate'
	},
	{
		value: 'SACK',
		label: 'Sack'
	}
]

export const packageUnitOptions = [
	{
		value: 'PIECE',
		label: 'Piece'
	},
	{
		value: 'PACKET',
		label: 'Packet'
	},
	{
		value: 'LITRE',
		label: 'Litre'
	},
	{
		value: 'KILOGRAM',
		label: 'Kilogram'
	}
]

/**
 * Convert measurement unit to metres
 * @param {*} value The measurement value.
 * @param {*} measuremenUnit The unit to convert from.
 * @returns a number in metres.
 */
export const convertMeasurementUnit = (value, measuremenUnit) => {
	if (!value) {
		return 0
	}
	switch (measuremenUnit) {
		case MeasurementUnits.CM:
			return value / 100.00
		case MeasurementUnits.FT:
			return value / 3.2808
		case MeasurementUnits.IN:
			return value / 39.37
		case MeasurementUnits.M:
			return value
		case MeasurementUnits.MM:
			return value / value, 1000.00
		case MeasurementUnits.YD:
			return value / value, 1.094
		default:
			return value
	}
}

/**
 * Convert weight unit to kilograms
 * @param {*} value The weight value.
 * @param {*} weightUnit The unit to convert from.
 * @returns a number in kilograms
 */
export const convertWeightUnit = (value, weightUnit = WeightUnits.KG) => {
	if (!value) {
		return 0
	}
	switch (weightUnit) {
		case WeightUnits.G:
			return value / 1000.00
		case WeightUnits.KG:
			return value
		case WeightUnits.LBS:
		case WeightUnits.LB:
			return value / 2.205
		case WeightUnits.OZ:
			return value / 35.274
		case WeightUnits.TONNE:
			return value * 1000
		default:
			return value
	}
}

/**
 * Convert volume unit to cubic metres.
 * @param {*} value The volume value.
 * @param {*} volumeUnit The unit to convert from.
 * @returns a number in cubic metres.
 */
export const convertVolumeUnit = (value, volumeUnit = VolumeUnits.M3) => {
	if (!value) {
		return 0
	}
	switch (volumeUnit) {
		case VolumeUnits.CC:
			return value / 1e6
		case VolumeUnits.CUFT:
			return value / 35.315
		case VolumeUnits.CUIN:
			return value / 61020
		case VolumeUnits.L:
			return value / 1000.0
		case VolumeUnits.M3:
			return value
		case VolumeUnits.ML:
			return value / 1e6
		default:
			return value
	}
}

/**
 * Convert milliseconds to days/hours string.
 * @param {*} milliseconds The milliseconds value.
 * @param {*} to The output format.
 * @returns a string of days/hours.
 */
export const convertMilliseconds = (milliseconds, to) => {
	const oneDayInMilliseconds = 86400000
	const oneHourInMilliseconds = 3600000
	switch (to) {
		case 'days':
			return Math.round(milliseconds / oneDayInMilliseconds)
		case 'days-and-hours': {
			const days = Math.floor(milliseconds / oneDayInMilliseconds).toString()
			const hours = Math.round((milliseconds - days * oneDayInMilliseconds) / oneHourInMilliseconds).toString()
			return {
				days,
				hours
			}
		}
		default:
			return ''
	}
}
