import { DatePicker as AntDatePicker } from 'antd'
import styles from './DatePicker.module.css'
require('./DatePicker.less')

const DatePicker = ({
	style,
	className,
	title,
	placeholder = 'Select a date',
	allowClear,
	autoFocus,
	popupContainer,
	disabledDate,
	value,
	error,
	onChange
}) => {
	return (
		<div className={`${styles.datePicker} date-picker`}>
			<p className={`${styles.title} ${error && styles.titleError}`}>{title}</p>
			<AntDatePicker
				style={style}
				className={`date-picker-picker ${className} ${error && styles.pickerError}`}
				placeholder={placeholder}
				allowClear={allowClear}
				autoFocus={autoFocus}
				suffixIcon={
					<img className={styles.calendarIcon} src='/img/calendar-line.svg' />
				}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
				disabledDate={disabledDate}
				value={value}
				onChange={onChange}
			/>
		</div>
	)
}

export default DatePicker
