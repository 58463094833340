import { convertNumberToBankNoteWords, getFormattedCurrency } from '../../utils'
import styles from './PostalOrderForm.module.css'

const PostalOrderForm = ({ data }) => {
	const {
		totalDueAmount,
		totalPaidAmount,
		pickUpLocation,
		distributor,
		location
	} = data
	const owingAmount = (+totalDueAmount || 0) - (+totalPaidAmount || 0)
	const roundedOwingAmount = Math.floor(owingAmount)
	const fractionalOwningAmount = owingAmount - roundedOwingAmount

	return (
		<>
			<div className={styles.page}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex' }}>
						<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 6 }}>
							<div>Month Stamp</div>
							<div className={styles.square} />
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 6, marginLeft: 'auto' }}>
							<div>Oblong M.O. Stamp issue</div>
							<div className={styles.rectangle} />
						</div>
					</div>
					<div style={{ display: 'flex', marginTop: 12 }}>
						<div style={{ flex: 1 }}>
							<div style={{ display: 'flex', flexDirection: 'column', width: '18mm', alignItems: 'center', gap: 4 }}>
							V.P.L.
								<div style={{ height: 2, background: 'black', width: '18mm' }} />
							V.P.P.
							</div>
						</div>
						<div style={{ flex: 1 }}>
						M.O. No. <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _</span> date <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _</span>
						</div>
					</div>
					<div style={{ display: 'flex', marginTop: 12, alignItems: 'center' }}>
					For
						<div style={{ display: 'flex', flexDirection: 'column', gap: 6, marginLeft: 24 }}>
							<div style={{ lineHeight: 1 }}>Rs.</div>
							<div className={styles.amount}>
								{roundedOwingAmount}
							</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
							<div style={{ lineHeight: 1 }}>Ps.</div>
							<div className={styles.amount} style={{ borderLeft: 'none' }}>
								{fractionalOwningAmount.toFixed(2).replace(/^0+/, '')}
							</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 24, marginLeft: 'auto', marginRight: 24 }}>
							<div>
							No. of V.P. Article <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _</span>
							</div>
							<div>
							(In Words)<span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _</span>
							</div>
						</div>
					</div>
					<div className={styles.divider} />
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 6, fontSize: 12 }}>
						<div style={{ direction: 'rtl' }}>
					ذیل کے تما م اندراج بھیجنے والے کو کرنے ہیں
						</div>
						<div style={{ display: 'flex' }}>
							<div style={{ direction: 'rtl', marginRight: 48 }}>
						لیٹر / <br />  پا رسل
							</div>
							<div style={{ textAlign: 'right', direction: 'rtl' }}>
					تصدیق کی جا تی ہے کہ منسلکہ قیمت طلب (V.P) <br />
کو با قاعدہ تحریری مطالبہ کے تحت جو مجھے مل چکا ہے بھیجا جا رہا ہے
							</div>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
                        رقم جو بھیجنے والے کو (ہند سوں میں) &nbsp;&nbsp;&nbsp;مبلغ روپیہ   <span className={styles.dotted}>{roundedOwingAmount}</span>   پیسہ  <span className={styles.dotted}>{fractionalOwningAmount.toFixed(2).replace(/^0+/, '')}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					ارسا ل ہو گی   (عبا رت میں)  &nbsp;&nbsp;&nbsp;  <span className={styles.dotted}>{convertNumberToBankNoteWords(owingAmount)}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					بھیجنے والے کا نا م <span className={styles.dotted}>{pickUpLocation.label || ''}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					اور مکمل پتہ <span className={styles.dotted} style={{ textAlign: 'right', direction: 'ltr' }}>{pickUpLocation.address} {pickUpLocation.phone ? `. Phone: ${pickUpLocation.phone}` : ''}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
پو سٹ کو ڈ <span className={styles.dotted}>{pickUpLocation.postCode || ''}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					مر سل الیہ کا نا م <span className={styles.dotted}>{distributor.name || ''}. &nbsp;Phone: {distributor.phone || ''}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					او پتہ <span className={styles.dotted}>{location.label || location.address}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					پو سٹ کو ڈ <span className={styles.dotted}>{location.postCode || ''}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					تا ریخ&nbsp;&nbsp;&nbsp; <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>  &nbsp;&nbsp;&nbsp;  بھیجنے وا لے کے دستخط &nbsp;&nbsp;&nbsp; <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
						</div>
					</div>
					<div className={styles.box}>
					Intimation
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 6, fontSize: 12 }}>
						<div style={{ display: 'flex', width: '100%' }}>
							<div style={{ marginRight: 'auto', display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'center' }}>
								<div style={{ textAlign: 'center' }}>
                                    Date stamp of <br /> the office of issue
								</div>
								<div className={styles.circle} />
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 6, fontSize: 12 }}>
								<div style={{ textAlign: 'right', direction: 'rtl' }}>
					مبلغ روپیہ   <span className={styles.dotted}>{roundedOwingAmount}</span>   پیسہ  <span className={styles.dotted}>{fractionalOwningAmount.toFixed(2).replace(/^0+/, '')}</span>
								</div>
								<div style={{ textAlign: 'right', direction: 'rtl' }}>
					پو سٹ کو ڈ <span className={styles.dotted}>{location.postCode || ''}</span>
								</div>
								<div style={{ textAlign: 'right', direction: 'rtl' }}>
					بر ائے  <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
								</div>
								<div style={{ textAlign: 'right', direction: 'rtl' }}>
									<span style={{ color: 'white' }}> _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </span>(دفتر اجرا ء کا نا م)
								</div>
							</div>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					قیمت طلب <span className={styles.dotted}>{getFormattedCurrency(owingAmount)}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					نمبر قطعہ <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					بنا م <span className={styles.dotted}>{distributor.name || ''}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
                        او پتہ <span className={styles.dotted}>{location.label || location.address}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					پو سٹ کو ڈ <span className={styles.dotted}>{location.postCode || ''}</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					یہا ں لیٹر پیکٹ یا پا رسل جو بھی صورت ہو تحریر کر یں۔
						</div>
					</div>
				</div>
				<div className={styles.pageNumber}>Page 1</div>
			</div>
			<div className={styles.page}>
				<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', fontSize: 18 }}>
						<div>
							Pakistan Post Office
						</div>
						<div>
							PAKISTAN MONEY ORDER
						</div>
						<div>
							Value Payable Letter قیمت طلب
						</div>
					</div>
					<div style={{ display: 'flex', marginTop: 12, alignSelf: 'center', borderBottom: '2px solid black' }}>
						<div style={{ textAlign: 'right', direction: 'rtl', padding: 12, flex: 1 }}>
                        یہا ں اس دفتر کا نا م درج کر یں جہا ں پر <br />
قیمت طلب قطعہ (VP) تقسیم ہو نا ہے
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl', padding: 12, borderLeft: '2px solid black' }}>
                         یہا ں منی آرڈرادا کرنے والے پوسٹ آفس<br />
                         کا نا م درج کریں
						</div>
					</div>
					<div style={{ marginTop: 12, alignSelf: 'center', display: 'flex' }}>
						<div style={{ marginTop: 'auto' }}>
							<span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
                        دی پی جر نل میں کو ائف منی آرڈر درج کرنے <br />
کی تاریخ
						</div>
					</div>
					<div style={{ marginTop: 12, alignSelf: 'center', display: 'flex' }}>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
						دستخط گو اہ <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
						</div>
						<div style={{ textAlign: 'right', direction: 'rtl', width: 230 }} />
					</div>
					<div style={{ marginTop: 48, alignSelf: 'center', display: 'flex' }}>
						<div style={{ flex: 1 }}>
							<div style={{ display: 'flex' }}>
								<div style={{ textAlign: 'right', direction: 'rtl' }}>
                                اگر رقم وصول کنند ہ (ا)نا خوانہ ہو (۲) ڈا ک خا نے<br />
                                کا عملہ اس کو نہ جا نتا ہو (۳) پردہ نشین<br />
                                عورت ہو (۴) مختص رقم ۰۰۰۱روپیہ<br />
                                یا اس سے زیا دہ ہو (۵) اگر ادائیگی<br />
                                کسی گاؤں میں کی جا رہی ہو
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', gap: 12, marginTop: -48, marginLeft: -48 }}>
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 150 }}>
									<div className={styles.circle} />
									<div style={{ marginTop: 6, textAlign: 'center' }}>
								Round M.O. Stamp Authorizing Payment
									</div>
								</div>
								<div className={styles.smallRectangle} />
								<div>Oblong M.O. Stamp on Payment</div>
							</div>
						</div>
						<div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 24 }}>
							<div style={{ textAlign: 'right', direction: 'rtl', marginRight: 48 }}>
                            مختصر دستخط رجسٹری یا پا رسل کلر ک<br />
دوسری طر ف لکھی ہو ئی رقم وصول پا ئی

							</div>
							<div style={{ textAlign: 'right', direction: 'rtl', width: 220, marginLeft: 48 }}>
						نشا ن انگو ٹھا <span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
							</div>
							<div style={{ textAlign: 'right', direction: 'rtl', marginLeft: 64, marginTop: 24 }}>
                             دستخط فر یسند ہ وی پی (روشنا ئی سے)<br />
                            میں نے ادا کیا
							</div>
							<div style={{ textAlign: 'right', direction: 'rtl', marginLeft: 64 }}>
								<div><span className={styles.dottedClear}>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span></div>
								<div style={{ textAlign: 'right', direction: 'rtl', marginTop: 12 }}>
								دستخط اہلکا ر بمعہ تا ریخ وعہدہ
								</div>
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', alignSelf: 'center', gap: 12, marginTop: 48 }}>
						_ _ _ _ _ _ _ _ _ _ _ _ _ _
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
						یہا ں سے تہہ کر یں
						</div>
						_ _ _ _ _ _ _ _ _ _ _ _ _ _
					</div>
					<div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
						<div style={{ textAlign: 'right', direction: 'rtl' }}>
					قیمت پچا س پیسے جو کہ منی آرڈر کمیشن سے منہا نہیں ہو گی
						</div>
					</div>
				</div>
				<div className={styles.pageNumber}>Page 2</div>
			</div>
		</>
	)
}

export default PostalOrderForm
