import styles from './SidebarLink.module.css'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Tooltip } from 'antd'
import useSearchParams from '../../hooks/useSearchParams'
import { paths } from '../../utils/constants'
import { useDispatch } from 'react-redux'
import { selectSalesOrderIds } from '../../store/sales-orders/actions'

const SidebarLink = ({
	link: {
		title,
		path,
		icon
	},
	expanded
}) => {
	const router = useRouter()
	const dispatch = useDispatch()
	const { clearSearchParams } = useSearchParams()

	const isActive = () => router.pathname === path.split('?')[0]

	return (
		<Link
			href={path}
		>
			<Tooltip
				trigger={expanded ? [] : ['hover']}
				overlayClassName='sidebar-link-tooltip'
				title={title}
				placement='right'
				zIndex={1103}
			>
				<a
					className={`${styles.link} ${isActive() && styles.linkActive} ${expanded && styles.linkExpanded}`}
					href={path}
					onClick={() => {
						if (path === paths.SALES_ORDERS) {
							dispatch(selectSalesOrderIds([], []))
						}
						clearSearchParams()
					}}
				>
					<div className={styles.iconContainer}>
						<svg className={styles.icon}>
							<use href={icon} />
						</svg>
					</div>
					<span className={styles.title}>{title}</span>
				</a>
			</Tooltip>
		</Link>
	)
}

export default SidebarLink
