import { Reports } from '../../services/api/reports'
import { FETCH_REPORTS_SUCCESS, CREATE_REPORTS_SUCCESS, FETCH_REPORT_DETAIL_SUCCESS, UPDATE_REPORTS_SUCCESS } from './action-types'

export function fetchReports (
	{ page, searchTerm, toDate, fromDate, type, types } = {}
) {
	return async (dispatch) => {
		const response = await Reports.index({ page, searchTerm, toDate, fromDate, type, types })
		dispatch({ type: FETCH_REPORTS_SUCCESS, payload: { data: response.data } })
	}
}

export function createReport (payload) {
	return async (dispatch) => {
		const response = await Reports.create(payload)
		dispatch({ type: CREATE_REPORTS_SUCCESS, payload: response.data })
		return response.data
	}
}

export function updateReport (id, payload) {
	return async (dispatch) => {
		const response = await Reports.update(id, payload)
		dispatch({ type: UPDATE_REPORTS_SUCCESS, payload: response.data })
		return response.data
	}
}

export function fetchReportDetail (id) {

	return async (dispatch) => {
		const response = await Reports.single(id)
		dispatch({ type: FETCH_REPORT_DETAIL_SUCCESS, payload: response.data })
	}
}
