import { Select as AntSelect, Checkbox, Spin, Tag, Tooltip } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
require('./Select.less')
import styles from './Select.module.css'
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'

const Select = ({
	style,
	containerStyle,
	className,
	containerClassName,
	dropdownClassName,
	title,
	tagsTitle,
	tooltip,
	placeholder,
	autoFocus,
	allowClear,
	clearIcon,
	showArrow = true,
	showSearch,
	loading,
	mode,
	optionRender,
	dropdownRender,
	disabled,
	error,
	getPopupContainer = () => document.body,
	notFoundContent,
	filterOption,
	searchTask,
	recentSearch,
	getOptions,
	options,
	selectedOptions,
	value,
	onChange = () => {},
	setSelectedOptions,
	onClear,
	onSearchError,
	onDeleteSearch,
	onClearSearch,
	forceRunSearchTask,
	onForceRunSearchTask
}) => {
	const [filteredOptions, setFilteredOptions] = useState([])
	const [selectedTags, setSelectedTags] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const selectRef = useRef(null)
	const fetchRef = useRef(0)
	const shouldShowRecentSearch = useMemo(() => {
		return !!(!searchTerm && recentSearch?.length)
	}, [searchTerm, recentSearch])
	const search = async (searchTerm) => {
		fetchRef.current += 1
		const fetchId = fetchRef.current
		setSearchTerm(searchTerm)
		setIsLoading(true)

		try {
			const response = await searchTask.task(searchTerm)

			if (fetchId !== fetchRef.current) {
				return
			}

			const { data } = response
			const results = Array.isArray(data) ? data : data.results

			if (results?.length) {
				const options = results.map((result) => searchTask.getOption(result))
				setFilteredOptions(options)
			} else {
				setFilteredOptions([])
			}
		} catch (error) {
			onSearchError?.()
		} finally {
			setIsLoading(false)
		}
	}
	const debouncedSearch = useMemo(() => debounce(search, 800))
	const activeOptions = shouldShowRecentSearch ? recentSearch : filteredOptions

	useEffect(() => {
		if (showSearch && searchTask && !options?.length) {
			search()
		}
		if (mode !== 'tags') {
			return
		}
		const updateDropdownState = ({ target }) => {
			if (!(target.closest('.ant-select') || target.closest('.ant-select-dropdown'))) {
				setIsDropdownOpen(false)
				blurSelect()
			}
		}
		window.addEventListener('click', updateDropdownState)
		return () => window.removeEventListener('click', updateDropdownState)
	}, [])

	useEffect(() => options && setFilteredOptions(options), [options])

	useEffect(() => {
		if (loading !== undefined) {
			setIsLoading(loading)
		}
	}, [loading])

	useEffect(() => {
		if (!forceRunSearchTask) {
			return
		}
		(async () => {
			await search()
			onForceRunSearchTask()
		})()
	}, [forceRunSearchTask])

	const handleChange = (value) => {
		if (mode === 'tags') {
			const isAlreadySelected = selectedOptions.includes(value)

			if (isAlreadySelected) {
				setSelectedOptions((prev) => prev.filter((option) => option.value !== value))
				setSelectedTags((prev) => prev.filter((option) => option.value !== value))
			} else {
				setSelectedOptions((prev) => [...prev, filteredOptions.find((option) => option.value === value)])
				setSelectedTags((prev) => [...prev, filteredOptions.find((option) => option.value === value)])
			}
		} else {
			onChange(value)
		}
		// Fix search() not executing after selecting options via search
		if (showSearch && searchTerm) {
			search()
		}
	}

	const handleMouseLeave = ({ target }) => {
		const dropdown = target.closest('.select-dropdown')
		if (dropdown) {
			setIsDropdownOpen(false)
			blurSelect()
		}
	}

	const blurSelect = () => selectRef.current?.blur()

	const onDropdownVisibleChange = (isOpen) => {
		if (!isOpen) {
			blurSelect()
		}
	}

	const renderDropdown = (originNode) => {
		return dropdownRender({
			originNode,
			searchTerm,
			loading: isLoading,
			recentSearch,
			onDeleteSearch,
			onClearSearch,
			options: filteredOptions
		})
	}

	return (
		<>
			<div
				style={containerStyle}
				className={`${styles.select} ${containerClassName} select ${title && 'select-with-title'}`}
			>
				{
					title &&
					<span className={`${styles.title} ${error && styles.titleError}`}>
						{title}
						{
							tooltip &&
							<Tooltip title={tooltip} placement='right'>
								<img
									className={styles.questionMarkIcon}
									src='/img/question-mark-circled.svg'
									alt='Question mark icon'
									width={16}
									height={16}
								/>
							</Tooltip>
						}
					</span>
				}
				{
					isLoading ?
						<div className={styles.icon}>
							<Spin
								indicator={
									<LoadingOutlined
										style={{ fontSize: 20 }}
										spin
									/>
								}
							/>
						</div> :
						showSearch ?
							<img
								className={styles.icon}
								src='/img/search-black.svg'
								alt='Search icon'
								width={24}
								height={24}
							/> :
							showArrow ?
								<img className={`${styles.icon} ${styles.chevronDown}`} src='/img/chevron-down.svg' /> :
								null
				}
				<AntSelect
					style={style}
					className={`${className} ${error && 'select-error'}`}
					dropdownClassName={`select-dropdown ${dropdownClassName}`}
					placeholder={placeholder}
					showArrow={false}
					autoFocus={autoFocus}
					showSearch={showSearch}
					allowClear={allowClear}
					disabled={disabled}
					clearIcon={clearIcon}
					getPopupContainer={getPopupContainer}
					dropdownRender={dropdownRender ? renderDropdown : null}
					notFoundContent={notFoundContent}
					filterOption={filterOption || !searchTask}
					ref={selectRef}
					open={mode === 'tags' ? isDropdownOpen : undefined}
					options={optionRender ? undefined : filteredOptions}
					value={value}
					onChange={handleChange}
					onSearch={searchTask ? debouncedSearch : undefined}
					onClear={onClear}
					onDropdownVisibleChange={onDropdownVisibleChange}
					onFocus={mode === 'tags' ? () => setIsDropdownOpen(true) : undefined}
					onMouseLeave={mode === 'tags' ? handleMouseLeave : undefined}
				>
					{
						optionRender &&
						(getOptions?.(activeOptions) || activeOptions).map(option => {
							const { value, label, disabled } = option
							const isSelected = selectedOptions?.includes(value)

							return (
								<AntSelect.Option
									key={value}
									value={value}
									label={label}
									disabled={disabled}
								>
									{
										optionRender({
											isSelected,
											showDeleteButton: shouldShowRecentSearch,
											onDeleteSearch,
											...option
										})
									}
								</AntSelect.Option>
							)
						})
					}
				</AntSelect>
			</div>
			{
				mode === 'tags' &&
				<div style={{ width: '100%' }}>
					<Checkbox
						style={{ marginTop: '12px' }}
						checked={selectedOptions.length && selectedOptions.length === selectedTags.length && !searchTerm}
						onChange={({ target: { checked } }) => {
							if (checked) {
								setSelectedOptions(filteredOptions)
								setSelectedTags(filteredOptions)
							} else {
								setSelectedOptions([])
								setSelectedTags([])
							}
						}}
					>
						Select All
					</Checkbox>
					{
						tagsTitle &&
						<h5 className={styles.tagsTitle}>{tagsTitle}</h5>
					}
					{
						selectedOptions.length ?
							<div style={{ marginTop: tagsTitle ? 0 : '12px' }} className={styles.tags}>
								{
									selectedOptions.map(value => {
										const { label } = selectedTags.find((option) => option.value === value)

										return (
											<Tag
												key={value}
												style={{ margin: 0 }}
												className='select-tag'
												closable={true}
												closeIcon={<CloseOutlined style={{ color: '#288ea5' }} />}
												onClose={() => setSelectedOptions((prev) => prev.filter((option) => option.value !== value))}
											>
												{label}
											</Tag>
										)
									})
								}
							</div> :
							null
					}
				</div>
			}
		</>
	)
}

export default Select
