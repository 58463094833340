import { useContext } from 'react'
import PropTypes from 'prop-types'
import { GlobalFilterSetter } from '../../contexts/GlobalFilter'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './CustomEmpty.module.css'
import { Button } from 'antd'

const CustomEmpty = ({
	fallback,
	showFallbackAction,
	style,
	icon
}) => {
	const setShouldResetFilters = useContext(GlobalFilterSetter)
	const { clearSearchParams } = useSearchParams()

	const clearSearchAndFilter = () => {
		setShouldResetFilters(true)
		clearSearchParams()
	}

	return (
		<div className={styles.customEmpty} style={style}>
			<span className={styles.iconContainer}>
				<img src={icon} alt='No file icon' />
			</span>
			<p className={styles.title}>{fallback?.title || 'No Data Found!'}</p>
			<p className={styles.description}>{fallback?.description || 'No data to display currently.'}</p>
			{
				showFallbackAction &&
					<Button className={styles.button} onClick={fallback?.action?.onClick || clearSearchAndFilter}>
						{fallback?.action?.text || 'Reset Search'}
					</Button>
			}
		</div>
	)
}

CustomEmpty.propTypes = {
	icon: PropTypes.string
}

CustomEmpty.defaultProps = {
	icon: '/img/file-error-black.svg'
}

export default CustomEmpty
