import { Expenses } from '../../services/api/expenses'
import { FETCH_EXPENSES_SUCCESS, FETCH_EXPENSE_COUNTS_SUCCESS, FETCH_EXPENSE_DETAILS_SUCCESS, SWAP_EXPENSE } from './action-types'

export function fetchExpenses ({
	page,
	status,
	expenseTypeId,
	assigneeId,
	creationFromDate,
	creationToDate,
	searchTerm
} = {}) {
	return async (dispatch) => {
		const response = await Expenses.index({
			page,
			status,
			expenseTypeId,
			assigneeId,
			creationFromDate,
			creationToDate,
			searchTerm
		})
		dispatch({ type: FETCH_EXPENSES_SUCCESS, payload: { data: response.data, status } })
	}
}

export function fetchExpenseDetails (id) {
	return async (dispatch) => {
		const response = await Expenses.single(id)
		dispatch({ type: FETCH_EXPENSE_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function swapExpense (sourceIndex, destinationIndex, source, destination) {
	return (dispatch) => {
		dispatch({ type: SWAP_EXPENSE, payload: { sourceIndex, destinationIndex, source, destination } })
	}
}

export function fetchExpenseCounts () {
	return async (dispatch) => {
		const response = await Expenses.counts()
		dispatch({ type: FETCH_EXPENSE_COUNTS_SUCCESS, payload: { data: response.data } })
	}
}
