import styles from './DeliveryPlanPreferencesModal.module.css'
require('./DeliveryPlanPreferencesModal.less')
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fetchVehicleTypes } from '../../store/vehicles/actions'
import { Button, Checkbox, notification, Collapse, Radio, Modal } from 'antd'
import { updateDeliveryPlanSettings } from '../../store/companies/actions'
import { updateUser } from '../../store/users/actions'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { fetchUserProfile } from '../../store/auth/actions'
import { deliveryPlanScalars } from '../../utils/delivery-plans'

const { Panel } = Collapse

const KEY_TRAILER = 'TRAILER'
const KEY_COVERED = 'COVERED'
const KEY_OPEN = 'OPEN'

const deliveryPlanModes = {
	DEFAULT: 'DEFAULT',
	DIVISION_WISE: 'DIVISION_WISE'
}

const DeliveryPlanPreferencesModal = ({
	visible,
	onCancel
}) => {
	const dispatch = useDispatch()
	const { companyDetails, userProfile } = useSelector(state => state.authReducer)
	const { vehicleTypes } = useSelector(state => state.vehiclesReducer)
	const [preferredVehicles, setPreferredVehicles] = useState([])
	const [groupedVehicleTypes, setGroupedVehicleTypes] = useState({
		TRAILER: [],
		COVERED: [],
		OPEN: []
	})
	const [deliveryPlanScalar, setDeliveryPlanScalar] = useState(deliveryPlanScalars.WEIGHT)
	const [deliveryPlanMode, setDeliveryPlanMode] = useState(deliveryPlanModes.DEFAULT)
	const [isSaving, setIsSaving] = useState(false)

	useEffect(() => {
		if (companyDetails) {
			setDeliveryPlanScalar(companyDetails?.deliveryPlanScalar || deliveryPlanScalars.WEIGHT)
			setDeliveryPlanMode(companyDetails?.deliveryPlanMode || deliveryPlanModes.DEFAULT)
		}
	}, [companyDetails])

	useEffect(() => {
		if (userProfile) {
			setPreferredVehicles(userProfile.vehicleTypes || [])
		}
	}, [userProfile])

	useEffect(() => {
		dispatch(fetchVehicleTypes())
	}, [])

	useEffect(() => {
		setGroupedVehicleTypes(getGroupedVehicleTypes())
	}, [vehicleTypes])

	const getGroupedVehicleTypes = () => {
		const groupedVehicleTypes = {
			TRAILER: [],
			COVERED: [],
			OPEN: []
		}
		vehicleTypes.forEach(type => {
			if (type.value.includes(KEY_TRAILER)) {
				groupedVehicleTypes[KEY_TRAILER].push(type)
			}
			if (type.value.includes(KEY_COVERED)) {
				groupedVehicleTypes[KEY_COVERED].push(type)
			}
			if (type.value.includes(KEY_OPEN)) {
				groupedVehicleTypes[KEY_OPEN].push(type)
			}
		})
		return groupedVehicleTypes
	}

	const isVehicleTypeSelected = (vehicleType) => {
		return preferredVehicles.includes(vehicleType)
	}

	const onVehicleTypeClick = (vehicleType) => {
		let vehicleTypes = [...preferredVehicles]
		if (vehicleTypes.includes(vehicleType.value)) {
			vehicleTypes = vehicleTypes.filter(vt => vt !== vehicleType.value)
		} else {
			vehicleTypes.push(vehicleType.value)
		}
		setPreferredVehicles(vehicleTypes)
	}

	const isModified = () => {
		const previousPreferredVehicles = userProfile?.vehicleTypes
		if (!previousPreferredVehicles) {
			return false
		}
		const vehicleTypesMap = {}
		previousPreferredVehicles.forEach(vehicleType => {
			vehicleTypesMap[vehicleType] = true
		})
		if (deliveryPlanScalar !== companyDetails?.deliveryPlanScalar) {
			return true
		}
		if (deliveryPlanMode !== companyDetails?.deliveryPlanMode) {
			return true
		}
		const areAllExist = preferredVehicles.every(preferredVehicleType => vehicleTypesMap[preferredVehicleType])
		return !(areAllExist && previousPreferredVehicles.length === preferredVehicles.length)
	}

	const onSave = async () => {
		try {
			setIsSaving(true)
			await Promise.all([
				dispatch(updateDeliveryPlanSettings(
					companyDetails.companyId,
					{
						deliveryPlanMode,
						deliveryPlanScalar,
						preferredVehicleTypes: preferredVehicles
					}
				)),
				dispatch(updateUser(
					userProfile.id,
					{
						vehicleTypes: preferredVehicles
					}
				))
			])
			dispatch(fetchUserProfile())
			notification.success({
				message: 'Saved',
				description: 'Delivery plan preferences has been saved successfully!',
				placement: 'bottomLeft'
			})
			onCancel()
		} catch (e) {
			setPreferredVehicles(companyDetails.preferredVehicles)
			notification.error({
				message: 'Unable to Save Delivery Plan Preferences',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSaving(false)
		}
	}

	const onReset = () => {
		setPreferredVehicles([])
		setDeliveryPlanScalar(deliveryPlanScalars.WEIGHT)
		setDeliveryPlanMode(deliveryPlanModes.DEFAULT)
	}

	const onRestore = () => {
		setPreferredVehicles(userProfile?.vehicleTypes || [])
		setDeliveryPlanScalar(companyDetails?.deliveryPlanScalar || deliveryPlanScalars.WEIGHT)
		setDeliveryPlanMode(companyDetails?.deliveryPlanMode || deliveryPlanModes.DEFAULT)
	}

	const renderVehicleTypes = (key) => {
		return groupedVehicleTypes[key].map(vehicleType => {
			return (
				<div
					onClick={(e) => {
						e.preventDefault()
						onVehicleTypeClick(vehicleType)
					}}
					className={isVehicleTypeSelected(vehicleType.value) ? 'vehicle-type-selected' : 'vehicle-type'}
					key={vehicleType.value}
				>
					<Checkbox
						className='vehicle-type-checkbox'
						checked={isVehicleTypeSelected(vehicleType.value)}
					>
						{vehicleType.label} - {vehicleType.maxWeight} Tonne {vehicleType.cbm ? `- ${Math.round(vehicleType.cbm)} CBM` : ''}
					</Checkbox>
				</div>
			)
		})
	}

	const renderFooter = () => {
		return (
			<div className={styles.footer}>
				{
					isModified() && preferredVehicles.length > 0 ?
						<Button
							disabled={false}
							style={{ color: '#FF5959', borderColor: '#FF5959' }}
							onClick={onReset}
						>
							Reset All
						</Button> :
						null
				}
				{
					isModified() &&
					<Button
						disabled={false}
						onClick={onRestore}
					>
						Restore Previous Version
					</Button>
				}
				<Button
					type='primary'
					disabled={!isModified()}
					onClick={onSave}
					loading={isSaving}
				>
					Save {isModified() ? preferredVehicles.length > 0 ? `(${preferredVehicles.length})` : '' : ''}
				</Button>
			</div>
		)
	}

	return (
		<Modal
			title='Preferences'
			visible={visible}
			onCancel={onCancel}
			width={1000}
			footer={renderFooter()}
		>
			<div className={styles.container}>
				<Collapse
					ghost
					expandIcon={({ isActive }) => isActive ? <DownOutlined /> : <RightOutlined />}
					style={{ fontSize: 18 }}
				>
					<Panel
						header='Fleet Preference'
						key='1'
					>
						<div className={styles.vehicleTypesContainer}>
							<h3 className={styles.title}>
						Trailer
							</h3>
							<div className={styles.vehicleTypes}>
								{
									renderVehicleTypes(KEY_TRAILER)
								}
							</div>
						</div>
						<div className={styles.vehicleTypesContainer}>
							<h3 className={styles.title}>
						Covered Van
							</h3>
							<div className={styles.vehicleTypes}>
								{
									renderVehicleTypes(KEY_COVERED)
								}
							</div>
						</div>
						<div className={styles.vehicleTypesContainer}>
							<h3 className={styles.title}>
						Open Truck
							</h3>
							<div className={styles.vehicleTypes}>
								{
									renderVehicleTypes(KEY_OPEN)
								}
							</div>
						</div>
					</Panel>
					<Panel
						header='Vehicle Utilization Preference'
						key='2'
					>
						<div style={{ marginBottom: 24, fontSize: 12 }}>
							Determine how delivery plans are generated. If weight is selected, vehicles are recommended based on the total weight of the orders associated with a delivery plan. If volume is selected, vehicles are recommended based on the total volume of the orders associated with a delivery plan.
						</div>
						<Radio.Group
							onChange={e => setDeliveryPlanScalar(e.target.value)}
							value={deliveryPlanScalar} buttonStyle='solid'
						>
							<Radio.Button style={{ borderRadius: 0 }} value={deliveryPlanScalars.WEIGHT}>Weight</Radio.Button>
							<Radio.Button style={{ borderRadius: 0 }} value={deliveryPlanScalars.VOLUME}>Volume</Radio.Button>
						</Radio.Group>
					</Panel>
					<Panel
						header='Location Preference'
						key='3'
					>
						<div style={{ marginBottom: 24, fontSize: 12 }}>
							Determine how delivery plans are generated. If nation-wide is selected, routes will not be segregated by divisions. If division-wide is selected, orders will be grouped by divisions before routes are generated for a delivery plan.
						</div>
						<Radio.Group
							onChange={e => setDeliveryPlanMode(e.target.value)}
							value={deliveryPlanMode} buttonStyle='solid'
						>
							<Radio.Button style={{ borderRadius: 0 }} value={deliveryPlanModes.DEFAULT}>Nation-wide</Radio.Button>
							<Radio.Button style={{ borderRadius: 0 }} value={deliveryPlanModes.DIVISION_WISE}>Division-wide</Radio.Button>
						</Radio.Group>
					</Panel>
				</Collapse>
			</div>
		</Modal>
	)
}

DeliveryPlanPreferencesModal.propTypes = {
	visible: PropTypes.bool,
	onCancel: PropTypes.func
}

export default DeliveryPlanPreferencesModal
