import { Popover, Tooltip } from 'antd'
import React, { useState } from 'react'
import { FileTextOutlined, PlusOutlined } from '@ant-design/icons'
import InternalNotesInput from './internal-notes-input'

const SalesOrderInternalNotesPopover = ({ id, notes, onSave, style }) => {
	const [isPopupVisible, setIsPopupVisible] = useState(false)

	const renderNotes = (notes) => {
		return (
			<div>
				<h3>Internal Notes</h3>
				<ol>
					{
						notes.map((element, index) => {
							return (
								<li key={index}>{element}</li>
							)
						})
					}
				</ol>
			</div>
		)
	}

	return (
		<>
			{
				notes && notes.length > 0 &&
				<Popover
					style={style}
					placement='topRight'
					content={renderNotes(notes)}
					trigger='click'
					overlayStyle={{ width: 450 }}
				>
					<Tooltip title={`${notes.slice(-1)[0]}`}>
						<FileTextOutlined
							style={{ fontSize: 20, color: '#288EA5', marginRight: 10 }}
						/>
					</Tooltip>
				</Popover>
			}
			<Popover
				style={style}
				placement='topRight'
				content={
					<InternalNotesInput
						id={id}
						addNote={true}
						notes=''
						onSave={async (id, internalNotes) => {
							await onSave(id, internalNotes)
							setIsPopupVisible(false)
						}}
					/>
				}
				trigger='click'
				overlayStyle={{ width: 450 }}
				visible={isPopupVisible}
				onVisibleChange={setIsPopupVisible}
			>
				<Tooltip title='Add a new note'>
					<PlusOutlined
						style={{ fontSize: 20 }}
					/>
				</Tooltip>
			</Popover>
		</>
	)
}

SalesOrderInternalNotesPopover.defaultProps = {
	id: '',
	notes: [],
	style: {},
	onSave: () => {}
}

export default SalesOrderInternalNotesPopover
