import { InputNumber, Spin, Tooltip } from 'antd'
import styles from './NumberInput.module.css'
require('./NumberInput.less')
import { LoadingOutlined } from '@ant-design/icons'

const NumberInput = ({
	containerClassName,
	className,
	size = 'normal',
	title,
	tooltip,
	placeholder,
	min,
	max,
	maxLength,
	step,
	loading,
	autoFocus,
	highlightOnSelect,
	formatter,
	readOnly,
	disabled,
	error,
	defaultValue,
	value,
	onChange
}) => {
	return (
		<div className={`${styles.numberInput} number-input ${containerClassName}`}>
			{
				title &&
				<span
					className={
					`
						${styles.title}
						${(readOnly || disabled) && styles.titleReadonly}
						${error && styles.titleError}
					`
					}
				>
					{title}
					{
						tooltip &&
						<Tooltip title={tooltip} placement='right'>
							<img
								className={styles.questionMarkIcon}
								src='/img/question-mark-circled.svg'
								alt='Question mark icon'
								width={16}
								height={16}
							/>
						</Tooltip>
					}
				</span>
			}
			{
				loading &&
					<div className={styles.loadingIcon}>
						<Spin
							indicator={
								<LoadingOutlined
									style={{ fontSize: 20 }}
									spin
								/>
							}
						/>
					</div>
			}
			<InputNumber
				className={
				`
					${size === 'small' ? 'input-small' : size === 'large' ? 'input-large' : ''}
					${!title && 'input-without-title'}
					${(readOnly || disabled) && 'input-readonly'}
					${error && 'input-error'}
					${className}
				`
				}
				placeholder={placeholder}
				autoFocus={autoFocus}
				onFocus={e => highlightOnSelect && e.target.select()}
				formatter={formatter || null}
				min={min}
				max={max}
				maxLength={maxLength}
				step={step}
				readOnly={readOnly}
				disabled={disabled}
				defaultValue={defaultValue}
				value={value}
				onChange={onChange}
			/>
		</div>
	)
}

export default NumberInput
