import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { SALES_ORDER_APPROVED_STATUS } from '../../pages/sales-orders'
import { convertEnumToString, getFormattedCurrency } from '../../utils'
import Table from '../table'
import moment from 'moment'
import Link from 'next/link'
import styles from './AddRoutePointsModal.module.css'
import { fetchSalesOrders } from '../../store/sales-orders/actions'
import { fetchDeliveryPlanDetails } from '../../store/delivery-plans/actions'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import { deliveryPlanStatuses, paths } from '../../utils/constants'
import { LocationTypes } from '../location-modal'
import { Locations } from '../../services/api/locations'
import TextInput from '../text-input'
import DatePicker from '../date-picker'
import Select from '../select'

const salesOrderColumns = [
	{
		title: 'Shipping Date',
		key: 'deliveryDate',
		render: salesOrder => {
			return moment(salesOrder.deliveryDate).format('MMM D, YYYY')
		}
	},
	{
		title: 'Order #',
		key: 'salesOrderID',
		render: salesOrder => {
			return (
				<Link href={`${paths.SALES_ORDERS}/${salesOrder.id}`}>
					<a className={styles.link}>{salesOrder.internalId}</a>
				</Link>
			)
		}
	},
	{
		title: 'Assigned To',
		key: 'assignedDeliveryPlanID',
		render: salesOrder => {
			return (
				salesOrder.deliveryPlan ?
					<Link href={`/delivery-plans/${salesOrder.deliveryPlan.id}`}>
						<a className={styles.link}>
							{`DP-${salesOrder.deliveryPlan.shortId}`}
						</a>
					</Link> :
					<div className={styles.unassigned}>
						Unassigned
					</div>
			)
		}
	},
	{
		title: 'Area Manager',
		key: 'areaManager',
		render: salesOrder => {
			if (salesOrder.distributor && salesOrder.distributor.accountManager) {
				return `${salesOrder.distributor.accountManager.name}`
			} else {
				return (
					<div className={styles.unassigned}>
						Unassigned
					</div>
				)
			}
		}
	},
	{
		title: 'Pick Up',
		key: 'from',
		render: salesOrder => {
			const {
				pickUpLocation
			} = salesOrder
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						pickUpLocation &&
						<div>
							<div>
								<div className={styles.badge}>
									{convertEnumToString(pickUpLocation.type.toLowerCase())}
								</div>
							</div>
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE].includes(pickUpLocation.type) && !pickUpLocation.deletedAt ?
									<Link href={`${paths.WAREHOUSES}/${pickUpLocation.id}`}>
										<a>
											{pickUpLocation.label}
											<div className={styles.location}>
												{pickUpLocation.address}
											</div>
										</a>
									</Link> :
									<div>
										{pickUpLocation.label}
										<div className={styles.location}>
											{pickUpLocation.address}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Drop Off',
		key: 'to',
		render: salesOrder => {
			const {
				distributor,
				location
			} = salesOrder
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						distributor ?
							<div style={{ marginBottom: 8 }}>
								<div className={styles.badge}>
									Distributor
								</div>
								<Link href={`${paths.CUSTOMERS}/${distributor.id}`}>
									<a style={{ color: '#278EA5' }}>
										{distributor.name}
									</a>
								</Link>
							</div> : null
					}
					{
						location &&
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{
								!distributor &&
								<div className={styles.badge}>
									{convertEnumToString(location.type.toLowerCase())}
								</div>
							}
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE].includes(location.type) && !location.deletedAt ?
									<Link href={`${paths.WAREHOUSES}/${location.id}`}>
										<a>
											{location.label}
											<div className={styles.location}>
												{location.address}
											</div>
										</a>
									</Link> :
									<div>
										{location.label}
										<div className={styles.location}>
											{location.address}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Sales Amount',
		key: 'salesAmount',
		render: salesOrder => {
			return `${getFormattedCurrency(salesOrder.totalAmount)}`
		}
	},
	{
		title: 'Advance Amount',
		key: 'advanceAmount',
		render: salesOrder => {
			return `${getFormattedCurrency(salesOrder.distributorAdvancePayment)}`
		}
	},
	{
		title: 'Payment Method',
		key: 'paymentMethod',
		render: salesOrder => {
			return `${convertEnumToString(salesOrder.paymentMethod)}`
		}
	}
]

const arrayToObject = (arr) => {
	return arr.reduce((acc, currentValue) => ({ ...acc, [currentValue]: currentValue }), {})
}

const AddRoutePointsModal = ({ onCancel, visible, deliveryPlanId, onAddClicked }) => {
	const dispatch = useDispatch()
	const {
		totalCount,
		page,
		approvedSalesOrders
	} = useSelector(state => state.salesOrdersReducer)
	const { userProfile } = useSelector(state => state.authReducer)
	const [isFetching, setIsFetching] = useState(false)
	const [isFetchingDeliveryPlanDetail, setIsFetchingDeliveryPlanDetail] = useState(false)
	const [selectedSalesOrderIds, setSelectedSalesOrderIds] = useState([])
	const [selectedSalesOrders, setSelectedSalesOrders] = useState([])
	const [previousSelectedSalesOrders, setPreviousSelectedSalesOrders] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const debouncedSearchTerm = useDebounceValue(searchTerm, 500)
	const { deliveryPlanDetails } = useSelector(state => state.deliveryPlansReducer)
	const deliveryPlanDetail = deliveryPlanDetails[deliveryPlanId]
	const [showUnassigned, setShowUnassigned] = useState(false)
	const [showAssigned, setShowAssigned] = useState(false)
	const [showStockTransferOrders, setShowStockTransferOrders] = useState(false)
	const [showSalesOrders, setShowSalesOrders] = useState(false)
	const [showAssignedToDeliveryPlan, setShowAssignedToDeliveryPlan] = useState(false)
	const [isLoadingPickupLocations, setIsLoadingPickupLocations] = useState(false)
	const [pickUpLocation, setPickUpLocation] = useState(null)
	const [pickUpLocationOptions, setPickUpLocationOptions] = useState([])
	const [pageSize, setPageSize] = useState(50)
	const [filteredTableColumns, setFilteredTableColumns] = useState([])
	const [filterColumnsMap, setFilterColumnsMap] = useState({})
	const [isFilterControlVisible, setIsFilterControlVisible] = useState(false)

	useEffect(() => {
		setFilterColumnsMap(getFilterColumns())
	}, [])

	useEffect(() => {
		if (userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setPickUpLocationOptions(locations)
				if (locations.length === 1) {
					const location = locations[0]
					const { label, id } = location
					setPickUpLocation({ label, value: id, data: location })
				}
			} else {
				fetchInitialPickUpLocations()
			}
		}
	}, [userProfile])

	useEffect(() => {
		getSalesOrders()
	}, [
		debouncedSearchTerm,
		toDate,
		fromDate,
		showAssigned,
		showUnassigned,
		showSalesOrders,
		showStockTransferOrders,
		showAssignedToDeliveryPlan,
		pickUpLocation,
		pageSize
	])

	useEffect(() => {
		if (deliveryPlanId) {
			getDeliveryPlanDetails(deliveryPlanId)
		}
	}, [deliveryPlanId])

	useEffect(() => {
		if (deliveryPlanDetail) {
			const salesOrderIds = deliveryPlanDetail.salesOrders.map(so => so.id)
			setSelectedSalesOrderIds(salesOrderIds)
			setPreviousSelectedSalesOrders(arrayToObject(salesOrderIds))
		}
	}, [deliveryPlanDetail])

	useEffect(() => {
		setFilteredTableColumns(salesOrderColumns.filter(({ key }) => filterColumnsMap?.[key]))
	}, [filterColumnsMap])

	const getSalesOrders = async () => {
		setIsFetching(true)
		await dispatch(fetchSalesOrders(getSearchParams()))
		setIsFetching(false)
	}

	const getDeliveryPlanDetails = async (deliveryPlanId) => {
		if (deliveryPlanId) {
			setIsFetchingDeliveryPlanDetail(true)
			await dispatch(fetchDeliveryPlanDetails(deliveryPlanId))
			setIsFetchingDeliveryPlanDetail(false)
		}
	}

	const getSearchParams = (page = 0) => {
		const params = {
			status: SALES_ORDER_APPROVED_STATUS,
			page: page,
			deliveryPlanStatus: deliveryPlanStatuses.DELIVERY_PLAN_PENDING_STATUS
		}
		if (debouncedSearchTerm) {
			params.searchTerm = debouncedSearchTerm
		}
		if (fromDate && toDate) {
			params.searchTerm = debouncedSearchTerm
			params.deliveryFromDate = moment(fromDate).startOf('day').toDate()
			params.deliveryToDate = moment(toDate).endOf('day').toDate()
		}
		if (showAssigned) {
			params.unassigned = false
		}
		if (showUnassigned) {
			params.unassigned = true
		}
		if (showAssigned && showUnassigned) {
			delete params.unassigned
		}
		if (showSalesOrders) {
			params.isStockTransfer = false
		}
		if (showStockTransferOrders) {
			params.isStockTransfer = true
		}
		if (showSalesOrders && showStockTransferOrders) {
			delete params.isStockTransfer
		}
		if (showAssignedToDeliveryPlan && deliveryPlanDetail) {
			params.deliveryPlanId = deliveryPlanDetail.id
		}
		if (pageSize) {
			params.pageSize = pageSize
		}
		if (pickUpLocation) {
			params.pickUpLocationIds = [pickUpLocation]
		} else {
			delete params.pickUpLocationIds
		}
		return params
	}

	const fetchInitialPickUpLocations = async () => {
		setIsLoadingPickupLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY], page: -1 })
		setPickUpLocationOptions(response.data.results)
		setIsLoadingPickupLocations(false)
	}

	const isModified = () => {
		return JSON.stringify(arrayToObject(selectedSalesOrderIds)) !== JSON.stringify(previousSelectedSalesOrders)
	}

	const onPageChanged = async (page) => {
		setIsFetching(true)
		await dispatch(fetchSalesOrders(getSearchParams(page - 1)))
		setIsFetching(false)
	}

	const onSelectChange = (selectedRowKeys, selectedRows) => {
		setSelectedSalesOrderIds(selectedRowKeys)
		setSelectedSalesOrders(selectedRows)
	}

	const onAdd = () => {
		const addedSalesOrders = selectedSalesOrders.filter(so => so && !previousSelectedSalesOrders[so.id])
		onAddClicked(addedSalesOrders)
		onCancel()
	}

	const isLoading = () => {
		return isFetching || isFetchingDeliveryPlanDetail
	}

	const getFilterColumns = (isChecked = true) =>
		salesOrderColumns.reduce((acc, { key }) => ({ ...acc, [key]: isChecked }), {})

	const isAllColumnChecked = () => Object.values(filterColumnsMap).every((value) => value === true)

	return (
		<Modal
			style={{ maxWidth: 1196 }}
			width='calc(100vw - 122px)'
			destroyOnClose={true}
			visible={visible}
			modalRender={() => {
				return (
					<div className={`${styles.modal} ant-modal-content`}>
						<div className={styles.titleContainer}>
							<p className={styles.modalTitle}>
								Delivery Plan: DP-{deliveryPlanDetail?.shortId}
							</p>
							<svg className={styles.closeIcon} onClick={onCancel}>
								<use href='/img/close-2.svg#icon' />
							</svg>
						</div>
						<div className={styles.inputGrid}>
							<TextInput
								containerClassName={styles.searchInput}
								placeholder='Search Order Number, Customer Name'
								showSearch
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
							<DatePicker
								title='From (Shipping Date)'
								placeholder='Select Date'
								allowClear={true}
								disabledDate={current => current && current.valueOf() > moment(toDate).valueOf()}
								value={fromDate ? moment(fromDate) : null}
								onChange={(date) => setFromDate(date?.toDate())}
							/>
							<DatePicker
								title='To (Shipping Date)'
								placeholder='Select Date'
								allowClear={true}
								disabledDate={current => current && current.valueOf() < moment(fromDate).valueOf()}
								value={toDate ? moment(toDate) : null }
								onChange={(date) => setToDate(date?.toDate())}
							/>
							<Select
								containerClassName={styles.pickUpLocationSelect}
								title='Pick Up Location'
								placeholder='Select'
								allowClear={true}
								isLoading={isLoadingPickupLocations}
								options={pickUpLocationOptions.map(({ id, internalId, label, type, address }) => {
									return {
										key: id,
										value: id,
										label: internalId || label || convertEnumToString(type.toLowerCase()),
										address
									}
								})}
								optionLabelProp='label'
								optionRender={({ label, address }) => {
									return (
										<div>
											<div>{label}</div>
											<div>{address}</div>
										</div>
									)
								}}
								value={pickUpLocation ? pickUpLocation.value : null}
								onChange={setPickUpLocation}
							/>
						</div>
						<div className={styles.checkboxes}>
							<Checkbox onChange={(e) => setShowSalesOrders(e.target.checked)}>
								Sales Orders
							</Checkbox>
							<Checkbox onChange={(e) => setShowStockTransferOrders(e.target.checked)}>
								Stock Transfer Orders
							</Checkbox>
							<Checkbox onChange={(e) => setShowAssigned(e.target.checked)}>
								Show assigned orders
							</Checkbox>
							<Checkbox onChange={(e) => setShowUnassigned(e.target.checked)}>
								Show unassigned orders
							</Checkbox>
							<Checkbox onChange={(e) => setShowAssignedToDeliveryPlan(e.target.checked)}>
								Show orders assigned to this delivery plan
							</Checkbox>
						</div>
						{
							selectedSalesOrderIds.length > 0 &&
							<p className={styles.rowSelectionText}>
								You have selected {selectedSalesOrderIds.length} item{selectedSalesOrderIds.length > 1 && 's'}.&nbsp;
								<span
									onClick={() => setSelectedSalesOrderIds(
										selectedSalesOrderIds.filter((salesOrderId) => previousSelectedSalesOrders[salesOrderId])
									)}
								>
									Clear Selection
								</span>
							</p>
						}
						<Table
							loading={isLoading()}
							columns={filteredTableColumns}
							dataSource={approvedSalesOrders}
							pagination={{
								pageSize,
								total: totalCount,
								position: ['topRight'],
								onChange: onPageChanged,
								showSizeChanger: false,
								current: page + 1
							}}
							scroll={{ y: 700 }}
							rowKey='id'
							rowSelection={{
								preserveSelectedRowKeys: true,
								selectedRowKeys: selectedSalesOrderIds,
								onChange: onSelectChange,
								getCheckboxProps: (record) => ({
									disabled: !!previousSelectedSalesOrders[record.id]
								})
							}}
							refresh={() => onPageChanged(page + 1)}
							onPageSizeChange={setPageSize}
							filter
							filterTrigger='click'
							filterPlacement='bottomLeft'
							filterVisible={isFilterControlVisible}
							onFilterVisibleChange={setIsFilterControlVisible}
							filterContent={
								<div className={styles.columnCheckboxes}>
									<Checkbox
										checked={isAllColumnChecked()}
										value='isAllChecked'
										onChange={(e) => {
											if (e.target.checked) {
												setFilterColumnsMap(getFilterColumns(true))
											} else {
												setFilterColumnsMap(getFilterColumns(false))
											}
										}}
									>
												Select All
									</Checkbox>
									{
										salesOrderColumns.map(({ key, title }) => {
											return (
												<div key={key}>
													<Checkbox
														checked={filterColumnsMap[key]}
														value={key}
														onChange={(e) => setFilterColumnsMap({
															...filterColumnsMap, [key]: e.target.checked
														})}
													>
														{title}
													</Checkbox>
												</div>
											)
										})
									}
								</div>
							}
						/>
						<div className={styles.buttons}>
							<Button
								type='primary'
								disabled={!selectedSalesOrderIds.length || !isModified()}
								onClick={onAdd}
							>
								Add
							</Button>
						</div>
					</div>
				)
			}}
			onCancel={onCancel}
		/>
	)
}

AddRoutePointsModal.propTypes = {
	onCancel: PropTypes.func,
	visible: PropTypes.bool,
	deliveryPlanId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onAddClicked: PropTypes.func
}

AddRoutePointsModal.defaultProps = {
	onCancel: () => {},
	visible: false,
	onAddClicked: () => {}
}

export default AddRoutePointsModal
