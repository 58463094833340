import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './AdvancedInventoryLogFilter.module.css'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import { capitalizeWord, convertEnumToString } from '../../utils'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import Input from '../input'
import moment from 'moment'
import { Users } from '../../services/api/users'
import { Button } from 'antd'

const AdvancedInventoryLogFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen
}) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [startDate, setStartDate] = useState(searchParams.startDate || null)
	const [endDate, setEndDate] = useState(searchParams.endDate || null)
	const [users, setUsers] = useState([])
	const [updatedBy, setUpdatedBy] = useState()
	const [locations, setLocations] = useState()
	const [location, setLocation] = useState()
	const [searchTerm, setSearchTerm] = useState('')
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [isLoadingUsers, setIsLoadingUsers] = useState(false)

	useEffect(() => {
		if (isFilterDropdownOpen) {
			fetchWarehouses()
			fetchUsers()
		}
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!locations?.length || !searchParams.locationId) {
			return
		}
		const location = locations.find(({ id }) => id === searchParams.locationId)

		setLocation({
			id: location.id,
			key: location.id,
			label: location.label,
			data: location
		})
	}, [locations])

	useEffect(() => {
		if (!users.length || !searchParams.updatedBy) {
			return
		}
		const user = users.find(({ id }) => id === searchParams.updatedBy)

		setUpdatedBy({
			id: user.id,
			key: user.id,
			label: user.name,
			data: user
		})
	}, [users])

	const fetchWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const fetchUsers = async () => {
		setIsLoadingUsers(true)
		const response = await Users.index()
		if (response.data) {
			setUsers(response.data.results)
		}
		setIsLoadingUsers(false)
	}

	const searchLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const searchUsers = value => {
		return Users.index({
			searchTerm: value
		})
	}

	const handleApply = async () => {
		const filters = {
			searchTerm
		}
		if (location) {
			filters.locationId = location.value
		}
		if (startDate) {
			filters.startDate = moment(startDate).startOf('day').toDate()
		}
		if (endDate) {
			filters.endDate = moment(endDate).endOf('day').toDate()
		}
		if (updatedBy) {
			filters.updatedBy = updatedBy.value
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setLocation(null)
		setStartDate(null)
		setEndDate(null)
		setUpdatedBy(null)
		clearFilter()
	}

	const isFilterEnabled = () => {
		return searchTerm.trim().length > 0 || location || startDate || endDate || updatedBy
	}

	return (
		<DropdownOverlay className='dropdown-overlay'>
			<div>
				<p className={styles.title}>Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='Start Date'
						value={startDate ? moment(startDate) : null}
						onChange={(date) => setStartDate(date?.toDate())}
						popupContainer={() => document.querySelector('.dropdown-overlay')}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='End Date'
						value={endDate ? moment(endDate) : null}
						onChange={(date) => setEndDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(startDate)}
						popupContainer={() => document.querySelector('.dropdown-overlay')}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Updated By</p>
				<CustomSearchableSelectSecondary
					isLoading={isLoadingUsers}
					defaultOptions={users}
					searchTask={searchUsers}
					customLabel={(data) => convertEnumToString(data?.name)}
					customDescription={(data) => convertEnumToString(capitalizeWord(data?.role))}
					valueIndex='id'
					descriptionIndex='role'
					placeholder='Select User'
					onChange={setUpdatedBy}
					onClear={() => setUpdatedBy(null)}
					value={updatedBy}
					allowClear={true}
				/>
			</div>

			<div>
				<p className={styles.title}>Warehouse</p>
				<CustomSearchableSelectSecondary
					searchTask={searchLocations}
					defaultOptions={locations}
					isLoading={isLoadingLocations}
					customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
					valueIndex='id'
					descriptionIndex='address'
					placeholder='Select Warehouse'
					onChange={location => setLocation(location)}
					onClear={() => setLocation(null)}
					value={location}
				/>
			</div>
			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

AdvancedInventoryLogFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func
}

export default AdvancedInventoryLogFilter
