import React from 'react'
import PropTypes from 'prop-types'
import styles from './MapLayersControl.module.css'
import { Switch } from 'antd'

const MapLayersControl = ({ checked, onChange }) => {
	return (
		<div className={styles.mapLayersControlContainer}>
			<div>
				Live traffic
			</div>
			<div className={styles.trafficLegendContainer}>
				<i>Fast</i>
				<img
					src='/img/traffic-legend.png'
					alt='traffic legend'
				/>
				<i>Slow</i>
			</div>
			<Switch size='small' checked={checked} onChange={onChange} />
		</div>
	)
}

MapLayersControl.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func
}

MapLayersControl.defaultProps = {
	checked: false,
	onChange: () => {}
}

export default MapLayersControl
