require('./PurchaseOrderFilter.less')
import styles from './PurchaseOrderFilter.module.css'
import { useContext, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Checkbox, notification } from 'antd'
import useSearchParams from '../../hooks/useSearchParams'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import Input from '../../components/input'
import { Locations } from '../../services/api/locations'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { PurchaseOrderStatus, purchaseOrderSubStatuses, LocationTypes } from '../../utils/constants'
import { convertEnumToString } from '../../utils'

const purchaseOrderStatusTabs = [
	{
		status: PurchaseOrderStatus.REQUESTED,
		title: convertEnumToString(PurchaseOrderStatus.REQUESTED)
	},
	{
		status: PurchaseOrderStatus.APPROVED,
		title: convertEnumToString(PurchaseOrderStatus.APPROVED)
	},
	{
		status: PurchaseOrderStatus.RECEIVED,
		title: convertEnumToString(PurchaseOrderStatus.RECEIVED)
	},
	{
		status: PurchaseOrderStatus.FLAGGED,
		title: convertEnumToString(PurchaseOrderStatus.FLAGGED)
	},
	{
		status: PurchaseOrderStatus.CANCELLED,
		title: convertEnumToString(PurchaseOrderStatus.CANCELLED)
	}
]

const purchaseOrderSubStatusTabs = purchaseOrderSubStatuses.map(({ status, subStatus }) => ({
	status,
	subStatus,
	title: convertEnumToString(subStatus)
}))

const PurchaseOrderFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen,
	onFilter
}) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { searchParams, setSearchParams, applyFilter, clearFilter } = useSearchParams()
	const [statuses, setStatuses] = useState(searchParams?.statuses || [])
	const [subStatuses, setSubStatuses] = useState(searchParams?.subStatuses || [])
	const [fromDate, setFromDate] = useState(searchParams.fromDate)
	const [toDate, setToDate] = useState(searchParams.toDate)
	const [approvedFromDate, setApprovedFromDate] = useState(searchParams.approvedFromDate)
	const [approvedToDate, setApprovedToDate] = useState(searchParams.approvedToDate)
	const [receivedFromDate, setReceivedFromDate] = useState(searchParams.receivedFromDate)
	const [receivedToDate, setReceivedToDate] = useState(searchParams.receivedToDate)
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [locations, setLocations] = useState([])
	const [selectedLocation, setSelectedLocation] = useState(null)
	const isFilterApplied = useMemo(() => {
		return Boolean(
			statuses.length ||
			subStatuses.length ||
			(fromDate && toDate) ||
			(approvedFromDate && approvedToDate) ||
			(receivedFromDate && receivedToDate) ||
			selectedLocation
		)
	}, [statuses, subStatuses, fromDate, toDate, approvedFromDate, approvedToDate, receivedFromDate, receivedToDate, selectedLocation])

	useEffect(() => {
		if (isFilterDropdownOpen) {
			getLocations()
		}
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (shouldResetFilter) {
			resetFilter()
		}
	}, [shouldResetFilter])

	const getLocations = async (params = {}) => {
		setIsLoadingLocations(true)
		try {
			const { data } = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY], ...params })
			if (data.results) {
				setLocations(data.results)
				if (searchParams?.locationId) {
					const selectedLocation = data.results.find(({ id }) => id === searchParams.locationId)
					setSelectedLocation(
						selectedLocation ?
							{
								key: selectedLocation.id,
								value: selectedLocation.id,
								label: selectedLocation?.label || selectedLocation?.address || 'N/A',
								data: selectedLocation
							} :
							null
					)
				}
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Get Locations',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingLocations(false)
		}
	}

	const searchLocations = async searchTerm => Locations.index({ searchTerm, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })

	const handleApply = async () => {
		const filters = {}

		if (searchParams?.searchTerm) {
			filters.searchTerm = searchParams.searchTerm
		}
		if (statuses.length) {
			filters.statuses = statuses
		}
		if (subStatuses.length) {
			filters.subStatuses = subStatuses
		}
		if (fromDate && toDate) {
			filters.fromDate = moment(fromDate).startOf('day').toDate()
			filters.toDate = moment(toDate).endOf('day').toDate()
		}
		if (approvedFromDate && approvedToDate) {
			filters.approvedFromDate = moment(approvedFromDate).startOf('day').toDate()
			filters.approvedToDate = moment(approvedToDate).endOf('day').toDate()
		}
		if (receivedFromDate && receivedToDate) {
			filters.receivedFromDate = moment(receivedFromDate).startOf('day').toDate()
			filters.receivedToDate = moment(receivedToDate).endOf('day').toDate()
		}
		if (selectedLocation?.value) {
			filters.locationId = selectedLocation.value
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
		onFilter?.(filters)
	}

	const resetFilter = () => {
		clearFilter()
		setSearchParams({})
		setStatuses([])
		setSubStatuses([])
		setFromDate(null)
		setToDate(null)
		setApprovedFromDate(null)
		setApprovedToDate(null)
		setReceivedFromDate(null)
		setReceivedToDate(null)
		setSelectedLocation(null)
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Status</p>
				<Checkbox.Group
					className={styles.inputGroup}
					value={statuses}
					onChange={statuses => {
						setStatuses(statuses)
						if (statuses.length) {
							setSubStatuses(prevState => {
								return prevState.filter(subStatus => {
									const { status } = purchaseOrderSubStatusTabs.find(tab => tab.subStatus === subStatus)
									return statuses.includes(status)
								})
							})
						}
					}}
				>
					{
						purchaseOrderStatusTabs.map(({ status, title }) => {
							return (
								<Checkbox
									key={status}
									className={styles.inputTitle}
									value={status}
								>
									{title}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Sub Status</p>
				<Checkbox.Group
					className={styles.inputGroup}
					value={subStatuses}
					onChange={setSubStatuses}
				>
					{
						purchaseOrderSubStatusTabs.map(({ status, subStatus, title }) => {
							return (
								<Checkbox
									key={subStatus}
									className={styles.inputTitle}
									value={subStatus}
									disabled={statuses.length && !statuses.includes(status)}
								>
									{title}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='Start Date'
						value={fromDate ? moment(fromDate) : null}
						onChange={date => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='End Date'
						value={toDate ? moment(toDate) : null}
						onChange={date => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Approved Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='Start Date'
						value={approvedFromDate ? moment(approvedFromDate) : null}
						onChange={date => setApprovedFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='End Date'
						value={approvedToDate ? moment(approvedToDate) : null}
						onChange={date => setApprovedToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(approvedFromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Received Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='Start Date'
						value={receivedFromDate ? moment(receivedFromDate) : null}
						onChange={date => setReceivedFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0, flex: 1 }}
						type='date'
						title='End Date'
						value={receivedToDate ? moment(receivedToDate) : null}
						onChange={date => setReceivedToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(receivedFromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Warehouse Name</p>
				<CustomSearchableSelectSecondary
					containerStyle={{ width: 424 }}
					searchTask={searchLocations}
					defaultOptions={locations}
					valueIndex='id'
					labelIndex='label'
					descriptionIndex='address'
					placeholder='Select Warehouse'
					onChange={setSelectedLocation}
					onClear={() => setSelectedLocation(null)}
					value={selectedLocation}
					allowClear={true}
					isLoading={isLoadingLocations}
				/>
			</div>
			<div className={styles.buttons}>
				{
					isFilterApplied &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					className={styles.button}
					type='primary'
					disabled={!isFilterApplied}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

PurchaseOrderFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func
}

PurchaseOrderFilter.defaultProps = {
	isFilterDropdownOpen: false,
	setIsFilterDropdownOpen: () => {}
}

export default PurchaseOrderFilter
