import { Payments } from '../../services/api/payments'
import { FETCH_INVOICES_SUCCESS, FETCH_PAYMENTS_SUCCESS, FETCH_UNPAID_ONE_TIME_INVOICE_SUCCESS, REMOVE_INVOICE, SET_INVOICE_TO_EDIT } from './action-types'

export function fetchInvoices ({ page, pageSize, status, searchTerm, cycleStartDate, cycleEndDate, companyId }) {
	return async (dispatch) => {
		const response = await Payments.billingInvoiceIndex({ page, pageSize, status, searchTerm, cycleStartDate, cycleEndDate, companyId })
		dispatch({ type: FETCH_INVOICES_SUCCESS, payload: { data: response.data } })
	}
}

export function fetchPayments ({ page, pageSize, status, searchTerm, cycleStartDate, cycleEndDate, companyId, method, type }) {
	return async (dispatch) => {
		const response = await Payments.index({ page, pageSize, status, searchTerm, cycleStartDate, cycleEndDate, companyId, method, type })
		dispatch({ type: FETCH_PAYMENTS_SUCCESS, payload: { data: response.data } })
	}
}

export function setInvoiceToEdit (invoice) {
	return (dispatch) => {
		dispatch({ type: SET_INVOICE_TO_EDIT, payload: { data: invoice } })
	}
}

export function removeInvoice (invoiceId) {
	return (dispatch) => {
		dispatch({ type: REMOVE_INVOICE, payload: { data: invoiceId } })
	}
}

export function fetchUnpaidOneTimeInvoicesForCompany (companyId) {
	return async (dispatch) => {
		const response = await Payments.fetchUnpaidOneTimeInvoiceForCompany(companyId)
		dispatch({ type: FETCH_UNPAID_ONE_TIME_INVOICE_SUCCESS, payload: { data: response.data } })
	}
}
