import { Distributors } from '../../services/api/distributors'
import {
	ADD_DISTRIBUTOR_SUCCESS,
	FETCH_DISTRIBUTORS_SUCCESS,
	FETCH_DISTRIBUTOR_DETAILS_SUCCESS,
	FETCH_DISTRIBUTOR_METRICS_SUCCESS,
	FETCH_DISTRIBUTOR_TREND_SUCCESS,
	UPDATE_DISTRIBUTOR_SUCCESS,
	FETCH_CUSTOMER_TAGS_SUCCESSS
} from '../distributors/action-types'

export function fetchDistributors ({
	page,
	disabled,
	searchTerm,
	types,
	type,
	fromDate,
	toDate,
	lastOrderFromDate,
	lastOrderToDate,
	noOrderSinceDate,
	customerTagId,
	tag,
	district,
	division,
	minOrderFrequency,
	maxOrderFrequency,
	minSalesAmount,
	maxSalesAmount,
	accountManagerId,
	sortLastOrderCreatedAt,
	salesOrderCount,
	productId,
	sources
}) {
	return async (dispatch) => {
		const response = await Distributors.index({
			page,
			disabled,
			searchTerm,
			types,
			type,
			fromDate,
			toDate,
			lastOrderFromDate,
			lastOrderToDate,
			noOrderSinceDate,
			customerTagId,
			tag,
			district,
			division,
			minOrderFrequency,
			maxOrderFrequency,
			minSalesAmount,
			maxSalesAmount,
			accountManagerId,
			sortLastOrderCreatedAt,
			salesOrderCount,
			productId,
			sources
		})
		dispatch({ type: FETCH_DISTRIBUTORS_SUCCESS, payload: response.data })
	}
}

export function addDistributor (params) {
	return async (dispatch) => {
		const response = await Distributors.create(params)
		dispatch({ type: ADD_DISTRIBUTOR_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function fetchDistributorDetails (id) {
	return async (dispatch) => {
		const response = await Distributors.single(id)
		dispatch({ type: FETCH_DISTRIBUTOR_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateDistributor (id, params) {
	return async (dispatch) => {
		const response = await Distributors.update(id, params)
		dispatch({ type: UPDATE_DISTRIBUTOR_SUCCESS, payload: response.data })
	}
}

export function fetchDistributorMetrics ({ type }) {
	return async (dispatch) => {
		const response = await Distributors.metrics({ type })
		dispatch({ type: FETCH_DISTRIBUTOR_METRICS_SUCCESS, payload: response.data })
	}
}

export function fetchDistributorTrend ({ year }) {
	return async (dispatch) => {
		const response = await Distributors.trend({ year })
		dispatch({ type: FETCH_DISTRIBUTOR_TREND_SUCCESS, payload: response.data })
	}
}

export function fetchCustomerTags ({ name, includeDefaultTags, defaultTagsOnly } = {}) {
	return async dispatch => {
		const response = await Distributors.customerTags({ name, includeDefaultTags, defaultTagsOnly })
		dispatch({ type: FETCH_CUSTOMER_TAGS_SUCCESSS, payload: { customerTags: response.data } })
		return response.data
	}
}
