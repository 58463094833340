import { TransportVendors } from '../../services/api/transport-vendors'
import {
	ADD_TRANSPORT_VENDOR_SUCCESS,
	FETCH_TRANSPORT_VENDORS_SUCCESS,
	FETCH_TRANSPORT_VENDOR_DETAILS_SUCCESS,
	UPDATE_TRANSPORT_VENDOR_SUCCESS,
	UPDATE_TRANSPORT_VENDOR_VEHICLE_SUCCESS
} from './action-types'

export function fetchTransportVendors ({
	page,
	disabled,
	searchTerm,
	is3pl
}) {
	return async (dispatch) => {
		const response = await TransportVendors.index({
			page,
			disabled,
			searchTerm,
			is3pl
		})
		dispatch({ type: FETCH_TRANSPORT_VENDORS_SUCCESS, payload: response.data })
	}
}

export function addTransportVendor (params) {
	return async (dispatch) => {
		const response = await TransportVendors.create(params)
		dispatch({ type: ADD_TRANSPORT_VENDOR_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function fetchTransportVendorDetails (id) {
	return async (dispatch) => {
		const response = await TransportVendors.single(id)
		dispatch({ type: FETCH_TRANSPORT_VENDOR_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateTransportVendor (id, params) {
	return async (dispatch) => {
		const response = await TransportVendors.update(id, params)
		dispatch({ type: UPDATE_TRANSPORT_VENDOR_SUCCESS, payload: response.data })
	}
}

export function updateTransportVendorVehicle (id, vehicleId, params) {
	return async (dispatch) => {
		const response = await TransportVendors.updateVehicle(id, vehicleId, params)
		dispatch({ type: UPDATE_TRANSPORT_VENDOR_VEHICLE_SUCCESS, payload: { ...response.data, vendorId: id } })
	}
}
