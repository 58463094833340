import { useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import Input from '../input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './ReportFilter.module.css'
import { industries, InventoryEditions, paths } from '../../utils/constants'
import DropdownOverlay from '../dropdown-overlay'
import CustomSelectSecondary from '../custom-select-secondary'
import { useSelector } from 'react-redux'
import { eCommerceReportTypeOptions, getFormattedReportType, ReportTypes } from '../../utils/reports'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { Button } from 'antd'

const generalReportTypeOptions = [
	{
		label: getFormattedReportType(ReportTypes.ORDER_FULFILLMENT),
		value: ReportTypes.ORDER_FULFILLMENT
	},
	{
		label: getFormattedReportType(ReportTypes.PENDING_ORDERS),
		value: ReportTypes.PENDING_ORDERS
	},
	{
		label: getFormattedReportType(ReportTypes.TRACEABILITY),
		value: ReportTypes.TRACEABILITY
	},
	{
		label: getFormattedReportType(ReportTypes.NATIONAL_ON_HAND_STOCK),
		value: ReportTypes.NATIONAL_ON_HAND_STOCK
	},
	{
		label: getFormattedReportType(ReportTypes.BY_LOCATION_ON_HAND_STOCK),
		value: ReportTypes.BY_LOCATION_ON_HAND_STOCK
	},
	{
		label: getFormattedReportType(ReportTypes.TRIPS_PER_VEHICLE),
		value: ReportTypes.TRIPS_PER_VEHICLE
	},
	{
		label: getFormattedReportType(ReportTypes.TRIPS_SUMMARY),
		value: ReportTypes.TRIPS_SUMMARY
	},
	{
		label: getFormattedReportType(ReportTypes.OPERATING_TIME_PER_VEHICLE),
		value: ReportTypes.OPERATING_TIME_PER_VEHICLE
	},
	{
		label: getFormattedReportType(ReportTypes.OPERATING_TIME_SUMMARY),
		value: ReportTypes.OPERATING_TIME_SUMMARY
	},
	{
		label: getFormattedReportType(ReportTypes.DISTANCE_PER_VEHICLE),
		value: ReportTypes.DISTANCE_PER_VEHICLE
	},
	{
		label: getFormattedReportType(ReportTypes.IDLE_TIME_SUMMARY),
		value: ReportTypes.IDLE_TIME_SUMMARY
	},
	{
		label: getFormattedReportType(ReportTypes.REFUEL_HISTORY),
		value: ReportTypes.REFUEL_HISTORY
	},
	{
		label: getFormattedReportType(ReportTypes.TRIP_ALLOWANCE),
		value: ReportTypes.TRIP_ALLOWANCE
	}
]

const ReportFilter = ({
	setIsFilterDropdownOpen
}) => {
	const shouldResetFilter = useContext(GlobalFilterContext)
	const router = useRouter()
	const { companyDetails } = useSelector(state => state.authReducer)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [fromDate, setFromDate] = useState(searchParams.fromDate || null)
	const [toDate, setToDate] = useState(searchParams.toDate || null)
	const [type, setType] = useState(searchParams.type)
	const [searchTerm, setSearchTerm] = useState('')

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	const isFilterEnabled = () => {
		return searchTerm || (fromDate && toDate) || type
	}

	const handleApply = async () => {
		const filters = {
			searchTerm
		}
		if (fromDate && toDate) {
			filters.fromDate = fromDate
			filters.toDate = toDate
		}
		if (type) {
			filters.type = type
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
		router.push(paths.REPORTS)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setType()
		clearFilter()
	}

	const modifiedReportTypeOptions = useMemo(() => {
		if (companyDetails) {
			if (companyDetails.industry === industries.E_COMMERCE) {
				const options = []
				for (const option of eCommerceReportTypeOptions) {
					if (option.children) {
						for (const child of option.children) {
							options.push(child)
						}
					} else {
						options.push(option)
					}
				}
				return options
			} else {
				if (companyDetails.isInventoryEnabled === false || companyDetails.inventoryEdition !== InventoryEditions.ENTERPRISE) {
					const inventoryReportTypes = [ReportTypes.BY_LOCATION_ON_HAND_STOCK, ReportTypes.NATIONAL_ON_HAND_STOCK, ReportTypes.TRACEABILITY]
					return generalReportTypeOptions.filter(option => !inventoryReportTypes.includes(option.value))
				}
				return generalReportTypeOptions
			}
		}
		return generalReportTypeOptions
	}, [companyDetails])

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='From'
						type='date'
						value={fromDate ? moment(fromDate) : null }
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						title='To'
						type='date'
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Report Type</p>
				<CustomSelectSecondary
					style={{ background: '#F7F7F7', borderRadius: 0 }}
					onChange={option => setType(option)}
					value={type}
					title='Select Report Type'
					options={modifiedReportTypeOptions}
				/>
			</div>

			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

export default ReportFilter
