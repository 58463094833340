import moment from 'moment'
import styles from './PakistanPostSlip.module.css'
import { convertEnumToString, getFormattedCurrency } from '../../utils'
import { convertWeightUnit } from '../../utils/units'
import { getItemTotals } from '../../utils/sales-orders'

const PakistanPostSlip = ({ data = [] }) => {

	return (
		<>
			<div className={styles.page}>
				<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					{
						data.map((datum, index) => {
							const {
								pickUpLocation,
								location,
								createdAt,
								paymentMethod,
								distributor,
								internalId,
								deliveryConsignment,
								salesOrderItems,
								referrer,
								totalDueAmount,
								totalPaidAmount
							} = datum
							const owingAmount = (+totalDueAmount || 0) - (+totalPaidAmount || 0)
							const totalWeight = (salesOrderItems ? salesOrderItems.reduce((acc, { totalWeight, totalWeightUnit }) =>
								acc + +convertWeightUnit(totalWeight, totalWeightUnit.toLowerCase())
							, 0) : 0).toFixed(2)
							const products = salesOrderItems.map(salesOrderItem => {
								const { totalQuantity, totalPrice } = getItemTotals(salesOrderItem, 'quantity')
								return `{${salesOrderItem.product.name} (QTY: ${totalQuantity} Piece) (Total Price: ${getFormattedCurrency(totalPrice)})}`
							}).join(', ')

							return (
								<>
									<div style={{ display: 'flex', flexDirection: 'column', height: '50%', border: '1px solid black', padding: '4px 0', gap: 8, marginLeft: '6mm', marginRight: '6mm' }} key={datum.id}>
										<div style={{ fontSize: 8, alignSelf: 'center', textDecoration: 'underline' }}>
											<span style={{ fontWeight: 'bold' }}>Special Note for CONSIGNEE:</span> (1) Please don&apos;t accept, if shipment is not intact. (2) Please don&apos;t open the parcel before payment. (3) Incase of any defects/complaints in parcel, please contact the shipper.
										</div>
										<div style={{ display: 'flex', fontSize: 12, gap: 4 }}>
											<div style={{ border: '1px solid black', padding: 4, paddingBottom: 12, marginLeft: 4, maxWidth: 200 }}>
												<div>
													<span style={{ fontWeight: 'bold' }}>Shipper: </span>
												    {referrer?.name || ''}
												</div>
												<div style={{ marginTop: 4 }}>
													{pickUpLocation.address}
												</div>
											</div>
											<div style={{ flex: 1, width: '100%' }}>
												<table border='1' style={{ width: '100%', borderRightStyle: 'hidden' }}>
													<tr>
														<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Date</td>
														<td style={{ padding: 2, paddingBottom: 6 }}>{moment(createdAt).format('DD-MM-YYYY')}</td>
														<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Time</td>
														<td style={{ padding: 2, paddingBottom: 6 }}>{moment(createdAt).format('HH:mm A')}</td>
													</tr>
													<tr>
														<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Service</td>
														<td style={{ padding: 2, paddingBottom: 6 }}>{convertEnumToString(paymentMethod.toLowerCase())}</td>
														<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Weight</td>
														<td style={{ padding: 2, paddingBottom: 6 }}>{totalWeight} KG</td>
													</tr>
													<tr>
														<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Origin</td>
														<td style={{ padding: 2, paddingBottom: 6 }}>{pickUpLocation.district}</td>
														<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Destination</td>
														<td style={{ padding: 2, paddingBottom: 6 }}>{location.district}</td>
													</tr>
													<tr>
														<td colSpan='2' style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>COD Amount</td>
														<td colSpan='2' style={{ padding: 2, paddingBottom: 6 }}>{getFormattedCurrency(owingAmount)}</td>
													</tr>
													<tr>
														<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Remarks</td>
														<td colSpan='3' style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>IN CASE OF ANY ISSUE CALL SHIPPER 0302-0482273</td>
													</tr>
												</table>
											</div>
										</div>
										<div style={{ width: '100%', fontSize: 12 }}>
											<table border='1' style={{ width: '100%', borderLeftStyle: 'hidden', borderRightStyle: 'hidden' }}>
												<tr>
													<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Name</td>
													<td style={{ padding: 2, paddingBottom: 6 }}>{distributor.name}</td>
													<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Phone Number</td>
													<td style={{ padding: 2, paddingBottom: 6 }}>{distributor.phone}</td>
													<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Post Office Address</td>
													<td style={{ padding: 2, paddingBottom: 6 }}><div style={{ width: 200 }} /></td>
												</tr>
												<tr>
													<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Address</td>
													<td style={{ padding: 2, paddingBottom: 6 }}>{location.label || location.address || ''}</td>
													<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Order Number</td>
													<td style={{ padding: 2, paddingBottom: 6 }}>{internalId}</td>
													<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>VPL Number</td>
													<td style={{ padding: 2, paddingBottom: 6 }}>{deliveryConsignment?.['vplNumber'] || ''}</td>
												</tr>
												<tr>
													<td style={{ padding: 2, paddingBottom: 6, fontWeight: 'bold' }}>Products</td>
													<td colSpan='5' style={{ padding: 2, paddingBottom: 6 }}>{products}</td>
												</tr>
												<tr>
													<td colSpan='5' style={{ padding: 2, paddingBottom: 6, direction: 'rtl', fontSize: 10, lineHeight: 1.8 }}>
												برائے میربانی اگر بندہ گھرپر یا دئیے گئے ایڈریس پر موجود نہ ہوتو بندہ کوکال الزمی کرلیں شکریہ
													</td>
													<td style={{ padding: 2, paddingBottom: 6, direction: 'rtl', fontSize: 10, lineHeight: 1.8 }}>:پوسٹ مین کے نام تاکید</td>
												</tr>
											</table>
										</div>
										<div style={{ display: 'flex', flexDirection: 'column', gap: 20, padding: '0 24px' }}>
											<div style={{ fontSize: 18, fontWeight: 'bold', alignSelf: 'center' }}>
											(For Post Office)
											</div>
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
													<div style={{ border: '1px solid black', height: 84, width: 84 }} />
													<div style={{ border: '1px solid black', height: 84, width: 84 }} />
												</div>
												<div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
													<div style={{ border: '1px solid black', height: 84, width: 84 }} />
													<div style={{ border: '1px solid black', height: 84, width: 84 }} />
												</div>
												<div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
													<div style={{ border: '1px solid black', height: 84, width: 84 }} />
													<div style={{ border: '1px solid black', height: 84, width: 84 }} />
												</div>
											</div>
										</div>
									</div>
									{
										index === 0 &&
										<div style={{ width: '100%', height: '1px', background: 'black', margin: '6mm' }} />
									}
								</>
							)
						})
					}
				</div>
			</div>
		</>
	)
}

export default PakistanPostSlip
