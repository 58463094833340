import moment from 'moment'
import { getCurrencyCode, isNotEmpty } from '../../utils'

export const Pdf = {
	downloadSalesOrder: async (id) => {
		const link = document.createElement('a')
		link.target = '_blank'
		link.href = `/pdf/sales-orders/${id}?timezone=${moment.tz.guess()}&currency=${getCurrencyCode()}`
		link.click()
		link.remove()
	},

	downloadSalesOrders: async ({ ids, toDate, fromDate, statuses, isStockTransfer, dateRangeType, columns, industry }) => {
		const link = document.createElement('a')
		let href = `/pdf/sales-orders?timezone=${moment.tz.guess()}&currency=${getCurrencyCode()}`
		if (ids) {
			href += `&ids=${ids.join(',')}`
		}
		if (toDate) {
			href += `&toDate=${toDate.toISOString()}`
		}
		if (fromDate) {
			href += `&fromDate=${fromDate.toISOString()}`
		}
		if (statuses) {
			href += `&statuses=${statuses.join(',')}`
		}
		if (isNotEmpty(isStockTransfer)) {
			href += `&isStockTransfer=${isStockTransfer}`
		}
		if (dateRangeType) {
			href += `&dateRangeType=${dateRangeType}`
		}
		if (columns) {
			href += `&columns=${columns}`
		}
		if (industry) {
			href += `&industry=${industry}`
		}
		link.target = '_blank'
		link.href = href
		link.click()
		link.remove()
	},
	downloadReportDetails: async (id) => {
		const link = document.createElement('a')
		link.target = '_blank'
		link.href = `/pdf/reports/${id}?timezone=${moment.tz.guess()}&currency=${getCurrencyCode()}`
		link.click()
		link.remove()
	},
	downloadDeliveryPlan: async (id) => {
		const link = document.createElement('a')
		link.target = '_blank'
		link.href = `/pdf/delivery-plans/${id}?timezone=${moment.tz.guess()}&currency=${getCurrencyCode()}`
		link.click()
		link.remove()
	}
}
