import { useEffect, useState } from 'react'
import FullPageLoader from '../components/full-page-loader'
import { useRouter } from 'next/router'
import getFirebaseApp from './initFirebase'
import { getAuth } from 'firebase/auth'

const app = getFirebaseApp()
const auth = getAuth(app)

export const AuthAction = {
	REDIRECT_TO_LOGIN: 'redirect_to_login',
	REDIRECT_TO_HOME: 'redirect_to_home',
	RENDER: 'render'
}

const withAuth = ({
	onAuth,
	onUnauth,
	authPageURL
}) => (Component) => (props) => {
	const router = useRouter()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		checkAuthState()
	}, [])

	const checkAuthState = () => {
		auth.onAuthStateChanged(user => {
			if (user) {
				if (onAuth === AuthAction.REDIRECT_TO_HOME) {
					router.push('/')
				} else if (onAuth === AuthAction.RENDER) {
					setIsLoading(false)
				} else {
					setIsLoading(false)
				}
			} else {
				if (onUnauth === AuthAction.REDIRECT_TO_LOGIN) {
					const redirect = authPageURL ? authPageURL() : '/login'
					router.push(redirect)
				} else if (onUnauth === AuthAction.RENDER) {
					setIsLoading(false)
				} else {
					setIsLoading(false)
				}
			}
		})
	}

	return (
		isLoading ?
			<FullPageLoader /> :
			<Component {...props} />
	)
}

export default withAuth
