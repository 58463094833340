import { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Select } from 'antd'
import useSearchParams from '../../hooks/useSearchParams'
import CustomCascader from '../custom-cascader'
require('./GlobalProductFilter.less')
import styles from './GlobalProductFilter.module.css'
import { useSelector } from 'react-redux'
import DropdownOverlay from '../dropdown-overlay'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { useRouter } from 'next/router'
import { StandardInventoryProductTypes, industries } from '../../utils/constants'
import { Products } from '../../services/api/products'
import Input from '../input'
import moment from 'moment'

const GlobalProductFilter = ({
	setIsFilterDropdownOpen
}) => {
	const router = useRouter()
	const { type } = router.query
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { categories } = useSelector(state => state.productsReducer)
	const { companyDetails } = useSelector(state => state.authReducer)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [categoryOptions, setCategoryOptions] = useState()
	const [selectedCategories, setSelectedCategories] = useState(
		[
			searchParams.category,
			searchParams.subCategory1,
			searchParams.subCategory2,
			searchParams.subCategory3,
			searchParams.subCategory4,
			searchParams.subCategory5
		].filter((category) => category)
	)
	const [creationFromDate, setCreationFromDate] = useState(searchParams.creationFromDate)
	const [creationToDate, setCreationToDate] = useState(searchParams.creationToDate)
	const [active, setActive] = useState((searchParams.all || !searchParams.deleted) || false)
	const [inactive, setInactive] = useState((searchParams.all || searchParams.deleted) || false)
	const [simple, setSimple] = useState(true)
	const [base, setBase] = useState(true)
	const [variable, setVariable] = useState(true)
	const [specifications, setSpecifications] = useState([])
	const [selectedAttributes, setSelectedAttributes] = useState([])
	const [selectedAttributeValues, setSelectedAttributeValues] = useState({})

	const selectedSpecifications = useMemo(() => {
		return specifications.filter(spec => selectedAttributes.includes(spec.key))
	}, [selectedAttributes])

	useEffect(() => {
		createCategoryOptions()
	}, [categories])

	useEffect(() => {
		if (Object.keys(searchParams).length === 0) {
			setActive(true)
			setInactive(true)
		} else {
			if (searchParams.deleted === undefined && searchParams.all === undefined) {
				setActive(true)
				setInactive(true)
			} else {
				setActive((searchParams.deleted === false && searchParams.all === undefined) || searchParams.all === true)
				setInactive((searchParams.deleted === true && searchParams.all === undefined) || searchParams.all === true)
			}
		}
	}, [searchParams])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		getDefaultSpecifications()
	}, [])

	const getDefaultSpecifications = async () => {
		const { data } = await Products.fetchSpecifications({ types: ['STRING', 'NUMBER'] })
		setSpecifications(data.filter(spec => spec.options && spec.options.length > 0))
		return data
	}

	const handleApply = async () => {
		const [category, subCategory1, subCategory2, subCategory3, subCategory4, subCategory5] = selectedCategories
		const filters = {
			searchTerm: searchParams.searchTerm,
			category,
			subCategory1,
			subCategory2,
			subCategory3,
			subCategory4,
			subCategory5,
			all: true
		}
		if (active && inactive) {
			filters.all = true
			filters.deleted = undefined
		} else if (active) {
			filters.all = undefined
			filters.deleted = false
		} else if (inactive) {
			filters.all = undefined
			filters.deleted = true
		}

		if (simple && variable && base) {
			filters.excludeBase = undefined
			filters.excludeVariants = undefined
			filters.excludeSimple = undefined
		} else if (simple && !variable && base) {
			filters.excludeBase = undefined
			filters.excludeVariants = true
			filters.excludeSimple = undefined
		} else if (!simple && variable && base) {
			filters.excludeBase = undefined
			filters.excludeVariants = undefined
			filters.excludeSimple = true
		} else if (simple && !variable && !base) {
			filters.excludeBase = true
			filters.excludeVariants = true
			filters.excludeSimple = undefined
		} else if (!simple && variable && !base) {
			filters.excludeBase = true
			filters.excludeVariants = undefined
			filters.excludeSimple = true
		} else if (!simple && !variable && base) {
			filters.excludeBase = undefined
			filters.excludeVariants = true
			filters.excludeSimple = true
		} else if (simple && variable && !base) {
			filters.excludeBase = true
			filters.excludeVariants = undefined
			filters.excludeSimple = undefined
		} else {
			filters.excludeBase = undefined
			filters.excludeVariants = undefined
			filters.excludeSimple = undefined
		}

		if (Object.keys(selectedAttributeValues).length > 0) {
			const attributeFilters = []
			Object.keys(selectedAttributeValues).forEach(key => {
				const values = selectedAttributeValues[key]
				if (values.length > 0) {
					attributeFilters.push(`${key}:${values.join(',')}`)
				} else {
					attributeFilters.push(`${key}:${undefined}`)
				}
			})
			filters.attributes = attributeFilters
		}

		if (creationFromDate && creationToDate) {
			filters.creationFromDate = moment(creationFromDate).startOf('day').toDate()
			filters.creationToDate = moment(creationToDate).endOf('day').toDate()
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSelectedCategories([])
		setActive(true)
		setInactive(true)
		setSimple(true)
		setVariable(true)
		setSelectedAttributes([])
		setSelectedAttributeValues({})
		setCreationFromDate(null)
		setCreationToDate(null)
		clearFilter()
	}

	const createCategoryOptions = () => {
		const options = []
		const createOptions = (categories, options) => {
			Object.keys(categories).forEach(categoryKey => {
				const option = {
					value: categoryKey,
					label: categories[categoryKey].label,
					children: []
				}
				options.push(option)
				if (categories[categoryKey].subCategories) {
					createOptions(categories[categoryKey].subCategories, option.children)
				}
			})
		}
		createOptions(categories, options)
		setCategoryOptions(options)
	}

	return (
		<DropdownOverlay>
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='Start Date'
						value={creationFromDate ? moment(creationFromDate) : null }
						onChange={(date) => setCreationFromDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() > moment(creationToDate)}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='End Date'
						value={creationToDate ? moment(creationToDate) : null }
						onChange={(date) => setCreationToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(creationFromDate)}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Categories</p>
				<CustomCascader
					className='product-category-cascader'
					allowClear={false}
					placeholder='Select a category and/or sub-categories...'
					changeOnSelect={true}
					options={categoryOptions}
					value={selectedCategories}
					onChange={setSelectedCategories}
				/>
			</div>
			{
				type !== StandardInventoryProductTypes.RAW_MATERIAL && companyDetails?.industry === industries.E_COMMERCE ?
					<div>
						<p className={styles.title}>Product Type</p>
						<div className={styles.inputGroup}>
							<Checkbox
								className={styles.inputTitle}
								checked={simple}
								onChange={e => setSimple(e.target.checked)}
							>
							Simple
							</Checkbox>
							<Checkbox
								className={styles.inputTitle}
								checked={base}
								onChange={e => setBase(e.target.checked)}
							>
							Base
							</Checkbox>
							<Checkbox
								className={styles.inputTitle}
								checked={variable}
								onChange={e => setVariable(e.target.checked)}
							>
							Variable
							</Checkbox>
						</div>
					</div> : null
			}
			<div>
				<p className={styles.title}>Status</p>
				<div className={styles.inputGroup}>
					<Checkbox
						className={styles.inputTitle}
						checked={active}
						onChange={e => setActive(e.target.checked)}
					>
						Active
					</Checkbox>
					<Checkbox
						className={styles.inputTitle}
						checked={inactive}
						onChange={e => setInactive(e.target.checked)}
					>
						Inactive
					</Checkbox>
				</div>
			</div>
			{
				type !== StandardInventoryProductTypes.RAW_MATERIAL && companyDetails?.industry === industries.E_COMMERCE ?
					<div>
						<p className={styles.title}>Attributes</p>
						<Select
							mode='multiple'
							allowClear
							style={{ width: '100%' }}
							placeholder='Select Attributes'
							className='product-attribute-select'
							value={selectedAttributes}
							onChange={(values) => {
								setSelectedAttributes(values)
								const newSelectedAttributeValues = {}
								values.forEach(value => {
									newSelectedAttributeValues[value] = selectedAttributeValues[value] || []
								})
								setSelectedAttributeValues(newSelectedAttributeValues)
							}}
						>
							{
								specifications.map(spec => {
									return (
										<Select.Option key={spec.key} value={spec.key}>
											{spec.label}
										</Select.Option>
									)
								})
							}
						</Select>
						{
							selectedSpecifications.length > 0 &&
							<div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 12 }}>
								{
									selectedSpecifications.map(spec => {
										return (
											<div key={spec.key} className={styles.specification}>
												<p className={styles.title} style={{ fontSize: 14 }}>{spec.label}</p>
												<Select
													mode='multiple'
													allowClear
													style={{ width: '100%' }}
													placeholder='Select Options'
													className='product-attribute-option-select'
													value={selectedAttributeValues[spec.key]}
													onChange={values => setSelectedAttributeValues({ ...selectedAttributeValues, [spec.key]: values })}
												>
													{
														spec.options.map(option => {
															return (
																<Select.Option key={option.value} value={option.value}>
																	{option.value}
																</Select.Option>
															)
														})
													}
												</Select>
											</div>
										)
									})
								}
							</div>
						}
					</div> : null
			}
			<div className={styles.buttons}>
				<Button
					className={`${styles.button} ${styles.resetButton}`}
					onClick={resetFilter}
				>
					<span className={styles.buttonText}>
						Reset All
					</span>
				</Button>
				<Button
					type='primary'
					className={styles.button}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

GlobalProductFilter.propTypes = {
	setIsFilterDropdownOpen: PropTypes.func
}

export default GlobalProductFilter
